import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { licenseActions } from "../../../_actions";
import { SideBar } from "../../../_components/Admin/More/License";
import { Header } from "../../../_components/Admin/Header";
import ButtonNews from "../../../_components/Button/ButtonNews";
import { history } from "../../../_helpers";
import { PopUpYesNo } from "../../../_components/Popup";
import "./style.scss";
import { isEmpty } from "lodash";
import APIBase from "../../../_base/APIBase";
import API from "../../../_apis/APIConstants";
import { alertActions } from "../../../_actions/alerts";
import { licenseConstants } from "../../../_constants";
import { Alert } from "../../../_components/Alert";
import $ from "jquery";
import { Fragment } from "react";

function HomePageLicense() {
  const dispatch = useDispatch();
  const licenses = useSelector((state) => state.licenses);
  const authentication = useSelector((state) => state.authentication);
  const packages_available = licenses.listCurentLicenses?.package_list;
  const [packageSelected, setPackageSelected] = useState({ id: "" });
  const [isLoading, setLoading] = useState(false);

  const alert = useSelector((state) => state.alert);

  useEffect(() => {
    dispatch(licenseActions.getCurrentLicense());
  }, []);

  const isStudent = authentication.role == "student";

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
  }, [licenses]);

  // Handle Action Package
  const handleActionPackage = (packageItem) => {
    switch (packageItem.status) {
      case "active":
      case "expired":
        history.push(`/${authentication.role}/more/license/add`);
        break;
      case "bought":
        setPackageSelected(packageItem);
        break;
      default:
        break;
    }
  };

  // Render Packages Available
  const renderPackagesAvailable = (packageItem) => {
    let packageItemStatus = "";
    let packageItemBtn = "";
    let colorTextStatus = "";

    switch (packageItem?.status.toLowerCase()) {
      case "active":
        packageItemStatus = "Đang sử dụng";
        packageItemBtn = !packageItem.isForever ? "Mua thêm" : "";
        colorTextStatus = "#00CC83";
        break;
      case "bought":
        packageItemStatus = "Chưa kích hoạt";
        packageItemBtn = isStudent ? "Kích hoạt" : "Gán";
        colorTextStatus = "#008ae6";
        break;
      case "expired":
        packageItemStatus = "Đã hết hạn";
        packageItemBtn = isStudent ? "Gia hạn" : "Gia hạn";
        colorTextStatus = "#BE1D2E";
        break;
      default:
        break;
    }

    return (
      <div
        key={packageItem?.id}
        className="package-item flex-align bg-white border_1_light"
      >
        <div className="flex-center package-img">
          <img
            src="/assets/images/license/img_package.png"
            alt="Img Package"
            className="contain_image"
          />
        </div>

        <div className="flex-column package-infor flex-1">
          <div className="font_myriad_bold package-infor_name ">
            {packageItem?.package_name}{" "}
            <span
              className="font_myriad_semi_bold_normal"
              style={{ color: colorTextStatus }}
            >{`(${packageItemStatus})`}</span>
          </div>

          <div className="color-text package-infor_date flex-align col-100 pd-top-1-pc">
            {packageItem.status != "expired" ? (
              <Fragment>
                <div className="flex-align">
                  {packageItem.status == "active"
                    ? "Ngày kích hoạt: " + packageItem?.activated_date
                    : "Ngày mua: " + packageItem?.bought_date}
                </div>

                <div className="flex-1 flex-center">-</div>
              </Fragment>
            ) : null}
            <div className="flex-align">
              {(packageItem.status == "active" ||
              packageItem.status == "expired"
                ? "Ngày hết hạn: "
                : "Thời hạn: ") +
                (packageItem?.isForever
                  ? "Vĩnh viễn"
                  : packageItem.status == "active" ||
                    packageItem.status == "expired"
                  ? packageItem?.end_date
                  : packageItem?.duration_expired)}
            </div>
          </div>

          <div className="flex-align package-infor_btn pd-top-1-pc">
            {!!packageItemBtn ? (
              <button
                onClick={() => handleActionPackage(packageItem)}
                className="btn-line-blue"
              >
                {packageItemBtn}
              </button>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  // Navigate to history package page
  const historyPackageComponent = () => {
    return (
      <Link
        className="mg-right-1"
        to={`/${authentication.role}/more/license/history`}
      >
        <button className="btn-line-blue">Lịch sử</button>
      </Link>
    );
  };

  // Handle upgrade Package
  const handleUpgradePackage = async () => {
    let urlApi = `${API.baseURL}${API.post_package_active}`;
    try {
      const result = await APIBase.apiBaseCaller("POST", urlApi, {
        id: packageSelected?.payment_id,
      });

      if (result?.status) {
        dispatch(licenseActions.getCurrentLicense());
      } else {
        dispatch(
          alertActions.success({
            message: result.msg.toString(),
            screen: licenseConstants.SCREEN_LICENSE_ADD_CODE,
          })
        );
      }
    } catch (error) {
      dispatch(
        alertActions.success({
          message: error.toString(),
          screen: licenseConstants.SCREEN_LICENSE_ADD_CODE,
        })
      );
    } finally {
      setPackageSelected({ id: "" });
    }
  };

  return (
    <div className="sunE-right-container license-page flex-column">
      <Header
        title={"Nâng cấp tài khoản"}
        isBack
        component={historyPackageComponent()}
      />
      <div className="flex-m sunE-content license-content scrollbar-custom  ">
        {/* <SideBar /> */}
        {alert.message &&
          alert.screen == licenseConstants.SCREEN_LICENSE_ADD_CODE && (
            <Alert alert={alert} />
          )}
        {!isLoading ? (
          <div className="flex-1 sunE-content-box flex-center-column">
            {!isEmpty(packages_available) && packages_available?.length > 0 ? (
              <div className="package-list flex-column col-100">
                {packages_available?.map((packageItem) =>
                  renderPackagesAvailable(packageItem)
                )}
              </div>
            ) : (
              <div className="text-center pd-top-3-pc">
                <img
                  src="/assets/images/license/no_payment.png"
                  alt="ico_remove_blue"
                  className="contain_image img-license-empty"
                />
                <div className=" license-text-empty font_myriad_bold">
                  Bạn chưa mua gói nào!
                </div>
                <div className="license-text-instruct">
                  Hãy click vào "Mua gói mới" để trải nghiệm.
                </div>
              </div>
            )}
          </div>
        ) : null}
      </div>
      <div className="flex-center license-btn">
        <ButtonNews
          width="16rem"
          height="3rem"
          className={"btn-line-blue flex-jus-between mg-right-2 "}
          onClick={() => {
            history.push(`/${authentication.role}/more/license/add`);
          }}
        >
          <div className="flex-center btn__icon ">
            <img
              src="/assets/images/icon/ico_cart.png"
              alt="Icon Cart"
              className="contain_image "
            />
          </div>

          <div className="fz-11-25 color-white font_myriad_bold">
            Mua gói mới
          </div>
        </ButtonNews>

        <ButtonNews
          width="16rem"
          height="3rem"
          className={"license-btn flex-jus-between"}
          border={"1px solid #00cc83"}
          onClick={() => {
            history.push(
              `/${authentication.role}/more/upgrade-account?from=license`
            );
          }}
        >
          <div className="flex-center btn__icon btn__icon-package">
            <img
              src="/assets/images/icon/ico_new_package.png"
              alt="Icon New Package"
              className="contain_image "
            />
          </div>

          <div className="fz-11-25 color-linear font_myriad_bold">
            Nhập gói đã mua
          </div>
        </ButtonNews>
      </div>

      {!isEmpty(packageSelected) && !!packageSelected?.id ? (
        <PopUpYesNo
          title="Bạn có chắc chắn muốn kích hoạt gói học tập này không?"
          message="Sau khi kích hoạt, các tính năng của gói sẽ được áp dụng và thời gian sử dụng gói sẽ bắt đầu được tính."
          labelYes="Kích hoạt"
          labelNo="Hủy"
          onClickYes={() => {
            handleUpgradePackage();
          }}
          onClickNo={() => {
            setPackageSelected({ id: "" });
          }}
        ></PopUpYesNo>
      ) : null}
    </div>
  );
}

export { HomePageLicense };
