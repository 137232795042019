import "./BenefitParent.style.scss";
import { HeaderNews } from "../../_components/Header";
import FooterNews from "../../_components/FooterNews/FooterNews";
import Slogan from "../../_components/Slogan/Slogan";
import ScrollFixed from "../../_components/ScrollFixed/ScrollFixed";
import { renderListDetail } from "../../_base/DetailListNews";
import { benefitParentLogic } from "./BenefitParent.logic";
import { homePageLogic } from "../HomePage/HomePage.logic";
import VideoBanner from "../../_components/ContentBanner/VideoBanner";
import AdvisementForm from "../../_components/AdvisementForm/AdvisementForm";
import ButtonNews from "../../_components/Button/ButtonNews";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";
import LazyLoad from "react-lazyload";
import SmartBaseScreen from "../../_base/SmartScreenBase";
import styled from "styled-components";

SmartBaseScreen.baseSetup();
const baseWidth = SmartBaseScreen.smBaseWidth,
  smFontSize = SmartBaseScreen.smFontSize;

const VideoSlider = styled.video`
  width: ${324 * baseWidth}px;
  height: ${694.8 * baseWidth}px;
  min-width: 200px;
  min-height: 429px;
`;

const VideoSliderParent = styled.div`
  border: 0.46rem solid #fff;
  width: ${324 * baseWidth}px;
  height: ${694.8 * baseWidth}px;
  min-width: 200px;
  min-height: 429px;
  overflow: hidden;
  position: relative;
  border-radius: 2rem;
  z-index: 2;
  right: 1rem;
  bottom: 0.5rem;
`;

const VideoSliderMobile = styled.video`
  width: ${414 * baseWidth}px;
  height: ${887.8 * baseWidth}px;
  min-width: 160px;
  min-height: 343px;
`;

const VideoSliderParentMobile = styled.div`
  width: ${414 * baseWidth}px;
  height: ${887.8 * baseWidth}px;
  min-width: 160px;
  min-height: 343px;
  overflow: hidden;
  position: relative;
  z-index: 2;
  right: 0.8rem;
  bottom: 1.5rem;
  border: 0.4rem solid var(--white-color);
  border-radius: 1rem;
`;

const BenefitParent = (props) => {
  let { dataContentParent, dataImgSlideParent } = benefitParentLogic();
  let { handleNavigateCurriculum } = homePageLogic();

  const renderContentList = (data) => {
    return (
      <LazyLoad
        once
        key={data.id}
        className="list_detail_parent flex-center-column"
        style={{
          borderBottom: data.id != 7 && "1px solid #c4c4c4",
        }}
      >
        <div className="list_detail_parent-title">
          <img
            src={`/assets/images/homeNews/content/parent/item_${data.id}.png`}
            alt="Image Item"
            className="contain_image img_item_detail"
          />
          {!!data?.title && <span>{data?.title}</span>}
        </div>

        <div className="detail_item_parent flex-center-column fz-11-25-mobile fz-19 col-90 col-100-mobile">
          <div className="fz-19 mg-bottom-1 text-align-center fz-11-25-mobile">
            {data?.topContent}{" "}
            {data.id == 1 ? (
              <div className="fz-19 text-align-center fz-11-25-mobile">
                <span className="color-red font_news_bold fz-11-25-mobile fz-19">
                  {data?.outstanding_1}{" "}
                </span>
                {data?.tailContent_1}{" "}
              </div>
            ) : (
              <span className="color-red font_news_bold fz-11-25-mobile fz-19">
                {data?.outstanding_1}{" "}
              </span>
            )}
            {data?.tailContent_3 && (
              <span className="fz-19 text-align-center fz-11-25-mobile">
                {data?.tailContent_3}{" "}
                <span className="color-red font_news_bold fz-11-25-mobile fz-19">
                  {data?.outstanding_3}{" "}
                </span>
              </span>
            )}
            {data?.tailContent_1_1 && (
              <div className="fz-19 text-align-center fz-11-25-mobile">
                {" "}
                {data?.tailContent_1_1}{" "}
              </div>
            )}
            {data.id != 1 && data?.tailContent_1}{" "}
            <span className="color-red font_news_bold fz-11-25-mobile fz-19">
              {data?.outstanding_2}{" "}
            </span>{" "}
            {data?.tailContent_2}{" "}
          </div>

          {data?.items && data?.items.map((item) => renderListDetail(item))}
        </div>

        {data.id != 7 && (
          <div className="col-100-mobile hide display_flex_mobile flex-center">
            <div className="col-45-mobile border-bottom-1-mobile"></div>
          </div>
        )}
      </LazyLoad>
    );
  };

  return (
    <div className="homepage bg-white commondPage">
      <HeaderNews type={TypeHeaderNewsItem.PARENT} />

      <div className="slider">
        <div className="slider_bg_parent flex-align-end  display_none_mobile">
          <div className="title_container_parent flex-column ">
            <div className=" title_detail_1_parent font-weight-500 fz-20-mobile ">
              ỨNG DỤNG TÍCH HỢP
            </div>

            <div className=" title_detail_2_parent font_news_extra_bold color-red fz-24-mobile ">
              CÔNG NGHỆ AI
            </div>

            <div className="title_detail_3_parent font_news_bold fz-20-mobile">
              giúp phụ huynh dễ dàng kèm cặp con
            </div>
          </div>

          <LazyLoad className="slider_4_full flex-align-end pos_rel">
            <img
              src="/assets/images/homeNews/slider/slider_4_full.png"
              alt="Slider 4"
              className="slider_4_img"
            />
            <div className="video_slider_container pos_abs">
              <LazyLoad className="video_slider_wrapper pos_rel">
                {/* <img
                  src="/assets/images/homeNews/slider/video_slider_parent.png"
                  alt="Video Slider Teacher"
                  className="contain_image video_slider"
                /> */}
                <VideoSliderParent className="box-shadow">
                  <VideoSlider
                    autoPlay
                    muted
                    loop
                    controls={false}
                    playsInline
                    poster="/assets/images/homeNews/slider/parent_poster.jpg"
                    src="/assets/videos/parent_video.mp4"
                    // alt="Video Slider Teacher"
                    className=" video_slider"
                  />
                </VideoSliderParent>
                <img
                  src="/assets/images/homeNews/slider/video_slider_side.png"
                  alt="Video Slider Side"
                  className="contain_image img_slider_side"
                />
              </LazyLoad>
            </div>
          </LazyLoad>
          <LazyLoad
            once
            className="icon_right_slider_container display_none_mobile"
          >
            <img
              src="/assets/images/homeNews/content/parent/ico_slider_right.png"
              alt="Icon Text Right"
              className="contain_image "
            />
          </LazyLoad>
        </div>

        {/* MOBILE */}
        <LazyLoad
          once
          className="slider_bg_parent pos_rel hide display_flex_mobile"
        >
          <img
            src="/assets/images/homeNews/slider/slider_bg_parent_mobile.png"
            alt="Img Slider Mobile"
            className="contain_image pos_abs col-100 img_slider_mobile"
          />
          <div className="slider_4_mobile pos_rel flex-center">
            <div className="title_container_parent flex-column">
              <div className="title_detail_1_parent font-weight-500  ">
                ỨNG DỤNG TÍCH HỢP
              </div>

              <div className="title_detail_2_parent font_news_extra_bold color-red">
                CÔNG NGHỆ AI
              </div>

              <div className="title_detail_3_parent font_news_bold">
                giúp phụ huynh dễ dàng kèm cặp con
              </div>
            </div>
            <div className="slider_4">
              <img
                src="/assets/images/homeNews/slider/slider_4_mobile.png"
                alt="Slider 4"
                className="slider_4_img contain_image"
              />
            </div>
            <LazyLoad className="video_slider_container pos_abs ">
              {/* <img
                src="/assets/images/homeNews/slider/video_slider_parent.png"
                alt="Video Slider Teacher"
                className="contain_image video_slider"
              /> */}
              <VideoSliderParentMobile className="box-shadow flex-center">
                <VideoSliderMobile
                  autoPlay
                  muted
                  loop
                  controls={false}
                  playsInline
                  poster="/assets/images/homeNews/slider/parent_poster.jpg"
                  src="/assets/videos/parent_video.mp4"
                  // alt="Video Slider Teacher"
                  className=" video_slider"
                />
              </VideoSliderParentMobile>
              <LazyLoad className="img_slider_side_container">
                <img
                  src="/assets/images/homeNews/slider/video_slider_side.png"
                  alt="Video Slider Side"
                  className="contain_image img_slider_side"
                />
              </LazyLoad>
            </LazyLoad>
          </div>
          <LazyLoad className="icon_right_slider_container ">
            <img
              src="/assets/images/homeNews/content/parent/ico_right_slider.png"
              alt="Icon Text Right"
              className="contain_image "
            />
          </LazyLoad>
        </LazyLoad>
      </div>

      <div className="content flex-center-column">
        <div className="content_bg_benefit flex-center-column pos_rel">
          <div className="flex-center-column col-100">
            <LazyLoad className="list_content_parent flex-column">
              {dataContentParent.map((data) => renderContentList(data))}
            </LazyLoad>
          </div>

          <div className="display_none_mobile list_icon_bg">
            <LazyLoad className="img_left_top pos_abs">
              <img
                src="/assets/images/homeNews/content/icon/ico_right_trial.png"
                alt="Icon"
                className="contain_image"
              />
            </LazyLoad>

            <LazyLoad className="img_left_center pos_abs">
              <img
                src="/assets/images/homeNews/content/parent/ico_left_parent.png"
                alt="Icon"
                className="contain_image"
              />
            </LazyLoad>
            <LazyLoad className="img_left_bottom pos_abs">
              <img
                src="/assets/images/homeNews/content/icon/ico_top_teacher.png"
                alt="Icon"
                className="contain_image"
              />
            </LazyLoad>

            <LazyLoad className="img_right_bonus pos_abs">
              <img
                src="/assets/images/homeNews/content/icon/ico_top_teacher.png"
                alt="Icon"
                className="contain_image"
              />
            </LazyLoad>
            <LazyLoad className="img_right_top pos_abs">
              <img
                src="/assets/images/homeNews/content/parent/ico_right_parent.png"
                alt="Icon"
                className="contain_image"
              />
            </LazyLoad>

            <LazyLoad className="img_right_bottom pos_abs">
              <img
                src="/assets/images/homeNews/content/icon/ico_right_trial.png"
                alt="Icon"
                className="contain_image"
              />
            </LazyLoad>
          </div>
          <div className="hide display_block_mobile list_icon_bg_mobile">
            <LazyLoad className="img_left_center pos_abs">
              <img
                src="/assets/images/homeNews/content/parent/ico_left_mobile.png"
                alt="Icon"
                className="contain_image"
              />
            </LazyLoad>

            <LazyLoad className="img_right_top pos_abs">
              <img
                src="/assets/images/homeNews/content/parent/ico_right_mobile.png"
                alt="Icon"
                className="contain_image"
              />
            </LazyLoad>

            <LazyLoad className="img_right_bottom pos_abs">
              <img
                src="/assets/images/homeNews/content/icon/ico_dots_teacher.png"
                alt="Icon"
                className="contain_image"
              />
            </LazyLoad>
          </div>
        </div>

        <div className="content_banner_top_container col-100 pos_rel">
          <LazyLoad once className="content_bg_top_banner flex-center-column">
            <img
              src={`/assets/images/homeNews/content/img_bn_top.png`}
              alt="Image Banner 3"
              className="img_banner_top"
            />

            <div className="col-100 display_none_mobile">
              <LazyLoad once className="icon_left_parent_container ">
                <img
                  src="/assets/images/homeNews/content/icon/ico_left_teacher.png"
                  alt="Icon Text Center"
                  className="contain_image"
                />
              </LazyLoad>
              <LazyLoad once className="icon_right_parent_container ">
                <img
                  src="/assets/images/homeNews/content/icon/ico_right_teacher.png"
                  alt="Icon Text Right"
                  className="contain_image "
                />
              </LazyLoad>
            </div>
          </LazyLoad>
        </div>

        <Slogan />

        <div className="display_none_mobile flex-center-column col-100 trial_parent_container">
          {/* <ButtonNews
            width="26rem"
            border={"1px solid#eb5757"}
            backgroundColor={"#eb5757"}
            className="text-align-center color-red font_news_bold  pointer_cursor"
            onClick={() => handleNavigateCurriculum()}
          >
            <div className="text-align-center color-white font_news_bold text_btn_test pointer_cursor">
              DÙNG THỬ MIỄN PHÍ
            </div>
          </ButtonNews> */}
          {/* <div className="col-100 display_none_mobile">
            <div className="icon_left_trial_container ">
              <img
                src="/assets/images/homeNews/content/icon/ico_left_trial.png"
                alt="Icon Text Center"
                className="contain_image "
              />
            </div>
            <div className="icon_right_trial_container ">
              <img
                src="/assets/images/homeNews/content/icon/ico_right_trial.png"
                alt="Icon Text Right"
                className="contain_image "
              />
            </div>
          </div> */}
        </div>

        <div className="hide display_flex_mobile col-100 flex-center pd-bottom-15">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="94%"
            height="2"
            viewBox="0 0 344 2"
            fill="none"
          >
            <path
              d="M0 1H343.5"
              stroke="black"
              strokeWidth="0.6"
              strokeDasharray="4 4"
            />
          </svg>
        </div>

        <AdvisementForm placeholder="Họ và tên học sinh" type="student" />
        {/* <div className="hide display_flex_mobile flex-center-column col-100 trial_parent_container">
          <ButtonNews
            width="26rem"
            border={"1px solid#eb5757"}
            backgroundColor={"#eb5757"}
            className="text-align-center color-red font_news_bold  pointer_cursor"
            onClick={() => handleNavigateCurriculum()}
          >
            <div className="text-align-center color-white font_news_bold text_btn_test pointer_cursor">
              DÙNG THỬ MIỄN PHÍ
            </div>
          </ButtonNews>
        </div> */}
      </div>

      <FooterNews />

      <ScrollFixed />
    </div>
  );
};

export default BenefitParent;
