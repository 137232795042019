import { useEffect, useRef, useState } from "react";
import useOutsideClick from "../../_helpers/customHook/useOutsideClick";
import { useSelector } from "react-redux";
import { classesConstants } from "../../_constants/classes";

export default function PopUpSelectCurriculum({open=false, onClose, listCurriculum=[], onSelectCurriculum}) {
  const [isOpenSelectGrade, setIsOpenSelectGrade] = useState(false)
  const [listGrade, setListGrade] = useState([])
  const [gradeSelected, setGradeSelected] = useState()
  const [listCurriculumByGrade, setListCurriculumByGrade] = useState([])
  const boxGradeRef = useRef(null)

  // const listGradeRedux = useSelector((state) => state.grades.listGrade);
  const gradeListRedux = useSelector((state) => state.classes.grade_list);
  const authentication = useSelector((state) => state.authentication);

  useOutsideClick(boxGradeRef, () => {
    if (boxGradeRef.current) {
      setIsOpenSelectGrade(false);
    }
  })

  const toggleBoxGrade = () => {
    setIsOpenSelectGrade(!isOpenSelectGrade)
  }

  const handleSelectGrade = (gradeItem) => {
    setGradeSelected(gradeItem)
    if (gradeItem?.value === 'ALL') {
      setListCurriculumByGrade(listCurriculum)
    } else if (gradeItem?.value === 'PREPARATION') {
      setListCurriculumByGrade(listCurriculum?.filter(item => item?.type_course === "test_preparation"))
    } else {
      setListCurriculumByGrade(listCurriculum?.filter(item => item?.grade_id == gradeItem?.value))
    }
  }
  
  const renderIconUpDown = () => {
    if (isOpenSelectGrade) {
      return <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"/><path fill="#000" d="M11.293 8.293a1 1 0 0 1 1.414 0l5.657 5.657a1 1 0 0 1-1.414 1.414L12 10.414l-4.95 4.95a1 1 0 0 1-1.414-1.414z"/></g></svg> 
    }
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><g fill="none" fill-rule="evenodd"><path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"/><path fill="#000" d="M12.707 15.707a1 1 0 0 1-1.414 0L5.636 10.05A1 1 0 1 1 7.05 8.636l4.95 4.95l4.95-4.95a1 1 0 0 1 1.414 1.414z"/></g></svg>
    )
  }

  useEffect(() => {
    if (open) {
      const gradeAll = {label: 'Tất cả', value: 'ALL'};
      const listGrade = [
        gradeAll, 
        ...gradeListRedux?.list?.map(item => ({
          label: !!item?.title ? ("Giáo trình " + (typeof item?.title === 'string' ? item.title.toLowerCase() : "")) : item?.name,
          value: item?.id
        })),
        {label: "Luyện thi", value: 'PREPARATION'}
      ]
      const gradeSelect = !!authentication?.grade_id && listGrade?.find(item => item?.value == authentication?.grade_id) ? 
                          listGrade?.find(item => item?.value == authentication?.grade_id) : 
                          gradeAll
      const curriculumsByGrade = listCurriculum?.filter(item => item?.grade_id == gradeSelect?.value);
      setListGrade(listGrade);
      setGradeSelected(gradeSelect);
      setListCurriculumByGrade(curriculumsByGrade)
    } else {
      setListGrade([]);
    }
  }, [open, listCurriculum?.length])

  if (!open) {
    return null;
  }

  return (
    <div className="popup-select-curriculum">
      <div className="overlay-select-curriculum" onClick={onClose}/>
      <div className="container-select-curriculum">
        <div className="content-select-curriculum">
          <div ref={boxGradeRef} className="box-select-grade mx-container-select-curriculum" onClick={toggleBoxGrade}>
            <p>{gradeSelected?.label}</p>
            {renderIconUpDown()}
            {isOpenSelectGrade && <div className="select-curriculum-grade-list scrollbar-custom">
              {listGrade?.map(item => (
                <div 
                  key={item?.value} 
                  className="select-curriculum-grade-item" 
                  onClick={() => handleSelectGrade(item)}
                  style={{
                    backgroundColor: gradeSelected?.value == item?.value && '#E6E6E6'
                  }}
                >
                  {item?.label}
                </div>
              ))}  
            </div>}
          </div>
          <div className="curriculum-by-grade-list scrollbar-custom ">
            {listCurriculumByGrade?.map((item, index) => (
              <div 
                key={index} 
                className="curriculum-by-grade-item"
                onClick={() => {
                  !!onSelectCurriculum && onSelectCurriculum(item?.id, item?.sub_type)
                  onClose();
                }}
              >
                <div 
                  className="curriculum-by-grade-img" 
                  style={{
                    borderColor: item?.type === classesConstants.SUNDAY ||
                    item?.type_class === classesConstants.SUNDAY_FREE
                      ? "#fdcd04"
                      : "#00cc83"
                  }}
                >
                  <img
                    src={
                      item?.type === classesConstants.SUNDAY ||
                      item?.type_class === classesConstants.SUNDAY_FREE
                        ? "/assets/images/logo_S.png"
                        : "/assets/images/giaotrinh/ico_gt.png"
                    }
                    alt={item?.curriculum_name}
                  />
                </div>
                <div className="curriculum-by-grade-info">
                  <p style={{fontWeight: 'bold', fontSize: 18, color: "#000"}}>{item?.class_name}</p>
                  {item?.class_name != item?.curriculum_name && <p style={{color: '#000'}}>{item?.curriculum_name}</p>}
                  <p>{item?.curriculum_description}</p>
                </div>
              </div>
            ))}
            {!listCurriculumByGrade?.length && <p style={{textAlign: 'center'}}>Không có giáo trình nào</p>}
          </div>
        </div>
      </div>
    </div>
  )
}