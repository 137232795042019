import PropTypes from "prop-types";
import React, { Component } from "react";
import SocialLogin from "react-social-login";

class Button extends Component {
    static propTypes = {
        triggerLogin: PropTypes.func.isRequired,
    };

    render() {
        const { triggerLogin, image, ...props } = this.props;
        return (
            <div
                className="social_login_item"
                onClick={(e) => {
                    props.clickprocessing && props.clickprocessing();
                    triggerLogin(e);
                }}
            >
                {props.children}
            </div>
        );
    }
}

export default SocialLogin(Button);
