import { history } from "../../../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { apiCaller } from "../../../../../_helpers";
import { TypeAssignExercise } from "../../../../../_constants/typeAssignExercise";
import { teacherService } from "../../../../../_services";
import { teacherConstants } from "../../../../../_constants";
import { floatObjectToArray } from "../../../../../_base/Validate";
import { cloneDeep } from "lodash";

export const assignSkillsLogic = () => {
  const { id, type } = useParams();
  const searchParams = new URLSearchParams(document.location.search);
  const unit_num = searchParams.get("unit_num");
  const _dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);
  const exercises = useSelector((state) => state.classes.exercises);

  const dataReduxAssign = useSelector((state) => state.classes.setting_assign);

  // Variable Data Assign Specific for Assign Demand
  const [dataAssignSpecific, setDataAssignSpecific] = useState(
    cloneDeep(dataReduxAssign)
  );

  // Get Data Proposal
  const getDataProposalDemand = async () => {
    var body = new FormData();

    body.append("list_student", JSON.stringify(exercises.students));
    body.append("id_class", id.toString());
    body.append("unit_learning_id", dataAssignSpecific.cur_unit.toString());
    body.append(
      "setting_student",
      JSON.stringify(
        dataAssignSpecific.study_data.map(
          ({
            practice_percent: practice_per,
            study_percent: study_per,
            ...rest
          }) => ({
            practice_per,
            study_per,
            ...rest,
          })
        )
      )
    );
    body.append(
      "ingredient",
      JSON.stringify(dataAssignSpecific?.lesson_list_propose)
    );

    const res = await teacherService.getDataAssignProposal("request", body);

    return res.data;
  };

  // Handle Go Back
  const handleGoBack = () => {
    history.push(
      "/" +
        authentication.role +
        "/class/view/" +
        id +
        "/exercise/student/" +
        TypeAssignExercise.DEMAND
    );
  };

  // Handle Submit Skills
  const handleSubmitSkills = async () => {
    _dispatch({
      type: teacherConstants.GET_DATA_SETTING_ASSIGN,
      setting: dataAssignSpecific,
    });

    const responseData = await getDataProposalDemand();

    if (responseData) {
      responseData.map(
        (item) =>
          (item.exercise_suggest = floatObjectToArray(item.exercise_suggest))
      );
      _dispatch({
        type: teacherConstants.GET_DATA_ASSIGN_SPECIFIC,
        proposal: responseData.map((item) => ({
          ...item,
          exercise_suggest: item.exercise_suggest.map((exercise) => ({
            ...exercise,
            user_id: item.user_id,
            list_guide_id: [],
          })),
        })),
      });

      const dataExercise = responseData.reduce(function (accumulator, obj) {
        return accumulator.concat(
          obj.exercise_suggest.map((exercise) => ({
            ...exercise,
            user_id: obj.user_id,
            list_guide_id: [],
          }))
        );
      }, []);

      _dispatch({
        type: teacherConstants.ADD_DATA_EXERCISE,
        data: {
          ...exercises,
          data_exercise: dataExercise,
          unit: exercises?.unit ? exercises.unit : unit_num
        },
      });
    }

    history.push(
      "/" +
        authentication.role +
        "/class/view/" +
        id +
        "/exercise/student/" +
        type +
        "/list_proposals?unit_num=" + unit_num
    );
  };

  return {
    dataAssignSpecific,
    handleGoBack,
    setDataAssignSpecific,
    handleSubmitSkills,
  };
};
