import "./IntroPage.style.scss";
import { HeaderNews } from "../../_components/Header";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";
import FooterNews from "../../_components/FooterNews/FooterNews";
import { introPageLogic } from "./IntroPage.logic";
import { handleCheckEven } from "../../_base/Validate";
import ScrollFixed from "../../_components/ScrollFixed/ScrollFixed";
import LazyLoad from "react-lazyload";

const IntroPage = (props) => {
  let {
    dataMissions,
    dataScholarly,
    dataResultScholarly,
    dataIntro,
    dataIntroMobile,
  } = introPageLogic(props);

  const TitleScholarly = (props) => {
    return (
      <span>
        <span
          className="font_news_bold"
          style={{
            fontSize: props.fontSize,
            color: "#C84242",
          }}
        >
          S
        </span>
        <span
          className="font_news_bold"
          style={{
            color: "#F29500",
            fontSize: props.fontSize,
          }}
        >
          E
        </span>
        <span
          className="font_news_bold"
          style={{
            color: "#FDCD04",
            fontSize: props.fontSize,
          }}
        >
          C
        </span>
        <span
          className="font_news_bold"
          style={{
            color: "#00E2A0",
            fontSize: props.fontSize,
          }}
        >
          A
        </span>
        <span
          className="font_news_bold"
          style={{
            color: "#24BBE0",
            fontSize: props.fontSize,
          }}
        >
          R
        </span>
      </span>
    );
  };

  const renderListDetailContent = (item, itemParent) => {
    return (
      <div
        key={item.id}
        className={`pos_rel line_height_14 fz-11-25 detail_item_content text-align-justify pd-bottom-0-5`}
      >
        <div className={`item_dot_${itemParent.id}`} />
        {item.content}
        {item?.listSideContent &&
          item?.listSideContent?.map((item) => (
            <div key={item.id} className="pd-top-0-5-pc fz-11-25">
              {item?.content}
            </div>
          ))}
      </div>
    );
  };

  // Render Table Scholarly
  const renderTableScholarly = (item, dataScholarly) => {
    return (
      <div
        key={item.id}
        className="item_scholarly col-25 flex-center-column"
        style={{
          borderRight: item.id != dataScholarly.length && "1px solid #000",
          borderBottom: `0.7rem solid ${item.colorSpecific}`,
        }}
      >
        <img
          src={`/assets/images/introNews/icon/img_title_${item.id}.png`}
          alt="Img Title"
          className="contain_image item_scholarly_img"
        />

        <div className="flex-center flex-wrap">
          <span className="font_news_bold fz-12-5 color-black">{`${item.titleSub}`}</span>
          <span className=" fz-12-5 title_eng_scholarly color-black">
            {" "}
            - {item.titleEng}
          </span>
        </div>

        <div className="img_scholary_ico_container flex-center">
          <img
            src={`/assets/images/introNews/icon/ico_scholary_${item.id}.png`}
            alt="Icon Scholary"
            className="contain_image item_scholary_ico"
          />
        </div>
        {item?.listContent && (
          <div className="list_detail_content">
            {item?.listContent?.map((itemChild) =>
              renderListDetailContent(itemChild, item)
            )}
          </div>
        )}
      </div>
    );
  };

  // Render Table Scholarly
  const renderListScholarlyMobile = (item, dataScholarly) => {
    return (
      <div
        key={item.id}
        className="item_scholary_mobile col-100 flex-align"
        style={{
          borderBottom: `1px solid black`,
        }}
      >
        <div className="item_scholary_img_title flex-center">
          <img
            src={`/assets/images/introNews/icon/img_title_${item.id}_mobile.png`}
            alt="Img Title Scholary"
            className="contain_image"
          />
        </div>
        {item?.listContent && (
          <div className="list_detail_content_mobile">
            {item?.listContent?.map((itemChild) =>
              renderListDetailContent(itemChild, item)
            )}
          </div>
        )}
      </div>
    );
  };

  // Render Mission Item
  const renderMissionItem = (item) => {
    return (
      <div key={item.id} className="mission_item flex-align">
        <img
          src={`/assets/images/introNews/icon/${item.keyImg}.png`}
          alt="Img Item Mission"
          className="contain_image img_item_mission"
        />

        <div className="fz-12-5 text_item_mission text-align-justify">
          {item.content}
        </div>
      </div>
    );
  };

  // Render List Result Schoolary
  const renderResultSchoolarly = (item) => {
    return (
      <div
        key={item.id}
        className="pos_rel item_result_schoolary fz-12-5 fz-1-mobile text-align-justify"
      >
        <div className={`item_dot_lozenge`} />
        {item.content}
      </div>
    );
  };

  // Render Intro Data
  const renderListIntro = (data, typePosition) => {
    return (
      <div
        key={data.id}
        className={`item_intro flex-align pos_rel item_intro_${data.id}_${typePosition}`}
      >
        {data.id == 1 && typePosition == "right" ? null : (
          <img
            src={`/assets/images/introNews/icon/${data.keyImg}.png`}
            alt="Img Item Intro"
            className="contain_image"
          />
        )}

        {data?.content && <div className="item_intro_content text-align-justify fz-11-25">
          {data.content}
        </div>}
        {!!data?.listContent?.length && (
          <ul className="list-content-intro">
            {data.listContent.map(item => (
              <li className="item_intro_content text-align-justify fz-11-25">
                {item?.content}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  // Render Intro Data Mobile
  const renderListIntroMobile = (data) => {
    return (
      <div key={data.id} className={`pos_rel item_intro_mobile flex-align`}>
        <img
          src={`/assets/images/introNews/icon/${data.keyImg}.png`}
          alt="Icon Intro Item"
          className={`contain_image pos_abs img_item_intro img_item_intro_${data.id}`}
          style={{
            left: !handleCheckEven(data.id) && "-1.6rem",
            right: handleCheckEven(data.id) && "-1.1rem",
          }}
        />

        {!!data?.content && <div className="fz-1-mobile text-align-justify">{data.content}</div>}
        {!!data?.listContent?.length && (
          <ul className="list-content-intro">
            {data.listContent.map(item => (
              <li className="fz-1-mobile text-align-justify">
                {item?.content}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  };

  return (
    <div className="introPage commondPage">
      <HeaderNews type={TypeHeaderNewsItem.INTRODUCTION} />

      <div id="about" className="bg_white content_intro ">
        <LazyLoad
          once
          className="display_none_mobile pos_rel content_1 flex-column pos_rel display_none_mobile"
          style={{
            background: `url(
              /assets/images/introNews/bg_content_1.png
            ) no-repeat top center /cover`,
            backgroundAttachment: "scroll",
          }}
        >
          <div className="flex-align-end ">
            <div className="pos_abs img_intro_tree_container">
              <img
                src="/assets/images/introNews/img_tree_intro.png"
                alt="Tree Courses"
                className="contain_image"
              />
            </div>
          </div>

          <div className="img_title_intro pos_rel">
            <img
              src="/assets/images/introNews/title_intro.png"
              alt="Title Intro"
              className="contain_image"
            />
          </div>

          <div className="img_top_title pos_abs">
            <img
              src="/assets/images/introNews/icon/img_cell_2_1.png"
              alt="Img Top Title"
              className="contain_image"
            />
          </div>

          <div className="list_intro flex-jus-between">
            <div className="list_intro_left">
              {dataIntro.cell_1.map((data) => renderListIntro(data, "left"))}
            </div>

            <div className="list_intro_right">
              {dataIntro.cell_2.map((data) => renderListIntro(data, "right"))}
            </div>
          </div>
        </LazyLoad>

        <LazyLoad className="col-100" once>
          <div
            className="hide display_flex_mobile pos_rel content_1_mobile flex-column pos_rel "
            style={{
              background: `url(
              /assets/images/introNews/bg_content_1_mobile.png
            ) no-repeat top center /cover`,
              backgroundAttachment: "scroll",
            }}
          >
            <div className="img_title_intro pos_rel">
              <img
                src="/assets/images/introNews/title_intro.png"
                alt="Title Intro"
                className="contain_image"
              />
            </div>

            <div className="list_intro_mobile flex-center-column">
              {dataIntroMobile.map((item) => renderListIntroMobile(item))}
            </div>
          </div>
        </LazyLoad>

        <div className="content_2 flex-center-column">
          <div
            id="our_missions"
            className="flex-center-column content_missions pos_rel col-100"
          >
            <div className="color-primary-green fz-20 font_news_bold title_mission">
              SỨ MỆNH CỦA CHÚNG TÔI
            </div>
            <div className="list_missions flex-center-column pos_rel">
              {dataMissions.map((item) => renderMissionItem(item))}

              <div className="hide display_block_mobile pos_abs border_abs_side" />
            </div>

            <div className="display_none_mobile icon_container">
              <LazyLoad className=" icon_left_container" once>
                <img
                  src="/assets/images/introNews/img_left_bg.png"
                  alt="Icon Text Left"
                  className="contain_image"
                />
              </LazyLoad>

              <LazyLoad
                className=" icon_right_container"
                once
                placeholder={
                  <img
                    src="/assets/images/introNews/img_right_bg.png"
                    alt="Icon Text Right"
                    className="contain_image"
                  />
                }
              >
                <img
                  src="/assets/images/introNews/img_right_bg.png"
                  alt="Icon Text Right"
                  className="contain_image"
                />
              </LazyLoad>
            </div>
          </div>

          <div className="scholary_container flex-center-column">
            <div className="display_none_mobile font_news_bold fz-25 pd-bottom-15 text-align-center">
              PHƯƠNG PHÁP HỌC THUẬT <TitleScholarly fontSize="2.5rem" />
            </div>

            <div className="hide display_block_mobile pos_rel font_news_bold title_schoolary pd-bottom-15 text-align-center">
              PHƯƠNG PHÁP HỌC THUẬT <br />{" "}
              <TitleScholarly fontSize="1.875rem" />
              <div className="hide display_block_mobile icon_schoolary_title">
                <LazyLoad className="icon_left_schoolary pos_abs" once>
                  <img
                    src="/assets/images/introNews/img_left_schoolary.png"
                    alt="Icon Text Left"
                    className="contain_image"
                  />
                </LazyLoad>
                <LazyLoad className="icon_right_schoolary pos_abs" once>
                  <img
                    src="/assets/images/introNews/img_right_schoolary.png"
                    alt="Icon Text Right"
                    className="contain_image"
                  />
                </LazyLoad>
              </div>
            </div>

            <LazyLoad className="flex-align-start list_schoolarly display_none_mobile">
              {dataScholarly?.map((item) =>
                renderTableScholarly(item, dataScholarly)
              )}
            </LazyLoad>

            <LazyLoad
              className="hide display_flex_mobile flex-column col-100"
              once
            >
              {dataScholarly.map((item) =>
                renderListScholarlyMobile(item, dataScholarly)
              )}
            </LazyLoad>
          </div>

          <LazyLoad
            className="hide display_flex_mobile banner_side_container"
            once
          >
            <img
              src="/assets/images/banner_side.png"
              alt="Banner Side"
              className="contain_image"
            />
          </LazyLoad>

          <div className="scholary_detail_container col-100">
            <div className="scholary_detail_content">
              <span className="fz-12-5 text-alin-justify title_schoolary_detail">
                <TitleScholarly fontSize="1.25rem" /> là phương pháp học thuật
                tiên tiến của Sunday English giúp học sinh phát triển và nâng
                cao năng lực Tiếng Anh của mình trong thời gian ngắn ngay cả với
                những học sinh mất gốc tiếng Anh.
              </span>

              <div className="schoolary_detail_result">
                <div className="fz-15 font_news_bold pd-bottom-1 title_schoolary_result">
                  Kết quả của phương pháp <TitleScholarly fontSize="1.5rem" />{" "}
                  giúp học sinh
                </div>

                <LazyLoad
                  className="flex-column list_result_schoolary pos_rel"
                  once
                >
                  {dataResultScholarly?.map((item) =>
                    renderResultSchoolarly(item)
                  )}
                  <LazyLoad
                    once
                    className="pos_abs icon_center_container display_none_mobile"
                  >
                    <img
                      src="/assets/images/homeNews/content/icon/ico_bonus_center.png"
                      alt="Icon Text Center"
                      className="contain_image"
                    />
                  </LazyLoad>
                </LazyLoad>
              </div>
            </div>

            <LazyLoad once className="img_banner_schoolary flex-align-end">
              <img
                src="/assets/images/introNews/img_banner_schoolary.png"
                alt="Img Banner Schoolary"
                className="contain_image"
              />
            </LazyLoad>
          </div>
        </div>
      </div>
      <ScrollFixed />
      <FooterNews />
    </div>
  );
};

export default IntroPage;
