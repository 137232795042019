import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { scheduleConstants } from "../../../../_constants";
import { scheduleActions } from "../../../../_actions";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { scheduleService } from "../../../../_services";
import "./styles.scss";

function ScheduleTodayItem(props) {
  const authentication = useSelector((state) => state.authentication);
  const schedules = useSelector((state) => state.schedules);
  const dispatch = useDispatch();
  let { schedule, page, selectedDate } = props;
  const [showEdit, setShowEdit] = useState(false);

  function showEditFunc() {
    setShowEdit(true);
  }

  function hideEditFunc() {
    setShowEdit(false);
  }

  function addDataDetailScheduleDay(data) {
    data.action = "edit";
    data.checkRemind = parseInt(data.remind_time) > 0 ? true : false;
    dispatch({
      type: scheduleConstants.ADD_DETAIL_SCHEDULE_DAY,
      data,
    });
  }

  function removeDataDeleteScheduleDay(id) {
    let data = {
      id,
      showForm: true,
      repeatType: schedule.repeat_type,
    };
    dispatch({
      type: scheduleConstants.ADD_DELETE_SCHEDULE_DAY,
      data,
    });
    setShowEdit(false);
  }
  
  function successScheduleDay(id) {
    let data = {
      ...schedule,
      status: "done",
    };
    dispatch(
      scheduleActions.completeSchedule(
        data,
        authentication.id,
        schedules.selectDate
      )
    );
    setShowEdit(false);
  }

  const cancelScheduleDay = (id) => {
    let now = new Date();
    const scheduleDate = new Date(schedule.end_time);

    let distanceTime = (now - scheduleDate) / (1000 * 60);
    let status = "";

    if (distanceTime > 0) {
      status = "over_time";
    } else if (distanceTime >= 0 && distanceTime <= 30) {
      status = "upcoming";
    } else {
      status = "no_status";
    }

    let data = {
      ...schedule,
      status: status,
    };

    dispatch(
      scheduleActions.completeSchedule(
        data,
        authentication.id,
        schedules.selectDate
      )
    );
    setShowEdit(false);
  };

  let isPageMore = window.location.pathname.indexOf("/more/") !== -1;

  useEffect(() => {
    setShowEdit(false)
  }, [schedules?.dateSelectedCalendar])
  
  return (
    <div
      className={
        "plan-item flex-m box-shadow " +
        (showEdit ? "edit-show " : "") +
        schedule.type
      }
    >
      <div className="plan-item-content rel">
        <div className="flex-m">
          <div
            className={
              "flex-1 pr-10" +
              (schedule.status !== "no_status"
                ? " width_70_percent "
                : " width-100-percent")
            }
          >
            <p
              className="check-work "
              style={{
                fontSize: "1rem",
              }}
            >
              {moment(schedule.start_time).format("HH:mm")}
            </p>
            <Link
              to={
                "/" +
                authentication.role +
                "/more/schedule/edit/" +
                page +
                "/" +
                schedule.id +
                `?date=${
                  selectedDate
                    ? moment(selectedDate).format("YYYY-MM-DD")
                    : moment(new Date()).format("YYYY-MM-DD")
                }${isPageMore ? "" : "&redirectUrl=/teacher"}`
              }
              onClick={() => addDataDetailScheduleDay(schedule)}
            >
              <p
                className={
                  "check-work one-line" +
                  (schedule.status !== "no_status" ? " width-100-percent " : "")
                }
                title={schedule.title}
              >
                {(schedule.type === "teaching_work"
                  ? "Giảng dạy tại lớp "
                  : "") + schedule.title}
              </p>
            </Link>
          </div>
          <div
            style={{
              display:
                scheduleConstants.TEXT_ICON_SCHEDULE_STATUS[schedule.status] &&
                scheduleConstants.TEXT_ICON_SCHEDULE_STATUS[schedule.status][
                  "class"
                ]
                  ? "-webkit-box"
                  : "none",
            }}
            className={
              "his flex-m width-115px " +
              (scheduleConstants.TEXT_ICON_SCHEDULE_STATUS[schedule.status] &&
              scheduleConstants.TEXT_ICON_SCHEDULE_STATUS[schedule.status][
                "class"
              ]
                ? scheduleConstants.TEXT_ICON_SCHEDULE_STATUS[schedule.status][
                    "class"
                  ]
                : "")
            }
          >
            {scheduleConstants.TEXT_ICON_SCHEDULE_STATUS[schedule.status] && (
              <Fragment>
                <div>
                  <img
                    src={
                      "/assets/images/icon/" +
                      scheduleConstants.TEXT_ICON_SCHEDULE_STATUS[
                        schedule.status
                      ]["icon"]
                    }
                    alt={schedule.status}
                  />
                </div>
                <span className="flex-1">
                  {
                    scheduleConstants.TEXT_ICON_SCHEDULE_STATUS[
                      schedule.status
                    ]["title"]
                  }
                </span>
              </Fragment>
            )}
          </div>
        </div>
        <Link
          to={
            "/" +
            authentication.role +
            "/more/schedule/edit/" +
            page +
            "/" +
            schedule.id +
            `?date=${
              selectedDate
                ? moment(selectedDate).format("YYYY-MM-DD")
                : moment(new Date()).format("YYYY-MM-DD")
            }${isPageMore ? "" : "&redirectUrl=/teacher"}`
          }
          onClick={() => addDataDetailScheduleDay(schedule)}
        >
          <p className="text-light one-line" title={schedule.content}>
            {schedule.content}
          </p>
        </Link>
        <img
          src="/assets/images/icon/ico_left_circle_blue.png"
          className="btn-plan-item hide-edit-box"
          onClick={showEditFunc}
          alt="ico_left_circle_blue"
        />
        <img
          src="/assets/images/icon/ico_right_circle_blue.png"
          className="btn-plan-item show-edit-box"
          onClick={hideEditFunc}
          alt="ico_right_circle_blue"
        />
      </div>
      <div className="edit-plan-box">
        <div className="flex-m">
          {!(schedule.status === "done") ? (
            <div
              className="flex-1 text-center btn"
              onClick={() => successScheduleDay(schedule.id)}
            >
              <img
                src="/assets/images/icon/ico_success_schedule.png"
                alt="ico_succes_small"
                className="icon_status"
              />
              {/* <br />
              <p className="text-light">Hoàn thành</p> */}
            </div>
          ) : (
            <div
              className="flex-1 text-center btn"
              onClick={() => cancelScheduleDay(schedule.id)}
            >
              <img
                src="/assets/images/icon/ico_cancel_schedule.png"
                alt="ico_delete"
                className="icon_status"
              />
              {/* <br />
              <p className="text-light">Bỏ Tick</p> */}
            </div>
          )}
          <div className="flex-1 text-center btn">
            <Link
              to={
                "/" +
                authentication.role +
                "/more/schedule/edit/" +
                page +
                "/" +
                schedule.id +
                `?date=${
                  selectedDate
                    ? moment(selectedDate).format("YYYY-MM-DD")
                    : moment(new Date()).format("YYYY-MM-DD")
                }${isPageMore ? "" : "&redirectUrl=/teacher"}`
              }
              onClick={() => addDataDetailScheduleDay(schedule)}
            >
              <img
                src="/assets/images/icon/ico_edit.png"
                alt="ico_edit"
                className="icon_status"
              />
              {/* <br />
              <p className="text-light">Sửa</p> */}
            </Link>
          </div>
          <div
            className="flex-1 text-center btn"
            onClick={() => removeDataDeleteScheduleDay(schedule.id)}
          >
            <img
              src="/assets/images/icon/ico_remove_schedule.png"
              alt="ico_remove"
              className="icon_status"
            />
            {/* <br />
            <p className="text-light">Hủy</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export { ScheduleTodayItem };
