import { useEffect, useState } from "react"
import { studentService } from "../../_services"
import { alertActions } from "../../_actions"
import { linkingParentConstants } from "../../_constants/linkingParent"
import { useDispatch } from "react-redux"

export const manageLinkingParentLogic = props => {
  const [typeLinking, setTypeLinking] = useState({
    MY_PARENT: 'MY_PARENT',
    INVITATION_SEND: 'INVITATION_SEND',
    INVITATION_RECEIVE: 'INVITATION_RECEIVE'
  })
  const [isLoadingMyParent, setIsLoadingMyParent] = useState(true)
  const [isLoadingInvitation, setIsLoadingInvitation] = useState(true)
  const [listMyParent, setListMyParent] = useState([])
  const [listInvitationSend, setListInvitationSend] = useState([])
  const [listInvitationReceive, setListInvitationReceive] = useState([])
  const [message, setMessage] = useState('')
  const dispatch = useDispatch()
  
  const getListMyParent = async () => {
    try {
      const res = await studentService.getListMyParent()
      setListMyParent(res?.data)
      setIsLoadingMyParent(false)
    } catch (err) {
      console.log(err)
      setListMyParent([])
      setIsLoadingMyParent(false)
    }
  }
  
  const getListInvitation = async () => {
    try {
      const res = await studentService.getListInvitation()
      setListInvitationReceive(res?.data?.revice_list)
      setListInvitationSend(res?.data?.invited_list)
      setIsLoadingInvitation(false)
    } catch (err) {
      console.log(err)
      setIsLoadingInvitation(false)
    }
  }

  const getData = () => {
    getListMyParent()
    getListInvitation()
  }

  const onRemoveParent = async (parent_id,type) => {
    try {
      const res = await studentService.removeParent(parent_id, type)
      setMessage(res?.msg)
      getData()
    } catch(err) {
      console.log(err)
      dispatch(
        alertActions.error({
          message: err.toString(),
          screen: linkingParentConstants.SCREEN_MANAGE_LINKING,
          isShowPopup: true
        })
      );
    }
  }

  const handleRemoveParent = async (parent_id,type) => {
    if (type === 2) {
      onRemoveParent(parent_id,type)
      return ;
    }

    const message = type === 0
     ? 'Bạn có chắc chắn muốn hủy lời mời liên kết với tài khoản này không?' 
     : 'Bạn có chắc chắn muốn xóa liên kết với tài khoản này không? Tài khoản này cũng sẽ bị xóa khỏi danh sách tài khoản liên kết.'
    dispatch(
      alertActions.error({
        message,
        screen: linkingParentConstants.SCREEN_MANAGE_LINKING,
        isShowPopup: true,
        onComplete: () => onRemoveParent(parent_id,type),
        hasBtnClosePopup: true,
        textComplete: 'Đồng ý',
        textClose: "Hủy",
      })
    );
  }

  const handleAcceptParent = async (parent_id) => {
    try {
      const res = await studentService.acceptInvitationParent({parent_id})
      setMessage(res?.msg)
      getData()
    } catch(err) {
      console.log(err)
      dispatch(
        alertActions.error({
          message: err.toString(),
          screen: linkingParentConstants.SCREEN_MANAGE_LINKING,
          isShowPopup: true
        })
      );
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return {
    listMyParent,
    typeLinking,
    listInvitationSend,
    listInvitationReceive,
    isLoadingMyParent,
    isLoadingInvitation,
    handleRemoveParent,
    message,
    setMessage,
    handleAcceptParent
  }
}