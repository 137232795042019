import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { licenseActions } from "../../../_actions";
import { SideBar } from "../../../_components/Admin/More/License";
import { Header } from "../../../_components/Admin/Header";
import "./style.scss";
import { isEmpty } from "lodash";
import moment from "moment";
import $ from "jquery";

function HistoryLicense() {
  const dispatch = useDispatch();
  const licenses = useSelector((state) => state.licenses);
  const authentication = useSelector((state) => state.authentication);
  const [isLoading, setLoading] = useState(false);

  //   Function convert data History month
  const convertHistoryTime = (packagesHistory) => {
    let dataConvert = [];
    let prevYear = 0;
    let prevMonth = 0;

    if (!isEmpty(packagesHistory) && packagesHistory.length > 0) {
      packagesHistory?.forEach((packageItem) => {
        let month = moment(packageItem?.date, "DD-MM-YYYY").get("month");
        let year = moment(packageItem?.date, "DD-MM-YYYY").get("year");

        if (prevYear != year || prevMonth != month) {
          packageItem.month = month + 1;
          packageItem.year = year;
          prevYear = year;
          prevMonth = month;
        }
      });
      dataConvert = dataConvert.concat(packagesHistory);
    }
    return dataConvert;
  };

  //   Init Packages History
  const packagesHistory = convertHistoryTime(
    licenses?.listHistoryLicenses?.package_list
  );

  useEffect(() => {
    dispatch(licenseActions.getHistoryLicense());
  }, []);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
  }, [licenses]);

  const showImgStatus = (action, is_success) => {
    switch (action?.toLowerCase()) {
      case "buy":
        return is_success ? "ico_package_gan" : "ico_package_failed";
      case "assign":
        return "ico_package_gan";
      case "activated":
        return "ico_package_actived";
      case "expired":
        return "ico_package_failed";
      case "deposed":
        return "ico_package_failed";
      case "refund":
        return "ico_package_failed";
      default:
        break;
    }
  };

  //   Render Title Package
  const showTitlePackage = (action, name) => {
    switch (action?.toLowerCase()) {
      case "buy":
        return "Mua " + name;
      case "assign":
        return "Gán " + name;
      case "activated":
        return "Kích hoạt " + name;
      case "expired":
        return name + " hết hạn";
      case "deposed":
        return "Hủy " + name;
      case "refund":
        return "Hoàn tiền " + name;
      default:
        break;
    }
  };

  //   Render History Payment
  const renderHistoryPayment = (license, index) => {
    return (
      <div
        key={license?.id + "-" + index.toString()}
        className="history-item flex-column"
      >
        {!!license?.year ? (
          <div className="history-header font_myriad_bold flex-align ">
            <div className="color-white flex-align font_myriad_bold">{`Tháng ${license?.month}/${license?.year}`}</div>
          </div>
        ) : null}
        <div
          style={{
            borderTopWidth: !!license?.year ? "1" : "0",
          }}
          className="border_1_light flex-jus-between col-100 history-infor bg_white"
        >
          <div className="flex-align flex-1">
            <div className="history-icon flex-center">
              <img
                src={`/assets/images/license/${showImgStatus(
                  license?.action,
                  license?.is_success
                )}.png`}
                alt="Icon Action"
                className="contain_image"
              />
            </div>

            <div className="flex-column">
              <div className="history-package_title">
                {showTitlePackage(license?.action, license?.package_name)}
              </div>

              <div className="pd-top-0-5-pc fz-1">{license?.date}</div>
            </div>
          </div>

          <div
            style={{
              color:
                license?.action != "deposed" &&
                license?.action != "refund" &&
                license?.is_success
                  ? "#00CC83"
                  : "#EB5757",
            }}
            className="flex-align history-package_detail"
          >
            {license?.detail}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="sunE-right-container license-page history-page flex-column">
      <Header title={"Lịch sử"} isBack />
      <div className="flex-m sunE-content license-content scrollbar-custom  list-buy-history ">
        {/* <SideBar /> */}
        {!isLoading ? (
          <div
            style={{ paddingLeft: "15px" }}
            className="flex-1 sunE-content-box "
          >
            {!isEmpty(packagesHistory) && packagesHistory.length > 0 ? (
              <div className="package-list history-list flex-column col-100">
                {packagesHistory?.map((license, index) =>
                  renderHistoryPayment(license, index)
                )}
              </div>
            ) : (
              <div className="empty-payment-custom pd-top-3-pc">
                <img
                  src="/assets/images/license/no_payment.png"
                  alt="Img Empty"
                  className="img-license-empty"
                />
                <div className=" license-text-empty font_myriad_bold">
                  Bạn chưa có lịch sử mua hàng!
                </div>
                {/* <div className="license-text-instruct">
                Hãy click vào "Mua gói mới" để trải nghiệm.
              </div> */}
              </div>
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
}

export { HistoryLicense };
