import { Alert } from 'react-uikit3';
import { configConstants } from '../_constants';
import { Mydata } from './Mydata';
import { replaceAnd } from './Validate';
import { useSelector } from 'react-redux';
import { logEvent } from './AnalyticsBase';

class LessonBase{

    static Type = {
        teacher : 'teacher',
        curriculum : 'curriculum',
        homework : 'homework',
        masterUnit : 'masterUnit'
    };

    static _moveLesson = (type, auth, data, navigation, from, from_link) => {
        if(type == LessonBase.Type.homework && (!auth.hasticket || auth.hasticket?.ticket < 1) && (data?.stt != "Unit 1" && data?.unit != "1" && !(data?.curriculum_name?.includes("Sunday Essential") && data?.unit_name == "Unit 1"))){
            window.alert("Bạn không thể truy cập nội dung này. Vui lòng nâng cấp tài khoản để tiếp tục!");
            $(".loading").addClass("hide");
            return
        } 
        let dataSide = replaceAnd(data)
        if (['exam', 'mini_test'].includes(data?.exercise_type)) {
            //navigate to the minitest/exam screen
            return
        }
        var id = "";
        switch (type) {
            case LessonBase.Type.homework:
                if(((data?.lesson_type || data?.exercise_type) == 'writing' && data?.question_type == '7') || (((data?.lesson_type || data?.exercise_type)) == 'speaking' && data?.question_type == '3') || (data?.lesson_type || data?.exercise_type) == 'project'){
                    id = data?.id
                }else{
                    id = (data?.lesson_id || data?.exercise_id)
                }
                break;
            case LessonBase.Type.masterUnit:
                id = data?.id
                break;
            default:
                id = (data?.lesson_id || data?.exercise_id)
                break;
        }
        const deviceID = localStorage.getItem('device_id_commond');
        // console.log("=====Go to type", type)
        // console.log("=====Go to id", id)
        // console.log("=====Go to with data", data)
        // console.log("=====Go to from", from)
        // var id = type == (LessonBase.Type.homework || LessonBase.Type.masterUnit) ? data?.id : (data?.lesson_id || data?.exercise_id)
        const url = `${configConstants.EXCERCISE_URL}index.php/example/lesson_demo?type=${type}&dataL=${JSON.stringify(dataSide)}&id=${id}&deviceID=${deviceID}&token=${auth?.jwt_token}${!!from_link ? `&from_link=${from_link}` : ''}`
        //const url = `http://localhost:11808/index.php/example/lesson_demo?type=${type}&dataL=${JSON.stringify(dataSide)}&id=${id}&deviceID=${deviceID}&token=${auth?.jwt_token}${!!from_link ? `&from_link=${from_link}` : ''}`
        //console.log("url lesson: "+ url)
        // console.log("url lesson auth?.jwt_token: "+ auth?.jwt_token)
        //console.log("===data: "+ JSON.stringify(dataSide))
        //window.open(url, "_blank")
        logEvent("Mở bài tập", {
            type: type,
            skill: data?.lesson_type || data?.exercise_type,
            question_type: data?.question_type,
            curriculum_name: data?.curriculum_name,
            unit: data?.stt,
            id: id
        })
        window.location.href = url
    };
}

export default LessonBase;