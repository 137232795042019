import { combineReducers } from 'redux';
import { authentication } from './Authentication';
import { register } from './Register';
import { alert } from './Alert';
import { grades, learns, curriculumStudent,assessments, rootlessness } from './Student';
import { classes, curriculums } from './Teacher';
import { licenses, schedules, profile, messages, setting } from './More';
import { home_page_reducer } from "./home_reducer"
import { Popup } from './Popup';
import { authenticationTeacherZalo } from './Authentication/authentiacionTeacherZalo'
import { audioPlayer } from './AudioPlayer';
import { persistCurriculumTeacher } from './Teacher/persistCurriculumTeacher';
import { classesStudent } from './Student/classes';
import { examTest } from './Student/examTest';
import { persistClassTeacher } from './Teacher/persistClassTeacher';

const appReducers = combineReducers({
    authentication,
    register,
    alert,
    grades,
    licenses,
    classes,
    schedules,
    curriculums,
    Popup,
    profile,
    messages,
    learns,
    setting,
    curriculumStudent,
    assessments,
    home_page_reducer,
    rootlessness,
    authenticationTeacherZalo,
    audioPlayer,
    persistCurriculumTeacher,
    classesStudent,
    examTest,
    persistClassTeacher,
});

export default appReducers;