import React, { useState } from "react";

export default function ResultQuestionWritingPassage({dataQuestion, answer, isReview, skill, question_type}) {
  const data = dataQuestion?.[0]?.list_option?.[0];

  return (
    <div style={{flex: 1, marginTop: 16}}>
      <div>
        <p style={{fontWeight: 'bold'}}>Gợi ý</p>
        <p style={{paddingLeft: 12, whiteSpace: 'pre-line'}}>{data?.hint || data?.option_text }</p>
      </div>
      <div style={{marginTop: 16}}>
        <p style={{fontWeight: 'bold'}}>{skill == 'speaking' && question_type == 3 ? 'Câu trả lời mẫu' : 'Bài viết mẫu'}</p>
        <p style={{paddingLeft: 12, whiteSpace: 'pre-line'}}>{data?.match_option_text?.[0]}</p>
      </div>
    </div>
  )
}