import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { curriculumConstants } from "./../../../_constants";
import { Alert } from "../../../_components/Alert";
import { isEmpty } from "lodash";
import LazyLoad from "react-lazyload";

function AddCurriculumForClass() {
  const { id } = useParams();
  const history = useHistory();

  const dispatch = useDispatch();
  const [type, setType] = useState("sunday");
  const [curriculumId, setCurriculumId] = useState("");
  useEffect(() => {
    dispatch(teacherActions.getCurriculum(type));
  }, []);
  const curriculums = useSelector((state) => state.curriculums);
  function changeType(type) {
    setType(type);
    dispatch(teacherActions.getCurriculum(type));
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (validateParam()) {
      if (isEmpty(curriculums.detail_class.curriculum_data)) {
        dispatch(
          teacherActions.addCurriculum({
            class_id: id,
            curriculum_id: curriculumId,
          })
        );
      } else {
        dispatch(
          teacherActions.editCurriculum({
            class_id: id,
            curriculum_id: curriculumId,
          })
        );
      }
    }
  }
  const alert = useSelector((state) => state.alert);

  function validateParam() {
    return curriculumId ? true : false;
  }
  const authentication = useSelector((state) => state.authentication);

  return (
    <div className="sunE-right-container flex-column">
      <Header
        title={"Chọn giáo trình"}
        isBack
        clickBack={() =>
          history.push(
            "/" + authentication.role + "/class/view/" + id + "/curriculum"
          )
        }
      />
      <div
        className="sunE-container-box giaotrinh canhan flex-1"
        style={{ paddingTop: "20px" }}
      >
        {alert.message &&
          alert.screen === curriculumConstants.ADD_CURRICULUM && (
            <Alert
              alert={alert}
              onComplete={() =>
                history.push(
                  "/" +
                    authentication.role +
                    "/class/view/" +
                    id +
                    "/curriculum"
                )
              }
            />
          )}
        <div className="gtcn-menu-gr">
          <span
            className={type === "sunday" ? " active" : ""}
            onClick={() => changeType("sunday")}
          >
            Sunday English
          </span>
          <span
            className={type === "personal" ? " active" : ""}
            onClick={() => changeType("personal")}
          >
            Cá nhân
          </span>
        </div>
        <div className="scrollbar-custom gt_list_hei">
          {curriculums.data.map((data, i) => {
            return (
              <div className="flex-m" key={i}>
                <div
                  className="flex-3"
                  onClick={() => setCurriculumId(data.id)}
                >
                  <div
                    className={
                      "box-giaotrinh-gr rel box-shadow hei-custom " +
                      (curriculumId === data.id ? " active" : "")
                    }
                  >
                    <div className="thumb3">
                      <img
                        src="/assets/images/giaotrinh/ico_gt.png"
                        alt="ico_gt_sun"
                      />
                    </div>
                    <div className="box-select-info">
                      <p className="two-line">{data.name}</p>
                      <span>{data.grade_name}</span>
                      <span>Số lượng Unit: {data.num_unit}</span>
                    </div>
                  </div>
                </div>
                <div className="flex-2"></div>
              </div>
            );
          })}
          {curriculums.data.length === 0 && (
            <span className="__text_empty_tutorial_guide">
              Bạn chưa có giáo trình cá nhân nào. Hãy Tạo giáo trình cá nhân của
              mình!
            </span>
          )}
        </div>
      </div>
      <div className="form-sunE-button text-right">
        <button
          className={"btn-line-blue" + (!validateParam() ? " btn-disable" : "")}
          onClick={handleSubmit}
        >
          {isEmpty(curriculums.detail_class.curriculum_data)
            ? "Thêm giáo trình"
            : "Thay giáo trình"}
        </button>
      </div>
    </div>
  );
}

export { AddCurriculumForClass };
