import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function HeaderCloud() {
  const authentication = useSelector((state) => state.authentication);
  return (
    <div className="logo">
      <Link to={`/`}>
        <img src={"/assets/images/logo.png"} alt={"Logo"} />
      </Link>
    </div>
  );
}

export { HeaderCloud };
