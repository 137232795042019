import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import { Wish } from "../../../_components/Admin/Teacher";
import { filter, isEmpty } from "lodash";
import { PopUpFilter } from "../../../_components/Popup";
import {
  configConstants,
  curriculumConstants,
  popupConstants,
} from "../../../_constants";
import { useHistory } from "react-router-dom";
import $ from "jquery";
import { apiCaller } from "../../../_helpers";
import moment from "moment";

function HomePageManageExercise() {
  const classes = useSelector((state) => state.classes);
  const authentication = useSelector((state) => state.authentication);
  const curriculums = useSelector((state) => state.curriculums);
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setLoading] = useState(false);
  const [listExerciseHistory, setExerciseHistory] = useState([]);

  const [isLoadMore, setLoadMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [offsetFilter, setOffsetFilter] = useState(0);

  const limitDefault = configConstants.DEFAULT_LIMIT;
  const paramFilter = useSelector((state) => state.curriculums.filters.param);
  const refsScroll = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
  }, [classes]);

  const filterDefault = {
    topic: "",
    skill: [],
    level: [],
    start_time: "",
    end_time: "",
  };
  
  useEffect(async () => {
    setLoading(true);
    await apiCaller('/api_exercise_report/exercise_turn_class?class_id='+id, 'get').then((res) => {
      setExerciseHistory(res.data);
      setLoading(false)
    })
    // await dispatch(
    //   teacherActions.getHistoryExercise(
    //     id,
    //     filterDefault,
    //     "class",
    //     limitDefault,
    //     offset,
    //     classes.exercises.histories.data
    //   )
    // ).then((res) => {
    //   setExerciseHistory(res.data);
    //   if (res.data?.length !== limitDefault) {
    //     setLoadMore(false);
    //   }
    // });
    return () => {
      dispatch({
        type: curriculumConstants.CLEAR_PARAM_FILTER_CURRICULUM,
      });
    };
  }, []);

  useEffect(() => {
    if (refsScroll.current) {
      refsScroll.current?.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (refsScroll.current) {
        refsScroll.current?.removeEventListener("scroll", handleScroll);
      }
    };
  }, [refsScroll]);

  function hasDuplicates(array1, array2) {
    var combinedArray = array1.concat(array2);
    return new Set(combinedArray).size !== combinedArray.length;
  }

  let isNotUseFilter =
    isEmpty(curriculums.filters.param.grade_id) &&
    isEmpty(curriculums.filters.param.level) &&
    isEmpty(curriculums.filters.param.skill) &&
    isEmpty(curriculums.filters.param.type);

  const handleScroll = (e) => {
    if (
      refsScroll &&
      refsScroll.current?.scrollTop + refsScroll.current?.clientHeight + 5 >=
        refsScroll.current?.scrollHeight &&
      isLoadMore &&
      !isLoading
    ) {
      if (
        isEmpty(paramFilter.skill) &&
        isEmpty(paramFilter.start_time) &&
        isEmpty(paramFilter.end_time)
      ) {
        fetchData(offset + limitDefault);
      } else {
        fetchDataFilter(offsetFilter + limitDefault);
      }
    }
    setScrollPosition(refsScroll.current?.scrollTop);
  };

  const fetchData = (offset_fetchData) => {
    if ((offset != offset_fetchData || offset == 0) && !isLoading) {
      setLoading(true);
      dispatch(
        teacherActions.getHistoryExercise(
          id,
          filterDefault,
          "class",
          limitDefault,
          offset_fetchData,
          classes.exercises.histories.data
        )
      ).then((res) => {
        if (!hasDuplicates(listExerciseHistory, res.data)) {
          setLoading(false);
          setExerciseHistory((prev) => [...prev, ...res.data]);
          setOffset(offset_fetchData);
          setLoadMore(res.data?.length == configConstants.DEFAULT_LIMIT);
          setOffsetFilter(0);
          if (scrollPosition > 0 && !isLoading) {
            refsScroll.current.scrollTop = scrollPosition;
          }
        }
      });
    }
  };

  const fetchDataFilter = (offset_fetchData_Filter) => {
    if (offsetFilter != offset_fetchData_Filter && !isLoading) {
      setLoading(true);
      dispatch(
        teacherActions.getHistoryExercise(
          id,
          paramFilter,
          "class",
          limitDefault,
          offset_fetchData_Filter,
          classes.exercises.histories.data
        )
      ).then((res) => {
        if (!hasDuplicates(res.data, listExerciseHistory)) {
          setLoading(false);
          setExerciseHistory((prev) => [...prev, ...res.data]);
          setLoadMore(res.data?.length === configConstants.DEFAULT_LIMIT);
          setOffsetFilter(offsetFilter + configConstants.DEFAULT_LIMIT);
          if (scrollPosition > 0 && !isLoading) {
            refsScroll.current.scrollTop = scrollPosition;
          }
        }
      });
    }
  };

  function addComponentFilter() {
    return isEmpty(
      classes.exercises.histories && classes.exercises.histories.data
    ) && isNotUseFilter ? (
      <Fragment></Fragment>
    ) : (
      <div
        className="form-sunE-button btn-filter rel"
        onClick={() => setshowFilter()}
      >
        <img
          src={
            isNotUseFilter
              ? `/assets/images/teacher/icon_not_yet_filter.png`
              : `/assets/images/teacher/ico_filter.png`
          }
          alt="ico_add"
          className="ml--5"
        />
        <button className="btn-line-blue">Lọc</button>
      </div>
    );
  }

  const Popup = useSelector((state) => state.Popup);

  function setshowFilter() {
    dispatch({
      type: popupConstants.SHOW_FORM_POPUP,
      data: {
        showFilterCurriculum: true,
      },
    });
  }

  function setcloseFilter() {
    dispatch({
      type: popupConstants.CLEAR_ALL_POPUP,
    });
  }

  const renderHourglass = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path fill="#4D4D4D" d="M15.6 4.576V2.228C15.6 1.439 13.092 0 10 0C6.907 0 4.4 1.439 4.4 2.228v2.348C4.4 6.717 8.277 8.484 8.277 10c0 1.514-3.877 3.281-3.877 5.422v2.35C4.4 18.56 6.907 20 10 20s5.6-1.44 5.6-2.229v-2.35c0-2.141-3.877-3.908-3.877-5.422c0-1.515 3.877-3.282 3.877-5.423M5.941 2.328c.696-.439 2-1.082 4.114-1.082s4.006 1.082 4.006 1.082c.142.086.698.383.317.609c-.838.497-2.478 1.02-4.378 1.02s-3.484-.576-4.324-1.074c-.381-.225.265-.555.265-.555M10.501 10c0 1.193.996 1.961 2.051 2.986c.771.748 1.826 1.773 1.826 2.435v1.328c-.97-.483-3.872-.955-3.872-2.504c0-.783-1.013-.783-1.013 0c0 1.549-2.902 2.021-3.872 2.504v-1.328c0-.662 1.056-1.688 1.826-2.435C8.502 11.961 9.498 11.193 9.498 10s-.996-1.961-2.051-2.986c-.771-.75-1.826-1.775-1.826-2.438l-.046-.998C6.601 4.131 8.227 4.656 10 4.656s3.406-.525 4.433-1.078l-.055.998c0 .662-1.056 1.688-1.826 2.438c-1.054 1.025-2.051 1.793-2.051 2.986"/></svg>
    )
  }

  return (
    <div className="sunE-right-container rel">
      {Popup.showFilterCurriculum && (
        <PopUpFilter
          filter_exercise={true}
          filter_curriculum={false}
          onClickNo={() => setcloseFilter()}
          width={460}
          filter_files={false}
          class_name={"custom-width"}
          setExerciseHistory={setExerciseHistory}
          listExerciseHistory={listExerciseHistory}
          setOffset={setOffsetFilter}
          setOffsetOrigin={setOffset}
          setLoadMore={setLoadMore}
        />
      )}
      <Header
        title={"Giao bài"}
        // component={addComponentFilter()}
        isBack
        clickBack={() =>
          history.push("/" + authentication.role + "/class/view/" + id)
        }
      />
      {!isLoading && (
        <div className="sunE-content sunE-class-content teacher-giaobai pad0 origin-vertical">
          {isEmpty(listExerciseHistory) ? (
            <div className="sunE-no-teacher-giaobai text-center">
              <img
                src="/assets/images/teacher/giaobai/bg_no_gb.png"
                alt="bg_no_gb"
              />

              <div style={{ marginTop: 10, color: "#404041" }}>
                <span>
                  Hãy ấn{" "}
                  <strong className="font_myriad_bold_no_top">Giao bài</strong>{" "}
                  để bắt đầu giao bài tập cho học sinh
                </span>
              </div>

              <Link
                to={
                  "/" +
                  authentication.role +
                  "/class/view/" +
                  id +
                  "/exercise/student"
                }
              >
                <div className="form-sunE-button btn-giaobai-gr">
                  <button className="btn-line-blue">Giao bài</button>
                </div>
              </Link>
            </div>
          ) : (
            <div className="sunE-giaobai-content">
              <div className="flex-1 text-center">
                <Link
                  to={
                    "/" +
                    authentication.role +
                    "/class/view/" +
                    id +
                    "/exercise/student"
                  }
                >
                  <div className="form-sunE-button btn-giaobai-gr" style={{margin: 0}}>
                    <button className="btn-line-blue">Giao bài</button>
                  </div>
                </Link>
              </div>
              <div className="p-h-16 p-v-10">
                  <p className="font-weight-bold fz-13-75 bottom-10">Danh sách đã giao</p>
                  <div className="origin-vertical scrollbar-custom manage-exercise-list">
                    {listExerciseHistory.map(item => (
                      <div key={item?.turn_id} className="p-v-10 origin-vertical gap-8 manage-exercise-item">
                        <div className="manage-exercise-item-range-date">
                          <span>{moment(item?.start_date).format('DD/MM/YYYY') + ' - ' + moment(item?.end_time).format('DD/MM/YYYY')}</span>
                        </div>
                        <div className="d-flex">
                          <div className="d-flex gap-4 w-per-25">
                            {item?.type == 3 && !!item?.minute_assign ? renderHourglass() : <img src="/assets/images/icon/icon_clarity_book-line.png" width={20}/>}
                            <span>{item?.type == 3 && !!item?.minute_assign ? `${item?.minute_assign} phút` : `${item?.total_homework} bài tập`}</span>
                          </div>
                          <div className="d-flex gap-4 w-per-25">
                            <img src="/assets/images/icon/icon_clarity_avatar-line.png" width={20}/>
                            <span>{`${item?.total_student} học sinh`}</span>
                          </div>
                          <div className="d-flex gap-4 w-per-25">
                            <img src="/assets/images/icon/ico_formkit_time.png" width={22}/>
                            <span>{`Giao ngày ${moment(item?.assign_date).format('DD/MM/YYYY')}`}</span>
                          </div>
                        </div>
                        <div className="flex flex-m">
                          <span>
                            <span 
                              style={{
                                color: item?.type == 1 ? '#EB5757' : item?.type == 2 ? '#FF7A00' : '#84C241', 
                                fontWeight: 700}}
                            >
                              {item?.type == 1 ? 'Giao bài tự do' : item?.type == 2 ? 'Giao bài theo yêu cầu' : 'Giao bài theo năng lực'}
                            </span>
                            {' '}
                            {item?.unit !== null && Number(item?.unit) >= 0 && <span>{'- Unit ' + item?.unit}</span>}
                          </span>
                          <Link 
                            to={
                              "/" +
                              authentication.role +
                              "/class/view/" +
                              id +
                              "/exercise/detail-assigned/" + item?.turn_id +
                              '?startTime=' + moment(item?.start_date).format('DD/MM/YYYY') + '&endTime=' + moment(item?.end_time).format('DD/MM/YYYY')
                            } 
                            className="manage-exercise-item-link"
                          >
                            Xem chi tiết
                          </Link>
                        </div>
                      </div>
                    ))}
                  </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export { HomePageManageExercise };
