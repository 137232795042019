import React from "react";

import "./ResultCurriculumPage.styles.scss";
import ButtonNews from "../../../../_components/Button/ButtonNews";
import { resultCurriculumPageLogic } from "./ResultCurriculumPage.logic";
import { isEmpty } from "lodash";

export const ResultCurriculumPage = () => {
  let {
    gradeStudent,
    handleCompletedChoose,
    resultPlacementTest,
    handleAnotherChooseCurriculum,
    secondsToTime,
    isLoading,
  } = resultCurriculumPageLogic();

  const renderCurriculum = (resultPlacementTest, gradeStudent) => {
    return (
      <div className="curriculum-card-item-container flex-center ">
        <div className={`curriculum-card-item active_card_item`}>
          <div className="curriculum-thumb-pointer">
            <img
              src={`${
                resultPlacementTest?.program_default?.id == 1
                  ? "/assets/images/rootlessnessCourse/Giao_trinh_co_ban.png"
                  : "/assets/images/rootlessnessCourse/Giao_trinh_mat_goc.png"
              }`}
              className="thumb-curriculum"
            />
          </div>
          <div className="info-curriculum flex-column-center-cross-axis">
            <div className="div-heading-curriculum flex-jus-start-between">
              <div className="heading-curriculum-pointer">
                <h1
                  className={`heading-curriculum ${
                    resultPlacementTest?.program_default?.id == 1
                      ? "color_heading_class_1"
                      : "color_heading_class_2"
                  }`}
                >
                  {resultPlacementTest?.program_default?.name}
                </h1>
              </div>
              <div className="curriculum-suggest-pointer">
                <div className="curriculum-header-suggest flex-jus-start-between">
                  <img
                    src="/assets/images/rootlessnessCourse/Starfish_mark.png"
                    alt="icon Star"
                    className="contain_image curriculum-header-suggest-icon"
                  />
                  <span className="curriculum-header-suggest-content">
                    Đề xuất
                  </span>
                </div>
              </div>
            </div>
            <h2 className="class-curriculum">
                {/* {gradeStudent && gradeStudent} */}
            </h2>
            <div className="description-curriculum">
              {resultPlacementTest?.program_default?.description}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {_.isEmpty(resultPlacementTest) && isLoading && (
        <div
          style={{
            background: `url(
        /assets/images/bg_gv.png
      ) no-repeat center center /contain`,
          width: "100vw", height: "100vh"}}
        ></div>
      )}
      {resultPlacementTest && !_.isEmpty(resultPlacementTest) && (
        <>
          <div
            className="curriculum_result_header"
            style={{
              background: `url(
         /assets/images/rootlessnessCourse/bg_result_placement_test.png
       ) no-repeat center center /cover`,
            }}
          >
            <div className="curriculum_result_heading flex-center">
              <h1 className="curriculum_result_heading_font">
                Điểm kiểm tra đầu vào
              </h1>
            </div>
            <div className="line_result flex-center">
              <img
                src="/assets/images/rootlessnessCourse/line_result.svg"
                className="line-white"
              />
            </div>
            <div className="curriculum_result_content flex-center">
              <div className="curriculum_result_score">
                <h1
                  className="curriculum_result_score_font"
                  title={
                    resultPlacementTest &&
                    parseFloat(
                      parseFloat(resultPlacementTest?.data[0]?.score).toFixed(1)
                    )
                  }
                >
                  {resultPlacementTest &&
                    parseFloat(
                      parseFloat(resultPlacementTest?.data[0]?.score).toFixed(1)
                    )}
                </h1>
              </div>
              <div className="curriculum_result_score_detail">
                <div className="curriculum_result_score_detail_time flex-start">
                  <img
                    src="/assets/images/rootlessnessCourse/time_result.png"
                    className="time_result_icon"
                  />

                  <p className="time_result_font">
                    {resultPlacementTest &&
                      secondsToTime(resultPlacementTest.data[0]?.duration)}
                  </p>
                </div>
                <div className="curriculum_result_score_detail_question flex-start">
                  <img
                    src="/assets/images/rootlessnessCourse/question_result.png"
                    className="question_result_icon"
                  />
                  <p className="question_result_font">
                    {resultPlacementTest &&
                      `${resultPlacementTest.data[0]?.number_true}/${resultPlacementTest.data[0]?.total_question}`}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="curriculum-container"
            style={{
              background: `url(
            /assets/images/rootlessnessCourse/bg_first_step.png
            ) no-repeat bottom center /cover`,
            }}
          >
            <div className="curriculum-header">
              <h2 className="curriculum_result_testing_heading">
                Dựa vào kết quả bài kiểm tra, chúng tôi đề xuất bạn học
              </h2>
              <h1 className="curriculum-header-heading">
                {resultPlacementTest &&
                  `“${resultPlacementTest?.program_default?.name}”`}
              </h1>
            </div>
            <div className="curriculum-content">
              <div className="curriculum-cards flex-center">
                {renderCurriculum(resultPlacementTest, gradeStudent)}
              </div>
            </div>
            <div className="curriculum-footer flex-center-custom">
              <div className="button-action flex-center">
                <div className="btn_custom_left">
                  <ButtonNews
                    width={"10.5rem"}
                    maxWidth={"100%"}
                    height={"2.5rem"}
                    onClick={handleAnotherChooseCurriculum}
                    className="btn_custom"
                  >
                    <span className="font_btn_custom_left">
                      Chọn giáo trình khác
                    </span>
                  </ButtonNews>
                </div>
                <div className="btn_custom_right">
                  <div className={`font_btn_custom btn_custom_continue`}>
                    <ButtonNews
                      width={"10rem"}
                      maxWidth={"100%"}
                      height={"2.5rem"}
                      className="btn_custom"
                      onClick={handleCompletedChoose}
                    >
                      Đồng ý
                    </ButtonNews>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
