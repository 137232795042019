import React, { useState, useEffect } from "react";
import { Header } from "../../../_components/Admin/Header";
import { teacherService } from "../../../_services";
import { useSelector } from "react-redux";

function PolicyAndTermPage() {
  const authentication = useSelector((state) => state.authentication);
  const cachedDeviceId = localStorage.getItem("device_id_" + authentication.id);
  const [dataPolicy, setDataPolicy] = useState("");
  
  const typeAbout = authentication.role == "student" ? "0" : "1";

  useEffect(() => {
    teacherService.getSettingDetail(cachedDeviceId, typeAbout).then((data) => {
      setDataPolicy(data.data_setting.app_info.policy);
    });
  }, []);

  const policyInfor = document.getElementById("policy_infor");
  if (policyInfor && dataPolicy) {
    policyInfor.innerHTML = dataPolicy;
  }
  return (
    <div className="sunE-right-container">
      <Header title="Chính sách và điều khoản" isBack />
      <div className="term-info box-shadow" id="policy_infor"></div>
    </div>
  );
}

export { PolicyAndTermPage };
