import React from 'react';
import { Header } from '../../../_components/Admin/Header';

function SupportPage() {
    return (
        <div className="sunE-container flex">
            <div className="sunE-right-container flex-column">
                <Header title='Hỗ trợ' isBack />
                <div className="sunE-container-box setting support flex-1">
                    <div className="box-shadow box-sp">
                        <p className="text-center">
                            Để được nhanh chóng hỗ trợ về sản phẩm học tập và các chức năng của ứng dụng, các bạn vui lòng liên hệ qua số hotline dưới đây.
                        </p>
                        <div className="hotline-sp center-flex">
                            <div className="text-center">
                                <img src="/assets/images/setting/ico_support.png" className="ico_err" alt="ico_support" />
                                <span>LIÊN HỆ QUA HOTLINE - 0979996125</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { SupportPage };