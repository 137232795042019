import React from "react";
import { isJsonString } from "../../../_helpers/utils";

export default function ResultQuestionMatching({dataQuestion, skill, question_type, answer, infoQuestion, numberReview}) {
  let finalAnswer = JSON.parse(answer);

  const listData = dataQuestion[0].list_option?.map(item => {
    const userChoice = finalAnswer.find(ans => {
      const words = isJsonString(ans?.words) ? JSON.parse(ans?.words) : ans?.words
      return item?.option_text == words
    })
    return {
      ...item,
      userChoice
    }
  }) || [];

  const renderItem = (item, index) => {
    const explainQuestion =  item?.question_explain?.content_question_text || item?.option_explain || ""
    return (
      <div>
        <p style={{
            marginLeft: 8, 
            flex: 1,
            display: 'flex',
            gap: 4
            // textAlign: 'justify',
          }}
        >
          <p style={{fontWeight: 'bold',}}>{(!!numberReview ? numberReview : (index + 1)) + '. '}</p>
          {item?.option_text}
        </p>
        {!!item?.userChoice?.user_choice && <p style={{
            color: item?.userChoice?.score > 0 ? '#62B440' : 'red',
            marginLeft: 30
          }}
        >
          {isJsonString(item?.userChoice?.user_choice) ? JSON.parse(item?.userChoice?.user_choice) : item?.userChoice?.user_choice}
        </p>}
        {(item?.userChoice?.score == 0 || item?.userChoice?.score === undefined) && <div style={{flexDirection: 'row', display: 'flex', marginLeft: 30}}>
          <img 
            src="/assets/images/ico_hover_answer.png"
            style={{
              width: 24,
              height: 20,  
            }}
          />
          <p style={{
              marginLeft: 16, 
              flex: 1,
              // textAlign: 'justify',
              color: '#62B440',
            }}
          >
            {item?.match_option_text?.[0]}
          </p>
        </div>}
        {explainQuestion && <p style={{
            paddingLeft: 16,
            paddingTop: 2
            // textAlign: 'justify'
          }}>
            <p style={{
              fontWeight: 'bold',
          }}>Giải thích: {' '}</p>
            {explainQuestion}
          </p>}
      </div>
    )
  }

  const paragraph = infoQuestion?.lesson_paragraph || infoQuestion?.paragraph;

  return (
    <div style={{flex: 1, marginTop: 16}}>
      {paragraph ? <p style={{
              paddingBottom: 16
          }}>
              {paragraph?.trim()}
          </p> : null
      }
      <div style={{display: 'flex', flexDirection: 'column', gap: 32}}>
        {listData?.map((item, index) => {
          return (
            <div key={index}>
              {renderItem(item, index)}
            </div>
          )
        })}
      </div>
    </div>
  )
}