import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { alertActions, teacherActions } from "../../../_actions";
import { Header } from "./../../../_components/Admin/Header";
import {
  curriculumConstants,
  popupConstants,
  teacherConstants,
} from "../../../_constants";
import moment from "moment";
import {
  PopUpFilter,
  PopUpYesNo,
  PopUpAddFile,
} from "../../../_components/Popup";
import { Alert } from "./../../../_components/Alert";
import { isEmpty } from "lodash";
import $ from "jquery";
import { configConstants } from "../../../_constants";
import { PopUpZoomImageV2 } from "../../../_components/Popup/PopUpZoomImageV2";
import LazyLoad from "react-lazyload";
import { history } from "../../../_helpers";

function HomePageResource() {
  const dispatch = useDispatch();
  const curriculums = useSelector((state) => state.curriculums);
  const exercises = useSelector((state) => state.classes.exercises);
  const Popup = useSelector((state) => state.Popup);
  const alert = useSelector((state) => state.alert);
  const [visibleModalUpdateSoon, setVisibleModalUpdateSoon] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [fileActive, setFileActive] = useState({});
  const [showHideDisplay, setShowHideDisplay] = useState(false);
  const [htmlWritingContent, setHtmlWritingContent] = useState([]);
  const [openZoomImage, setOpenZoomImage] = useState(false);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
  }, [exercises]);

  useEffect(() => {
    setLoading(true);
    dispatch(teacherActions.getFileExercise(curriculums.filters.param));
    return () => {
      dispatch({
        type: curriculumConstants.CLEAR_PARAM_FILTER_CURRICULUM,
      });
    };
  }, []);

  function setshowFilter() {
    dispatch({
      type: popupConstants.SHOW_FORM_POPUP,
      data: {
        showFilterCurriculum: true,
      },
    });
  }

  function setclosePopup() {
    dispatch(alertActions.error({ message: "", screen: "" }));
    dispatch({
      type: popupConstants.CLEAR_ALL_POPUP,
    });
  }

  function componentFilter() {
    let isEmptyData =
      isEmpty(curriculums.filters.param.grade_id) &&
      isEmpty(curriculums.filters.param.skill) &&
      isEmpty(curriculums.filters.param.type);
      
    if (exercises.files && exercises.files.data.length || !isEmptyData) {
      return (
        <button
          className="btn-line-blue btn-filter-custom rel"
          onClick={() => setshowFilter()}
        >
          <img
            src={`/assets/images/teacher/${
              isEmptyData ? "icon_not_yet_filter" : "ico_filter"
            }.png`}
            alt="ico_filter"
          />
          Lọc
        </button>
      );
    }
  }

  const [idRmove, setIdRemove] = useState(0);
  const [showFormRemove, setShowFormRemove] = useState(false);

  function setShowRemove(id) {
    setShowFormRemove(true);
    setIdRemove(id);
  }

  function revemoFile(id) {
    setShowFormRemove(false);
    setIdRemove(0);
    dispatch(
      teacherActions.deleteFileExercise({
        id: idRmove,
        param: curriculums.filters.param,
      })
    );
  }

  function setshowAddFile() {
    dispatch({
      type: popupConstants.SHOW_FORM_POPUP,
      data: {
        showAddFile: true,
      },
    });
  }

  const setIsBack = () => {
    if (showHideDisplay) {
      setShowHideDisplay(false);
      setFileActive({});
    } else {
      history.go(-1);
    }
  };

  function renderIcoFile(data) {
    if (data.type === "document") {
      let arr = data.path.split(".");
      let fileFormat = arr[arr.length - 1];
      if (["doc", "docx"].includes(fileFormat)) {
        return (
          <img
            src={`/assets/images/teacher/giaobai/ico_doc.png`}
            alt={data.type}
          />
        );
      } else if (["xlsx", "xls", "xlsm", "xlsb"].includes(fileFormat)) {
        return (
          <img
            src={`/assets/images/teacher/giaobai/ico_excel.png`}
            alt={data.type}
          />
        );
      } else if (["ppt", "pptx", "pps", "ppsx", "pptm"].includes(fileFormat)) {
        return (
          <img
            src={`/assets/images/teacher/giaobai/ico_ppt.png`}
            alt={data.type}
          />
        );
      } else if (fileFormat === "pdf") {
        return (
          <img
            src={`/assets/images/teacher/giaobai/ico_pdf.png`}
            alt={data.type}
          />
        );
      } else {
        return (
          <img
            src={`/assets/images/teacher/giaobai/ico_${data.type}.png`}
            alt={data.type}
          />
        );
      }
    } else {
      return (
        <img
          src={`/assets/images/teacher/giaobai/ico_${data.type}.png`}
          alt={data.type}
        />
      );
    }
  }
  
  const openWindow = (file) => {
    setFileActive(file);
    if (["video", "writing", "audio", "img"].includes(file.type)) {
      setShowHideDisplay(true);
    } else {
      window.open(file?.path, "_blank");
    }
  };

  const detailImage = () => {
    // window.open(fileActive?.path);
    setOpenZoomImage(true);
  };

  let isNotUseFilter =
    isEmpty(curriculums.filters.param.grade_id) &&
    isEmpty(curriculums.filters.param.level) &&
    isEmpty(curriculums.filters.param.skill) &&
    isEmpty(curriculums.filters.param.type);

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  useEffect(() => {
    if (fileActive.type == "writing") {
      const htmlFileContent = fileActive?.content?.split("\n");
      // console.log(htmlFileContent);
      setHtmlWritingContent(htmlFileContent);
    }
  }, [fileActive.type]);

  return (
    <div className="sunE-right-container flex-column">
      {Popup.showAddFile && (
        <PopUpAddFile
          param={curriculums.filters.param}
          onClickNo={() => setclosePopup()}
          width={400}
          messageErr={
            alert.screen === teacherConstants.SCREEN_LIST_FILE
              ? alert.message
              : ""
          }
          class_name={"custom-width"}
          onSubmit={() => setShowHideDisplay(false)}
        />
      )}
      {alert?.message &&
        alert?.screen === teacherConstants.SCREEN_LIST_FILE &&
          <Alert
            alert={alert}
          />
      }
      {Popup.showFilterCurriculum && (
        <PopUpFilter
          onClickNo={() => setclosePopup()}
          width={460}
          filter_files={true}
          class_name={"custom-width"}
        />
      )}
      {showFormRemove && (
        <PopUpYesNo
          onClickYes={() => revemoFile()}
          onClickNo={() => setShowFormRemove(false)}
          labelNo={"Không"}
          message={[
            "Bạn có chắc chắn muốn xoá",
            "Hướng dẫn học tập này",
            "ra khỏi danh sách ?",
          ]}
        />
      )}
      {/* {Popup.addFileCompleteResource && <PopUpYesNo onClickYes={() => setclosePopup()}  width={320} labelNo={'Không'} onClickNo={() => setclosePopup(false)} message={['Tạo hướng dẫn thành công !', 'Bạn có muốn thêm hướng dẫn', 'vào bài tập không ?']} />} */}
      {/* {alert.message && alert.screen === teacherConstants.SCREEN_LIST_FILE && <Alert alert={alert} />} */}
      <Header
        title={"Hướng dẫn học tập"}
        component={componentFilter()}
        isBack
        clickBack={setIsBack}
      />
      <div className="sunE-container-box giaobai-support huong-dan-hoc-tap flex-1">
        {!isLoading && (
          <LazyLoad
            once
            className="sunE-giaotrinh-list scrollbar-custom h-giaobai-support-list"
          >
            {!showHideDisplay &&
              exercises.files &&
              exercises.files.data.map((data, i) => {
                return (
                  <div
                    className="box-giaotrinh-gr rel box-shadow hei-custom flex-m"
                    key={i}
                  >
                    <div
                      className="flex-m"
                      style={{ flex: 1 }}
                      onClick={() => openWindow(data)}
                    >
                      <div className="thumb2">{renderIcoFile(data)}</div>
                      <div className="box-select-info flex-1">
                        <p className="semibold text-left one-line">
                          {data.title}
                        </p>
                        <span>
                          Ngày tạo:{" "}
                          {moment(data.created_at).format("DD/MM/yyyy")}
                        </span>
                        <span>
                          {data?.grade_name} {" >> "}{" "}
                          {capitalizeFirstLetter(data?.skill)}
                        </span>
                      </div>
                    </div>
                    <div
                      className="btn-remove-item center-flex"
                      onClick={() => setShowRemove(data.id)}
                    >
                      <img
                        src="/assets/images/teacher/ico_remove_white.png"
                        alt="ico_remove_blue"
                      />
                    </div>
                  </div>
                );
              })}
            {!showHideDisplay &&
              exercises.files &&
              exercises.files.data.length === 0 &&
              (isNotUseFilter ? (
                <div className="__text_empty_tutorial_guide">
                  <img src="/assets/images/empty_excercise.png" />
                  <br />
                  Bạn chưa tạo nội dung hướng dẫn học tập nào.
                  <br /> Chọn <strong className="__semibold">
                    "Tạo mới"
                  </strong>{" "}
                  để tạo hướng dẫn.
                </div>
              ) : (
                <span className="__text_empty_tutorial_guide">
                  Không tìm thấy kết quả phù hợp.
                </span>
              ))}
            {showHideDisplay && (
              <div>
                <div className="box-giaotrinh-gr rel box-shadow hei-custom">
                  <div className="flex-m" style={{ flex: 1 }}>
                    <div className="thumb2">{renderIcoFile(fileActive)}</div>
                    <div className="box-select-info flex-1">
                      <p className="semibold text-left one-line">
                        {fileActive?.title}
                      </p>
                    </div>
                  </div>
                </div>
                {fileActive?.type === "writing" && (
                  <div className="box-content-writing-study-guide rel box-shadow hei-custom">
                    {htmlWritingContent.map((item, index) => (
                      <div key={index}>{item}</div>
                    ))}
                  </div>
                )}
                {fileActive?.type === "video" && (
                  <LazyLoad
                    once
                    className="box-content-study-guide rel hei-custom"
                  >
                    <video controls="controls" className="content-study-guide">
                      <source
                        src={fileActive?.path}
                        alt={fileActive?.type}
                        type="video/mp4"
                      />
                    </video>
                  </LazyLoad>
                )}
                {fileActive?.type === "audio" && (
                  <div className="box-content-study-guide rel hei-custom">
                    <audio controls="controls">
                      <source
                        src={fileActive?.path}
                        alt={fileActive?.type}
                        type="audio/mpeg"
                      />
                    </audio>
                  </div>
                )}
                {fileActive?.type === "img" && (
                  <div className="box-content-study-guide rel hei-custom">
                    {console.log("=====fileActive3")}
                    <LazyLoad once className="pos_rel">
                      <img
                        className="content-study-guide"
                        src={encodeURI(fileActive?.path)}
                        alt={fileActive?.type}
                      />
                      <img
                        onClick={detailImage}
                        className="contain_image pos_abs ico_zoom_out"
                        src="/assets/images/icon/ico_zoom_out.png"
                        alt="Icon Zoom out"
                      />
                    </LazyLoad>
                  </div>
                )}
              </div>
            )}
          </LazyLoad>
        )}
      </div>
      <div
        className="form-sunE-button text-right mg-10-0"
        onClick={() => setshowAddFile()}
      >
        <button className="btn-line-blue ">Tạo mới</button>
      </div>

      {visibleModalUpdateSoon && (
        <PopUpYesNo
          onClickYes={() => removeCurriculum(curriculum.id)}
          onClickNo={() => setVisibleModalUpdateSoon(false)}
          message={
            "Tính năng xem chi tiết hướng dẫn học tập đang cập nhật. Vui lòng đăng nhập app để sử dụng."
          }
          labelNo={"Đóng"}
          styleGroupBtn={{ paddingLeft: 30, paddingRight: 30 }}
          width={"278px"}
          hideButtonYes={true}
        />
      )}

      {openZoomImage && (
        <PopUpZoomImageV2
          src={fileActive?.path}
          height="90%"
          onClickNo={() => setOpenZoomImage(false)}
        />
      )}
    </div>
  );
}

export { HomePageResource };
