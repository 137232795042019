import React from 'react';
import { alertActions } from '../../_actions';
import { useDispatch } from 'react-redux';

function AlertSuccess(props) {
    const {isAutoClose = true} = props
    const dispatch = useDispatch();

    let autoClose = setTimeout(() => {
        isAutoClose && complete();
    }, 2000);

    function complete() {
        clearTimeout(autoClose);
        props.onComplete();
        props?.onCompletePopup && props.onCompletePopup();
        dispatch(alertActions.clear());
    }

    function close() {
        props?.onClosePopup && props.onClosePopup();
        dispatch(alertActions.clear());
    }
    if (props.notShowComplete) {
        return null;
    } else {
        return (
            // <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
            //     <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
            //         <p className="text-center">{props.message}</p>
            //         <div className="form-sunE-button">
            //             <button className="btn-line-blue" onClick={close}>Đóng</button>
            //         </div>
            //     </div>
            // </div>
            <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open" uk-modal="">
                <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                    <p className="text-center white_space_pre_wrap">{props.message}</p>
                    <div className="form-sunE-button">
                        {props?.hasBtnClosePopup && <button className="btn-default mr-20" style={{width: 'unset', padding: '0 24px'}} onClick={close}>{props?.textClose || 'Đóng'}</button>}
                        <button className="btn-line-blue" onClick={complete}>{props?.textComplete || 'Đóng'}</button>
                    </div>
                </div>
            </div>

        );
    }

}

export { AlertSuccess };