import { teacherConstants, userConstants } from "../../_constants";
var initialState = {
  dataClassPersist: {}
};

const persistClassTeacher = (state = initialState, action) => {
  switch (action.type) {
    case teacherConstants.SAVE_DATA_CLASS:
      return {
        ...state,
        dataClassPersist: {
          ...state.dataClassPersist,
          [action.payload.id]: {
            data: action.payload.data,
            timeGetData: new Date()
          }
        }
      }
    case userConstants.RESET_ALL_STATE:
      return initialState;
    default:
      return state;
  }
};

export { persistClassTeacher };
