import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import { teacherService } from "../../../_services";
import { PopUpAddCommentRollUp } from "../../../_components/Popup";
import {
  PopUpSelectObjectRollUp,
  PopUpYesNo,
} from "../../../_components/Popup";
import { SelectDate } from "./../../../_components/Calendar";
import { SelectAsDiv } from "../../../_components/Select";
import { teacherConstants } from "./../../../_constants";
import { Alert } from "./../../../_components/Alert";
import { useHistory } from "react-router-dom";
import moment from "moment";

function AddExamCard() {
  const history = useHistory();
  const alert = useSelector((state) => state.alert);
  const dispatch = useDispatch();
  const { id, cardId } = useParams();
  const params = new URL(document.location).searchParams;
  const dateSelected = params.get("date")
  const examNameSelected = params.get("examName")
  const authentication = useSelector((state) => state.authentication);
  const classes = useSelector((state) => state.classes);
  const [isSendedBox, checkSendBox] = useState(false);
  const [isSHowConfirmEdit, showConfirmEdit] = useState(false);

  const [students, setStudents] = useState({
    data: [],
  });

  const [dataAddExamCard, setdataAddExamCard] = useState({
    exam_mark_detail: [],
    is_send_parent: false,
    send_content: "student",
    exam_name: examNameSelected || "",
    date_test: dateSelected ? new Date(dateSelected) : new Date(),
    type: "oral",
    score_percent: "1",
    semester: "1",
    class_id: id,
  });

  const [validate, setValidate] = useState(false);
  let {
    exam_mark_detail,
    is_send_parent,
    exam_name,
    date_test,
    score_percent,
    type,
    semester,
  } = dataAddExamCard;
  useEffect(() => {
    teacherService.getStudentOfClass(id, 999).then((students) => {
      setStudents(students);
      if (!cardId) {
        setdataAddExamCard({
          ...dataAddExamCard,
          exam_mark_detail: students.data,
        });
      }
    });
    dispatch(teacherActions.getExamcardOffline(id));
  }, []);

  useEffect(() => {
    if (cardId) {
      teacherService.getExamCardDetail(cardId).then((res) => {
        if (res) {
          setdataAddExamCard({
            exam_mark_detail: res.data,
            is_send_parent: parseInt(res.card_data.is_send_parent) === 1,
            send_content: res.card_data.send_content,
            exam_name: res.card_data.exam_name,
            date_test: moment(
              res.card_data.date_test,
              "YYYY-MM-DD hh:mm:ss"
            ).toDate(),
            type: res.card_data.type,
            score_percent: res.card_data.score_percent,
            semester: res.card_data.semester,
            class_id: id,
          });
          setValidate(true);
          checkSendBox(parseInt(res.card_data.is_send_parent) === 1);
        }
      });
    }
  }, [cardId]);

  function handleSubmit() {
    if (validateParam()) {
      if (cardId) {
        dispatch(
          teacherActions.updateExamCard({
            ...dataAddExamCard,
            is_send_parent: is_send_parent ? 1 : 0,
            date_test: moment(dataAddExamCard?.date_test).format("YYYY-MM-DD HH:mm"),
            card_id: cardId,
          })
        );
        showConfirmEdit(false);
      } else {
        dispatch(
          teacherActions.createExamCard({
            ...dataAddExamCard,
            date_test: moment(date_test).format("YYYY-MM-DD HH:mm"),
            is_send_parent: is_send_parent ? 1 : 0,
          })
        );
      }
    }
  }

  function validateParam() {
    return exam_name && date_test ? true : false;
  }

  function avalidate(s) {
    var rgx = /^[0-9]*\.?[0-9]*$/;
    return s.match(rgx);
  }

  function handleChange(e, data, key) {
    let { name, value } = e.target;
    if (Number(value) > 10) {
      value = 10;
    }
    let countLength = (value + "").length;
    if ((value && value <= 10 && countLength <= 4) || countLength === 0) {
      data[name] = value;
      exam_mark_detail[key] = data;
      setdataAddExamCard({
        ...dataAddExamCard,
        exam_mark_detail,
      });

      let temp_exam_mark_detail = [];
      let not_valid_data = false;
      for (let i = 0; i < exam_mark_detail.length; i++) {
        if (
          !exam_mark_detail[i].score ||
          exam_mark_detail[i].score < 0 ||
          exam_mark_detail[i].score > 10
        ) {
          not_valid_data = true;
        }
        if (cardId) {
          temp_exam_mark_detail.push({
            member_id: exam_mark_detail[i].member_id,
            score: exam_mark_detail[i].score,
            user_name: exam_mark_detail[i].user_name,
            comment: exam_mark_detail[i].comment,
            avatar: exam_mark_detail[i].avatar,
            fullname: exam_mark_detail[i].fullname,
            student_card_id: exam_mark_detail[i].student_card_id,
          });
        } else {
          temp_exam_mark_detail.push({
            member_id: exam_mark_detail[i].member_id,
            score: exam_mark_detail[i].score,
            user_name: exam_mark_detail[i].fullname,
            comment: exam_mark_detail[i].comment,
            avatar: exam_mark_detail[i].avatar,
            fullname: exam_mark_detail[i].fullname,
          });
        }
      }
      if (not_valid_data) {
        setValidate(false);
      } else {
        setdataAddExamCard({
          ...dataAddExamCard,
          exam_mark_detail: temp_exam_mark_detail,
        });
        setValidate(true);
      }
    }
  }

  const specialCharacters = ['@', '#', '$', '%' , '^', '!', '&', '*', '=', '_', '<', '>', '?', '/', '\\', '|', '[', ']', '{', '}', '(', ')', '"', `'`, ':', ';'];

  const [showPopupAddComment, setShowPopupAddComment] = useState(false);

  const [dataPopupComment, setDataPopupComment] = useState({
    key: 0,
    title: "",
    value: "",
  });

  function openPopUpComment(key, title, value) {
    setDataPopupComment({
      ...dataPopupComment,
      key,
      title,
      value,
    });
    setShowPopupAddComment(true);
  }

  function handleChangeComment(comment, key) {
    exam_mark_detail[key]["comment"] = comment;
    // console.log(comment, key)
    setdataAddExamCard({
      ...dataAddExamCard,
      exam_mark_detail,
    });
  }

  const [showPopupSelectObject, setShowPopupSelectObject] = useState(false);
  function handleChangeSendParent() {
    if (!is_send_parent) {
      setShowPopupSelectObject(true);
    }
    setdataAddExamCard({
      ...dataAddExamCard,
      is_send_parent: !is_send_parent,
    });

  }

  return (
    <div className="sunE-right-container flex-column scrollbar-custom">
      <Header title={!cardId ? "Phiếu điểm kiểm tra" : exam_name} isBack />
      {isSHowConfirmEdit && (
        <PopUpYesNo
          onClickYes={() => handleSubmit()}
          onClickNo={() => showConfirmEdit(false)}
          labelNo={"Không"}
          message={["Bạn có chắc chắn muốn Lưu thông tin đã chỉnh sửa không?"]}
        />
      )}
      {alert.message &&
        alert.screen === teacherConstants.SCREEN_ADD_EXAM_CARD && (
          <Alert
            alert={alert}
            onComplete={() =>
              history.push(
                "/" + authentication.role + "/class/view/" + id + "/exam_card/"
              )
            }
          />
        )}
      {showPopupSelectObject && (
        <PopUpSelectObjectRollUp
          onClickNo={() => setShowPopupSelectObject(false)}
          onClickYes={(send_content) =>
            setdataAddExamCard({ ...dataAddExamCard, send_content })
          }
          title="Gửi điểm danh"
        />
      )}
      {/* {showPopupAddComment && (
        <PopUpAddCommentRollUp
          title={dataPopupComment.title}
          value={dataPopupComment.value}
          onClickNo={() => setShowPopupAddComment(false)}
          onClickYes={(comment) => handleChangeComment(comment)}
        />
      )} */}
      <div className="sunE-container-box diemdanh offline flex-1">
        <div className="flex-m">
          <div className="flex-1"></div>
          {/* {!isSendedBox ? (
            <h2 className="medium-title text-right checbox-right">
              Gửi phụ huynh
              <span className="chk-custom-gr wh20">
                <input
                  type="checkbox"
                  id="all"
                  onChange={handleChangeSendParent}
                />
                <label htmlFor="all" />
              </span>
            </h2>
          ) : (
            <span className="flex-m __checked_send_exam">
              <img src="/assets/images/icon/ico_check_calendar.png" />
              Đã gửi phụ huynh
            </span>
          )} */}
        </div>
        <div className="flex-m">
          {
            <div className="box-bg-gr">
              <div className="box-shadow box-bg-content">
                <div className="menu-gr-right">
                  <input
                    type="text"
                    name="exam_name"
                    placeholder="Nhập tên bài kiểm tra"
                    value={exam_name}
                    onBlur={e => {
                      setdataAddExamCard({
                        ...dataAddExamCard,
                        exam_name: e.target.value.trim()
                      })
                    }}
                    onChange={(e) =>
                      setdataAddExamCard({
                        ...dataAddExamCard,
                        exam_name: e.target.value,
                      })
                    }
                    className="btn"
                  />
                  <SelectAsDiv
                    keySelected={semester}
                    keyName="name"
                    textDefault={"Chọn Hệ số"}
                    data={teacherConstants.TEXT_LIST_SEMESTER}
                    onChangeSelect={(semester) =>
                      setdataAddExamCard({ ...dataAddExamCard, semester })
                    }
                    className="hoc-ky btn-ob"
                  />
                  <SelectAsDiv
                    keySelected={type}
                    id="type"
                    keyName="name"
                    textDefault={"Chọn Hệ số"}
                    data={classes.exam_card.list_exam_type}
                    onChangeSelect={(type) =>
                      setdataAddExamCard({ ...dataAddExamCard, type })
                    }
                    className="kiem-tra-mieng btn-ob"
                  />
                  <SelectAsDiv
                    keySelected={score_percent}
                    id="type"
                    keyName="name"
                    textDefault={"Chọn Hệ số"}
                    data={classes.exam_card.list_score_percent}
                    onChangeSelect={(score_percent) =>
                      setdataAddExamCard({ ...dataAddExamCard, score_percent })
                    }
                    className="he-so btn-ob"
                  />
                  <SelectDate
                    // minDate
                    // disableMouseFocus={true} 
                    // showTimeSelect={true}
                    timeIntervals={15}
                    dateFormat="dd/MM/yyyy"
                    name="date_test"
                    selected={date_test}
                    onChange={(date_test) =>
                      setdataAddExamCard({ ...dataAddExamCard, date_test })
                    }
                  />
                </div>
              </div>
            </div>
          }
          <div className="diemdanh-container flex-1">
            <div className="list-giaobai diemdanh-list-hs scrollbar-custom pr-0">
              {exam_mark_detail.map((data, i) => {
                return (
                  <div className="item-student flex-m box-shadow rel" key={i}>
                    <div className="item-student-img">
                      <img
                        src={students.base_url + "/" + data.avatar}
                        alt="avatar"
                      />
                    </div>
                    <div className="flex-1 item-student-name pl-15">
                      <h2 className="semibold">
                        {!cardId ? data.fullname : data.user_name}
                      </h2>
                      <input className="__input_note_exam_card" placeholder="Viết nhận xét..." defaultValue={data?.comment || ''}
                        onBlur={(e) => handleChangeComment(e.target.value, i)}
                      />
                    </div>
                    <div className="flex-m" style={{ alignItems: cardId ? "center" : "flex-start" }}>
                      <div
                        style={{ marginLeft: 8 }}
                        className="score-bg-blue flex-center-custom"
                      >
                        <p>Điểm</p>
                        <input
                          max={10}
                          onWheel={(e) => e.target.blur()}
                          step="any"
                          type="number"
                          name="score"
                          key={i}
                          onChange={(e) => handleChange(e, data, i)}
                          placeholder="..."
                          value={data?.score?.toString() || ''}
                          onKeyDown={(e) => {
                            if (
                              e.which === 38 ||
                              e.which === 40 ||
                              e.key === "-" ||
                              e.key === "+" ||
                              e.key === "e" ||
                              e.key === "," ||
                              specialCharacters.includes(e.key)
                            ) {
                              return e.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="flex-1 text-right">
        <button
          className={"btn-line-blue" + (!validateParam() ? " btn-disable" : "")}
          onClick={() => {
            !cardId ? handleSubmit() : showConfirmEdit(true);
          }}
        >
          {"Lưu"}
        </button>
      </div>
    </div>
  );
}

export { AddExamCard };
