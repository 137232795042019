import "./ModalAuth.style.scss";
import { HashLink } from "react-router-hash-link";

const ModalAuth = (props) => {
  let { title, desc1, desc2, linkAuth, children } = props;

  return (
    <div className="flex-center-column modalAuth">
      <div className="fz-20 text_uppercase title_modal_auth fz-12-5-mobile text-align-center font_news_bold mg-bottom-0-5">
        {title}
      </div>
      <div className="flex-center col-100 mg-bottom-2">
        <div className="modal_desc_1 fz-12-5 fz-09-mobile">{desc1}</div>
        <HashLink
          to={linkAuth}
          className="font_news_bold fz-12-5 fz-09-mobile color-primary-green"
        >
          {desc2}
        </HashLink>
      </div>

      {children}
    </div>
  );
};

export default ModalAuth;
