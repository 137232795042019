export const studentConstants = {
    GET_ALL_GRADE: 'GET_ALL_GRADE',
    GET_LIST_DATA_LEARN: 'GET_LIST_DATA_LEARN',
    GET_LIST_EXERCISE_STUDENT: 'GET_LIST_EXERCISE_STUDENT',
    ADD_PARENT_COMPLETE: 'Bạn đã gửi liên kết thành công. Hãy chờ phụ huynh xác nhận.',
    SCREEN_ADD_PARENT: 'SCREEN_ADD_PARENT',
    SCREEN_APPLY_CLASS: 'SCREEN_APPLY_CLASS',
    GET_ASSESSMENT_ONLINE: 'GET_ASSESSMENT_ONLINE',
    GET_ASSESSMENT_OFFLINE: 'GET_ASSESSMENT_OFFLINE',
    GET_ASSESSMENT_RANKING: 'GET_ASSESSMENT_RANKING',
    GET_DATA_LEARNING_HISTORY_ASSESSMENT: 'GET_DATA_LEARNING_HISTORY_ASSESSMENT',
    GET_TIME_ASSESSMENT_ONLINE: 'GET_TIME_ASSESSMENT_ONLINE',
    GET_TIME_ASSESSMENT_OFFLINE: 'GET_TIME_ASSESSMENT_OFFLINE',
    GET_TIME_ASSESSMENT_RANKING: 'GET_TIME_ASSESSMENT_RANKING',
    GET_LOG_LEARNING_BY_DATE: 'GET_LOG_LEARNING_BY_DATE',
    GET_LIST_LOG_LEARNING: 'GET_LIST_LOG_LEARNING',
    APPLY_FOR_CLASS_COMPLETE: 'Xin vào lớp thành công',
    ASSESSMENT_RANK: 'rank',
    ASSESSMENT_OFFLINE: 'offline',
    POINT_A: 'A',
    POINT_B: 'B',
    POINT_C: 'C',
    POINT_D: 'D',
    POINT_E: 'E',
    RANK_UP: 'up',
    RANK_DOWN: 'down',
    TEXT_LIST_FILTER_DAY: [{
        'name': 'Lọc theo ngày',
        'id': 'day'
    },
    {
        'name': 'Lọc theo tuần',
        'id': 'week'
    },
    {
        'name': 'Lọc theo tháng',
        'id': 'month'
    },

    ],
    LIST_CLASS_FILTER: "LIST_CLASS_FILTER",
    LIST_ADDRESS_FILTER: "LIST_ADDRESS_FILTER",
    GET_REDIRECT_URL: "GET_REDIRECT_URL",
    STATUS_HOMEWORK: "STATUS_HOMEWORK",
    CLEAR_STATUS_HOMEWORK: "CLEAR_STATUS_HOMEWORK",
    SCREEN_LESSSON_SKILL: 'SCREEN_LESSSON_SKILL',
    HOMEWORK_SELECTED: 'HOMEWORK_SELECTED',
    GET_DATA_EXERCISE_TEACHER: 'GET_DATA_EXERCISE_TEACHER',
    GET_DATA_EXERCISE_PARENT: 'GET_DATA_EXERCISE_PARENT',
    GET_DATA_EXERCISE_CURRICULUM: 'GET_DATA_EXERCISE_CURRICULUM',
    GET_RANKING_STUDENT_CLASS: "GET_RANKING_STUDENT_CLASS",
    GET_RANKING_CLASS: "GET_RANKING_CLASS",
    GET_EXAM_15: "GET_EXAM_15",
    GET_EXAM_45: "GET_EXAM_45",
    GET_EXAM_60: "GET_EXAM_60",
    GET_EXAM_CLASS_10: "GET_EXAM_CLASS_10",
    GET_ENTRANCE_SCORE: "GET_ENTRANCE_SCORE",
};