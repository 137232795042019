import { customBarLogic } from "./CustomBarRange.logic";
import { convertTitleAssign, vietsubSkillName } from "../../_base/Validate";
import "./CustomBarRange.style.scss";
import { useSelector } from "react-redux";

const CustomBarRange = (props) => {
  let { study_data_item, setMovePercent, currUnit, totalUnit, levelSkills, isLostRoot } =
    props;
  let { handleTimeDrag } = customBarLogic(props);

  const exercises = useSelector((state) => state.classes.exercises);
  
  if(study_data_item && levelSkills) {

    study_data_item.list_skill_en = levelSkills?.filter(
      (item) =>
      item?.level?.toLocaleLowerCase() ==
        convertTitleAssign(study_data_item?.stand).toLocaleLowerCase()
    );
  } 

  const curPercentage = (study_data_item?.practice_percent / 100) * 100;

  const renderLevelSkill = (data, index) => {
    return (
      <div
        key={data?.toString() + index.toString()}
        className="font_style_italic skill_item fz-1 text-align-center"
      >
        {vietsubSkillName(data?.skill?.toLocaleLowerCase())}
      </div>
    );
  };

  return (
    <div className="flex-column-start bar_range">
      {!isLostRoot && <div className="color-title-assign fz-14 font_myriad_bold pd-bottom-0-5">
        Trình độ {convertTitleAssign(study_data_item?.stand)}
      </div>}

      {Array.isArray(exercises?.students) &&
        exercises?.students.length == 1 && (
          <div className="flex-align pd-bottom-0-7">
            {study_data_item?.list_skill_en?.map((data, index) =>
              renderLevelSkill(data, index)
            )}
          </div>
        )}
      <div
        className="bar__progress col-100 mg-bottom-0-7"
        style={{
          background: `linear-gradient(to right, #F8AF36 ${curPercentage}%, #E9DECD 0)`,
        }}
        onMouseDown={(e) => handleTimeDrag(e)}
      >
        <span
          className="bar__progress__knob"
          style={{ left: `${curPercentage - 3}%` }}
        />
      </div>

      <div className="flex-jus-between">
        <div className="font_style_italic fz-11-25">
          Ôn luyện {`(${study_data_item?.practice_percent}%)`}
        </div>

        <div className="font_style_italic fz-11-25">
          Kiến thức mới{" "}
          {`(${100 - parseInt(study_data_item?.practice_percent)}%)`}
        </div>
      </div>
    </div>
  );
};

export default CustomBarRange;
