import { studentConstants } from "../../_constants";
import { userConstants } from "../../_constants";
var initialState = {
  rankingStudent: null,
  rankingClass: null,
};

const classesStudent = (state = initialState, action) => {
  switch (action.type) {
    case studentConstants.GET_RANKING_STUDENT_CLASS:
      return {
        ...state,
        rankingStudent: {
          ...state.rankingStudent,
          [action.payload.classId]: {
            data: action.payload.data,
            timeGetData: new Date(),
          },
        }
      }
    case studentConstants.GET_RANKING_CLASS:
      return {
        ...state,
        rankingClass: {
          ...state.rankingClass,
          [action.payload.classId]: {
            data: action.payload.data,
            timeGetData: new Date(),
          },
        }
      }
    case userConstants.RESET_ALL_STATE:
      return initialState;
    default:
      return state;
  }
};

export { classesStudent };
