import ReactGA from "react-ga4";

const measurementId = "G-MCRZHNJX5H"; // Thay thế bằng ID đo lường của bạn

export const initGA = () => {
  ReactGA.initialize(measurementId);
};

export const logPageView = () => {
  ReactGA.send("pageview");
};

export const logEvent = (eventName, eventParams = {}) => {
  ReactGA.event(eventName, eventParams);
  console.log("Event sent:", eventName, eventParams);
};
