import { useState, useEffect } from "react";

export const benefitParentLogic = () => {
  // Data Content Parent
  const [dataContentParent, setDataContentParent] = useState([
    {
      id: 1,
      title: 'SUNDAY PARENT',
      topContent: "Ba mẹ có thể giao bài cho con",
      outstanding_1: "NGAY CẢ KHI KHÔNG BIẾT",
      tailContent_1: "Tiếng Anh",
      outstanding_2: null,
      tailContent_2: null,
      items: [
        {
          id: 1,
          name: "Dễ dàng – Ba mẹ chỉ cần nhập lên hệ thống thời gian mong muốn con học tiếng Anh",
        },
        {
          id: 2,
          name: "Hiệu quả – Sunday English tự động giao bài phù hợp với học lực của con theo thời gian ba mẹ mong muốn",
        },
        {
          id: 3,
          name: "Nhanh chóng – Ba mẹ nhận được báo cáo tức thì về tình hình làm bài tập của con",
        },
      ],
    },
    {
      id: 2,
      topContent: "Nội dung học",
      outstanding_1: "BÁM SÁT",
      tailContent_1: "sách giáo khoa, ",
      outstanding_2: "ĐÚNG TRỌNG TÂM",
      tailContent_2: "các kỳ thi",
    },
    {
      id: 3,
      topContent: "Phương pháp học tập",
      outstanding_1: "NGHIÊM TÚC SÁT SAO",
      tailContent_3: " mang lại kết quả học tập",
      outstanding_3: "TỨC THÌ",
      tailContent_2: null,
    },
    {
      id: 4,
      topContent: null,
      outstanding_1: "PHÁT TRIỂN TOÀN DIỆN",
      tailContent_1_1:
        "4 kỹ năng, từ dễ tới khó phù hợp với mọi trình độ của con",
      outstanding_2: null,
      tailContent_2: null,
    },
    {
      id: 5,
      topContent: null,
      outstanding_1: "DỄ DÀNG QUẢN LÝ",
      tailContent_1: "thời gian và kết quả học tập của con",
      outstanding_2: null,
      tailContent_2: null,
    },
    {
      id: 6,
      topContent: null,
      outstanding_1: "TIẾT KIỆM THỜI GIAN",
      tailContent_1_1: "đưa đón con đi học thêm",
      outstanding_2: null,
      tailContent_2: null,
    },
    {
      id: 7,
      topContent: "CHI PHÍ",
      outstanding_1: "THẤP",
      tailContent_3: ", HIỆU QUẢ",
      outstanding_3: "CAO",
      tailContent_2: null,
    },
  ]);

  // Slide Teacher
  const dataImgSlideParent = [
    {
      id: 1,
      url: "/assets/images/homeNews/content/banner_3.png",
    },
    {
      id: 2,
      url: "/assets/images/homeNews/content/banner_4.png",
    },
    {
      id: 3,
      url: "/assets/images/homeNews/content/banner_4.png",
    },
    {
      id: 4,
      url: "/assets/images/homeNews/content/banner_3.png",
    },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return { dataContentParent, dataImgSlideParent };
};
