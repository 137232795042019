import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";
import { createStore, applyMiddleware, compose } from "redux";
import appReducers from "./_reducers";
import { Provider } from "react-redux";
import reduxThunk from "redux-thunk";
import GlobalStyles from "./_components/GlobalStyles";
import { persistStore, persistReducer } from "redux-persist";
import ReduxPersist from "./_config/ReduxPersist";
import { PersistGate } from "redux-persist/integration/react";

const middleware = [reduxThunk];

const persistedReducer = persistReducer(ReduxPersist.storeConfig, appReducers);

export const store = createStore(
  persistedReducer,
  compose(
    applyMiddleware(...middleware),
    typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "undefined"
      ? (a) => a
      : window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export const persistor = persistStore(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <GlobalStyles>
        <App />
      </GlobalStyles>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
registerServiceWorker();
