import "./ScrollFixed.style.scss";
import { scrollFixedLogic } from "./ScrollFixed.logic";

const ScrollFixed = () => {
  let { handleScrollTop, handleNavigate } = scrollFixedLogic();

  return (
    <>
      <div className="box_fixed">
        {/* <div onClick={handleScrollTop}>
          <img
            src="/assets/images/homeNews/icon/ico_scroll_up.png"
            alt="Icon Scroll Up"
            className="icon_scale mg-bottom-0-5 icon_scroll_fixed"
          />
        </div> */}
        {/* <div>
          <img
            src="/assets/images/homeNews/icon/ico_box_chat.png"
            alt="Icon Box Chat"
            className="icon_scale icon_scroll_fixed"
          />
        </div> */}
      </div>
    <div className="enable-mobile">
      <div className="box-left_fixed">
        <div onClick={() => handleNavigate('/parent_news')}>
          <img
            src="/assets/images/homeNews/icon/icon_text_ph.png"
            alt="Icon phu huynh"
            className="icon_scale mg-bottom-0-5 icon_scroll_fixed"
          />
        </div>
        <div onClick={() => handleNavigate('/teacher_news')}>
          <img
            src="/assets/images/homeNews/icon/icon_text_gv.png"
            alt="Icon giao vien"
            className="icon_scale icon_scroll_fixed"
          />
        </div>
      </div>
    </div>
    </>
  );
};
export default ScrollFixed;
