import React, { useEffect, useState } from "react";
import { HeaderCloud, HeaderTS } from "../../_components/Header";
import { stepAuthConstants } from "../../_constants/auth";
import { apiCaller, history } from "../../_helpers";
import AccountType from "./components/AccountType";
import CreateAccount from "./components/CreateAccount/index";
import RegisterSuccess from "./components/RegisterSuccess";
import UpdateInformation from "./components/UpdateInformation";
import { userConstants } from "../../_constants";
import API from "../../_apis/APIConstants";
import APIBase from "../../_base/APIBase";
import { useDispatch } from "react-redux";
import moment from "moment";
import { isEmpty } from "lodash";
import AddClassName from "./components/AddClassName";

function RegisterPage() {
  const params = new URL(location.href).searchParams;
  const org = params.get('org');
  const role = params.get('role');

  const [step, setStep] = useState(stepAuthConstants.STEP_ACCOUNT_TYPE);
  const dispatch = useDispatch();
  const [dataCreateAccount, setDataCreateAccount] = useState({
    phone: "",
    email: "",
    password: "",
    rePassword: "",
  });

  let initialRole = ''
  if(!!org) {
    if(!!role) 
      initialRole = role;
    else 
      initialRole = userConstants.ROLE_STUDENT
  }
  const [dataStepRole, setDataStepRole] = useState({
    role: initialRole,
    grade: "",
    schoolType: "",
  });
  const [dataClassName, setDataClassName] = useState({})
  const [dataUpdateInfo, setDataUpdateInfo] = useState({
    fullname: "",
    birthday: "",
    gender: "",
    phone: "",
    province: "",
    district: "",
    school: "",
    onRegister: false,
    school_id: "",
  });
  const [statusRegister, setStatusRegister] = useState({
    type: "",
    message: "",
  });
  const [messageTeacher, setMessageTeacher] = useState("");
  const [popupMessageRegisterTeacher, setPopupMessageRegisterTeacher] =
    useState(false);
  const [saveDataUser, setSaveDataUser] = useState({});
  const [statusSuccessRegisterTeacher, setStatusSuccessRegisterTeacher] =
    useState();

  useEffect(() => {
    if (dataUpdateInfo.onRegister) {
      onRegister();
    }
  }, [dataUpdateInfo]);

  const onClickYes = () => {
    const data = {
      email: dataCreateAccount.email ? dataCreateAccount.email : undefined,
      password: dataCreateAccount.password,
      re_password: dataCreateAccount.rePassword,
      role: dataStepRole.role,
      class: dataStepRole.grade,
      school_type: dataStepRole.schoolType,
      school: dataUpdateInfo.school,
      birthday: dataUpdateInfo.birthday,
      grade_id: dataStepRole.grade,
      fullname: dataUpdateInfo.fullname,
      gender: dataUpdateInfo.gender,
      province_alias: dataUpdateInfo.province,
      district_alias: dataUpdateInfo.district,
      phone: dataCreateAccount.phone ? dataCreateAccount.phone : undefined,
      year_of_birth: dataUpdateInfo.birthday?.getFullYear(),
      school_id: dataUpdateInfo?.school_id,
      organization_code : org,
      class_nickname: dataStepRole.role === userConstants.ROLE_STUDENT ? dataClassName?.class_nickname : undefined,
      list_class: dataStepRole.role === userConstants.ROLE_TEACHER ? dataClassName?.list_class  : undefined
    };
    data.birthday = moment(dataUpdateInfo.birthday).format("YYYY-MM-DD");
    setPopupMessageRegisterTeacher(false);
    history.push(`/register/authentication`, { data: data });
  };

  const onRegister = async () => {
    const data = {
      email: dataCreateAccount.email ? dataCreateAccount.email : undefined,
      password: dataCreateAccount.password,
      re_password: dataCreateAccount.rePassword,
      role: dataStepRole.role,
      class: dataStepRole.grade,
      school_type: dataStepRole.schoolType,
      school: dataUpdateInfo.school,
      birthday: dataUpdateInfo.birthday,
      grade_id: dataStepRole.grade,
      fullname: dataUpdateInfo.fullname,
      gender: dataUpdateInfo.gender,
      province_alias: dataUpdateInfo.province,
      district_alias: dataUpdateInfo.district,
      phone: dataCreateAccount.phone ? dataCreateAccount.phone : undefined,
      year_of_birth: dataUpdateInfo.birthday?.getFullYear(),
      school_id: dataUpdateInfo?.school_id,
      organization_code : org,
      class_nickname: dataStepRole.role === userConstants.ROLE_STUDENT ? dataClassName?.class_nickname : undefined,
      list_class: dataStepRole.role === userConstants.ROLE_TEACHER ? dataClassName?.list_class  : undefined,
    };
    if (userConstants.ROLE_TEACHER == dataStepRole.role) {
      data.birthday = moment(dataUpdateInfo.birthday).format("YYYY-MM-DD");
      try {
        let urlApi = `${API.baseURL}${API.register_teacher}`;
        const result = await APIBase.apiBaseCaller("POST", urlApi, data);
        if (result.status) {
          if(!!dataCreateAccount.phone) {
            setMessageTeacher(
              "Đăng ký tài khoản thành công. Vui lòng xác thực để có thể đăng nhập và sử dụng tài khoản."
            );
            setPopupMessageRegisterTeacher(true);
            setStatusSuccessRegisterTeacher(true);
          } else {
            setStatusRegister({
                type: "success",
                message: result.msg,
            });
          }
        } else {
          if (!isEmpty(Object.values(result?.message?.errors)?.flat())) {
            setMessageTeacher(Object.values(result?.message?.errors)?.flat());
            setPopupMessageRegisterTeacher(true);
            setStatusSuccessRegisterTeacher(false);
          }
        }
      } catch (error) {
        if (!isEmpty(Object.values(error?.message?.errors)?.flat())) {
          setMessageTeacher(Object.values(error?.message?.errors)?.flat());
          setPopupMessageRegisterTeacher(true);
          setStatusSuccessRegisterTeacher(false);
        }
      }
    } else {
      const newData = {
        ...data,
        auto_active: !!org ? 1 : 0,
      }
      apiCaller("/api_register/register", "POST", newData).then((res) => {
        if (res.status) {
          if(!!dataCreateAccount.phone) {
            setMessageTeacher(
              "Đăng ký tài khoản thành công. Vui lòng xác thực để có thể đăng nhập và sử dụng tài khoản."
            );
            setPopupMessageRegisterTeacher(true);
            setStatusSuccessRegisterTeacher(true);
          } else {
            setStatusRegister({
                type: "success",
                message: res.msg,
            });
          }
        } else {
            setMessageTeacher(result?.msg);
            setPopupMessageRegisterTeacher(true);
            setStatusSuccessRegisterTeacher(false);
        }
      }).catch((error) => {
        setMessageTeacher(error);
        setPopupMessageRegisterTeacher(true);
        setStatusSuccessRegisterTeacher(false);
      })
    }
  };

  return (
    <div className="bg-register rel">
      {!!org ? <HeaderTS /> : <HeaderCloud />}
      {statusRegister.type !== "success" ? (
        <>
          <div
            style={{
              display:
                step === stepAuthConstants.STEP_CREATE_ACCOUNT
                  ? "block"
                  : "none",
            }}
          >
            <CreateAccount
              setStep={setStep}
              data={dataCreateAccount}
              setData={setDataCreateAccount}
              org={org}
              role={dataStepRole.role}
            ></CreateAccount>
          </div>
          <div
            style={{
              display:
                step === stepAuthConstants.STEP_ACCOUNT_TYPE ? "block" : "none",
            }}
          >
            <AccountType
              setStep={setStep}
              data={dataStepRole}
              setData={setDataStepRole}
              step={step}
              org={org}
            ></AccountType>
          </div>
          <div
            style={{
              display:
                step === stepAuthConstants.STEP_ADD_CLASS_NAME ? "block" : "none",
            }}
          >
            <AddClassName
              setStep={setStep}
              data={dataClassName}
              setData={setDataClassName}
              step={step}
              role={dataStepRole.role}
            />
          </div>
          <div
            style={{
              display:
                step === stepAuthConstants.STEP_UPDATE_ACCOUNT
                  ? "block"
                  : "none",
            }}
          >
            <UpdateInformation
              setStep={setStep}
              data={dataUpdateInfo}
              setData={setDataUpdateInfo}
              onRegister={onRegister}
              step={step}
              role={dataStepRole.role}
              schoolType={dataStepRole.schoolType}
              messageTeacher={messageTeacher}
              popupMessageRegisterTeacher={popupMessageRegisterTeacher}
              setPopupMessageRegisterTeacher={setPopupMessageRegisterTeacher}
              dataUser={saveDataUser}
              statusSuccessRegisterTeacher={statusSuccessRegisterTeacher}
              onClickYes={onClickYes}
              org={org}
            ></UpdateInformation>
          </div>
        </>
      ) : (
        <RegisterSuccess org={org}></RegisterSuccess>
      )}
    </div>
  );
}

export { RegisterPage };
