import React, { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import "./index.scss";
import useOutsideClick from "../../../_helpers/customHook/useOutsideClick";
import { PresentToAllSharp } from "@material-ui/icons";

// interface Props {
//     value: OptionInputRadio;
//     setValue: (..._args: any[]) => void;
//     label: string;
//     className?: string;
//     options: OptionInputRadio[];
//     inputEditable?: boolean;
//     placeholder: string;
//     isWarning?: boolean;
//     setIsWarning?: (..._args: any[]) => void;
//     disabledClick?: boolean;
// }

const InputRadio = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const $selectRef = useRef(null);
  const isOpenCached = useRef(null);

  useEffect(() => {
    isOpenCached.current = isOpen;
  }, [isOpen]);

  useOutsideClick($selectRef, () => {
    if (isOpenCached.current) {
      setIsOpen(false);
    }
  });

  // console.log(props.value);

  return (
    <div
      className={classnames(
        {
          [props.className]: props.className,
          focus: isOpen,
          warning_select: props.errorText,
        },
        "input_radio_base"
      )}
      ref={$selectRef}
    >
      {props.errorText && props?.typeErrText != "underAbsolute" ? (
        <div
          className={
            props.errorAbsolute
              ? "error_text_absolute error_select"
              : "error_text error_select"
          }
        >
          <span>{props.errorText}</span>
        </div>
      ) : null}
      <div
        className="input_select"
        onClick={() => {
          props.isWarning && props.setIsWarning(false);
          props.setErrorText && props.setErrorText("");
          if (!props.disabledClick) {
            setIsOpen(!isOpen);
          } else {
            props.onClickDisable && props.onClickDisable();
          }
        }}
      >
        <div
          className={`input_select_main_wrapper ${props.classNameProps}`}
          style={{
            marginBottom: props.mgBottom,
          }}
        >
          <div className="input_select_main">
            {props.label ? <span className="">{props.label}</span> : null}
            <div className="icon_label">
              {props.renderLabelIcon ? props.renderLabelIcon() : null}
            </div>
            <input
              name={props.name}
              className={`no_select cursor_pointer input_text ${
                (
                  typeof props?.value == "object"
                    ? props?.value?.title != ""
                    : props?.value != ""
                )
                  ? props.colorInput
                  : ""
              }`}
              value={
                typeof props?.value == "object"
                  ? props?.value?.title || ""
                  : props?.value || ""
              }
              disabled={props.inputEditable ? false : true}
              placeholder={props.placeholder}
              onChange={(e) => {
                if (props.inputEditable) {
                  props.setValue({
                    value: e.target?.value,
                    title: e.target?.value,
                  });
                }
              }}
              style={props.inputEditable ? {} : { pointerEvents: "none" }}
            ></input>
            <div className="icon  icon_dropdown cursor_pointer">
              <svg
                width="23"
                height="14"
                viewBox="0 0 26 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={classnames({
                  rotate_180: isOpen,
                })}
                style={{ transition: "all 0.3s" }}
              >
                <path
                  d="M13.2809 15.704L0.507935 2.93097L2.74695 0.692017L13.2611 11.206L23.7389 0.539978L25.9979 2.75897L13.2809 15.704Z"
                  fill={props.fillColor || "#8695A9"}
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div
        className={classnames(
          {
            active: isOpen,
          },
          `drop_down`
        )}
        style={{}}
      >
        <div className="drop_down_wrapper ">
          {props.options.map((option) => {
            return (
              <div
                className="option_item no_select"
                key={option?.value}
                onClick={() => {
                  props.setValue(option);
                  setIsOpen(false);
                }}
              >
                <span
                  className={classnames(
                    {
                      active:
                        option.value === props.value?.value ||
                        option.value === props.value,
                    },
                    "title color-text"
                  )}
                >
                  {option.title}
                </span>
              </div>
            );
          })}
        </div>
      </div>
      {props.errorText && props?.typeErrText == "underAbsolute" ? (
        <div
          className={
            props.errorAbsolute
              ? "error_text_absolute_new error_select"
              : "error_text error_select"
          }
        >
          <span>{props.errorText}</span>
        </div>
      ) : null}
    </div>
  );
};

export default InputRadio;
