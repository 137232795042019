import "./LoginNews.style.scss";
import { HeaderNews } from "../../../_components/Header";
import { TypeHeaderNewsItem } from "../../../_constants/headerNews";
import FooterAuth from "../../../_components/FooterNews/FooterAuth";
import ModalAuth from "../../../_components/Modal/ModalAuth/ModalAuth";
import InputText from "../../../_components/Auth/InputText";
import { loginNewsLogic } from "./LoginNews.logic";
import ButtonNews from "../../../_components/Button/ButtonNews";
import { Alert } from "../../../_components/Alert";
import { useSelector } from "react-redux";
import { userConstants } from "../../../_constants";
import { PopUpResentEmail } from "../../../_components/Popup";
import GoogleLogin from "react-google-login";
import { configConstants } from "../../../_constants";
import { history } from "../../../_helpers";

const LoginNews = () => {
  let {
    email,
    password,
    emailError,
    passwordError,
    disabledBtnLogin,
    msgResent,
    onBlurField,
    setEmail,
    setPassword,
    setEmailError,
    setPasswordError,
    changeEmail,
    changePassword,
    handleLogin,
    handleNavigateAuth,
    handleResentEmail,
    handleCloseModalResent,
    onLoginGoogleSuccess,
  } = loginNewsLogic();

  const alert = useSelector((state) => state.alert);

  const renderAuthIcon = (type) => {
    return (
      <div className="flex-center">
        <img
          src={`/assets/images/authNews/ico_${type}.png`}
          alt="Icon Input"
          className="contain_image img_label_input"
        />
      </div>
    );
  };

  return (
    <div className="loginNew loginAbsolute commondPage">
      <HeaderNews type={TypeHeaderNewsItem.LOGIN} />
      <div
        className="content flex-column"
        style={{
          background: `url(
              /assets/images/authNews/bg_login.png
            ) no-repeat top center /cover`,
        }}
      >
        <div className="content_1 flex-center-column">
          <ModalAuth
            title="Đăng nhập"
            desc1="Thành viên mới?"
            desc2="Đăng ký ngay"
            linkAuth={"/register_news"}
          >
            {alert.message &&
              alert.message !==
                "Tài khoản chưa được kích hoạt, vui lòng kiểm tra email và kích hoạt tài khoản trước khi sử dụng." &&
              alert.screen === userConstants.SCREEN_LOGIN && (
                <Alert alert={alert} />
              )}
            <form
              onSubmit={handleLogin}
              className="form_input_mobile form_input_login"
            >
              <InputText
                value={email}
                setValue={changeEmail}
                type="text"
                name="email"
                placeholder="Địa chỉ Email"
                required={true}
                renderLabelIcon={() => renderAuthIcon("email")}
                errorText={emailError}
                onBlur={() => {
                  onBlurField("email");
                }}
                setErrorText={setEmailError}
                errorAbsolute={true}
                onFocus={() => setEmail(email?.trim())}
                // autoComplete={savePassword ? "on" : "new-password"}
              ></InputText>
              <InputText
                value={password}
                setValue={changePassword}
                type="password"
                name="password"
                placeholder="Nhập mật khẩu"
                required={true}
                renderLabelIcon={() => renderAuthIcon("password")}
                errorText={passwordError}
                setErrorText={setPasswordError}
                onBlur={() => {
                  onBlurField("password");
                }}
                onFocus={() => setPassword(password?.trim())}
                // autoComplete={savePassword ? "on" : "new-password"}
              ></InputText>

              <div className="flex-jus-between btn_auth_container">
                <ButtonNews
                  disabled={disabledBtnLogin}
                  width="15rem"
                  height="3.5rem"
                  onClick={() => handleLogin()}
                  border={"1px solid #00cc83"}
                  backgroundColor={"#00cc83"}
                  boxShadow={"2px 2px 5px white, 0 0 1px black"}
                  maxWidth={"47%"}
                  maxHeight={"7vw"}
                >
                  <div className="fz-12  fz-08-mobile  color-white font_news_bold">
                    ĐĂNG NHẬP
                  </div>
                </ButtonNews>

                <ButtonNews
                  width="15rem"
                  height="3.5rem"
                  onClick={() => handleNavigateAuth("forgot_password_news")}
                  border={"1px solid #00cc83"}
                  backgroundColor={"transparent"}
                  maxWidth={"47%"}
                  maxHeight={"7vw"}
                >
                  <div className="fz-12 fz-08-mobile  color-primary-green font_news_bold">
                    QUÊN MẬT KHẨU
                  </div>
                </ButtonNews>
              </div>

              <div className="flex-center-column hide">
                <div className=" fz-08-mobile ">Hoặc kết nối với</div>

                <GoogleLogin
                  clientId={configConstants.APP_ID_GOOGLE}
                  buttonText="Login"
                  cookiePolicy={"single_host_origin"}
                  onSuccess={onLoginGoogleSuccess}
                  render={(renderProps) => (
                    <div
                      onClick={() => {
                        if (!renderProps.disabled) {
                          renderProps.onClick();
                        }
                      }}
                      className="pointer_cursor box_auth_social flex-center"
                    >
                      <img
                        src="/assets/images/authNews/ico_gg.png"
                        alt="Icon Google"
                        className="contain_image icon_auth_social"
                      />
                    </div>
                  )}
                ></GoogleLogin>
              </div>
            </form>
          </ModalAuth>
        </div>
        <FooterAuth />
      </div>

      {alert.message &&
        alert.screen === userConstants.SCREEN_LOGIN &&
        alert.message ===
          "Tài khoản chưa được kích hoạt, vui lòng kiểm tra email và kích hoạt tài khoản trước khi sử dụng." && (
          <PopUpResentEmail
            message={
              "Email kích hoạt đã được gửi đến địa chỉ email của bạn. Kiểm tra và kích hoạt tài khoản."
            }
            onClickNo={() => handleCloseModalResent()}
            msgResentEmail={msgResent?.msg}
            handleResentEmail={handleResentEmail}
          />
        )}
    </div>
  );
};

export default LoginNews;
