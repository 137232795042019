import React from "react";
import _ from "lodash";
import { Header } from "../../../../../_components/Admin/Header";
import "./AssignSkills.style.scss";
import "../AssignExerciseSelection/AssignExerciseSelection.style.scss";
import { Redirect, useParams } from "react-router-dom";
import { TypeAssignExercise } from "../../../../../_constants/typeAssignExercise";
import { useSelector } from "react-redux";
import SkillsAssign from "../../../../../_components/SkillsAssign/SkillsAssign";
import { assignSkillsLogic } from "./AssignSkills.logic";

const AssignSkills = () => {
  let {
    handleGoBack,
    dataAssignSpecific,
    setDataAssignSpecific,
    handleSubmitSkills,
  } = assignSkillsLogic();

  const { id, type } = useParams();
  const authentication = useSelector((state) => state.authentication);

  // Redirect for not allow router
  if (type != TypeAssignExercise.DEMAND) {
    return (
      <Redirect
        exact={true}
        from="*"
        to={
          "/" +
          authentication.role +
          "/class/view/" +
          id +
          "/exercise/student/" +
          TypeAssignExercise.DEMAND +
          "/skills"
        }
      />
    );
  }

  return (
    <div className="sunE-right-container flex-column selection_assign skills_assign_container assign_specific_container">
      <Header
        title={"Giao bài theo yêu cầu"}
        isBack
        clickBack={() => handleGoBack()}
      />
      <div
        className={`content_selection ${
          type == TypeAssignExercise.CAPACITY
            ? " content_selection_capacity"
            : ""
        } flex-column-start  scrollbar-custom-2  pos_rel col-100`}
      >
        {dataAssignSpecific?.lesson_list_propose && (
          <SkillsAssign
            dataSkills={dataAssignSpecific}
            setDataSkills={setDataAssignSpecific}
          />
        )}
      </div>

      <div className=" form-sunE-button text-right mb0 btn_continue_selection">
        <button
          className={
            "btn-bg-blue btn-default " +
            (dataAssignSpecific?.lesson_list_propose?.every(
              (item) => item.sum <= 0
            )
              ? " btn-disable"
              : "")
          }
          onClick={handleSubmitSkills}
          disabled={dataAssignSpecific?.lesson_list_propose?.every(
            (item) => item.sum <= 0
          )}
        >
          Tiếp tục
        </button>
      </div>
    </div>
  );
};

export default AssignSkills;
