export const teacherConstants = {
  SCREEN_HOME_PAGE: "SCREEN_HOME_PAGE",
  GET_LIST_CLASS: "GET_LIST_CLASS",
  SAVE_DATA_CLASS: "SAVE_DATA_CLASS",
  SCREEN_GET_LIST_CLASS: "SCREEN_GET_LIST_CLASS",
  GET_DETAIL_CLASS: "GET_DETAIL_CLASS",
  MESSAGE_ADD_CLASS_COMPLETE: "Thêm lớp thành công",
  SCREEN_ADD_CLASS: "SCREEN_ADD_CLASS",
  GET_STUDENT_OFF_CLASS: "GET_STUDENT_OFF_CLASS",
  SCREEN_ADD_STUDENT: "SCREEN_ADD_STUDENT",
  ADD_STUDENT_COMPLETE: "Thêm học sinh thành công",
  GET_STUDENT_REQUEST: "GET_STUDENT_REQUEST",
  SCREEN_LIST_STUDENT: "SCREEN_LIST_STUDENT",
  SCREEN_REVIEW_STUDENT: "SCREEN_REVIEW_STUDENT",
  DELETE_STUDENT: "DELETE_STUDENT",
  REMOVE_REQUEST_STUDENT: "REMOVE_REQUEST_STUDENT",
  SCREEN_ADD_SCHEDULE: "SCREEN_ADD_SCHEDULE",
  SCREEN_ADD_CURRICULUM: "SCREEN_ADD_CURRICULUM",
  ADD_STUDENT: "ADD_STUDENT",
  ADD_DATA_EXERCISE: "ADD_DATA_EXERCISE",
  RESET_DATA_EXERCISE: "RESET_DATA_EXERCISE",
  GET_LIST_HISTORY_EXERCISE: "GET_LIST_HISTORY_EXERCISE",
  GET_DETAIL_HISTORY_EXERCISE: "GET_DETAIL_HISTORY_EXERCISE",
  SCREEN_ADD_STUDENT_EXERCISE: "SCREEN_ADD_STUDENT_EXERCISE",
  ADD_DATA_FILE: "ADD_DATA_FILE",
  SCREEN_ADD_EXERCISE: "SCREEN_ADD_EXERCISE",
  GET_LIST_HOMEWORK: "GET_LIST_HOMEWORK",
  GET_DETAIL_HOMEWORK: "GET_DETAIL_HOMEWORK",
  GET_DETAIL_HOMEWORK_EXERCISE: "GET_DETAIL_HOMEWORK_EXERCISE",
  SCREEN_EDIT_HOME_WORK: "SCREEN_EDIT_HOME_WORK",
  GET_LIST_CLASS_OFFLINE: "GET_LIST_CLASS_OFFLINE",
  CALL_AI_HOMEWORK: "CALL_AI_HOMEWORK",
  GET_STUDENT_RANKING: "GET_STUDENT_RANKING",
  GET_CLASS_RANKING: "GET_CLASS_RANKING",
  GET_DETAIL_CLASS_OFFLINE: "GET_DETAIL_CLASS_OFFLINE",
  ADD_TIME_ADD_ROLL_UP: "ADD_TIME_ADD_ROLL_UP",
  SCREEN_ADD_ROLL_UP: "SCREEN_ADD_ROLL_UP",
  GET_EXAM_CARD: "GET_EXAM_CARD",
  SCREEN_ADD_EXAM_CARD: "SCREEN_ADD_EXAM_CARD",
  SCREEN_LIST_FILE: "SCREEN_LIST_FILE",
  GET_LIST_REPORT_CLASS: "GET_LIST_REPORT_CLASS",
  GET_REPORT_BY_STUDENT_ID: "GET_REPORT_BY_STUDENT_ID",
  GET_LOG_LEARNING_BY_STUDENT_ID: "GET_LOG_LEARNING_BY_STUDENT_ID",
  TEXT_LIST_ROLL_UP_STATUS: [
    {
      status: "Đúng giờ",
      id: "intime",
    },
    {
      status: "Đi muộn",
      id: "late",
    },
    {
      status: "Nghỉ KP",
      id: "absence_not_allowed",
    },
    {
      status: "Nghỉ CP",
      id: "absence_allowed",
    },
  ],
  TEXT_LIST_SEMESTER: [
    {
      name: "Học kỳ I",
      id: "1",
    },
    {
      name: "Học kỳ II",
      id: "2",
    },
  ],
  TEXT_LIST_SKILL: [
    {
      name: "Pronunciation",
      id: "pronunciation",
    },
    {
      name: "Listening",
      id: "listening",
    },
    {
      name: "Vocabulary",
      id: "vocabulary",
    },
    {
      name: "Speaking",
      id: "speaking",
    },
    {
      name: "Grammar",
      id: "grammar",
    },
    {
      name: "Writing",
      id: "writing",
    },
    {
      name: "Reading",
      id: "reading",
    },
    {
      name: "Test",
      id: "test",
    },
    {
      name: "Project",
      id: "project",
    },
  ],
  GET_LIST_CRITERIA: "GET_INFO_CONTACT",
  TEXT_LIST_FILTER_TYPE_MESSAGE: [
    {
      name: "Tất cả",
      id: "all",
    },
    {
      name: "Phụ huynh",
      id: "parent",
    },
    {
      name: "Học sinh",
      id: "student",
    },
  ],
  GET_LIST_CRITERIA: "GET_LIST_CRITERIA",
  EDIT_EXERCISE: "EDIT_EXERCISE",
  SOURCE_PAGE_ADD_EXCERCISE: "SOURCE_PAGE_ADD_EXCERCISE",
  SCREEN_REMIND_STUDENT: "SCREEN_REMIND_STUDENT",
  ADD_WISH: "ADD_WISH",
  GET_LOG_LEARNING_BY_DATE: "GET_LOG_LEARNING_BY_DATE",
  ADD_FILE_ATTACH_ORIGIN: "ADD_FILE_ATTACH_ORIGIN",
  GET_DATA_SETTING_ASSIGN: "GET_DATA_SETTING_ASSIGN",
  CLEAR_DATA_EXERCISES: "CLEAR_DATA_EXERCISES",
  GET_DATA_ASSIGN_SPECIFIC: "GET_DATA_ASSIGN_SPECIFIC",
  ADD_TIME_TO_CHOOSE_FILE_SCREEN: "ADD_TIME_TO_CHOOSE_FILE_SCREEN",
  CLEAR_DATA_LESSONS: "CLEAR_DATA_LESSONS",
  ADD_DATA_LESSONS: "ADD_DATA_LESSONS",
  SCREEN_ASSIGN_HOME_WORK: "SCREEN_ASSIGN_HOME_WORK",
  ON_STATUS_ATTACH_FILE: "ON_STATUS_ATTACH_FILE",
  OFF_STATUS_ATTACH_FILE: "OFF_STATUS_ATTACH_FILE",
  SAVE_DATE_ADD_STUDENT_EXERCISE: "SAVE_DATE_ADD_STUDENT_EXERCISE",
  GET_SERVICE_TEACHER: "GET_SERVICE_TEACHER",
  GET_CLASSLIST_TEACHER: "GET_CLASSLIST_TEACHER",
  GET_GRADE_LIST: "GET_GRADE_LIST",
  CLEAR_ONLY_DATA_EXERCISES: "CLEAR_ONLY_DATA_EXERCISES",
  SCREEN_ASSIGN_SETTING: 'SCREEN_ASSIGN_SETTING',
};
