import React from 'react';
import { useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';

function SideBarAssessment(props) {
    const activeHome  = props.home;
    const authentication = useSelector(state => state.authentication);
    return (
        <div className="list-menu-custom student">
            <NavLink className="btn-gr flex-m" exact activeClassName="active" to={'/' + authentication.role + "/assessment"} >
                <span className="btn-gr-img ico_thanhtich_offline">
                    <img src="/assets/images/student/thanhtich/ico_thanhtich_offline.png" className="ico_default" alt="ico_default"/>
                    <img src="/assets/images/student/thanhtich/ico_thanhtich_offline_active.png" className="ico_active" alt="ico_default"/>
                </span>
                <div className="flex-1 btn-gr-text">
                    <p>Thành tích Online</p>
                </div>
            </NavLink>
            <NavLink className="btn-gr flex-m" exact activeClassName="active" to={'/' + authentication.role + "/assessment/offline"} >
                <span className="btn-gr-img ico_thanhtich_online">
                    <img src="/assets/images/student/thanhtich/ico_thanhtich_online.png" className="ico_default" alt="ico_default"/>
                    <img src="/assets/images/student/thanhtich/ico_thanhtich_online_active.png" className="ico_active" alt="ico_default"/>
                </span>
                <div className="flex-1 btn-gr-text">
                    <p>Thành tích Offline</p>
                </div>
            </NavLink>
            <NavLink className="btn-gr flex-m ico_xephang" exact activeClassName="active" to={'/' + authentication.role + "/assessment/rank"} >
                <span className="btn-gr-img">
                    <img src="/assets/images/student/thanhtich/ico_xephang.png" className="ico_default" alt="ico_default"/>
                    <img src="/assets/images/student/thanhtich/ico_xephang_active.png" className="ico_active" alt="ico_default"/>
                </span>
                <div className="flex-1 btn-gr-text">
                    <p>Bảng xếp hạng</p>
                </div>
            </NavLink>

            {activeHome && <div className="bxh-info-student">
                <h2 className="sub-title-18">Thành tích cá nhân</h2>
                <div className="bxh-info-student-box">
                    <div className="img-avatar-k">
                        <img src="/../assets/images/student/thanhtich/ico_avt.png" alt="ico_avt" />
                    </div>
                    <h3 className="name">Nguyễn Anh Hùng</h3>
                    <div className="gr-r flex-m">
                        <p className="flex-1">Thứ Hạng:</p>
                        <div className="flex-1">
                            <span>4</span>
                        </div>
                    </div>
                    <div className="gr-r flex-m">
                        <p className="flex-1">Điểm:</p>
                        <div className="flex-1">
                            <span>1200</span>
                        </div>
                    </div>
                    <p className="text-center f-18">Bạn đang ở hạng Lục bảo</p>
                    <div className="center-flex">
                        <img src="/../assets/images/student/thanhtich/ico_pl_lucbao.png" alt="ico_pl_lucbao" />
                    </div>
                </div>
            </div>}

        </div>
    );
}

export { SideBarAssessment };