import { studentConstants } from "../../_constants";
import { userConstants } from "../../_constants";
var initialState = {
  base_url: "",
  data: {
    latest_lesson_data: [],
    list_teacher: [],
    list_curriculum: [],
    list_home_work: [],
    list_exercise: [],
    list_exercise_by_parent: [],
    list_parent: []
  },
  exercises: {
    list_home_work: [],
  },
  homework_by_teacher: {
    list_home_work_new: [],
    list_home_work_complete: [],
  },
  startLearning: {
    redirect_url: "",
    message: "",
    status: false,
  },
  statusHomeWork: "list_home_work_new",
  homeworkSelected: undefined,
  dataExerciseTeacher: {},
  dataExerciseParent: {},
  dataExerciseCurriculum: {}
};

const learns = (state = initialState, action) => {
  switch (action.type) {
    case studentConstants.GET_DATA_EXERCISE_TEACHER:
      return {
        ...state,
        dataExerciseTeacher: {
          ...state?.dataExerciseTeacher,
          [action?.payload?.id]: {
            data: action?.payload?.data,
            timeGetData: new Date()
          }
        },
      };
    case studentConstants.GET_DATA_EXERCISE_PARENT:
      return {
        ...state,
        dataExerciseParent: {
          ...state?.dataExerciseParent,
          [action?.payload?.id]: {
            data: action?.payload?.data,
            timeGetData: new Date()
          }
        },
      };
    case studentConstants.GET_DATA_EXERCISE_CURRICULUM:
      return {
        ...state,
        dataExerciseCurriculum: {
          ...state?.dataExerciseCurriculum,
          [action?.payload?.id]: {
            data: action?.payload?.data,
            timeGetData: new Date()
          }
        },
      };
    case studentConstants.GET_LIST_DATA_LEARN:
      return {
        ...state,
        data: action.learns.data,
        base_url: action.learns.base_url,
      };
    case studentConstants.GET_LIST_EXERCISE_STUDENT:
      return {
        ...state,
        exercises: action.exercises,
      };
    case studentConstants.GET_REDIRECT_URL:
      return {
        ...state,
        startLearning: action.data,
      };
    case studentConstants.STATUS_HOMEWORK:
      return {
        ...state,
        statusHomeWork: action.data,
      }
    case studentConstants.CLEAR_STATUS_HOMEWORK:
      return {
        ...state,
        statusHomeWork: "list_home_work_new"
      }
    case studentConstants.HOMEWORK_SELECTED: 
      return {
        ...state,
        homeworkSelected: action.data
      }
    case userConstants.RESET_ALL_STATE:
      return initialState;
    default:
      return state;
  }
};

export { learns };
