import { useState } from "react"
import "./styles.scss";

export default function ReviewLaterBox({isActive, ...props}) {
  const [isHover, setIsHover] = useState(false)

  return (
    <div className="review-later-box" onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)} {...props}>
      <img 
        src={isActive ? "/assets/images/icon/ico_review_active.png" : "/assets/images/icon/ico_review_inactive.png"}
        alt="rv-later"
        className="review-later-img"
      />
      {isHover && !isActive && <div className="review-later-tool">
        Xem lại sau
      </div>}
    </div>
  )
}