import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import { isEmpty } from "lodash";
import {
  EmptyClassBlock,
  ListClassMessage,
} from "../../../_components/Admin/Teacher";
import $ from "jquery";
import { teacherService } from "../../../_services";

function HomePageMessages() {
  const dispatch = useDispatch();
  // const classes = useSelector((state) => state.classes);
  const [isLoading, setLoading] = useState(false);
  const [classes, setClasses] = useState([]);
  const [limitOnline] = useState(10);
  const [offsetOnline, setOffsetOnline] = useState(0);
  const [isEndOnlineClasses, setIsEndOnlineClasses] = useState(false);
  const [baseUrl, setBaseUrl] = useState("");
  const [isLoadMoreOnline, setLoadMoreOnline] = useState(true);
  const [isFirstLoading, setIsFirstLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      $(".loading").removeClass("hide");
    } else {
      $(".loading").addClass("hide");
    }
  }, [isLoading]);

  useEffect(() => {
    getListClass();
  }, []);

  const getListClass = async () => {
    setLoading(true);
    try {
      let resOnline = await teacherService.getListClassOnlineAsyncLimit(
        limitOnline,
        offsetOnline,
        3
      );
      setLoading(false);
      setBaseUrl(resOnline?.base_url);
      setClasses(resOnline?.data);
      isFirstLoading && setIsFirstLoading(false);
      if (resOnline.data?.length < limitOnline) setIsEndOnlineClasses(true);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  const handleScroll = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 5 &&
      isLoadMoreOnline &&
      !isLoading
    ) {
      onLoadMoreClasses();
    }
  };

  // Load More Classes for Teacher
  const onLoadMoreClasses = async () => {
    let offsetOnlineMore = offsetOnline + limitOnline;
    let concatListSide = [];
    setLoading(true);
    try {
      if (!isEndOnlineClasses) {
        let resOnline = await teacherService.getListClassOnlineAsyncLimit(
          limitOnline,
          offsetOnlineMore,
          3
        );
        concatListSide = resOnline?.data;
        setOffsetOnline(offsetOnline + limitOnline);
        if (resOnline?.data?.length < limitOnline) {
          setLoadMoreOnline(false);
          if (resOnline?.data?.length == 0) setLoadMoreOnline(false);
          setIsEndOnlineClasses(true);
        }
      } else {
        setIsEndOnlineClasses(true);
      }

      let listClassSide = classes;
      setClasses(listClassSide?.concat(concatListSide));
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  let componentClass =
    isEmpty(classes) && isEmpty(classes.dataOffline) ? (
      <EmptyClassBlock
        messagEmpty={
          "Bạn chưa có lớp học nào. Hãy tạo lớp mới và thêm học sinh để bắt đầu nhé!"
        }
      />
    ) : classes.every((data) => data.count_student <= 0) ? (
      <EmptyClassBlock
        messagEmpty={
          "Lớp học online chưa có thành viên. Bạn vui lòng thêm học sinh vào lớp để tạo cuộc trò chuyện!"
        }
      />
    ) : (
      <ListClassMessage
        onScroll={handleScroll}
        classes={classes}
        base_url={baseUrl}
      />
    );

  return (
    <div className='sunE-container flex flex'>
      <div className='sunE-right-container rel'>
        <Header title={"Tin nhắn"} />
        <div className='sunE-content sunE-class-content tinnhan'>
          {!isFirstLoading && componentClass}
        </div>
      </div>
    </div>
  );
}

export { HomePageMessages };
