import { useEffect, useState } from "react";
import { isJsonString } from "../../../_helpers/utils";
import './styles.resultLesson.scss';
import { AudioCustom, AudioV2 } from "../../../_components/Audio";
import { configConstants } from "../../../_constants";
import _ from 'lodash'

const ResultQuestionFillTheBlank = (props) => {
  const { dataQuestion, type, dataAns, answer, skill, question_type, infoQuestion } = props;

  const [userChose,setUserChose] = useState([]);

//   const isShowExplainOption = (skill == 'listening' && question_type == 4) ||
//                         (skill == 'listening' && question_type == 8) ||
//                         (skill == 'grammar' && question_type == 8) ||
//                         (skill == 'reading' && question_type == 2) ||
//                         (skill == 'reading' && question_type == 3) ||
//                         (skill == 'reading' && question_type == 4);
    const isReading10 = skill == 'reading' && question_type == 10;
  const isShowContentJsonDataRow = (skill == 'reading' && question_type == 13) ||
                                  (skill == 'listening' && question_type == 8); 
  const notShowLessonParagraph = (skill == 'reading' && question_type == 3) ||
                                  (skill == 'reading' && question_type == 2)
                                //   (skill == 'reading' && question_type == 4)
  
  useEffect(() => {
    setUserChose(JSON.parse(answer))
  }, []);

  const _renderResult = (data,option,iOption, iQues) => {
      let chose = userChose[dataQuestion?.length > 1 ? iQues : iOption];
      const user_choice = isJsonString(chose?.user_choice) ? JSON.parse(chose?.user_choice) : chose?.user_choice;
      const explainQuestion =  option?.question_explain?.content_question_text
                              || option?.option_explain
                              || "";
    const linkAudio = JSON.parse(option?.content_question)?.content_question_audio;
                
      return (
          <div style={{
            display: 'flex',
            flexDirection: isReading10 ? 'column' : 'row',
            paddingTop: 8,
          }}>
              <p style={{
                  fontSize:16,
                  paddingTop: 5
              }}>
              {dataQuestion?.length === 1 && (skill != 'pronunciation' && question_type != 9) ? ((props?.numberReview ? props?.numberReview : (iOption + 1)) + '. ') : ''} 
              {isReading10 ? option?.option_text : ''}
              </p>
              <div style={{flex:1}}>
                {linkAudio ? <div style={{paddingBottom: 16}}>
                    <AudioV2 link={linkAudio} />
                </div> : null}
                  <div style={{
                        display: 'flex',
                      flexDirection: 'column',
                      paddingLeft: 6,
                      flexWrap:'wrap'
                  }}>
                      {
                          !!user_choice ?
                          <p style={{
                              fontSize:16,
                              color: chose?.score > 0 ? '#62B440' : '#C22D39',
                              paddingTop: 5
                          }}>{user_choice}</p>
                          :
                          null
                      }
                      {
                          !Number(chose?.score) &&
                          <div style={{
                            display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              gap: 6
                          }}>
                              <img
                                style={{ height: 24, marginLeft: 16 }}
                                src="/assets/images/ico_hover_answer.png"
                              />
                              <p style={{
                                  fontSize:16,
                                  color: '#62B440',
                                  paddingTop: 5
                              }}>{option?.match_option_text[0]}</p>
                          </div>
                      }
                  </div>
                  {
                    //   isShowExplainOption && 
                      !!explainQuestion && <div
                      style={{paddingTop: 8}}
                      >
                          <p style={{fontSize:16, color: '#000'}}>Giải thích:</p>
                          <p style={{fontSize:16, whiteSpace: 'pre-wrap', color: '#000'}}>{explainQuestion}</p>
                      </div>
                  }
              </div>
          </div>
      );
  };

  const formatScript=(s, index)=>{
    const ordinalByNumberReview = !!props?.numberReview && (
        (skill == 'reading' && question_type == 13) ||
        (skill == 'grammar' && question_type == 1) ||
        (skill == 'grammar' && question_type == 4) ||
        (skill == 'grammar' && question_type == 13) ||
        (skill == 'pronunciation' && question_type == 9) 
    )
      if(!s)return '';
      let idx = 1;
      return s.replace(/{.+?}/g,function(a,v){
          return `______(${ordinalByNumberReview ? props?.numberReview : (index ? (index + 1) : idx++)})`
      })
  }

  const getListJammingAnswer = (data) => {
      let listJammingAnswer = data?.list_option[0].jamming_answer?.split(",");
      if(skill == 'grammar' && question_type == 12) {
          if (!data?.list_option[0].jamming_answer?.split(",")?.find(item => data?.list_option[0]?.match_option_text?.[0]?.trim() == item?.trim())) {
              listJammingAnswer.splice((listJammingAnswer?.length+1) * Math.random() | 0, 0, data.list_option[0]?.match_option_text)
          }
      }
      if ((skill == 'grammar' && question_type == 13) ||
          (skill == 'reading' && question_type == 3)
      ) {
          listJammingAnswer = props?.listJammingAnswer
      }
      return listJammingAnswer
  }

  if(!dataQuestion){
      return null;
  }

  const paragraph = infoQuestion?.lesson_paragraph || infoQuestion?.paragraph;

  return (
      <div>
        {!!paragraph && !notShowLessonParagraph ? <p style={{
                fontSize: 16,
                color: 'black',
                paddingBottom: 16
            }}>
                {paragraph?.trim()}
            </p> : null
        }
        {
          !!dataQuestion?.[0]?.list_option?.[0].jamming_answer && !(skill == 'grammar' && question_type == 12) ? (
              <div style={{
                  display: 'flex',
                  flexDirection:'row',
                  flexWrap:'wrap',
                  borderRadius: 16,
                  border: '1px solid gray',
                  padding: 16,
                  marginTop: 16,
                  gap: 16
              }}>
                  {
                      getListJammingAnswer(dataQuestion[0])?.map((e,i)=>{
                          return <span>
                              {e}
                          </span>
                      })
                  }
              </div>
          ):null
        }
        {dataQuestion?.map((item, index) => {
          return (
            <div style={{width: '100%', paddingTop: 24}}>
              <div >
                  {isShowContentJsonDataRow ? (
                      <>
                          <p style={{
                              // marginTop:SmartScreenBase.smPercenHeight,
                              fontSize:16,
                              textAlign:'justify',
                              color: '#000',
                              whiteSpace: 'pre-wrap'
                          }}>{(dataQuestion?.length > 1 ? (index + 1 +'. ') : '') + formatScript(item?.list_option[0]?.json_data_parse?.[index]?.row_1, index)}</p>
                          <p style={{
                              // marginTop:SmartScreenBase.smPercenHeight,
                              fontSize:16,
                              textAlign:'justify',
                              color: '#000',
                              whiteSpace: 'pre-wrap'
                          }}>{formatScript(item?.list_option[0]?.json_data_parse?.[index]?.row_2, index)}</p>
                      </>
                  ) : (
                      <p style={{
                          // marginTop:SmartScreenBase.smPercenHeight,
                          fontSize:16,
                          textAlign:'justify',
                          color: '#000',
                          whiteSpace: 'pre-wrap'
                      }}>
                        {dataQuestion?.length > 1 ? ((props?.numberReview ? props?.numberReview :  (index + 1)) +'. ') : ''}
                        {isReading10 ? '' : formatScript(item.list_option[0].question_content, index)}
                      </p>
                  )}
              </div>
              {
                !!item?.list_option?.[0].jamming_answer && (skill == 'grammar' && question_type == 12) ? (
                    <div style={{
                        display: 'flex',
                        flexDirection:'row',
                        flexWrap:'wrap',
                        borderRadius: 16,
                        border: '1px solid gray',
                        padding: 16,
                        marginTop: 16,
                        marginBottom: 12,
                        gap: 16
                    }}>
                        {
                            getListJammingAnswer(item)?.map((e,i)=>{
                                return <span>
                                    {e}
                                </span>
                            })
                        }
                    </div>
                ):null
              }
              <div>
              {
                item.list_option.map((option, key) => {
                    return (
                        <div key={key}>
                            {
                                _renderResult(item,option, key, index)
                            }
                        </div>
                    );
                })
              }
              </div>
            </div>
          )
        })}
      </div>
  );
};

export default ResultQuestionFillTheBlank;