import React, {useState, useEffect} from 'react';

const ResultQuestionFillTheSentence = (props) => {
    const {dataQuestion, answer, infoQuestion} = props;
    const [userChose,setUserChose] = useState([]);

    useEffect(() => {
            setUserChose(JSON.parse(answer))
    }, [dataQuestion]);

    const _renderResult = (item, index) => {
        const chose = userChose[index];
        const explainQuestion =  item?.question_explain?.content_question_text
                              || item?.option_explain
                              || "";
        return (
            <>
            <div style={{marginLeft: 16}}>
                {
                    chose?.user_choice != "" ? 
                        <p style={{
                            color: chose?.score > 0 ? '#62B440' : '#C22D39',
                        }}>{!!item?.hint&&`${item?.hint} `}{chose?.user_choice}</p>
                        : null
                }
                {
                    !Number(chose?.score) &&
                    <div style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                      display: 'flex'
                    }}>
                        <img
                          style={{ height: 20, marginRight: 6 }}
                          src="/assets/images/ico_hover_answer.png"
                        />
                        <p style={{
                            color: '#62B440',
                        }}>
                            {!!item?.hint&&`${item?.hint} `}{item?.match_option_text[0]}
                        </p>
                    </div>
                }
                {
                      !!explainQuestion && <div
                      style={{paddingTop: 8}}
                      >
                          <p style={{fontSize:16,}}>Giải thích:</p>
                          <p style={{fontSize:16, whiteSpace: 'pre-wrap'}}>{explainQuestion}</p>
                      </div>
                  }
            </div>
            </>
        )
    };

    const paragraph = infoQuestion?.lesson_paragraph || infoQuestion?.paragraph;

    return (
        <div>
            {paragraph ? <p style={{
                    paddingBottom: 16
                }}>
                    {paragraph?.trim()}
                </p> : null
            }
            <div style={{display: 'flex', flexDirection: 'column', gap: 32}}>
              {dataQuestion.map((item, index) => {
                  return (
                      <div key={index}>
                          <div style={{display: 'flex', gap: 3}}>
                            <p>{props?.numberReview ? props?.numberReview : (index + 1)}.</p>
                            {
                                item?.list_option?.[0]?.question_content.replace(/\{.+?}/g, " ______ ").split(' ').map((e,i)=>{
                                    return <div key={i} >
                                        <span>{e}</span>
                                    </div>
                                })
                            }
                          </div>
                          {
                            _renderResult(item?.list_option?.[0], index)
                          }
                      </div>
                  );
              })}
            </div>
        </div>
    );
};

export default ResultQuestionFillTheSentence;
