import { getMobileOperatingSystem } from "./Validate";
import { LinkApp } from "../_constants/linkDownloadApp";
import { history } from "../_helpers";

export const handleNavigateCurriculumDownload = (type) => {
  const osMobile = getMobileOperatingSystem();
  if (osMobile == "Android") {
    window.open(LinkApp[`${type}`].GG_PLAY, "_blank");
  } else if (osMobile == "iOS") {
    window.open(LinkApp[`${type}`].APP_STORE, "_blank");
  } else {
    // if (authentication.isLogin) {
    //   history.push(`/${authentication.role}/curriculum`);
    // } else {
    //   // localStorage.setItem(
    //   //   "purposeLogin",
    //   //   JSON.stringify({
    //   //     purpose: "curriculum",
    //   //   })
    //   // );
    //   // history.push(`/login`);

    // }
    history.push(`/download_page/${type}`);
  }
};
