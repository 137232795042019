import { useState, useEffect } from "react";
import { apiCaller } from "../../_helpers";
import { configConstants, teacherConstants } from "../../_constants";
import { validateNumber, validateEmail } from "../../_base/Validate";
import { userConstants } from "../../_constants";
import { sendMsgContact } from "../../_services/user";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Mydata } from "../../_base/Mydata";

export const advisementLogic = (props) => {
  // Time Advisement
  const [timeAdvisement, setTimeAdvisement] = useState({});
  const [timeAdvisementError, setTimeAdvisementError] = useState();
  const gradeListRedux = useSelector((state) => state.classes.grade_list);
  const dispatch = useDispatch();
  const changeTimeAdvisement = (newValue) => {
    if (timeAdvisementError) {
      setTimeAdvisementError("");
      setCompulError("");
    }
    setTimeAdvisement(newValue);
  };

  let dataTimeAdvisement = [
    {
      id: 0,
      title: "Thời gian nhận tư vấn",
      value: null,
    },
  ];

  // Render List Time Advisement
  for (var i = 1; i < 15; i++) {
    let dateValue = new Date();
    dateValue.setHours(i + 7, 0, 0, 0);

    dataTimeAdvisement.push({
      id: i,
      title: `${i + 7}h - ${i + 1 + 7}h`,
      value: `${i + 7} - ${i + 1 + 7}`,
    });
  }
  // Daisu
  const [daisuCode, setDaisuCode] = useState();

  // Handle Change Daisu
  const changeDaisu = (value) => {
    setDaisuCode(value);
  };

  // Grades
  const [gradeSelected, setGradeSelected] = useState();
  const [gradeError, setGradeError] = useState();
  const [dataGrades, setDataGrades] = useState([]);

  useEffect(() => {
    if (!dataGrades.length) {
      getGradeList()
    }
  }, []);

  const getGradeList = () => {
    var gradeListdata
    if(!gradeListdata?.list){
      gradeListdata = JSON.parse(localStorage.getItem(teacherConstants.GET_GRADE_LIST));
      dispatch({
        type: teacherConstants.GET_GRADE_LIST,
        data: gradeListdata,
      });
    }else{
      gradeListdata = gradeListRedux
    }
      if(gradeListdata?.list && gradeListdata?.list.length > 0 && ((new Date()).getTime() - gradeListdata?.saveAt) < Mydata.save_cache_grade_duration){
        setDataGrades(gradeListdata?.list);
        return
      }
    callGradeList()
  };

  const callGradeList = () => {
    console.log("=====getAllGrade A")
    apiCaller(
      "/api_login/grade",
      "GET",
      {},
      null,
      true,
      configConstants.API_URL_SETEST,
      false
    ).then((res) => {
      if (res.status) {
        const newListGrade = res.list_grade;

        newListGrade?.map((item) => {
          item.title = item.name.replace("Khối", "Lớp");
          item.value = item.id;
        });
        setDataGrades(newListGrade);
        var datared = {
          saveAt: (new Date()).getTime(),
          list: newListGrade
        }
        dispatch({
          type: teacherConstants.GET_GRADE_LIST,
          data: datared,
        });
        localStorage.setItem(teacherConstants.GET_GRADE_LIST, JSON.stringify(datared));
      }
    });
  };

  // Handle Change Grade for Student
  const changeGradeSelected = (newValue) => {
    if (gradeError) {
      setGradeError("");
    }
    setGradeSelected(newValue);
  };

  // Name
  const [nameUser, setNameUser] = useState();
  const [nameUserError, setNameUserError] = useState();

  // Handle Change Name User
  const changeNameUser = (value) => {
    if (nameUserError) {
      setNameUserError("");
    }
    setNameUser(value?.capitalize());
  };

  // Phone Number
  const [phone, setPhone] = useState();
  const [phoneWarning, setPhoneWarning] = useState("");

  // Handle Change Phone
  const changePhone = (value) => {
    setPhone(validateNumber(value));
    if (phoneWarning) {
      setPhoneWarning("");
      if ([10, 11].includes(value?.length) || phone == "") {
        setPhoneWarning("");
      }
    }
  };

  // Address
  const [addressTeach, setAddressTeach] = useState();
  const [addressTeachError, setAddressTeachError] = useState();

  // Handle Change Address Teach for Teacher
  const changeAddressTeach = (newValue) => {
    if (addressTeachError) {
      setAddressTeachError("");
    }
    setAddressTeach(newValue);
  };

  // TEACHER

  // Email
  const [email, setEmail] = useState();
  const [emailError, setEmailError] = useState();

  const [isVisibleTime, setIsVisibleTime] = useState(false);
  const [isVisbileTrial, setIsVisibleTrial] = useState(false);

  const [errorAdviseTrial, setErrorAdviseTrial] = useState("");

  // Variable compulsory advise trial
  const [compulError, setCompulError] = useState(false);

  // Validate
  const onBlurField = (type) => {
    switch (type) {
      case "email": {
        if (email && !emailError && !validateEmail(email)) {
          setEmailError("Định dạng email không đúng");
        } else if (validateEmail(email) || email == "") {
          setEmailError("");
        }

        return;
      }
      case "phone": {
        if (phone == "") {
          setPhoneWarning("");
        } else if (phone && phone?.length != 10 && phone?.length != 11) {
          setPhoneWarning("Số điện thoại hợp lệ gồm 10-11 số!");
          // console.log("123");
        } else if (phone && phone?.length && phone[0] !== "0") {
          setPhoneWarning("Định dạng số điện thoại không đúng");
        }
        return;
      }
      case "name": {
        setNameUser(nameUser?.trim());
        return;
      }
      case "address": {
        setAddressTeach(addressTeach?.trim());
        return;
      }
      case "timeAdvise": {
        if (!timeAdvisement?.value) {
          setIsVisibleTime(false);
        }
      }
      default:
    }
  };

  // Variable for open modal success
  const [isSuccessRegist, setIsSuccessRegist] = useState(false);

  // Handle Close Modal
  const handleCloseModal = () => {
    setNameUser("");
    setPhone("");
    setDaisuCode("");
    setTimeAdvisement({ value: null });
    setGradeSelected({ value: null });
    setAddressTeach("");
    setIsVisiblePolicy(false);
    setIsVisibleTrial(false);
    setIsVisibleTime(false);
    setEmail("");
    setIsSuccessRegist(false);
  };

  // Handle Regist Advisement
  const handleRegistAdvisement = async (e) => {
    e?.preventDefault();
    if (
      nameUser &&
      phone &&
      !nameUserError &&
      !phoneWarning 
      // &&
      // timeAdvisement?.value &&
      // !timeAdvisementError
    ) {
      if (
        (!addressTeach && props.type == userConstants.ROLE_TEACHER) ||
        (!gradeSelected?.value && props.type != userConstants.ROLE_TEACHER)
      ) {
        setAddressTeachError("Vui lòng nhập thông tin.");
        setGradeError("Vui lòng nhập thông tin.");
      } else {
        try {
          // let [from_time, end_time] = timeAdvisement?.value.split("-");

          const data = {
            user_name: nameUser,
            phone,
            agent_code: daisuCode,
            // from_time,
            // end_time,
            grade_id: gradeSelected?.value,
            school: addressTeach,
            source: "advise",
            role: props.type,
          };
          const result = await sendMsgContact(data);
          if (result.status) {
            //   setIsSuccess(true);
            setIsSuccessRegist(true);
          }
        } catch (e) {
          // console.log(e);
        }
      }
    } else {
      if (!nameUser) {
        setNameUserError("Vui lòng nhập thông tin.");
      } else {
        if (!phone) {
          setPhoneWarning("Vui lòng nhập thông tin.");
        } else {
          if (
            (!addressTeach && props.type == userConstants.ROLE_TEACHER) ||
            (!gradeSelected?.value && props.type != userConstants.ROLE_TEACHER)
          ) {
            setAddressTeachError("Vui lòng nhập thông tin.");
            setGradeError("Vui lòng nhập thông tin.");
          } else if (
            (!addressTeachError || !gradeError) &&
            !timeAdvisement?.value
          ) {
            setTimeAdvisementError("Vui lòng nhập thông tin.");
          }
        }
      }
    }
  };

  // TEACHER
  const changeEmail = (value) => {
    setEmailError("");
    if (emailError) {
      if (validateEmail(value) || value == "") {
        setEmailError("");
      }
    }
    setEmail(value?.trim());
  };

  // Variable Boolean check checked box
  const [isVisiblePolicy, setIsVisiblePolicy] = useState(false);
  const [isAgreePolicy, setIsAgreePolicy] = useState(true);

  // Handle Checked Box Time Advisement
  const handleCheckedBoxTime = () => {
    setIsVisibleTime(!isVisibleTime);
    setErrorAdviseTrial("");
    setTimeAdvisementError("");
    setCompulError("");
  };

  // Handle Checked Box Trial
  const handleCheckedBoxTrial = () => {
    if (isVisbileTrial) {
      setIsVisiblePolicy(false);
      setIsAgreePolicy(true);
    }
    setTimeAdvisementError("");
    setCompulError("");
    setErrorAdviseTrial("");
    setIsVisibleTrial(!isVisbileTrial);
  };

  // Handle Checked Box Policy
  const handleCheckedBoxPolicy = () => {
    if (isVisiblePolicy) {
      setIsAgreePolicy(false);
    } else {
      setIsAgreePolicy(true);
    }
    setIsVisiblePolicy(!isVisiblePolicy);
  };

  // Handle Regist Advisement Teacher
  const handleRegistAdvisementTeacher = async (e) => {
    e?.preventDefault();
    if (
      nameUser &&
      !nameUserError &&
      email &&
      !emailError &&
      phone &&
      !phoneWarning &&
      addressTeach &&
      !addressTeachError &&
      ((isVisbileTrial && !errorAdviseTrial) ||
        (timeAdvisement?.value && !timeAdvisementError))
    ) {
      if (isVisbileTrial && !isVisiblePolicy) {
        setIsAgreePolicy(false);
      } else {
        try {
          let from_time = "";
          let end_time = "";
          if (timeAdvisement?.value) {
            [from_time, end_time] = timeAdvisement?.value.split("-");
          }
          let type_contact = "";

          const data = {
            user_name: nameUser,
            phone,
            agent_code: daisuCode,
            school: addressTeach,
            role: props.type,
            number_teacher: 1,
            number_student: numberStudentAccount,
            source: "trial",
            number_teacher: 1,
            number_student: numberStudentAccount,
            from_time,
            end_time,
            email
          };

          if (timeAdvisement?.value && isVisbileTrial) {
            type_contact = "both";
          } else if (timeAdvisement?.value) {
            type_contact = "care";
            delete data?.number_teacher;
            delete data?.number_student;
          } else {
            type_contact = "free_trial";
            delete data?.from_time;
            delete data?.end_time;
          }

          data.type_contact = type_contact;

          const result = await sendMsgContact(data);
          if (result.status) {
            //   setIsSuccess(true);
            setIsSuccessRegist(true);
          }
        } catch (e) {
          console.log(e);
        }
      }
    } else {
      if (!nameUser) {
        setNameUserError("Vui lòng nhập thông tin.");
      } else if (!phone) {
        setPhoneWarning("Vui lòng nhập thông tin.");
      } else if (!email) {
        setEmailError("Vui lòng nhập thông tin.");
      } else if (!addressTeach) {
        setAddressTeachError("Vui lòng nhập thông tin.");
      } else {
        if (!isVisbileTrial && !isVisibleTime && !timeAdvisement?.value) {
          setTimeAdvisementError("Vui lòng chọn tối thiểu 1 thông tin.");
          setCompulError(true);
          // setErrorAdviseTrial("Vui lòng chọn tối thiểu 1 thông tin.");
        }
      }
    }
  };

  // Variable Number Account
  const [numberStudentAccount, setNumberStudentAccount] = useState(25);

  // Handle Change Number Account
  const handleChangeNumberAccount = (type) => {
    switch (type) {
      case "increase":
        if (numberStudentAccount + 5 <= 50) {
          setNumberStudentAccount(numberStudentAccount + 5);
        }
        return;
      case "decrease":
        if (numberStudentAccount - 5 >= 5) {
          setNumberStudentAccount(numberStudentAccount - 5);
        }
        return;
      default:
        return;
    }
  };

  return {
    dataTimeAdvisement,
    dataGrades,
    timeAdvisement,
    gradeSelected,
    dataGrades,
    changeTimeAdvisement,
    changeGradeSelected,
    nameUser,
    setNameUser,
    changeNameUser,
    nameUserError,
    setNameUserError,
    phone,
    phoneWarning,
    setPhoneWarning,
    changePhone,
    onBlurField,
    addressTeach,
    setAddressTeach,
    changeAddressTeach,
    handleRegistAdvisement,
    timeAdvisementError,
    setTimeAdvisementError,
    addressTeachError,
    setAddressTeachError,
    gradeError,
    setGradeError,
    isSuccessRegist,
    handleCloseModal,
    handleCheckedBoxTime,
    isVisibleTime,
    handleCheckedBoxTrial,
    isVisbileTrial,
    isVisiblePolicy,
    handleCheckedBoxPolicy,
    handleRegistAdvisementTeacher,
    email,
    emailError,
    setEmailError,
    changeEmail,
    handleChangeNumberAccount,
    numberStudentAccount,
    isAgreePolicy,
    errorAdviseTrial,
    setErrorAdviseTrial,
    compulError,
    setCompulError,
    setIsVisibleTime,
    setIsAgreePolicy,
    daisuCode, 
    setDaisuCode,
    changeDaisu
  };
};
