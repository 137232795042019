import React from "react";
import { isValidUrl } from "../../../_helpers/utils";
import { configConstants } from "../../../_constants";
import { Audio, AudioV2 } from "../../../_components/Audio";

export default function ResultQuestionProject({dataQuestion}) {

  return (
    <div 
      style={{
        flex: 1, 
        marginTop: 16,
        borderWidth: 1,
        borderColor: '#cdcdcd',
        borderRadius: 8,
        backgroundColor: '#ffffff',
        padding: 16,
      }}
    >
      <p style={{fontWeight: 700, color: '#000'}}>{dataQuestion?.question}</p>
      <div style={{paddingTop: 16}}>
        {dataQuestion?.instruction?.map((item, index) => {
          return (
            <div style={{paddingTop: 16}} key={index}>
              <p style={{fontSize: 16, fontWeight: 'bold', color: '#000'}}>{`Gợi ý ${index + 1}:`}</p>
              {!!item?.i_file && (
                <div style={{paddingBottom: 16, paddingTop: 16}}>
                  {(item?.i_file_type === 'img' || item?.i_file_type === '') && <img src={configConstants.BASE_URL + item.i_file} style={{width: '100%', height: 'auto', maxHeight: 350, objectFit: 'contain'}} />}
                  {item?.i_file_type === 'audio' && <div className="content-audio" style={{width: '100%',marginBottom: 0}}>
                    <AudioV2 link={configConstants.BASE_URL + item.i_file} />
                  </div>}
                  {item?.i_file_type === 'video' && 
                    <Audio classNameVideo="video-reading15" type="video" link={configConstants.BASE_URL + item.i_file} style={{width: '100%', height: 'auto',  maxHeight: 350, aspectRatio: 16/9, borderRadius:12}}/>
                  }
                </div>
              )}
              <div>
                {item?.i_text?.split('\n')?.map((line, iLine) => {
                  return (
                    <p style={{fontSize: 16, display: 'flex', gap: 3}} key={iLine}>
                      {line?.split(' ')?.map((text, iText) => {
                        if (isValidUrl(text)) {
                          return <a style={{color: 'blue'}} key={iText} href={text} target="_blank">{text}</a>
                        }
                        return (
                          <p key={iText}>{text}</p>
                        )
                      })}
                    </p>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}