import { Fragment } from "react";
import { ExamGrammar1, ExamGrammar9, ExamPro1, ExamReading1 } from "../../../_components/exam-test";
import { ExamListening } from "../../../_components/exam-test/ExamListening";
import { ExamWriting } from "../../../_components/exam-test/ExamWriting";
import "./styles.scss";

export default function ModalReviewExam({ visible = false, onClose, questionNumber, userChoice, categories}) {
  
  const renderQuestion = (category) => {
    if (category.skill === "pronunciation") {
      return (
        <ExamPro1
          isReview={true}
          category={category}
          indexQuestion={questionNumber}
          userAnswerText={userChoice?.[0]?.user_choice}
          onChangeResult={(questionId, answer) => {}}
        />
      );
    } else if (category.skill === "reading") {
      return (
        <ExamReading1
          isReview={true}
          type={"reading" + category?.question_type}
          category={category}
          indexQuestion={questionNumber}
          userAnswerText={userChoice?.[0]?.user_choice}
          onChangeResult={(questionId, answer) => {}}
        />
      );
    } else if (category.skill === "writing") {
      return (
        <ExamWriting
          type={"writing" + category?.question_type}
          isReview={true}
          category={category}
          indexQuestion={questionNumber}
          userAnswerText={userChoice?.[0]?.user_choice}
          onChangeResult={(questionId, answer) => {}}
        />
      );
    } else if (category.skill === "listening") {
      return (
        <ExamListening
          type={"listening" + category?.question_type}
          isReview={true}
          category={category}
          indexQuestion={questionNumber}
          userAnswerText={userChoice?.[0]?.user_choice}
          onChangeResult={(questionId, answer) => {}}
        />
      );
    } else if (category.skill === "grammar") {
      if (category?.question_type === "1") {
        return (
          <ExamGrammar1
            category={category}
            isReview={true}
            indexQuestion={questionNumber}
            userAnswerText={userChoice?.[0]?.user_choice}
            onChangeResult={(questionId, answer) => {}}
          />
        );
      } else if (
        category?.question_type === "9" ||
        category?.question_type === "11"
      ) {

        return (
          <ExamGrammar9
            category={category}
            isReview={true}
            indexQuestion={questionNumber}
            userAnswerText={userChoice?.[0]?.user_choice}
            onChangeResult={(questionId, answer) => {}}
          />
        );
      }
    }
  };

  if(!visible) {
    return null
  }
  return (
    <div className="modal-review-exam">
      <div className="layout" onClick={onClose}/>
        <div className="modal-form">
          <div className="content-review-exam scrollbar-custom">
            {/* <p className="question-name">{questionName}</p> */}
            {categories?.map((item, index) => <Fragment key={index}>{renderQuestion(item)}</Fragment>)}
          </div>
          <div className="btn-close-review-container">
            <button className="btn-line-blue" onClick={onClose}>
                Đóng
            </button>
          </div>
        </div>
    </div>
  )
}