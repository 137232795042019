import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { Link } from "react-router-dom";
import { teacherActions } from "../../../_actions";
import { EmptyClassBlock, ListClass } from "../../../_components/Admin/Teacher";
import { Header } from "../../../_components/Admin/Header";
import {
  configConstants,
  teacherConstants,
  userConstants,
} from "../../../_constants";
import $ from "jquery";
import { studentService, teacherService } from "../../../_services";
import { history } from "../../../_helpers";

function HomePageClass() {
  const authentication = useSelector((state) => state.authentication);
  const [isLoading, setLoading] = useState(false);
  const [listClass, setListClass] = useState([]);
  const [baseUrl, setBaseUrl] = useState();
  const [isLoadMoreOnline, setLoadMoreOnline] = useState(true);
  const [isLoadMoreOffline, setLoadMoreOffline] = useState(true);
  const [offSetOnlineState, setOffSetOnlineState] = useState(0);
  const [offSetOfflineState, setOffSetOfflineState] = useState(0);
  const [offsetClassStudent, setOffsetClassStudent] = useState(0);
  const [listClassOnlineState, setListClassOnlineState] = useState([]);
  const [listClassOffLineState, setListClassOffLineState] = useState([]);
  const dispatch = useDispatch();
  
  const classListTeacher = useSelector((state) => state.classes.classListTeacher);
  const [limitOnline, setLimitOnline] = useState(configConstants.DEFAULT_LIMIT);
  const [offsetOnline, setOffsetOnline] = useState(0);

  const [limitOffline, setLimitOffline] = useState(
    configConstants.DEFAULT_LIMIT
  );
  const [offsetOffline, setOffsetOffline] = useState(0);

  const [isEndOnlineClasses, setIsEndOnlineClasses] = useState(false);

  const [firstLoadOffline, setFirstLoadOffline] = useState(true);

  useEffect(() => {
    if (isLoading) {
      $(".loading").removeClass("hide");
    } else {
      $(".loading").addClass("hide");
    }
  }, [isLoading]);

  useEffect(() => {
    if (authentication.role == userConstants.ROLE_TEACHER) {
      getListClass();
    } else {
      getListClassStudent(configConstants.DEFAULT_LIMIT, 0);
    }
  }, []);

  // Has Duplicate Data
  function hasDuplicates(array1, array2) {
    var combinedArray = array1.concat(array2);
    return new Set(combinedArray).size !== combinedArray.length;
  }

  const handleScroll = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 5 &&
      (isLoadMoreOnline || isLoadMoreOffline) &&
      !isLoading
    ) {
      if (authentication.role == userConstants.ROLE_TEACHER) {
        onLoadMoreClasses();
      } else {
        if (
          e.target.scrollHeight - e.target.scrollTop <
            e.target.clientHeight + 5 &&
          isLoadMoreOnline &&
          !isLoading
        ) {
          getListClassStudent(
            configConstants.DEFAULT_LIMIT,
            offsetClassStudent + configConstants.DEFAULT_LIMIT
          );
        }
      }
    }
  };

  const getListClassStudent = async (limit, offsetClassStudentParam) => {
    if (authentication.role === userConstants.ROLE_STUDENT) {
      let listClassOnline = [];
      if (
        (offsetClassStudentParam != offsetClassStudent ||
          offsetClassStudent == 0) &&
        !isLoading
      ) {
        setLoading(true);
        let resOnline = await studentService.getListClassStudentOnline(
          limit,
          offsetClassStudentParam,
          1
        );
        if (!hasDuplicates(resOnline.data, listClassOnlineState)) {
          setLoading(false);
          setListClassOnlineState((prev) => [...prev, ...resOnline.data]);
          setLoadMoreOnline(
            resOnline.data.length == configConstants.DEFAULT_LIMIT
          );
          setOffsetClassStudent(offsetClassStudentParam);
        }
        listClassOnline = resOnline.data || [];
        setListClass((prev) => [...prev, ...resOnline.data]);
        setBaseUrl(resOnline.base_url);
      }
    }
  };

  const refreshClick = async () => {
    dispatch({
      type: teacherConstants.GET_CLASSLIST_TEACHER,
      data: null,
    });
      setLoadMoreOnline(true);
      setIsEndOnlineClasses(false);
      setOffsetOffline(0)
      setLoadMoreOffline(true);
      setListClass([])
    // callClassList()
    if (authentication.role == userConstants.ROLE_TEACHER) {
      getListClass();
    } else {
      getListClassStudent(configConstants.DEFAULT_LIMIT, 0);
    }
  }

  const getdataListClassfromCache = () => {
    if (authentication.role === userConstants.ROLE_TEACHER) {
      setListClass(classListTeacher?.list);
      setBaseUrl(configConstants.BASE_URL);
      var countOn = 0
      var countOff = 0
      classListTeacher?.list.forEach(element => {
        if(element.type == "online")
          countOn = countOn + 1
        if(element.type == "offline")
          countOff = countOff + 1
      });
      if(countOn < 10 || countOn % 10 != 0){
        setLoadMoreOnline(false);
        setIsEndOnlineClasses(true);
      }
      if(countOff > 0){
        setOffsetOffline(countOff)
      }
      if((countOn + countOff) < 10 || (countOn + countOff) % 10 != 0){
        setLoadMoreOffline(false);
      }
    }
  }

  // Get List Classes for Teacher
  const getListClass = async () => {
    try {
      if (authentication.role === userConstants.ROLE_TEACHER) {
        if(classListTeacher && classListTeacher.saveAt){
          getdataListClassfromCache()
          return
        }
        callClassList()
      }
    } catch (e) {
    } 
  };

  // Get List Classes for Teacher
  const callClassList = async () => {
    try {
        setLoading(true);
        let resOnline = await teacherService.getListClassOnlineAsyncLimit(
          limitOnline,
          0,
          2
        );
        setBaseUrl(resOnline?.base_url);
        let concatList = resOnline.data;

        if (resOnline.data?.length < limitOnline) {
          setLoadMoreOnline(false);
          let resOffline = await teacherService.getListClassOfflineAsyncLimit(
            limitOnline - resOnline.data?.length,
            0
          );
          if (resOffline?.data.length > 0) {
            concatList = concatList.concat(resOffline?.data);
            setOffsetOffline(limitOnline - resOnline?.data?.length);
          }
          setLoadMoreOffline(
            resOffline?.data?.length >= limitOnline - resOnline?.data?.length
              ? true
              : false
          );
          setIsEndOnlineClasses(true);
      }
      setListClass(concatList);
      var datared = {
        saveAt: (new Date()).getTime(),
        list: concatList
      }
      dispatch({
        type: teacherConstants.GET_CLASSLIST_TEACHER,
        data: datared,
      });
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  // Load More Classes for Teacher
  const onLoadMoreClasses = async () => {
    let offsetOfflineMore = isEndOnlineClasses
      ? offsetOffline
      : firstLoadOffline
      ? offsetOffline
      : offsetOffline + limitOffline;
    let offsetOnlineMore = offsetOnline + limitOnline;
    let concatListSide = [];
    setLoading(true);

    try {
      if (!isEndOnlineClasses) {
        let resOnline = await teacherService.getListClassOnlineAsyncLimit(
          limitOnline,
          offsetOnlineMore,
          2
        );

        concatListSide = resOnline?.data;

        setOffsetOnline(offsetOnline + limitOnline);

        if (resOnline?.data?.length < limitOnline) {
          setLoadMoreOnline(false);
          if (resOnline?.data?.length == 0) setLoadMoreOnline(false);
          let limitOfflineMore = limitOnline - resOnline?.data?.length;

          let resOffline = await teacherService.getListClassOfflineAsyncLimit(
            limitOfflineMore,
            offsetOffline
          );
          if (resOffline?.data.length > 0) {
            concatListSide = concatListSide.concat(resOffline?.data);
            setOffsetOffline(limitOfflineMore);
            setLoadMoreOffline(true);
          }
          setLoadMoreOffline(
            resOffline?.data?.length >= limitOfflineMore ? true : false
          );

          setIsEndOnlineClasses(true);
        }
      } else {
        setIsEndOnlineClasses(true);
        let resOffline = await teacherService.getListClassOfflineAsyncLimit(
          limitOffline,
          offsetOfflineMore
        );
        concatListSide = concatListSide.concat(resOffline?.data);

        if (resOffline?.data.length > 0) {
          setOffsetOffline(offsetOffline + limitOffline);
          setFirstLoadOffline(false);
        }
        setLoadMoreOffline(
          resOffline?.data?.length >= limitOffline ? true : false
        );
      }

      let listClassSide = listClass;
      setListClass(listClassSide?.concat(concatListSide));
      var datared = {
        saveAt: (new Date()).getTime(),
        list: listClassSide?.concat(concatListSide)
      }
      dispatch({
        type: teacherConstants.GET_CLASSLIST_TEACHER,
        data: datared,
      });
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  let componentClass = isEmpty(listClass) ? (
    !isLoading && <EmptyClassBlock />
  ) : (
    <ListClass
      handleScroll={handleScroll}
      classes={listClass}
      base_url={baseUrl}
    />
  );
  return (
    <div className='sunE-right-container rel flex-column'>
      <Header
        title={
          authentication.role === userConstants.ROLE_TEACHER
            ? "Quản lý lớp"
            : "Lớp học online của tôi"
        }
        isBack={authentication.role === userConstants.ROLE_STUDENT}
        clickBack={() => history.push("/" + authentication.role + "/more")}
        component={
          <div className="refresh-curriculum-btn-teacher" onClick={refreshClick} style={{width: 40, height: 40, marginRight: 16}}>
            <img src="/assets/images/icon_refresh_curriculum.png" style={{width: '100%', height: '100%', borderRadius: '50%'}}/>
          </div>
        }
      />
      <div
        className='sunE-content sunE-class-content flex-1'
        style={{ padding: 0 }}
      >
        {componentClass}
      </div>
      {authentication.role === userConstants.ROLE_TEACHER ? (
        <div className='form-sunE-button text-right btn-mr-custom'>
          <Link to={"/" + authentication.role + "/class/add"}>
            <button className='btn-check-custom btn-line-blue bottom-30'>
              Tạo lớp mới
            </button>
          </Link>
        </div>
      ) : (
        <div className='form-sunE-button text-right btn-mr-custom'>
          <Link to={"/" + authentication.role + "/more/class/apply"}>
            <button className='btn-line-blue'>Xin vào lớp mới</button>
          </Link>
        </div>
      )}
    </div>
  );
}

export { HomePageClass };
