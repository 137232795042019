import React from "react";
import { useState } from "react";
import "./style.css";

function PopUpZoomImageV2(props) {
  let { onClickNo, src, width, height } = props;

  const [valueRotated, setValueRotated] = useState(90);

  const rotateImage = () => {
    document.querySelector(
      ".image_zoom"
    ).style.transform = `rotate(${valueRotated}deg)`;
    setValueRotated(valueRotated + 90);
  };

  return (
    <div
      id="modal-center"
      className=" uk-flex-top uk-modal uk-flex uk-open"
      uk-modal=""
    >
      <div
        className="pos_rel uk-modal-dialog uk-modal-body uk-margin-auto-vertical showImg"
        style={{
          width: "auto",
          height: "100%",
          backgroundColor: "transparent",
        }}
      >
        <div
          className="zoomImg center-flex"
          style={{
            height: "100%",
            backgroundColor: "transparent",
          }}
        >
          <img
            src={src}
            alt="zoom"
            className="contain_image image_zoom"
            style={{
              height: height,
            }}
          />
        </div>
      </div>
      <div className="pos_abs icon_image_container">
        <img
          onClick={rotateImage}
          className="contain_image ico_image"
          src="/assets/images/icon/ico_rotate.png"
          alt="Icon Zoom out"
        />
        <img
          onClick={onClickNo}
          className="contain_image ico_image"
          src="/assets/images/icon/ico_zoom_in.png"
          alt="Icon Zoom out"
        />
      </div>
    </div>
  );
}

export { PopUpZoomImageV2 };
