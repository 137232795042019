import { useEffect, useState } from "react";
import { studentService } from "../../../../_services";
import { useDispatch, useSelector } from "react-redux";
import { userConstants } from "../../../../_constants";
import { history } from "../../../../_helpers";
import { isEmpty } from "lodash";
import { studentActions } from "../../../../_actions";

export const resultCurriculumPageLogic = () => {
  const dispatch = useDispatch();
  const [resultPlacementTest, setResultPlacementTest] = useState();
  const authentication = useSelector((state) => state.authentication);
  const getGrades = useSelector((state) => state.grades.listGrade);
  const proposedCurriculum = useSelector(
    (state) => state.rootlessness.proposedCurriculum
  );
  const [isLoading, setIsLoading] = useState(false);

  const filterGrade = getGrades?.filter(
    (grade) => grade.id == authentication.grade_id
  );
  let gradeStudent = filterGrade && filterGrade[0]?.name.replace("Khối", "Lớp");

  // if (!proposedCurriculum || authentication.last_login !== null) {
  //   window.location.replace(`/${userConstants.ROLE_STUDENT}/curriculum`);
  // }

  const secondsToTime = (seconds) =>
    new Date(seconds * 1000).toLocaleTimeString("en-GB", {
      timeZone: "Etc/UTC",
      hour12: false,
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
    });

  useEffect(() => {
    window.onpopstate = (event) => {
      if (window.location.pathname === `/${userConstants.ROLE_STUDENT}`) {
        window.history.forward();
      }
    };
  }, []);

  useEffect(async () => {
    try {
      setIsLoading(true);
      const res = await studentService.getResultPlacementTest();
      if (res) {
        if (isEmpty(res.data)) {
          history.push(`/${userConstants.ROLE_STUDENT}/`);
        } else {
          setResultPlacementTest(res);
          dispatch({
            type: userConstants.RESULT_PLACEMENT_TEST,
            data: res.program_default,
          });
        }
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleCompletedChoose = async () => {
    await studentService.postCurriculumFirstStep({
      grade_id: authentication.grade_id,
      program_id: resultPlacementTest.program_default.id,
    });
    if (!isEmpty(resultPlacementTest.program_default?.curriculum_id)) {
      await studentActions.updateDefaultCurriculumFirst(
        resultPlacementTest.program_default?.curriculum_id
      );
      authentication?.isLogin &&
        dispatch({
          type: userConstants.LOGIN,
          user: {
            ...authentication,
            curriculum_default:
              resultPlacementTest.program_default?.curriculum_id?.toString(),
          },
        });
        const dataCurriculum = await studentService.getListCurriculum();
        const curriculumSelected = dataCurriculum?.data?.list_curriculum?.find(item => item?.curriculum_id == resultPlacementTest.program_default?.curriculum_id)
        curriculumSelected && localStorage.setItem("curriculum_id_Selected", JSON.stringify(curriculumSelected?.curriculum_id));
      }
    dispatch({ type: userConstants.OFF_ROOTLESSNESS });
    dispatch({ type: userConstants.OFF_PROPOSED_CURRICULUM });
    dispatch({ type: userConstants.HIDE_SIDEBAR_ROOTLESSNESS });
    dispatch({ type: userConstants.RESET_RESULT_PLACEMENT_TEST });
    history.push(`/${userConstants.ROLE_STUDENT}/curriculum`);
  };

  const handleAnotherChooseCurriculum = () => {
    dispatch({ type: userConstants.OFF_ROOTLESSNESS });
    dispatch({ type: userConstants.HIDE_SIDEBAR_ROOTLESSNESS });
    dispatch({ type: userConstants.OFF_PROPOSED_CURRICULUM });
    dispatch({ type: userConstants.ON_CHOOSE_LIST_CURRICULUM });
    dispatch({ type: userConstants.RESET_RESULT_PLACEMENT_TEST });
    history.push(`/${userConstants.ROLE_STUDENT}/curriculum`);
  };

  return {
    gradeStudent,
    handleCompletedChoose,
    resultPlacementTest,
    handleAnotherChooseCurriculum,
    secondsToTime,
    isLoading,
  };
};
