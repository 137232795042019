import React, { useEffect } from "react";
import Slider from "react-slick";
import { SideBar } from "../../../_components/Admin/More/License";
import { Header } from "../../../_components/Admin/Header";
import { SliderItem } from "./SliderItem";
import { useSelector, useDispatch } from "react-redux";
import { SelectAsDiv } from "../../../_components/Select";
import { feePageLogic } from "../../../_screens/FeePage/FeePage.logic";
import { renderListPackages } from "../../../_screens/FeePage/FeePage";
import { renderListPackagesTeacher } from "../../../_screens/TariffPage/TariffPage";
import { tariffPageLogic } from "../../../_screens/TariffPage/TariffPage.logic";
import LazyLoad from "react-lazyload";
import { userConstants } from "../../../_constants";

function SampleNextArrow(props) {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={className}>
      <img alt="dropdown right" src="/assets/images/icon/ico_right.png" />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, onClick } = props;
  return (
    <div onClick={onClick} className={className}>
      <img src="/assets/images/icon/ico_left.png" alt="ico_left_white" />
    </div>
  );
}

function AddLicense() {
  let { listPackages, handleNavigatePayment } = feePageLogic();

  const authentication = useSelector((state) => state.authentication);

  let {
    listPackagesTeacher,
    accountNumber,
    currPriceSpecific,
    openPopUpAccount,
    handleNavigatePaymentTeacher,
    handleChangeAccountNumber,
    handleActionAccount,
    setOpenPopUpAccount,
    handleResetAccount,
  } = tariffPageLogic();

  return (
    <div
      className="sunE-right-container flex-column"
      style={{ paddingBottom: "0.5rem" }}
    >
      <Header title={"Mua gói mới"} isBack />

      <div className="feePage commondPage add-license scrollbar-custom flex-m ">
        <div className="content flex-center flex-wrap">
          <LazyLoad
            once
            className="content_bg flex-center-column"
            style={{
              background: `url(
              /assets/images/feeNews/bg_content.png
            ) no-repeat top center /cover`,
            }}
          >
            <div
              id="list_packages"
              className="list_packages flex-jus-evenly flex-wrap"
            >
              {authentication.role == userConstants.ROLE_STUDENT
                ? listPackages.map((item, index) =>
                    renderListPackages(item, handleNavigatePayment, index)
                  )
                : listPackagesTeacher.map((item) =>
                    renderListPackagesTeacher(
                      item,
                      handleChangeAccountNumber,
                      handleActionAccount,
                      handleNavigatePaymentTeacher,
                      accountNumber,
                      currPriceSpecific,
                      openPopUpAccount
                    )
                  )}
            </div>
          </LazyLoad>
        </div>
      </div>

      {openPopUpAccount == "popup" && (
        <PopUpYesNo
          hideButtonNo={true}
          onClickYes={() => handleResetAccount()}
          labelYes="Đóng"
          message="Bạn cần mua tối thiểu 10 tài khoản học sinh."
        />
      )}
    </div>
  );
}

export { AddLicense };
