import "./MockTestNews.style.scss";
import { HeaderNews } from "../../_components/Header";
import {
  TypeHeaderNewsItem,
  NameTitleNewsItem,
} from "../../_constants/headerNews";
import FooterNews from "../../_components/FooterNews/FooterNews";
import ButtonNews from "../../_components/Button/ButtonNews";
import { HashLink } from "react-router-hash-link";
import TitleNews from "../../_components/Header/HeaderNews/TitleNews";
import ScrollFixed from "../../_components/ScrollFixed/ScrollFixed";
import { mockTestLogic } from "./MockTestNews.logic";
import SelectCustom from "../../_components/Select/SelectCustom/SelectCustom";
import InputText from "../../_components/Auth/InputText";
import { useSelector } from "react-redux";
import { validateTime } from "../../_base/Validate";
import ModalFilterMockTest from "../../_components/Modal/ModalFilterMockTest/ModalFilterMockTest";
import LazyLoad from "react-lazyload";

const MockTestNews = () => {
  let {
    listMockTest,
    dataGrades,
    provinceList,
    dataTypeTopic,
    valueSearch,
    changeValueSearch,
    setValueSearch,
    handleClickOption,
    gradeSlelected,
    provinceSelected,
    topicSelected,
    handleClickMockTest,
    handleSearchMockTest,
    handleScroll,
    handleOpenModal,
    isOpenModalFilter,
    handleCloseModal,
    handleClickOptionMobile,
    handleFilter,
    dataTypeTopicMobile,
    provinceListMobile,
    dataGradesMobile,
    isFiltered,
    handleResetValueSearch,
  } = mockTestLogic();

  const authentication = useSelector((state) => state.authentication);

  const renderAuthIcon = (type) => {
    return (
      <div
        className="flex-center pointer_cursor"
        onClick={() => handleSearchMockTest()}
      >
        <img
          src={`/assets/images/authNews/ico_${type}.png`}
          alt="Icon Input"
          className="contain_image img_label_input"
        />
      </div>
    );
  };

  // Render mock test item
  const renderMockTestItem = (item) => {
    return (
      <tr key={item.id} className="text-align-center table_row_item">
        <td>{item.name}</td>
        <td>{item?.number_student}</td>
        <td>{Number.parseFloat(item.hightest_score).toFixed(1)}</td>
        <td className="break_word_all">{validateTime(item.date_upload)}</td>
        <td className="">
          <div className="flex-center">
            <ButtonNews
              width="8.2rem"
              height="2.3rem"
              onClick={() => handleClickMockTest(item)}
              border={"1px solid rgba(0, 0, 0, 0.2)"}
              backgroundColor={"#fff"}
              boxShadow={"2px 2px 5px white, 0 0 1px black"}
              maxWidth={"82%"}
            >
              <div className="fz-08 btn_text font_news_bold">Bắt đầu thi</div>
            </ButtonNews>
          </div>
        </td>
      </tr>
    );
  };

  // Render Box Mocktest mobile
  const renderBoxMockTest = (item) => {
    return (
      <div
        key={item.id}
        className="box_item_mocktest flex-center-column-mobile"
      >
        <div className="fz-09 pd-bottom-1 text-align-center">{item.name}</div>
        <div className="flex-jus-between col-100 pd-bottom-2">
          <div className="flex-column box_item_info_left">
            <div className="flex-align pd-bottom-1">
              <div once className="icon_box_container flex-align">
                <img
                  src="/assets/images/icon/ico_participant.png"
                  alt="Icon Participant"
                  className="contain_image icon_box_mocktest icon_participant"
                />
              </div>
              <div className="fz-09">{item?.number_student}</div>
            </div>

            <div className=" flex-align pd-bottom-1">
              <div once className="icon_box_container flex-align">
                <img
                  src="/assets/images/icon/ico_date_active.png"
                  alt="Icon Date Active"
                  className="contain_image icon_box_mocktest"
                />
              </div>
              <div className="fz-09">{validateTime(item.date_upload)}</div>
            </div>
          </div>

          <div className="pos_rel">
            <div once className="pos_rel col-100">
              <img
                src="/assets/images/mockTestNews/achievement.png"
                alt="Image Achievement"
                className="contain_image img_achievement"
              />
              <div className="point_achievement_text font_news_bold ">
                {Number.parseFloat(item.hightest_score).toFixed(1)}
              </div>
            </div>
          </div>
        </div>

        <ButtonNews
          width="15rem"
          height="2.2rem"
          onClick={() => handleClickMockTest(item)}
          border={"1px solid #00cc83"}
          backgroundColor={"#00cc83"}
          // boxShadow={"2px 2px 5px white, 0 0 2px black"}
          maxWidth={"70%"}
        >
          <div className="fz-07-5-mobile btn_text font_news_bold color-white">
            BẮT ĐẦU THI
          </div>
        </ButtonNews>
      </div>
    );
  };

  // console.log(listMockTest);

  return (
    <div className="commondPage mockTestNews">
      <HeaderNews type={TypeHeaderNewsItem.MOCK_TEST} />
      {/* <TitleNews title={NameTitleNewsItem.MOCK_TEST} /> */}

      <LazyLoad once className="content">
        <img
          src="/assets/images/mockTestNews/banner.png"
          alt="Banner Mock Test"
          className="contain_image col-100 img_banner_mockTest display_none_mobile"
        />
        <img
          src="/assets/images/mockTestNews/banner_mobile.png"
          alt="Banner Mock Test"
          className="contain_image col-100 img_banner_mockTest hide display_block_mobile"
        />

        <div className="content_1 display_none_mobile">
          <div className="flex-align">
            <SelectCustom
              defaultTitle="Chọn Khối Đề Thi"
              dataSelect={dataGrades}
              titleSelected={gradeSlelected}
              typeSelect="grade"
              handleClickOption={handleClickOption}
            />

            <SelectCustom
              defaultTitle="Chọn Tỉnh/Thành phố"
              dataSelect={provinceList}
              titleSelected={provinceSelected}
              typeSelect="province"
              handleClickOption={handleClickOption}
            />

            <SelectCustom
              defaultTitle="Chọn loại đề"
              dataSelect={dataTypeTopic}
              titleSelected={topicSelected}
              typeSelect="topic"
              handleClickOption={handleClickOption}
            />

            <form
              onSubmit={(e) => handleSearchMockTest(e)}
              className="input_search_container "
            >
              <InputText
                typeSearch="search"
                handleResetValueSearch={handleResetValueSearch}
                value={valueSearch}
                setValue={changeValueSearch}
                type="text"
                name="name_mocktest"
                placeholder="NHẬP TỪ KHÓA TÊN ĐỀ THI"
                required={true}
                renderLabelIcon={() => renderAuthIcon("search")}
                errorAbsolute={true}
                onFocus={() => setValueSearch(valueSearch?.trim())}
              ></InputText>
            </form>
          </div>

          <div>
            <div
              onScroll={handleScroll}
              className="col-100 table_list_mocktest scrollbar-custom-3"
            >
              {listMockTest?.length != 0 ? (
                <div className="display_none_mobile col-100 table_list_mocktest_container">
                  <table className="col-100 ">
                    <thead>
                      <tr className="table_row_header">
                        <th className="fz-09-mobile col-25">Tên đề thi</th>
                        <th className="fz-09-mobile col-23">
                          Số học sinh đã thi
                        </th>
                        <th className="fz-09-mobile col-23">Điểm cao nhất</th>
                        <th className="fz-09-mobile col-20">Ngày cập nhật</th>
                        <th className="fz-09-mobile col-20">Luyện thi</th>
                      </tr>
                    </thead>

                    <tbody className="table_body_mocktest">
                      {listMockTest?.map((item) => renderMockTestItem(item))}
                    </tbody>
                  </table>

                  <div className="display_none_mobile table_divider_bottom"></div>
                </div>
              ) : (
                <div className="col-100 text_invalid_search color-red fz-11 fz-09-mobile text-align-center font_news_bold">
                  Không có kết quả tìm kiếm phù hợp
                </div>
              )}
              {/* <div
              style={{ float: "left", clear: "both" }}
              ref={(el) => {
                refScroll = el;
              }}
            ></div> */}
            </div>
          </div>
        </div>

        <div className="content_1_mobile hide display_flex_mobile flex-column">
          <div className="flex-align col-100-mobile">
            <form
              onSubmit={(e) => handleSearchMockTest(e)}
              className="input_search_container_mobile one-line col-100-mobile "
            >
              <InputText
                typeSearch="search"
                handleResetValueSearch={handleResetValueSearch}
                value={valueSearch}
                setValue={changeValueSearch}
                type="text"
                name="name_mocktest"
                placeholder="Nhập từ khóa tìm kiếm đề thi"
                required={true}
                renderLabelIcon={() => renderAuthIcon("search")}
                errorAbsolute={true}
                onFocus={() => setValueSearch(valueSearch?.trim())}
              ></InputText>
            </form>

            <div
              once
              onClick={handleOpenModal}
              className="icon_filter_container pointer_cursor"
            >
              <img
                src={`/assets/images/mockTestNews/${
                  isFiltered ? "ico_filter_done" : "ico_filter"
                }.png`}
                alt="Icon Filter"
                className="contain_image icon_filter_mocktest"
              />
            </div>
          </div>

          <div
            onScroll={handleScroll}
            className="flex-column-mobile box_mocktests_scroll scrollbar-custom-3"
          >
            <div className="list_box_mocktests flex-column ">
              {listMockTest.length != 0 ? (
                listMockTest?.map((item) => renderBoxMockTest(item))
              ) : (
                <div className="color-red fz-09-mobile text-align-center font_news_bold">
                  Không có kết quả tìm kiếm phù hợp
                </div>
              )}
            </div>
          </div>
        </div>
      </LazyLoad>
      <FooterNews />
      <ScrollFixed />

      {isOpenModalFilter && (
        <ModalFilterMockTest
          handleClose={handleCloseModal}
          dataGrades={dataGradesMobile}
          gradeSlelected={gradeSlelected}
          handleClickOption={handleClickOptionMobile}
          provinceList={provinceListMobile}
          provinceSelected={provinceSelected}
          dataTypeTopic={dataTypeTopicMobile}
          topicSelected={topicSelected}
          handleFilter={handleFilter}
        />
      )}
    </div>
  );
};

export default MockTestNews;
