import React, { useState } from "react";
import "./style.css";
import { SelectDate } from "./../../_components/Calendar";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { teacherConstants } from "./../../_constants";
import { useHistory } from "react-router-dom";
import { useParams } from "react-router-dom";

function PopUpAddRollUp(props) {
  const { id } = useParams();
  let { width } = props;
  const history = useHistory();
  let { onClickNo } = props;
  const [inputs, setInputs] = useState({
    date: new Date(),
  });
  let { date } = inputs;
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);

  function addRollUp() {
    dispatch({
      type: teacherConstants.ADD_TIME_ADD_ROLL_UP,
      date: date,
    });
    history.push(
      "/" + authentication.role + "/class/view/" + id + "/roll_up/add"
    );
  }

  return (
    <div
      id="modal-center"
      className="uk-flex-top uk-modal uk-flex uk-open"
      uk-modal=""
    >
      <div
        className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical date-dd"
        style={{ width: width }}
      >
        <h2 className="text-center popup-title bold title24">Tạo điểm danh</h2>
        <div className="rel">
          <img
            src="/assets/images/teacher/diemdanh/ico_calender.png"
            alt="ico_calender"
            className="ico-calender-ab"
          />
          <SelectDate
            // disableMouseFocus={true}
            showTimeSelect={false}
            timeIntervals={15}
            dateFormat="dd/MM/yyyy"
            name="date"
            selected={date}
            onChange={(date) => setInputs({ ...inputs, date })}
            // minDate 
          />
        </div>
        <div className="flex-m form-sunE-button text-center">
          <button className="btn-line-blue btn-create" onClick={addRollUp}>
           Tiếp tục
          </button>
        </div>
        <img
          src="/assets/images/icon/ico_close_popup.png"
          alt="ico_close_popup"
          className="close-popup-ab"
          onClick={onClickNo}
        />
      </div>
    </div>
  );
}

export { PopUpAddRollUp };
