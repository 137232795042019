import React, { useEffect, Fragment, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { scheduleActions } from "./../../../_actions";
import { SideBar } from "../../../_components/Admin/Schedule/SideBar";
import { Header } from "../../../_components/Admin/Header";
import { CalendaSchedule } from "../../../_components/Calendar";
import {
  EmptyScheduleToday,
  ScheduleToday,
} from "../../../_components/Admin/More/Schedule";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { isEmpty } from "lodash";
import $ from "jquery";
import { scheduleConstants } from "../../../_constants";

function ScheduleDayPage() {
  const history = useHistory();

  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);
  const schedules = useSelector((state) => state.schedules);
  const [selectedDate, setSelectedDate] = useState(schedules.selectDate);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
  }, [schedules]);

  useEffect(() => {
    setLoading(true);    
    if (!!schedules.dateSelectedCalendar) {
      dispatch(
        scheduleActions.getScheduleToday(
          authentication.id,
          schedules.dateSelectedCalendar
        )
      );
    } else {
      dispatch(
        scheduleActions.getScheduleToday(
          authentication.id,
          schedules.selectDate
        )
      );
    }
  }, [schedules.delete_schedule]);

  function changeDateCalendaSchedule(date) {
    setSelectedDate(
      date.year + "-" + parseInt(parseInt(date.month) + 1) + "-" + date.day
    );
    dispatch({
      type: scheduleConstants.SET_DATE_SELECTED_CALENDAR,
      time:
        date.year + "-" + parseInt(parseInt(date.month) + 1) + "-" + date.day,
    });
    dispatch(
      scheduleActions.getScheduleToday(
        authentication.id,
        date.year + "-" + parseInt(parseInt(date.month) + 1) + "-" + date.day
      )
    );
  }

  function addComponentCreate() {
    return (
      <Fragment>
        <div className="form-sunE-button btn-create-kh">
          <Link
            to={
              "/" +
              authentication.role +
              `/more/schedule/add/more?date=${
                selectedDate
                  ? moment(selectedDate).format("YYYY-MM-DD")
                  : moment(new Date()).format("YYYY-MM-DD")
              }`
            }
          >
            <button className="btn-line-blue">Tạo kế hoạch</button>
          </Link>
        </div>
      </Fragment>
    );
  }

  let componentScheduleToday = isEmpty(schedules.today) ? (
    !isLoading && <EmptyScheduleToday />
  ) : (
    <ScheduleToday schedules={schedules.today} selectedDate={selectedDate} />
  );

  return (
    <div className="sunE-right-container scrollbar-custom">
      <Header
        title={"Lịch làm việc ngày"}
        component={addComponentCreate()}
        isBack
        clickBack={() => history.push("/" + authentication.role + "/more")}
      />
      <div className="flex-m sunE-content lichngay-teacher box-40-40">
        <SideBar />
        <div className="flex-1 sunE-content-box">
          <div className="box-calender rel">
            <CalendaSchedule
              changeDateCalendaSchedule={(date) =>
                changeDateCalendaSchedule(date)
              }
              collapse
              showMonth
            />
          </div>
          {componentScheduleToday}
        </div>
      </div>
    </div>
  );
}

export { ScheduleDayPage };
