import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "../../../_components/Admin/Header";
import { teacherConstants } from "../../../_constants";
import LazyLoad from "react-lazyload";

function HomePageCurriculum() {
  const authentication = useSelector((state) => state.authentication);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: teacherConstants.RESET_DATA_EXERCISE,
    });
  }, []);

  return (
    <div className="sunE-right-container">
      <Header title={"Giáo trình"} />
      <div className="sunE-container-box giaotrinh canhan">
        <div className="flex-m">
          <div className="flex-1 sunE-select-gt pr-15">
            <Link to={"/" + authentication.role + "/curriculum/view/sunday?page=curriculum"}>
              <div className="box-select-gr rel box-shadow">
                <LazyLoad className="thumb">
                  <img
                    src="/assets/images/giaotrinh/ico_gt_sun.png"
                    alt="ico_gt_sun"
                  />
                </LazyLoad>
                <div className="box-select-info">
                  <p>Giáo trình</p>
                  <h2>SUNDAY ENGLISH</h2>
                </div>
              </div>
            </Link>
            <Link to={"/" + authentication.role + "/curriculum/view/personal?page=curriculum"}>
              <div className="box-select-gr rel box-shadow">
                <LazyLoad className="thumb">
                  <img
                    src="/assets/images/giaotrinh/ico_gt_hs.png"
                    alt="ico_gt_hs"
                  />
                </LazyLoad>
                <div className="box-select-info">
                  <p>Giáo trình</p>
                  <h2>CÁ NHÂN</h2>
                </div>
              </div>
            </Link>
          </div>
          <div className="flex-1 pl-15">
            <LazyLoad className="text-center">
              <img src="/assets/images/giaotrinh/bg_gt.png" alt="bg_gt" />
            </LazyLoad>
          </div>
        </div>
      </div>
    </div>
  );
}

export { HomePageCurriculum };
