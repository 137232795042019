import { useState, useEffect } from "react";
import API from "../../../_apis/APIConstants";
import APIBase from "../../../_base/APIBase";
import { useDispatch, useSelector } from "react-redux";
import { teacherActions } from "../../../_actions";

export const upgradeAccountLogic = (props) => {
  const [msgText, setMsgText] = useState("");
  const [lsCode, setLsCode] = useState("");
  const [isActived, setIsActived] = useState(false);
  const [msgSuccess, setMsgSuccess] = useState("");
  const authentication = useSelector((state) => state.authentication);
  
  const dispatch = useDispatch()

  // Change LsCode Input
  const changeLsCode = (newValue) => {
    setLsCode(newValue);
    if (msgText) {
      setMsgText("");
    }
  };

  // Handle Upgrade Account
  const handleUpgrade = async (e) => {
    e?.preventDefault();
    try {
      let urlApi = `${API.baseURL}${API.upgradeAccountByCode}`;
      const body = {
        license_code: lsCode?.trim(),
      };
      const result = await APIBase.apiBaseCaller("POST", urlApi, body);
      if (result?.status) {
        setIsActived(true);
        setMsgText("");
        setMsgSuccess(
          JSON.parse(result.msg)?.notification ??
            "Kích hoạt tài khoản thành công!"
        );
        dispatch(teacherActions.getProfileV2(authentication?.id));
      } else {
        setMsgText(result?.msg);
      }
    } catch (e) {}
  };

  return {
    msgText,
    handleUpgrade,
    lsCode,
    setLsCode,
    changeLsCode,
    setMsgText,
    isActived,
    setIsActived,
    msgSuccess,
  };
};
