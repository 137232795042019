import { history } from "../../_helpers";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";
import { useSelector } from "react-redux";

export const sloganLogic = (props) => {
  const authentication = useSelector((state) => state.authentication);

  // Handle Navigate
  const handleNavigate = (type) => {
    history.push(type);
  };

  // Handle navigate register
  const handleNavigateRegister = () => {
    if (authentication.isLogin) {
      history.push(`/${authentication.role}`);
    } else {
      history.push("/register_news");
    }
  };

  return {
    handleNavigate,
    handleNavigateRegister,
  };
};
