import { useEffect } from "react";
import { LinkApp } from "../../_constants/linkDownloadApp";

export const downloadLogic = (props) => {
  // Data Download Student
  const dataStudent = {
    type: "student",
    title_1: "GIẢI PHÁP NÂNG CAO",
    title_2: "NĂNG LỰC TIẾNG ANH HỌC SINH THCS",
    links: [
      {
        id: 1,
        type: "appstore",
      },
      {
        id: 2,
        type: "ggplay",
      },
    ],
  };

  // Data Download Teacher
  const dataTeacher = {
    type: "teacher",
    title_1: "GIẢI PHÁP ƯU VIỆT",
    title_2: "GIÚP THẦY CÔ DẠY TIẾNG ANH HIỆU QUẢ",
    links: [
      {
        id: 1,
        type: "appstore",
      },
      {
        id: 2,
        type: "ggplay",
      },
    ],
  };

  // Data Download Parent
  const dataParent = {
    type: "parent",
    title_1: "ỨNG DỤNG GIÚP PHỤ HUYNH",
    title_2: "KÈM CON HỌC TIẾNG ANH",
    links: [
      {
        id: 1,
        type: "appstore",
      },
      {
        id: 2,
        type: "ggplay",
      },
    ],
  };

  // Handle Navigate Link APP
  const handleLinkApp = (type, role) => {
    window.open(
      type == "appstore"
        ? LinkApp[`${role}`].APP_STORE
        : LinkApp[`${role}`].GG_PLAY,
      "_blank"
    );
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
  }, []);

  return { dataTeacher, dataStudent, dataParent, handleLinkApp };
};
