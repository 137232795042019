import React, { useState } from "react";
import { CheckBoxCustom, renderStatusCheckBox } from "../../../_components/exam-test/CheckBoxCustom";
import { validWord } from "../../../_helpers/utils";

export default function ResultQuestionTrueFalseWriting({dataQuestion, skill, questionType, answer, isReview, numberReview}) {
  let finalAnswer = JSON.parse(answer);

  const listData = dataQuestion?.map((ques, iQues) => {
    return ques?.list_option?.map((item, index) => {
      const userChoice = finalAnswer[iQues]
      return {
        ...item,
        userChoice
      }
    });
  })

  const renderItem = (item, index) => {
    const isTrueAnswer = !!item?.option_text && item?.match_option_text?.includes(item?.option_text);
    const isRightChoose = item?.userChoice?.score > 0;
    let isChooseAnswerFalse = !!item?.userChoice?.user_choice;
    if(isChooseAnswerFalse && (validWord(item?.userChoice?.user_choice)?.replaceAll('.', '') === validWord(item?.option_text)?.replaceAll('.', ''))) { // case choose false choice but text = option_text
      isChooseAnswerFalse = !isRightChoose;
    }
    const isChooseAnswerTrue = !isChooseAnswerFalse;
    return (
      <div>
        <p style={{
          textAlign: 'justify'
        }}>
          {(!!numberReview ? numberReview : (index + 1)) + '. ' + item?.option_text}
        </p>
        <div style={{paddingLeft: 16}}>
          <div style={{display: 'flex', gap: 8}}>
            <div style={{width: 24}}>
              {!isRightChoose && isTrueAnswer && (isChooseAnswerFalse || !!isReview) && <img
                src="/assets/images/ico_hover_answer.png"
                style={{
                  width: 24,
                  height: 20,
                }}
              />} 
            </div>
            <CheckBoxCustom
              status={renderStatusCheckBox(
              isChooseAnswerTrue && !isReview,
              isTrueAnswer,
              true
              )}
              label={'True'}
            />
          </div>
          <div style={{display: 'flex', gap: 8}}>
            <div style={{width: 24}}>
              {!isRightChoose && !isTrueAnswer && (isChooseAnswerTrue || !!isReview) && <img
                src="/assets/images/ico_hover_answer.png"
                style={{
                  width: 24,
                  height: 20,
                }}
              />} 
            </div>
            <CheckBoxCustom
              status={renderStatusCheckBox(
              isChooseAnswerFalse && !isReview,
              !isTrueAnswer,
              true
              )}
              label={'False'}
            />
          </div>
          {!isRightChoose && !!item?.userChoice?.user_choice && 
            <div style={{marginTop: 8}}><p style={{color: 'red', paddingLeft: 32}}>{item?.userChoice?.user_choice}</p></div>
          }
          {!isTrueAnswer &&    
            <div style={{marginTop: 8}}>
              <div 
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                  gap: 8
                }}
              >
                <div 
                  style={{
                    width: 24,
                    height: 20,
                  }}
                >
                  {!isRightChoose && <img
                    src="/assets/images/ico_hover_answer.png"
                    style={{
                      width: 24,
                      height: 20,
                    }}
                  />}
                </div>
                <p style={{
                  textAlign: 'justify',
                  color: '#62B440',
                  flex: 1
                }}>
                  {item?.match_option_text?.[0]}
                </p>
              </div>
            </div>
          }
        </div>
      </div>
    )
  }
  return (
    <div style={{flex: 1, marginTop: 16}}>
      <div  style={{display: 'flex', flexDirection: 'column', gap: 32}}>
        {listData?.map((data, index) => {
          const explainQuestion = data?.[0]?.question_explain?.content_question_text
          || data?.[0]?.option_explain || "";
          return (
            <div key={index}>
              {data?.map((item, i) => {
                return (
                  <div key={i}>
                    {renderItem(item, index)}
                  </div>
                )
              })}
              {!!explainQuestion && <div style={{marginTop: 8}}>
                  <p style={{fontWeight: 600}}>Giải thích:</p>
                  <p>{explainQuestion}</p>
              </div>
              }
            </div>
          )
        })}
      </div>
    </div>
  )
}
