import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import moment from "moment";
import styled from "styled-components";
import { teacherConstants } from "../../../_constants";
import { roundNumber } from "../../../_base/Validate";

const WrapItem = styled.div`
  margin-bottom: 20px;

  .title-item {
    font-size: 18px;
    line-height: 135%;
    font-family: "Myriadpro-SemiBold";
  }
`;

function ViewExerciseMore() {
  const { id } = useParams();
  const query = new URLSearchParams(window.location.search);
  const skill = query.get("skill");
  const curriculumID = query.get("curriculum_id");
  const unitId = query.get("unit_id");
  const exercisesData = useSelector((state) => state.classes.exercises);
  const history = useHistory();

  const exercises = useSelector(
    (state) => state.classes.exercises.histories.detail
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      teacherActions.getDetailExerciseWithoutClass(id, skill, curriculumID)
    );
  }, []);

  let list_class = _.groupBy(exercises.data?.list_class_detail, function (b) {
    return b.class_name;
  });
  let list_view_class = [];
  for (let key in list_class) {
    list_view_class.push({
      class_name: key,
      list_excercise: _.orderBy(
        list_class[key],
        function (dateObj) {
          return new Date(moment(dateObj.date_assign));
        },
        "desc"
      ),
    });
  }

  // Convert data to full of 100%
  const convertDataTable = (type, item) => {
    switch (type) {
      case "poor":
        return parseInt((item.poor / item?.total_complete) * 100);
      case "average":
        if (!item.excellent && !item.good) {
          return parseInt(
            100 - parseInt((item.poor / item?.total_complete) * 100)
          );
        } else {
          return parseInt((item.average / item?.total_complete) * 100);
        }
      case "good":
        if (item.excellent) {
          return parseInt((item.good / item?.total_complete) * 100);
        } else {
          return parseInt(
            100 -
              (parseInt((item.average / item?.total_complete) * 100) +
                parseInt((item.poor / item?.total_complete) * 100))
          );
        }
      case "excellent":
        return parseInt(
          100 -
            (parseInt((item.good / item?.total_complete) * 100) +
              parseInt((item.average / item?.total_complete) * 100) +
              parseInt((item.poor / item?.total_complete) * 100))
        );
      default:
        return;
    }
  };

  const renderListClassAssign = useMemo(() => {
    return (
      list_view_class?.length > 0 &&
      list_view_class?.map((data, index) => {
        return (
          <WrapItem key={index}>
            <div className='title-item'>
              {index + 1}. {data?.class_name || ""}
            </div>
            <div className='_tb'>
              <div className='flex-m mb-5'>
                {/* <div className="_class title center-flex">
                  <p>Lớp</p>
                </div> */}
                <div className='_date title center-flex'>
                  <p>Ngày giao</p>
                </div>
                <div className='_score flex-1'>
                  <div className='title center-flex pad100 mb-5'>
                    <p>Điểm số</p>
                  </div>
                  <div className='flex-m'>
                    <div className='flex-1 title center-flex pad100 mr-2-5'>
                      <p>Y</p>
                    </div>
                    <div className='flex-1 title center-flex pad100 mg-2-5-2'>
                      <p>TB</p>
                    </div>
                    <div className='flex-1 title center-flex pad100 mg-2-5-2'>
                      <p>K</p>
                    </div>
                    <div className='flex-1 title center-flex pad100 ml-2-5'>
                      <p>G</p>
                    </div>
                  </div>
                </div>
                <div className='_number title center-flex'>
                  <p>
                    Số lượng
                    <br />
                    hoàn thành
                  </p>
                </div>
              </div>
              <div className='list-details scrollbar-custom'>
                {data.list_excercise.map((excercise, index) => (
                  <div className='flex-m item' key={index}>
                    {/* <div className="_class _detail center-flex">
                      <p>{data.class_name}</p>
                    </div> */}
                    <div className='_date _detail center-flex'>
                      <p>
                        {moment(excercise?.date_assign).format("DD/MM/YYYY")}
                      </p>
                    </div>
                    <div className='_score _detail flex-1'>
                      <div className='flex-m'>
                        <div className='flex-1 center-flex pad100 mr-2-5'>
                          <p>
                            {excercise?.total_complete
                              ? convertDataTable("poor", excercise)
                              : 0}
                            %
                          </p>
                        </div>
                        <div className='flex-1 center-flex pad100 mg-2-5-2'>
                          <p>
                            {excercise?.total_complete
                              ? convertDataTable("average", excercise)
                              : 0}
                            %
                          </p>
                        </div>
                        <div className='flex-1 center-flex pad100 mg-2-5-2'>
                          <p>
                            {excercise?.total_complete
                              ? convertDataTable("good", excercise)
                              : 0}
                            %
                          </p>
                        </div>
                        <div className='flex-1 center-flex pad100 ml-2-5'>
                          <p>
                            {excercise?.total_complete
                              ? convertDataTable("excellent", excercise)
                              : 0}
                            %
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className='_number _detail center-flex'>
                      <p>
                        {excercise?.total_user
                          ? `${excercise?.total_complete} / ${excercise?.total_user}`
                          : "0 / 0"}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </WrapItem>
        );
      })
    );
  }, [exercises?.data?.list_class_detail]);

  const actionAddExcercise = () => {
    let data_exercise = {
      exercise_id: id,
      list_guide_id: [],
      exercise_type: exercises?.data?.lesson_type,
      parent: [exercises?.data?.curriculum_name],
      detail: {
        ...exercises?.data,
        lesson_topic: exercises?.data?.topic,
        lesson_name: exercises?.data?.exercise_name,
        curriculum_id: curriculumID,
        unit_id: unitId,
      },
    };
    exercisesData.data_exercise = [data_exercise];
    dispatch({
      type: teacherConstants.ADD_DATA_EXERCISE,
      data: { ...exercisesData, students: [] },
    });
    dispatch({
      type: teacherConstants.SOURCE_PAGE_ADD_EXCERCISE,
      sourcePageAddExcercise: "PAGE_HISTORY_ADD_EXCERCISE",
    });
    history.push(`/teacher/more/exercise/${id}/class?page=morepage`);
  };

  const renderHeaderButton = () => {
    return (
      <button
        className='btn-line-blue'
        style={{ display: "flex", alignItems: "center", marginRight: 10 }}
        onClick={actionAddExcercise}
      >
        <img
          src='/assets/images/teacher/lichlamviec/ico_add.png'
          alt='ico_add'
          style={{ height: 19, marginRight: 5 }}
        />
        Giao bài
      </button>
    );
  };

  return (
    <div className='sunE-right-container scrollbar-custom'>
      <Header title={"Lịch sử giao"} isBack component={renderHeaderButton()} />
      <div className='sunE-container-box giaobai-ganday ql-bai-da-giao'>
        <div className='box-shadow-2 box-topic'>
          <h2>{exercises?.data?.topic}</h2>
          <p>{exercises?.data?.exercise_name}</p>
        </div>
        {renderListClassAssign}
      </div>
    </div>
  );
}

export { ViewExerciseMore };
