import "./ModalFilterMockTest.style.scss";
import SelectCustomDouble from "../../Select/SelectCustomDouble/SelectCustomDouble";
import ButtonNews from "../../Button/ButtonNews";

const ModalFilterMockTest = (props) => {
  return (
    <div className="modal_filter_mocktest hide display_flex_mobile uk-flex-top uk-modal uk-flex uk-open commondPages col-100 flex-align flex-column">
      <div className="modal_filter_container box-shadow">
        <div className="modal_filter_header pos_rel flex-center">
          <div className="text-align-center color-white font_news_bold fz-11-5">
            Lọc kết quả
          </div>
          <div
            onClick={props.handleClose}
            className="icon_close_modal pointer_cursor flex-center"
          >
            <img
              src="/assets/images/icon/ico_close_filter.png"
              alt="Icon Close"
              className="contain_image"
            />
          </div>
        </div>
        <div className="modal_filter_content pos_rel flex-column">
          <div>
            <SelectCustomDouble
              title="Chọn khối đề thi"
              dataSelect={props.dataGrades}
              titleSelected={props.gradeSlelected}
              typeSelect="grade"
              handleClickOption={props.handleClickOption}
            />

            <SelectCustomDouble
              title="Chọn Tỉnh/Thành phố"
              dataSelect={props.provinceList}
              titleSelected={props.provinceSelected}
              typeSelect="province"
              handleClickOption={props.handleClickOption}
            />

            <SelectCustomDouble
              title="Chọn loại đề"
              dataSelect={props.dataTypeTopic}
              titleSelected={props.topicSelected}
              typeSelect="topic"
              handleClickOption={props.handleClickOption}
            />
          </div>
        </div>
        <div className="flex-center btn_filter_container">
          <ButtonNews
            width="80%"
            height="2.5rem"
            onClick={props.handleFilter}
            border={"1px solid #00cc83"}
            backgroundColor={"#00cc83"}
            boxShadow={"2px 2px 5px white, 0 0 1px black"}

            //   maxWidth={"80%"}
            //   maxHeight={"3.5rem"}
            // disabled={disabledBtnRegist}
          >
            <div className="fz-11-5-mobile color-white font_news_bold">
              Áp dụng
            </div>
          </ButtonNews>
        </div>
      </div>
    </div>
  );
};

export default ModalFilterMockTest;
