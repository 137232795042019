import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import $ from "jquery";
import { studentActions, teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import { Alert } from "../../../_components/Alert";
import { PickDay } from "../../../_components/Calendar/PickDay";
import { teacherConstants, configConstants } from "./../../../_constants";
import { useHistory, useParams } from "react-router-dom";
import { SelectAsDiv } from "../../../_components/Select";
import moment from "moment";
import { teacherService } from "../../../_services";

function AddClass() {
  const params = useParams();

  const history = useHistory();
  const dispatch = useDispatch();
  const authentication = useSelector((state) => state.authentication);

  const [isShowConfirmDelete, showConfirmDelete] = useState(false);
  const [isEnableEdit, enableEdit] = useState(false);
  const [inputs, setInputs] = useState({
    file: "",
    class_name: "",
    organization_name: authentication?.organization_name,
    grade_id: "",
    type: "online",
    start_time: moment().format("YYYY-MM-DD"),
    end_time: moment().format("YYYY-MM-DD"),
  });
  const [urlBack, setUrlBack] = useState("");
  const [typeAction, setTypeAction] = useState("ADD");
  const [isLoadingEdit, setIsLoadingEdit] = useState(false);
  const [previewImage, setPreviewImage] = useState()

  const search = history?.location?.search;
  const paramsPage = new URLSearchParams(search);
  const page = paramsPage.get("page");

  useEffect(() => {
    const { id, type } = params;
    if (id) {
      setTypeAction("EDIT");
      setIsLoadingEdit(true)
      if (type === "offline") {
        dispatch(teacherActions.getDetailClassOffline(id));
        setUrlBack("/view/" + id + "/offline");
      } else {
        setUrlBack("/view/" + id);
        dispatch(teacherActions.getDetailClass(id, "ONLY_DATA_CLASS"));
        dispatch({
          type: teacherConstants.RESET_DATA_EXERCISE,
        });
      }
    } else {
      setUrlBack("");

      setInputs({
        ...inputs,
        file: "",
      });
    }
  }, [params.id]);

  const dataDetailClass = useSelector((state) => state.classes.detail);
  useEffect(() => {
    if (params.id && dataDetailClass) {
      setInputs({
        file: dataDetailClass?.class_avatar,
        class_name: dataDetailClass?.class_name,
        organization_name: dataDetailClass?.organization_name,
        grade_id: "",
        type: "",
        start_time: moment(dataDetailClass?.start_time).format("YYYY-MM-DD"),
        end_time: moment(dataDetailClass?.end_time).format("YYYY-MM-DD"),
      });
    } else {
      setInputs({
        ...inputs,
        file: "",
      });
    }
    let isLoading = !$(".loading").hasClass("hide");
    setIsLoadingEdit(isLoading);
  }, [dataDetailClass]);

  useEffect(() => {
    console.log("=====getAllGrade 5")
    dispatch(studentActions.getAllGrade());
    $(document).ready(function ($) {
      $("#avatar").change(function () {
        readURL(this, "#preview-avatar");
      });

      function readURL(input, element) {
        if (input.files && input.files[0]) {
          var reader = new FileReader();
          reader.onload = function (e) {
            $(element).attr("src", e.target.result);
          };
          reader.readAsDataURL(input.files[0]);
        }
      }
    });
  }, []);

  const alert = useSelector((state) => state.alert);
  const grades = useSelector((state) => state.grades);
  const [submitted, setSubmitted] = useState(false);
  const {
    class_name,
    organization_name,
    grade_id,
    type,
    file,
    start_time,
    end_time,
  } = inputs;

  useEffect(() => {
    if (!grade_id) {
      setInputs({ ...inputs, grade_id: grades[0]?.id });
    }
  }, [grades]);

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
    enableEdit(true);
  }

  function handleBlur(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value.trim() }));
  }

  function handleChangeDate(date) {
    enableEdit(true);
    setInputs((inputs) => ({
      ...inputs,
      start_time: date.start_time
        ? moment(date.start_time).format("YYYY-MM-DD")
        : inputs.start_time,
      end_time: date.end_time
        ? moment(date.end_time).format("YYYY-MM-DD")
        : inputs.end_time,
    }));
  }

  function handleChangeType(type) {
    setInputs((inputs) => ({
      ...inputs,
      type,
    }));
  }

  function handleChangeFile(event) {
    const objectUrl = URL.createObjectURL(event.target.files[0])
    setPreviewImage(objectUrl)
    setInputs((inputs) => ({
      ...inputs,
      file: event.target.files[0],
    }));
    enableEdit(true);
  }

  function openSelectFile(e) {
    e.preventDefault();
    $("#avatar").trigger("click");
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    // console.log(inputs);
    if (
      (typeAction === "ADD" && validateParam()) ||
      (typeAction !== "ADD" && validateParamEdit())
    ) {
      var dataAddClass = new FormData();
      dataAddClass.append("class_name", class_name);
      dataAddClass.append("organization_name", organization_name);
      dataAddClass.append("start_time", start_time);
      dataAddClass.append("end_time", end_time);
      if (file) {
        dataAddClass.append("file", file);
      }
      if (typeAction === "ADD") {
        dataAddClass.append("grade_id", grade_id);
        dataAddClass.append("type", type);
        dispatch(teacherActions.addClass(dataAddClass, page));
        dispatch({
          type: teacherConstants.GET_CLASSLIST_TEACHER,
          data: null,
        });
      } else {
        dataAddClass.append("class_id", params.id);
        dispatch(teacherActions.updateClassById(dataAddClass));
        dispatch({
          type: teacherConstants.GET_CLASSLIST_TEACHER,
          data: null,
        });
      }
    }
  }

  function validateParam() {
    return type &&
      grade_id &&
      class_name.trim() &&
      start_time &&
      end_time &&
      start_time <= end_time
      ? true
      : false;
  }

  function validateParamEdit() {
    return class_name && start_time && end_time && start_time <= end_time
      ? true
      : false;
  }

  const submitDeleteClass = () => {
    dispatch(teacherActions.deleteClassById(params.id));
    showConfirmDelete(false);
    dispatch({
      type: teacherConstants.GET_CLASSLIST_TEACHER,
      data: null,
    });
  };

  const toggleModalConfirmDelete = (bool) => {
    showConfirmDelete(bool);
    if (bool) {
      setUrlBack("");
    } else {
      setUrlBack("/view/" + params.id);
    }
  };
  
  const updateDetailClass = () => {
    teacherService.getDetailClass(params?.id).then((res) => {
      if (res?.data) {
        dispatch({
          type: teacherConstants.GET_DETAIL_CLASS,
          class: res.data,
        });
        dispatch({
          type: teacherConstants.SAVE_DATA_CLASS,
          payload: {
            id,
            data: res.data
          }
        })
      } else {
        type = "offline";
        dispatch(teacherActions.getDetailClassOffline(id));
      }
    });
    dispatch({
      type: teacherConstants.RESET_DATA_EXERCISE,
    });
  }
  
  return (
    <div className="sunE-right-container flex-column">
      <Header
        title={typeAction === "ADD" ? "Tạo lớp mới" : "Chỉnh sửa thông tin lớp"}
        isBack
      />
      {isLoadingEdit ? null : <>
        <div className="sunE-content sunE-create-class-content flex-1">
          <div className="flex-m">
            <div className="flex-1 sunE-create-class-img">
              <div className="img-upload box-shadow rel height-330">
                <div className="avt-img">
                  <img
                    id="preview-avatar"
                    alt="avatar"
                    src={
                      previewImage || (configConstants.BASE_URL +
                      ((params.id && dataDetailClass?.class_avatar) ||
                        "assets/img_base/class_dfa.jpg"))
                    }
                  />
                </div>
                <input
                  type="file"
                  name="img"
                  accept="image/*"
                  className="hide"
                  onChange={handleChangeFile}
                  id="avatar"
                />
                {typeAction === "ADD" ? (
                  <div
                    className="upload-gr select-avatar-class-custom"
                    onClick={openSelectFile}
                  >
                    <img
                      src="/assets/images/icon/ico_upload_image.png"
                      alt="ico_edit"
                    />
                    <p>Chọn ảnh đại diện</p>
                  </div>
                ) : (
                  <div className="__change_avatar" onClick={openSelectFile}>
                    <img
                      src="/assets/images/icon/ico_camera.png"
                      alt="ico_edit"
                    />
                    <p>Chọn ảnh khác</p>
                  </div>
                )}
              </div>
            </div>
            <div className="flex-1 sunE-class-info">
              {alert.message &&
              alert.screen === teacherConstants.SCREEN_ADD_CLASS ? (
                <Alert
                  alert={alert}
                  onComplete={() => {
                    history.push("/" + authentication.role + "/class" + urlBack)
                    updateDetailClass()
                  }}
                />
              ) : (
                <Alert
                  alert={alert}
                  onComplete={() => history.push("/" + authentication.role)}
                />
              )}
              {typeAction === "ADD" && (
                <div
                  className={
                    "select-custom-bg text-center " +
                    (submitted && !grade_id ? " err" : "")
                  }
                >
                  <SelectAsDiv
                    keySelected={grade_id}
                    textDefault={"Khối"}
                    data={grades}
                    onChangeSelect={(grade_id) =>
                      setInputs({ ...inputs, grade_id })
                    }
                    styleBox={{
                      justifyContent: "center",
                    }}
                    styleOption={{
                      paddingRight: '56px'
                    }}
                    styleItem={{
                      justifyContent: 'center',
                    }}
                  />
                </div>
              )}
              {typeAction === "ADD" && (
                <div className="flex-m class-on-off">
                  <div
                    className={
                      "flex-1 text-center" + (submitted && !type ? " err" : "")
                    }
                  >
                    <input
                      className="pointer_cursor"
                      type="radio"
                      name="type"
                      defaultValue="offline"
                      checked={inputs.type === "offline"}
                      onChange={() => handleChangeType("offline")}
                    />
                    <label htmlFor="offline">Lớp Offline</label>
                    <br />
                  </div>
                  <div
                    className={
                      "flex-1 text-center" + (submitted && !type ? " err" : "")
                    }
                  >
                    <input
                      className="pointer_cursor"
                      type="radio"
                      name="type"
                      defaultValue="online"
                      checked={inputs.type === "online"}
                      onChange={() => handleChangeType("online")}
                    />
                    <label htmlFor="online">Lớp Online</label>
                    <br />
                  </div>
                </div>
              )}
              <div
                className={
                  "sunE-input-group" + (submitted && !class_name ? " err" : "")
                }
              >
                <label>
                  Tên lớp<span className="red">*</span>
                </label>
                <input
                  type="text"
                  name="class_name"
                  value={inputs.class_name}
                  placeholder="Nhập tên lớp"
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>
              <div className="sunE-input-group">
                <label>Tên trường/Tổ chức/Trung tâm/...</label>
                <input
                  type="text"
                  name="organization_name"
                  value={inputs.organization_name}
                  placeholder="Nhập tên trường"
                  // onChange={handleChange}
                  readOnly
                  style={{ color: "#a6a6a6", cursor: "not-allowed" }}
                />
              </div>
  
              {/* <div className="flex-m class-slect-time">
                              <div className="flex-1">
                                  <div className={'sunE-input-group slect-time' + (submitted && start_time > end_time ? ' err' : '')}>
                                      <label>Ngày bắt đầu<span className="red">*</span></label>
                                      <PickDay time={moment(inputs.start_time).format('DD-MM-YYYY')} name_time={'start_time'} handleChangeDate={handleChangeDate} />
                                  </div>
                              </div>
                              <div className="flex-1">
                                  <div className={'sunE-input-group slect-time' + (submitted && start_time > end_time ? ' err' : '')}>
                                      <label>Ngày kết thúc<span className="red">*</span></label>
                                      <PickDay time={moment(inputs.end_time).format('DD-MM-YYYY')} name_time={'end_time'} handleChangeDate={handleChangeDate} />
                                  </div>
                              </div>
                          </div> */}
  
              {typeAction !== "ADD" && (
                <div className="form-sunE-button text-center btn-mr-custom __vertical-center">
                  <button
                    className={
                      "btn-line-blue btn-update-class" +
                      (!validateParamEdit() || !isEnableEdit
                        ? " btn-disable"
                        : "")
                    }
                    onClick={handleSubmit}
                  >
                    Lưu
                  </button>
                  <a
                    className="__action_delete_class"
                    onClick={() => toggleModalConfirmDelete(true)}
                  >
                    Giải tán lớp
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
        {typeAction === "ADD" && (
          <div className="form-sunE-button text-right btn-mr-custom">
            <button
              className={
                "btn-add-class-custom btn-line-blue bottom-60" +
                (!validateParam() ? " btn-disable" : "")
              }
              onClick={handleSubmit}
            >
              Xong
            </button>
          </div>
        )}
        {isShowConfirmDelete && (
          <div
            id="modal-center"
            className="uk-flex-top uk-modal uk-flex uk-open"
            uk-modal=""
          >
            <div
              className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical __modal_confirm_delete_class"
              style={{ width: 300 }}
            >
              <div className="__vertical-center __body_confirm_delete_class">
                <h5 className="__title_confirm">Bạn có muốn giải tán lớp?</h5>
                <span className="__message_confirm">
                  Khi bạn xoá lớp thì thành viên của lớp và dữ liệu liên quan đến
                  lớp cũng sẽ không còn.
                </span>
              </div>
              <div className="flex-m form-sunE-button">
                <button
                  className={
                    "btn-default width-100-percent mr-10 btn-action-popup btn-line-blue"
                  }
                  onClick={() => toggleModalConfirmDelete(false)}
                >
                  Không
                </button>
                <button
                  className="btn-line-blue width-100-percent btn-action-popup"
                  onClick={submitDeleteClass}
                >
                  Có
                </button>
              </div>
            </div>
          </div>
        )}
      </>}
    </div>
  );
}

export { AddClass };
