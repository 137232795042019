import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "../../../_components/Admin/Header";
import { PopUpFilter } from "../../../_components/Popup";
import { ContentSkill } from "./";
import { isEmpty } from "lodash";
import { popupConstants, curriculumConstants, userConstants } from "../../../_constants";
import { useHistory, useLocation, useParams } from "react-router-dom";

function Filter() {
  const filters = useSelector((state) => state.curriculums.filters.data);
  const dispatch = useDispatch();
  const history = useHistory();
  const paramFilter = useSelector(state => state.curriculums.filters.param);

  const location = useLocation()
  const fromPageRedux = useSelector((state) => state.curriculums.fromPage)
  const classId = useSelector(state => state.classes?.exercises?.class_id)
  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const assign = params?.get("assign");
  const user_id = params?.get("user_id");

  const isFilter = (params) => 
    !!params?.skill?.length || 
    !!params?.type?.length || 
    !!params?.grade_id?.length || 
    !!params?.end_time?.length ||
    !!params?.level?.length ||
    !!params?.start_time?.length ||
    !!params?.topic?.length ? true : false
    
  useEffect(() => {
    return () => {
      dispatch({
        type: curriculumConstants.CLEAR_PARAM_FILTER_CURRICULUM,
      });
    };
  }, []);

  useEffect(() => {
    if(!!filters?.length) {
      const listEle = document.querySelector('.sunE-giaotrinh-resuft-filter')
      if(listEle) {
        listEle.scrollTo({top: 0})
      }
    }
  }, [filters?.length])

  function setshowFilter() {
    dispatch({
      type: popupConstants.SHOW_FORM_POPUP,
      data: {
        showFilterCurriculum: true,
      },
    });
  }
  const Popup = useSelector((state) => state.Popup);
  const authentication = useSelector((state) => state.authentication);

  function setcloseFilter() {
    dispatch({
      type: popupConstants.CLEAR_ALL_POPUP,
    });
  }

  function resetFilter() {
    // setcloseFilter();
    // history.push("/" + authentication.role + "/curriculum/view/sunday");
  }
  function componentFilter() {
    return (
      <div
        className="form-sunE-button btn-filter rel"
        onClick={() => setshowFilter()}
      >
         <img src={isFilter(paramFilter)
          ? `/assets/images/teacher/ico_filter.png` 
          : `/assets/images/teacher/icon_not_yet_filter.png`} alt="ico_add" 
          className="ml--5"
          />
        <button className="btn-line-blue">Lọc</button>
      </div>
    );
  }

  const isBack = () => {
    if(fromPageRedux == "morepage") {
       history.go(-1)
    } else if (fromPageRedux == "curriculum") {
      history.replace(`/${authentication.role}/curriculum/view/sunday?page=curriculum`)
    } else if (fromPageRedux == "classmanager") {
      !isEmpty(classId) ? history.push(`/${authentication.role}/class/view/${classId}/exercise/curriculum?assign=assign_freedom&page=classmanage`): history.go(-1)
    } else if(!isEmpty(assign) && !isEmpty(user_id)) {
      history.replace(`/${authentication.role}/class/view/${classId}/exercise/curriculum?assign=${assign}&user_id=${user_id}`)
    }  
    else {
      history.replace(`/${authentication.role}/curriculum/view/sunday`)
    }
    dispatch({type: curriculumConstants.CLEAR_FROM_PAGE})
  }
  return (
    <div className="sunE-right-container flex-column">
      {Popup.showFilterCurriculum && (
        <PopUpFilter
          filter_curriculum={true}
          actionResetFilter={resetFilter}
          onClickNo={() => setcloseFilter()}
          width={420}
        />
      )}
      <Header
        title={"Giáo trình Sunday English"}
        component={componentFilter()}
        isBack
        clickBack={location.state?.curriculum_sunday ? isBack : history.go(-1)}
      />
      {!isEmpty(filters) ? (
        <ContentSkill
          data={filters}
          filters={filters}
          textEmpty="Không tìm thấy kết quả phù hợp."
          filterCurriculumStatus={true}
          typeAssign={assign}
          userID={user_id}
        />
      ) : (
        <div className="flex-1 no-plan">
          <div className="text-center">
            <img src="/assets/images/giaotrinh/bg_gt.png" alt="bg_gt" />
            <h2 className="mt-20">Không tìm thấy kết quả phù hợp</h2>
          </div>
        </div>
      )}
    </div>
  );
}

export { Filter };
