import { useDispatch, useSelector } from "react-redux";
import { Header } from "../../../../_components/Admin/Header";
import { apiCaller, history } from "../../../../_helpers";
import { useParams } from "react-router-dom";
import './ActiveLicenseStudent.style.scss';
import { useEffect, useState } from "react";
import { alertActions } from "../../../../_actions";
import { Alert } from "../../../../_components/Alert";
import APIBase from "../../../../_base/APIBase";
import API from "../../../../_apis/APIConstants";
import { configConstants, teacherConstants } from "../../../../_constants";
import { teacherService } from "../../../../_services";

export default function ActiveLicenseStudent() {
  const authentication = useSelector((state) => state.authentication);
  const alert = useSelector((state) => state.alert);
  const classes = useSelector((state) => state.classes);
  const dispatch = useDispatch();
  const {id} = useParams()
  const [listLicense, setListLicense] = useState([])
  const [licenseSelected, setLicenseSelected] = useState()
  const [listStudentInClass, setListStudentInClass] = useState(classes?.students?.data || [])
  const [listIdStudentChoose, setListIdStudentChoose] = useState([])
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleSelectedLicense = (item) => {
    setLicenseSelected(item);
    setListIdStudentChoose([]);
  }

  const handleChooseStudent = (item) => {
    if (!!listIdStudentChoose.find(id => id === item?.id)) {
      setListIdStudentChoose(listIdStudentChoose.filter(id => id!== item?.id))
      return;
    }
    if (listIdStudentChoose?.length === licenseSelected?.available) {
      showMessageUnavailable()
      return;
    }
    setListIdStudentChoose(prev => [...prev, item?.id])
  }
  
  const handleChooseAllStudent = (e) => {
    e.preventDefault();
    if (listIdStudentChoose?.length === listStudentInClass?.length) {
      setListIdStudentChoose([])
      return;
    }
    if (listIdStudentChoose?.length === licenseSelected?.available) {
      showMessageUnavailable()
      return;
    }
    if (licenseSelected?.available < listStudentInClass?.length) {
      setListIdStudentChoose(listStudentInClass?.slice(0, licenseSelected?.available)?.map(item => item?.id))
      showMessageUnavailable()
    } else {
      setListIdStudentChoose(listStudentInClass?.map(item => item?.id))
    }
  }

  const showMessageUnavailable = () => {
    dispatch(
      alertActions.error({
        message: 'Số lượng code còn lại không đủ',
        screen: 'ACTIVE_LICENSE_STUDENT',
        isShowPopup: true
      })
    );
  }

  const handleSubmit = async () => {
    if (step === 0) {
      if (!listStudentInClass?.length) {
        await teacherService
        .getStudentOfClassV2(id)
        .then((students) => {
          // dispatch({
          //   type: teacherConstants.GET_STUDENT_OFF_CLASS,
          //   students,
          // });
          setListStudentInClass(students?.data)
        });
      }
      setStep(1)
      return;
    }
    onAssignLicense();
  }

  const getData = async () => {
    setIsLoading(true)
    try {
      const url = API.baseURL + 'api/license/assign_license'
      const res = await  APIBase.apiBaseCaller("GET", url, {})
      if (res?.status) {
        setListLicense(res.data?.filter(item => !!item?.available) || [])
      }
      setIsLoading(false)
    } catch (err) { 
      setIsLoading(false)
    }
  }

  const onAssignLicense = async () => {
    try {
      const url = API.baseURL + 'api/license/assign_license_student'
      const data = {
        student_list: listIdStudentChoose,
        license_id: licenseSelected?.license_id
      }
      const res = await  APIBase.apiBaseCaller("POST", url, data)
      if (res?.status) {
        dispatch(
          alertActions.success({
            message: res?.msg?.toString(),
            screen: 'ACTIVE_LICENSE_STUDENT',
            onCompletePopup: () => {
              history.push("/" + authentication.role + `/class/view/${id}/student`);
            }
          })
        );
      } else {
        dispatch(
          alertActions.error({
            message: res?.msg?.toString(),
            screen: 'ACTIVE_LICENSE_STUDENT',
            isShowPopup: true
          })
        );
      }
    } catch (err) { 
      dispatch(
        alertActions.error({
          message: err?.toString(),
          screen: 'ACTIVE_LICENSE_STUDENT',
          isShowPopup: true
        })
      );
    }
  }

  const validateParams = () => {
    if (step === 0) {
      return !!licenseSelected
    }
    return !!listIdStudentChoose?.length
  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <div className="sunE-right-container rel">
      <Header
        title={"Kích hoạt gói"}
        isBack
        clickBack={() =>
          history.push("/" + authentication.role + `/class/view/${id}/student`)
        }
      />
      <div className="sunE-content" style={{height: '84vh'}}>
        {isLoading ? null : <div className="active-license-student-container">
          <div style={{padding: '0 16px 16px'}}>
            <h2 className="bold sunE-title-medium" style={{padding:0}}>{step === 0 ? 'Chọn license muốn sử dụng' : 'Chọn học sinh kích hoạt'}</h2>
            {step === 1 && 
            <div style={{display: 'flex', justifyContent:'space-between', alignItems: 'center',paddingRight: 16}}>
              <p>Tổng số mã đã dùng:{' '}
                  <span style={{fontWeight: 700}}>{`${listIdStudentChoose?.length}/${licenseSelected?.available}`}</span>
              </p>
              <span
                className='chk-custom-gr size-27 top-1'
                style={{marginLeft: 'auto'}}
                onClick={handleChooseAllStudent}
              >
                <input
                  type='checkbox'
                  checked={listIdStudentChoose?.length === listStudentInClass?.length}
                  id={'checkAllStudent'}
                />
                <label className="check-all-student-btn" htmlFor={'checkAllStudent'}>Chọn tất cả</label>
              </span>
            </div>
            }
          </div>
          <div className="active-license-student-list scrollbar-custom">
            {step === 0 && (!!listLicense?.length ? listLicense?.map((item) => {
              return (
                <div className="active-license-student-item" onClick={() => handleSelectedLicense(item)}>
                  <div className='radio-item' style={{marginTop: 0}}>
                    <input
                      id={item?.license_id}
                      className="pointer_cursor"
                      type="radio"
                      name="radio-group"
                      checked={licenseSelected?.license_id === item?.license_id}
                    />
                    <label htmlFor={item?.license_id} style={{cursor: 'pointer', fontSize: 16}}></label>
                  </div>
                  <div className="active-license-student-info">
                    <p style={{fontWeight: 700}}>{item?.license_name}</p>
                    <p>
                      Tổng số code:{' '}
                      <span style={{fontWeight: 700}}>{item?.total_code}</span>
                    </p>
                    <p>
                      Còn lại:{' '}
                      <span style={{fontWeight: 700}}>{item?.available}</span>
                    </p>
                    <p>
                      Thời hạn kích hoạt:{' '}
                      <span style={{fontWeight: 700}}>{item?.duration}</span>
                    </p>
                    {item?.description && <p>
                      Mô tả:{' '}
                      <span>{item?.description}</span>
                    </p>}
                  </div>
                </div>
              )
            }) : <p style={{color: 'red', fontWeight: 700}}>Bạn chưa có gói kích hoạt nào</p>)}
            {step === 1 && listStudentInClass?.map(item => {
              return (
                <div className="active-license-student-item" onClick={() => handleChooseStudent(item)}>
                  <div className='active-license-student-img'>
                    <img
                      src={configConstants.BASE_URL + (item?.avatar ? item.avatar : `assets/img_base/student_${item?.gender ? item.gender : 'male'}_dfa.jpg`)}
                      alt="avatar"
                    />
                  </div>
                  <div className="active-license-student-info">
                    <p style={{fontWeight: 700}}>{item?.name}</p>
                    {item?.email && <p>{item?.email}</p>}
                    {item?.phone && <p>{item?.phone}</p>}
                    {item?.username && item?.username !== '*******' && <p>{item?.username}</p>}
                  </div>
                  <span
                    className='chk-custom-gr size-27 top-1'
                    style={{marginLeft: 'auto'}}
                    onClick={(e) => {
                      e.preventDefault();
                      // handleChooseStudent(item)
                    }}
                  >
                    <input
                      type='checkbox'
                      checked={!!listIdStudentChoose?.find(id => id === item?.id)}
                      id={item?.id}
                    />
                    <label htmlFor={item.id}></label>
                  </span>
                </div>
              )
            })}
          </div>
          <div className="active-license-student-action">
            {step === 1 && <button className="btn-default" style={{width: '100%', textWrap: 'nowrap'}} onClick={() => setStep(0)}>Quay lại</button>}
            <button 
              disabled={!validateParams()} 
              className={"btn-bg-blue btn-default " + (!validateParams() ? 'btn-disable' : '')}
              onClick={handleSubmit}  
              style={{width: '100%', textWrap: 'nowrap'}}
            >
              {step === 0 ? 'Tiếp tục' : 'Kích hoạt'} 
            </button>
          </div>
        </div>}
      </div>
      {alert?.message && alert?.screen === 'ACTIVE_LICENSE_STUDENT' && 
        <Alert 
          alert={alert}
        />
      }
    </div>
  )
}