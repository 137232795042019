import React, { useState } from "react";
import "./style.css";
import { useSelector, useDispatch } from "react-redux";
import { scheduleConstants, userConstants } from "./../../_constants";
import { scheduleActions } from "./../../_actions";
import { Alert } from "./../../_components/Alert";

function PopUpRadio({ selectedDate, student }) {
  const alert = useSelector((state) => state.alert);
  const schedules = useSelector((state) => state.schedules.delete_schedule);
  const authentication = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const [updateAll, setupdateAll] = useState(schedules.updateAll);
  function close() {
    dispatch({
      type: scheduleConstants.RESET_DELETE_SCHEDULE_DAY,
    });
  }

  function deleteSchedule() {
    dispatch({
      type: scheduleConstants.SET_DATE_SELECTED_CALENDAR,
      time: schedules.dateSelectedCalendar
        ? schedules?.dateSelectedCalendar
        : schedules?.selectDate,
    });
    if (schedules.repeatType == "no_repeat") {
      dispatch(
        scheduleActions.deleteSchedule(
          {
            id: schedules.id,
            user_id: authentication.id,
            update_all: 0,
            selectedDate,
          },
          schedules.dateSelectedCalendar
            ? schedules?.dateSelectedCalendar
            : schedules?.selectDate,
          authentication.role === userConstants.ROLE_STUDENT
        )
      );
    } else {
      dispatch(
        scheduleActions.deleteSchedule(
          {
            id: schedules.id,
            user_id: authentication.id,
            update_all: updateAll,
            selectedDate,
          },
          schedules.dateSelectedCalendar
            ? schedules?.dateSelectedCalendar
            : schedules?.selectDate,
          authentication.role === userConstants.ROLE_STUDENT
        )
      );
    }

    dispatch(
      scheduleActions.getScheduleToday(
        authentication.id,
        schedules.dateSelectedCalendar
          ? schedules?.dateSelectedCalendar
          : schedules?.selectDate
      )
    );
  }
  
  if (schedules.showForm) {
    return (
      <div
        id="modal-center"
        className="uk-flex-top uk-modal uk-flex uk-open"
        uk-modal=""
      >
        <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
          {alert.message &&
            alert.screen === scheduleConstants.SCREEN_DELETE_SCHEDULE && (
              <Alert alert={alert} />
            )}
          <p className="text-center popup-title">
            Bạn có chắc muốn xóa kế hoạch này?
          </p>
          {schedules.repeatType != "no_repeat" && (
            <div className="radio-body">
              <div>
                <p className="radio-gr">
                  <input
                    type="radio"
                    name="radioName"
                    checked={!updateAll}
                    id="One"
                    // defaultChecked={updateAll === 0 ? true : false}
                    onChange={() => setupdateAll(0)}
                  />
                  <label htmlFor="One">Chỉ xoá kế hoạch này</label>
                </p>
                <p className="radio-gr">
                  <input
                    type="radio"
                    name="radioName"
                    id="Two"
                    checked={!!updateAll}
                    onChange={() => setupdateAll(1)}
                  />
                  <label htmlFor="Two">Xoá tất cả chuỗi</label>
                </p>
              </div>
            </div>
          )}

          <div className="flex-m btn-popup-gr">
            <button className="btn-default wd-c mr-10" onClick={close}>
              Hủy
            </button>
            <button
              className="btn-bg-blue btn-default wd-c"
              onClick={deleteSchedule}
            >
              Đồng ý
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export { PopUpRadio };
