import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions, alertActions } from "../../../_actions";
import moment from "moment";
import {
  PopUpAddFile,
  PopUpFilter,
  PopUpYesNo,
} from "../../../_components/Popup";
import { curriculumConstants, popupConstants } from "../../../_constants";
import { Header } from "../../../_components/Admin/Header";
import _, { isEmpty } from "lodash";
import { useParams } from "react-router-dom";
import { teacherConstants } from "../../../_constants";
import { useHistory } from "react-router-dom";
import { TypeAssignExercise } from "../../../_constants/typeAssignExercise";
import $ from "jquery";
import { PopUpZoomImageV2 } from "../../../_components/Popup/PopUpZoomImageV2";

function ListFileSupport() {
  const dispatch = useDispatch();
  const { id, skill_key } = useParams();
  const history = useHistory();

  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const userID = params.get("user_id");
  const lessonID = params.get("lesson_id");
  const type = params.get("type");
  const fromPage = params?.get("page");

  const alert = useSelector((state) => state.alert);
  const exercises = useSelector((state) => state.classes.exercises);
  const authentication = useSelector((state) => state.authentication);
  const curriculums = useSelector((state) => state.curriculums);
  const [isShowDetail, showDetail] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const paramFilter = useSelector((state) => state.curriculums.filters.param);
  const [fileActive, setFileActive] = useState({});
  const [showHideDisplay, setShowHideDisplay] = useState(false);
  const [htmlWritingContent, setHtmlWritingContent] = useState([]);
  const [openZoomImage, setOpenZoomImage] = useState(false);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
  }, [exercises]);

  useEffect(() => {
    setLoading(true);
    dispatch(teacherActions.getFileExercise(curriculums.filters.param));
    return () => {
      dispatch({
        type: curriculumConstants.CLEAR_PARAM_FILTER_CURRICULUM,
      });
    };
  }, []);

  const Popup = useSelector((state) => state.Popup);

  function setshowFilter() {
    dispatch({
      type: popupConstants.SHOW_FORM_POPUP,
      data: {
        showFilterCurriculum: true,
      },
    });
  }

  function setcloseFilter() {
    dispatch({
      type: popupConstants.CLEAR_ALL_POPUP,
    });
  }

  let isNotUseFilter =
    _.isEmpty(curriculums.filters.param.grade_id) &&
    _.isEmpty(curriculums.filters.param.level) &&
    _.isEmpty(curriculums.filters.param.skill) &&
    _.isEmpty(curriculums.filters.param.type);

  function componentFilter() {
    if (isNotUseFilter && _.isEmpty(exercises.files.data)) {
      return null;
    }
    return (
      <div
        className="form-sunE-button btn-filter rel"
        onClick={() => setshowFilter()}
      >
        <img
          src={
            _.isEmpty(paramFilter.skill) &&
            _.isEmpty(paramFilter.type) &&
            _.isEmpty(paramFilter.grade_id)
              ? `/assets/images/teacher/icon_not_yet_filter.png`
              : `/assets/images/teacher/ico_filter.png`
          }
          alt="ico_add"
          className="ml--5"
        />
        <button className="btn-line-blue">Lọc</button>
      </div>
    );
  }

  const [listFileId, setListFileId] = useState(
    exercises.data_exercise[skill_key - 1]?.list_guide_id
      ? exercises.data_exercise[skill_key - 1]?.list_guide_id
      : []
  );
  function hanldeChecked(data) {
    var index = _.findIndex(listFileId, { id: data.id });
    let listIds = [];
    if (index === -1) {
      listIds = [...listFileId, data];
    } else {
      listIds = listFileId.filter((item) => item.id !== data.id);
    }
    setListFileId(listIds);
  }

  function handleSubmit() {
    if (validateParam()) {
      exercises.data_exercise[skill_key - 1].list_guide_id = listFileId;
      exercises.isNew = true;
      dispatch({
        type: teacherConstants.ADD_DATA_EXERCISE,
        data: exercises,
      });
      if (
        [TypeAssignExercise.DEMAND, TypeAssignExercise.CAPACITY].includes(type)
      ) {
        history.push(
          "/" +
            authentication.role +
            "/class/view/" +
            id +
            "/exercise/student/" +
            type +
            "/detail_lesson/" +
            lessonID +
            "?user_id=" +
            userID +
            (!isEmpty(fromPage) ? `&page=${fromPage}` : "")
        );
      } else {
        history.push(
          "/" +
            authentication.role +
            "/class/view/" +
            id +
            "/exercise/detail_skill/" +
            skill_key +
            (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
        );
      }
    }
  }

  function validateParam() {
    return _.isEmpty(listFileId) ? false : true;
  }

  function resetFilter(params) {
    dispatch(teacherActions.getFileExercise(params, false));
  }

  function setshowAddFile() {
    dispatch({
      type: popupConstants.SHOW_FORM_POPUP,
      data: {
        showAddFile: true,
      },
    });
  }

  function setclosePopup() {
    dispatch(alertActions.error({ message: "", screen: "" }));
    dispatch({
      type: popupConstants.CLEAR_ALL_POPUP,
    });
  }

  function renderIcoFile(data) {
    if (data.type === "document") {
      let arr = data.path.split(".");
      let fileFormat = arr[arr.length - 1];
      if (["doc", "docx"].includes(fileFormat)) {
        return (
          <img
            src={`/assets/images/teacher/giaobai/ico_doc.png`}
            alt={data.type}
          />
        );
      } else if (["xlsx", "xls", "xlsm", "xlsb"].includes(fileFormat)) {
        return (
          <img
            src={`/assets/images/teacher/giaobai/ico_excel.png`}
            alt={data.type}
          />
        );
      } else if (["ppt", "pptx", "pps", "ppsx", "pptm"].includes(fileFormat)) {
        return (
          <img
            src={`/assets/images/teacher/giaobai/ico_ppt.png`}
            alt={data.type}
          />
        );
      } else if (fileFormat === "pdf") {
        return (
          <img
            src={`/assets/images/teacher/giaobai/ico_pdf.png`}
            alt={data.type}
          />
        );
      } else {
        return (
          <img
            src={`/assets/images/teacher/giaobai/ico_${data.type}.png`}
            alt={data.type}
          />
        );
      }
    } else {
      return (
        <img
          src={`/assets/images/teacher/giaobai/ico_${data.type}.png`}
          alt={data.type}
        />
      );
    }
  }

  useEffect(() => {
    if (fileActive.type == "writing") {
      const htmlFileContent = fileActive?.content?.split("\n");
      // console.log(htmlFileContent);
      setHtmlWritingContent(htmlFileContent);
    }
  }, [fileActive.type]);

  const openWindow = (file) => {
    showDetail(true);
    setFileActive(file);
    if (["video", "writing", "audio", "img"].includes(file.type)) {
      setShowHideDisplay(true);
    } else {
      window.open(file?.path, "_blank");
    }
  };

  const setIsBack = () => {
    showDetail(false);
    if (showHideDisplay) {
      setShowHideDisplay(false);
      setFileActive({});
    } else {
      history.go(-1);
    }
  };

  const detailImage = () => {
    setOpenZoomImage(true);
  };

  function isIdInList(id, list) {
    return list.some((item) => item.id === id);
  }

  return (
    <div className="sunE-right-container flex-column">
      {isShowDetail
        ? ""
        : Popup.showFilterCurriculum && (
            <PopUpFilter
              actionResetFilter={(params) => resetFilter(params)}
              onClickNo={() => setcloseFilter()}
              width={460}
              filter_files={true}
              class_name={"custom-width"}
            />
          )}
      <Header
        title={"Đính kèm nội dung"}
        component={isShowDetail ? "" : componentFilter()}
        isBack
        clickBack={showHideDisplay ? setIsBack : ""}
      />
      {/* {Popup.showFilterCurriculum && (
        <PopUpFilter
          actionResetFilter={(params) => resetFilter(params)}
          onClickNo={() => setcloseFilter()}
          width={460}
          filter_files={true}
          class_name={"custom-width"}
        />
      )}
      <Header
        title={"Đính kèm nội dung"}
        component={componentFilter()}
        isBack
      />
      {Popup.showAddFile && (
        <PopUpAddFile
          param={curriculums.filters.param}
          onClickNo={() => setclosePopup()}
          width={320}
          messageErr={
            alert.screen === teacherConstants.SCREEN_LIST_FILE
              ? alert.message
              : ""
          }
          class_name={"custom-width"}
        />
      )} */}
      {Popup.showAddFile && (
        <PopUpAddFile
          param={curriculums.filters.param}
          onClickNo={() => setclosePopup()}
          width={400}
          messageErr={
            alert.screen === teacherConstants.SCREEN_LIST_FILE
              ? alert.message
              : ""
          }
          class_name={"custom-width"}
        />
      )}
      {showHideDisplay ? (
        <div>
          <div className="box-giaotrinh-gr rel box-shadow hei-custom">
            <div className="flex-m" style={{ flex: 1 }}>
              <div className="thumb2">{renderIcoFile(fileActive)}</div>
              <div className="box-select-info flex-1">
                <p className="semibold text-left one-line">
                  {fileActive?.title}
                </p>
              </div>
            </div>
          </div>
          {fileActive?.type === "writing" && (
            <div className="box-content-writing-study-guide rel box-shadow hei-custom">
              {htmlWritingContent.map((item, index) => (
                <div key={index}>{item}</div>
              ))}
            </div>
          )}
          {fileActive?.type === "video" && (
            <div className="box-content-study-guide rel hei-custom">
              <video controls="controls" className="content-study-guide">
                <source
                  src={fileActive?.path}
                  alt={fileActive?.type}
                  type="video/mp4"
                />
              </video>
            </div>
          )}
          {fileActive?.type === "audio" && (
            <div className="box-content-study-guide rel hei-custom">
              <audio controls="controls">
                <source
                  src={fileActive?.path}
                  alt={fileActive?.type}
                  type="audio/mpeg"
                />
              </audio>
            </div>
          )}
          {fileActive?.type === "img" && (
            <div className="box-content-study-guide rel hei-custom">
              <div className="pos_rel">
                <img
                  className="content-study-guide"
                  src={encodeURI(fileActive?.path)}
                  alt={fileActive?.type}
                />
                <img
                  onClick={detailImage}
                  className="contain_image pos_abs ico_zoom_out"
                  src="/assets/images/icon/ico_zoom_out.png"
                  alt="Icon Zoom out"
                />
              </div>
            </div>
          )}
          {openZoomImage && (
            <PopUpZoomImageV2
              src={fileActive?.path}
              height="90%"
              onClickNo={() => setOpenZoomImage(false)}
            />
          )}
        </div>
      ) : (
        <>
          <div className="sunE-container-box giaobai-support canhan flex-1 pasd">
            <div className="uk-container">
              <div className="flex-1 sunE-giaotrinh-list scrollbar-custom h-giaobai-support-list">
                <div className="uk-grid">
                  {exercises.files &&
                    !_.isEmpty(exercises.files.data) &&
                    exercises.files.data.map((data, i) => {
                      // var index = _.findIndex(listFileId, { id: data.id });
                      const isDefaultChecked = isIdInList(data.id, listFileId);
                      return (
                        <div className=" uk-width-1-2" key={i}>
                          <div className="box-giaotrinh-gr rel box-shadow hei-custom responsive_low_screen_files_support">
                            <div
                              className="thumb2"
                              onClick={() => openWindow(data)}
                            >
                              {renderIcoFile(data)}
                            </div>
                            <div
                              className="box-select-info box-select-file-full"
                              onClick={() => openWindow(data)}
                            >
                              <p className="semibold one-line">{data.title}</p>
                              <span className="blue-main">
                                {data.grade_name} &gt;&gt;{" "}
                                {data.skill.capitalize()}
                              </span>
                              <span>
                                Ngày tạo:{" "}
                                {moment(data.created_at).format("DD-MM-YYYY")}
                              </span>
                            </div>
                            <div className="chk-ab">
                              <span className="chk-custom-gr wh20 check-select-student">
                                <input
                                  type="checkbox"
                                  onChange={() => hanldeChecked(data)}
                                  id={data.id}
                                  checked={isDefaultChecked}
                                />
                                <label htmlFor={data.id}></label>
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
                {!isLoading &&
                  _.isEmpty(exercises.files.data) &&
                  (isNotUseFilter ? (
                    <div className="__text_empty_tutorial_guide">
                      <img src="/assets/images/empty_excercise.png" />
                      <br />
                      Bạn chưa tạo nội dung hướng dẫn giảng dạy nào.
                      <br /> Chọn{" "}
                      <strong className="__semibold">"Tạo mới"</strong> để tạo
                      hướng dẫn.
                    </div>
                  ) : (
                    <div
                      className="__text_empty_tutorial_guide"
                      style={{ fontWeight: "normal" }}
                    >
                      Không tìm thấy kết quả phù hợp.
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div className="form-sunE-button text-right">
            <button
              className="btn-line-blue mr-10 btn-size-sm"
              onClick={setshowAddFile}
            >
              Tạo mới
            </button>
            <button
              className={
                "btn-bg-blue btn-default btn-size-sm" +
                (!validateParam() ? " btn-disable" : "")
              }
              onClick={handleSubmit}
            >
              Thêm
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export { ListFileSupport };
