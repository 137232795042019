import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { licenseActions } from '../../../_actions';
import { SideBar } from '../../../_components/Admin/More/License';
import { licenseConstants } from '../../../_constants';
import { Alert } from './../../../_components/Alert';
import { Header } from '../../../_components/Admin/Header';


function EnterCodeLicense() {

    const alert = useSelector(state => state.alert);
    const [inputs, setInputs] = useState({
        code: '',
    });
    const authentication = useSelector(state => state.authentication);
    const dispatch = useDispatch();

    const [submitted, setSubmitted] = useState(false);
    const { code } = inputs;
    // console.log(submitted);
    function handleChange(e) {
        const { name, value } = e.target;
        setInputs(inputs => ({ ...inputs, [name]: value }));
    }

    function handleSubmit(e) {
        e.preventDefault();
        setSubmitted(true);
        if (code) {
            dispatch(licenseActions.forgotPassword({ code }));
        }
    }


    return (
        <div className="sunE-right-container">
            <Header title={'Nâng cấp tài khoản'} isBack/>
            <div className="flex-m sunE-content">
                <SideBar />
                <div className="flex-1 sunE-content-box">
                    {alert.message && alert.screen === licenseConstants.SCREEN_LICENSE_ADD_CODE && <Alert alert={alert} />}
                    <div className="box-shadow box-white-content">
                        <div className="pad-box">
                            <input type="text" className="input-nm" placeholder="Nhập mã của bạn" onChane={handleChange} />
                            {/* <p className="text-light">Nếu chưa có mã vui lòng <Link to={"/" + authentication.role + "/more/license/add"}><span className="bold btn_click_link">Click vào đây</span></Link></p> */}
                        </div>
                    </div>
                    <div className="form-sunE-button text-center btn-send">
                        <button className="btn-line-blue btn-custom-hei" onClick={handleSubmit}>Gửi đi</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export { EnterCodeLicense };