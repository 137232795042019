import { apiCaller } from "./../_helpers";

export const scheduleService = {
  getScheduleStatus,
  markSystemSeen,
};

function getScheduleStatus(month, year) {
  return apiCaller(
    "/API_schedule/schedule_status?month=" + month + "&year=" + year,
    "GET",
    {},
    null,
    true
  );
}

function markSystemSeen(msgId) {
  let body = new URLSearchParams({
    msg_id: msgId,
  });
  return apiCaller("/api_inbox/mark_system_seen", "PUT", body, null, true);
}
