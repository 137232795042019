import classNames from "classnames";
import React, { useRef, useState } from "react";
// import { SelectDate } from "../../Calendar";
import "./index.scss";
import DatePicker, { registerLocale } from "react-datepicker";
import vi from "date-fns/locale/vi";
import { isEmpty } from "lodash";
registerLocale("vi", vi);

const InputDate = (props) => {
    const $inputRef = useRef(null);
    const [isFocus, setIsFocus] = useState(false);

    /**
     * changeValue: input change value
     * @param e event
     */
    const changeValue = (date) => {
        if (props.isWarning) {
            props.setIsWarning && props.setIsWarning("");
        }
        props.setValue(date);
    };

    return (
        <div className="input_date_base_container" style={props?.styleContainer}>
            {props.errorText && props?.typeErrText != "underAbsolute" ? (
                <div className={props.errorAbsolute ? "error_text_absolute error_input" : "error_text error_input"}>
                    <span>{props.errorText}</span>
                </div>
            ) : null}
            <div
                ref={$inputRef}
                className={classNames(
                    {
                        focus: isFocus,
                        warning: props.isWarning,
                    },
                    `${props.className ? props.className : ""} input_date_base ${props.readOnly && " bg_readonly"}`
                )}
                style={props.style}
            >
                <div className="icon_label">{props.renderLabelIcon ? props.renderLabelIcon() : null}</div>
                <DatePicker
                    maxDate={props?.maxDate}
                    className={`date_picker ${
                        props.readOnly && " notallowed_cursor"
                    }`}
                    selected={props.value}
                    onChange={(date) => changeValue(date)}
                    showMonthDropdown
                    showYearDropdown
                    fixedHeight
                    dropdownMode="select"
                    dateFormat="P"
                    locale="vi"
                    placeholderText={!isEmpty(props?.placeholder) ? `${props?.placeholder}`: `DD/MM/YYYY`}
                    name={props.name}
                    popperPlacement={props?.popperPlacement ? props.popperPlacement : "bottom"}
                    popperModifiers={[
                        {
                            name: "flip",
                            options: {
                                fallbackPlacements: ["bottom"],
                            },
                        },
                    ]}
                    onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                    readOnly={props?.readOnly}
                />
            </div>
            {props.errorText && props?.typeErrText == "underAbsolute" ? (
                <div
                className={
                    props.errorAbsolute
                    ? "error_text_absolute_new error_input"
                    : "error_text error_input"
                }
                >
                <span>{props.errorText}</span>
                </div>
            ) : null}
        </div>
    );
};

export default InputDate;
