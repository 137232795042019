export const LinkApp = {
  student: {
    APP_STORE: "https://apps.apple.com/vn/app/sunday-english/id1536078421",
    GG_PLAY:
      "https://play.google.com/store/apps/details?id=gk.app.sunday&pcampaignid=web_share",
  },
  teacher: {
    APP_STORE: "https://apps.apple.com/in/app/sunday-teacher/id6473355553",
    GG_PLAY:
      "https://play.google.com/store/apps/details?id=gk.app.sundayteacher",
  },
  parent: {
    APP_STORE: "https://apps.apple.com/vn/app/sunday-parent/id6479732371",
    GG_PLAY:
      "https://play.google.com/store/apps/details?id=gk.app.sundayparent",
  },
};
