import React, { useEffect, useState } from 'react';
import { studentService } from '../../_services';
import { Header } from '../../_components/Admin/Header';
import _ from "lodash";
import { useParams } from "react-router-dom";
import { PopUpVocabularyDetail } from "../../_components/Popup";
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

var audioElement;
function HomePageListVocabulary() {
    const { id } = useParams();
    const { unit_id } = useParams();
    const [curriculum, setCurriculum] = useState({
        base_url: "",
        data: {list_unit: []},
    });
    const history = useHistory();
    const searchClass_id = history?.location?.search;
    const params = new URLSearchParams(searchClass_id);
    const class_id = params?.get("class_id");

    const [showPopUp, setShowPopup] = useState(false);
    const [dataPopUp, setDataPopUp] = useState([]);
    const [scrumUrl, setScrumUrl] = useState('');


    const listCurr = curriculum ? curriculum.list_vocab_curriculum : '';
    let [dataFilter, setdataFilter] = useState(listCurr);
    // const listUnit = dataFilter[0] ? dataFilter[0].list_unit : '';
    let [dataFilterUnit, setDataFilterUnit] = useState('');

    useEffect(() => {
        studentService.getVocabularyByClass(class_id).then(
            (data) => {
                const convertData = data.data.list_unit.filter((item) => item.unit_id == unit_id)
                setDataFilterUnit(convertData[0])
                setCurriculum(data);
            }
        );
    }, []);

    // useEffect(() => {
    //     setdataFilter([
    //         ..._.filter(listCurr, data => (data.curriculum_id === id))
    //     ]);
    // }, [listCurr]);


    // useEffect(() => {
    //     setDataFilterUnit([
    //         ..._.filter(listUnit, data => (data.unit_id === unit_id))
    //     ]);

    // }, [listUnit]);

    function handleClickItem(e, $url) {
        e.stopPropagation()
        e.preventDefault();
        if (audioElement) {
            audioElement.pause();
        }
        audioElement = new Audio($url);
        const playPromise = audioElement.play();

        if (playPromise !== undefined) {
            playPromise
                .then(_ => {
                    // Automatic playback started!
                    // Show playing UI.
                    console.log("audio played auto");
                })
                .catch(error => {
                    // Auto-play was prevented
                    // Show paused UI.
                    console.log("playback prevented");
                });
        }
    }

    function getImage(imageJson) {
        const myObject = JSON.parse(imageJson);
        myObject.forEach(element => {
            const index = element.search('300x300');
            if (index >= 0) {
                return element;
            }
        });
        return myObject[0];
    }
    // console.log(dataFilterUnit)

    const show = (url, data) => {
        setShowPopup(!showPopUp)
        setScrumUrl(url)
        setDataPopUp(data)
    }

    return (
        <div className="sunE-right-container">
            <Header title='Từ Vựng' isBack />
            <div className="flex-1 sunE-content tuvung-container">
                <div className="uk-container tuvung_list scrollbar-custom">
                    <div className="uk-grid">
                        {dataFilterUnit && dataFilterUnit.list_vocab_in_unit.map((data, i) => {
                            return (
                                <div className="uk-width-1-2" key={i}>
                                    <div className="tv-item flex-m rel box-shadow" onClick={() => show(curriculum.base_url, data)}>
                                        <div className="tv-thumb box-shadow">
                                            <img src={curriculum.base_url + getImage(data.image)} alt="thumb" />
                                        </div>
                                        <div className="flex-1">
                                            <h2>{data.vocabulary}</h2>
                                        </div>
                                        {/* <div className="voice center-flex" onClick={(e) => handleClickItem(e, curriculum.base_url + data.audio)}> */}
                                        <div className="voice center-flex" onClick={(e) => handleClickItem(e, curriculum.base_url + data.audio)}>
                                            <img src="/../../assets/images/student/thanhtich/ico_voice.png" alt="voice" />
                                        </div>
                                       {data.score && <div className="tv-score center-flex">
                                            <span>{data.score}</span>
                                            {/* <span>{data.audio}</span>
                                            <span>{data.image}</span> */}
                                        </div>}
                                    </div>
                                </div>
                            )
                        })}

                    </div>
                </div>
            </div>
            {showPopUp && <PopUpVocabularyDetail width={380} data={dataPopUp} img_url={scrumUrl} close={status => setShowPopup(status)}/>}
        </div >
    );
}

export { HomePageListVocabulary };