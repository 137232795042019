import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import API from "../../../../_apis/APIConstants";
import APIBase from "../../../../_base/APIBase";
import $ from "jquery";
import { isEmpty } from "lodash";
import { history } from "../../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { setTimeRemaining } from "../../../../_reducers/Authentication/authentiacionTeacherZalo";
import { userConstants } from "../../../../_constants";

export const accountAuthenticationMethods = () => {
  const _location = useLocation();
  const getDataAccount = _location.state?.data;
  const dispatch = useDispatch();
  const registerStatus = useSelector(
    (state) => state.authenticationTeacherZalo.registerStatus
  );
  const loginWhenNotVerify = useSelector(
    (state) => state.authenticationTeacherZalo.loginWhenNotVerify
  );
  const timeRemaining = useSelector(
    (state) => state.authenticationTeacherZalo.timeRemaining
  );
  const codeSent = useSelector(
    (state) => state.authenticationTeacherZalo.codeSent
  );
  const [disabledBtnRegist, setDisabledBtnRegist] = useState(true);
  const [verificationCode, setVerificationCode] = useState("");
  const [popupRegisterSuccess, setPopupRegisterSuccess] = useState(false);
  const [messageReSendCode, setMessageReSendCode] = useState("");

  const [codeFailMessage, setCodeFailMessage] = useState("");

  useEffect(() => {
    let timeout;

    if (codeSent) {
      timeout = setTimeout(() => {
        if (timeRemaining === 0) {
          dispatch({ type: userConstants.CODE_SENT_OFF });
          dispatch(setTimeRemaining(0));
          $(".msg_resent_code").addClass("hide");
          setMessageReSendCode("");
        } else {
          dispatch(setTimeRemaining(timeRemaining - 1));
        }
      }, 1000);

      return () => {
        clearTimeout(timeout);
      };
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [codeSent, timeRemaining, dispatch]);

  const handleResentVertifyCode = async () => {
    if (!codeSent) {
      setVerificationCode("");
      $(".msg_fail_code").addClass("hide");
      dispatch(setTimeRemaining(30));
      dispatch({ type: userConstants.CODE_SENT_ON });
      try {
        let urlApi = `${API.baseURL}api/teacher/resend_otp_zalo`;
        const resultSendCode = await APIBase.apiBaseCaller("POST", urlApi, {
          phone: getDataAccount?.phone,
          from_web: 1
        });
        if (!resultSendCode?.status) {
          resultSendCode?.msg?.includes("Đã lấy quá số") &&
            setMessageReSendCode(resultSendCode?.msg);
        }
      } catch (error) {
        // setMessageReSendCode(resultSendCode);
        setMessageReSendCode("Gửi mã xác thực thất bại");
      }
      $(".msg_resent_code").removeClass("hide");
    }
  };

  useEffect(async () => {
    if (isEmpty(getDataAccount)) {
      return history.push("/login");
    }
    if (loginWhenNotVerify) {
      let urlResendCode = `${API.baseURL}api/teacher/resend_otp_zalo`;
      try {
        const result = await APIBase.apiBaseCaller("POST", urlResendCode, {
          phone: getDataAccount?.phone,
          from_web: 1
        });
      } catch (error) {
        setMessageReSendCode("Gửi mã xác thực thất bại");
      } finally {
        dispatch({ type: userConstants.RESET_LOGIN_WHEN_NOT_VERIFY });
      }
    }

    $(".modal_confirm_otp").removeClass("hide");
  }, []);

  useEffect(() => {
    $(".msg_fail_code").addClass("hide");
    if (isEmpty(verificationCode) || verificationCode.length < 6) {
      setDisabledBtnRegist(true);
    } else {
      setDisabledBtnRegist(false);
    }
  }, [verificationCode]);

  /**
   * changePhone change và validate phone
   * @param value value change
   */
  const changeVerificationCode = (value) => {
    const validatedValue = value.replace(/[^0-9]/g, "");
    setVerificationCode(validatedValue);
  };

  const handleConfirmOTP = async () => {
    let data = {
      phone: getDataAccount?.phone,
      code: verificationCode,
    };

    let urlApi = `${API.baseURL}${API.verify_register_teacher_by_zalo}`;
    const result = await APIBase.apiBaseCaller("POST", urlApi, data);
    if (result?.status) {
      setPopupRegisterSuccess(true);
    } else {
      if (result.msg) {
        $(".msg_fail_code").removeClass("hide");
        setCodeFailMessage(result.msg);
        $(".msg_resent_code").addClass("hide");
        $(".confirmTeacher").removeClass("hide");
      }
    }
  };

  const handleGoBack = () => {
    history.replace("/login");
  };

  return {
    getDataAccount,
    verificationCode,
    changeVerificationCode,
    handleConfirmOTP,
    handleResentVertifyCode,
    disabledBtnRegist,
    timeRemaining,
    codeSent,
    codeFailMessage,
    handleGoBack,
    popupRegisterSuccess,
    messageReSendCode,
  };
};
