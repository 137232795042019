import { store } from "..";
import { configConstants, curriculumConstants } from "../_constants";
import { apiCaller } from "../_helpers";

function getDataMap(curriculumId) {
  return apiCaller(
    "/open_api/Api_content/list_unit?id=" + curriculumId,
    "GET",
    {},
    null,
    true
  )
}

const getDataUserInCurriculum = (curriculumId, classId, isLoading = true) => {
  return apiCaller('/student/api_student_curriculum/userDataInCurriculum?id=' + curriculumId + (!!classId ? `&class_id=${classId}` : ''), "GET", {}, null, true, configConstants.API_URL_SETEST, isLoading)
}

const getLessonByUnit = (unitId, classId, userId) => {
  // use classId for student to filter list lesson with level
  return apiCaller('/open_api/Api_content/lesson_unit?unit_id=' + unitId + (!!classId ? `&class_id=${classId}` : '') + (!!userId ? `&user_id=${userId}` : ''), "GET")
}

const getDataUserInUnit = (unitId, classId) => {
  return apiCaller('/student/api_student_curriculum/userDataInUnit?unit_id=' + unitId + (!!classId ? `&class_id=${classId}` : ''), "GET")
}

const getDataLessonCurriculumStudent = (unitId, classId) => {
  const authentication = store.getState().authentication;
  const curriculumStudent = store.getState().curriculumStudent;
  const unit_name = curriculumStudent?.data_map?.data_lesson?.unit_name?.find(item => item?.unit_id == unitId)?.unit_name;
  const curriculum_id = curriculumStudent?.data_map?.data_lesson?.unit_name?.find(item => item?.unit_id == unitId)?.curriculum_id;

  return async (dispatch) => {
    const resDataUserInUnit = await getDataUserInUnit(unitId, classId);
    let listDataUserInUnit = [];
    if (resDataUserInUnit?.status) {
      listDataUserInUnit = resDataUserInUnit?.skill_list;
    }
    const resDataLesson = await getLessonByUnit(unitId, classId, authentication?.id);
    if (resDataLesson?.status) {
      const listSkill = resDataLesson?.skill_list?.map(item => {
        const dataUserInUnit = listDataUserInUnit?.find(data => data?.skill == item.skill);
        const list_lesson = item?.list_lesson?.map(lesson => {
          const dataUserLesson = dataUserInUnit?.list_lesson?.find(data => data?.lesson_id == lesson?.lesson_id)
          return {
            ...lesson,
            ...dataUserLesson,
            unit_id: unitId,
            unit_name,
            curriculum_id,
            class_id: classId
          }
        })
        return {
          ...dataUserInUnit,
          ...item,
          list_lesson
        }
      });
      dispatch({
        type: curriculumConstants.GET_LESSON_CURRICULUM_STUDENT,
        lessons: {
          data: listSkill,
          base_url: resDataLesson?.base_url,
          require_learn_master_unit: resDataUserInUnit?.require_learn_master_unit,
        },
      });
      dispatch({
        type: curriculumConstants.SAVE_DETAIL_UNIT_STUDENT,
        payload: {
          data: listSkill,
          base_url: resDataLesson?.base_url,
          require_learn_master_unit: resDataUserInUnit?.require_learn_master_unit,
          classId,
          unitId
        },
      });
    }
  }
}

const updateDataUserCurriculum = (curriculumId, classId, isLoading) => {
  const curriculumStudent = store.getState().curriculumStudent;
  const listUnit = curriculumStudent?.data_map?.data_lesson?.unit_name;
  return (dispatch) => {
    getDataUserInCurriculum(curriculumId, classId, isLoading).then(resDataUserInCurriculum => {
      if (resDataUserInCurriculum?.status) {
        const listDataUserInCurriculum = resDataUserInCurriculum?.unit_list;
        const listDataMap = listUnit?.map(item => {
        const dataUserInCurriculum = listDataUserInCurriculum?.find(data => data?.unit_id == item.unit_id); 
          return {
            ...item,
            ...dataUserInCurriculum,
          }
        });
        dispatch({
          type: curriculumConstants.GET_DATA_MAP_CURRICULUM_STUDENT,
          data_map: {
            require_learn_master_unit: resDataUserInCurriculum?.require_learn_master_unit,
            data_lesson: {
              data: [...listDataMap],
              unit_name: listDataMap?.map(item => ({
                list_skill: [],
                ...item
              }))
            },
          },
          curriculumId
        });
      }
    });
  }
}

const updateDataUserLearned = (unitId, classId) => {
  const authentication = store.getState().authentication;
  const curriculumStudent = store.getState().curriculumStudent;
  const listUnit = curriculumStudent?.data_map?.data_lesson?.unit_name;
  const unit = listUnit?.find(item => item?.unit_id == unitId);
  const dataLesson = curriculumStudent?.lessons;
  return (dispatch) => {
    // update curriculum
    getDataUserInCurriculum(unit?.curriculum_id, classId).then(resDataUserInCurriculum => {
      if (resDataUserInCurriculum?.status) {
        const listDataUserInCurriculum = resDataUserInCurriculum?.unit_list;
        const listDataMap = listUnit?.map(item => {
        const dataUserInCurriculum = listDataUserInCurriculum?.find(data => data?.unit_id == item.unit_id); 
          return {
            ...item,
            ...dataUserInCurriculum,
          }
        });
        dispatch({
          type: curriculumConstants.GET_DATA_MAP_CURRICULUM_STUDENT,
          data_map: {
            require_learn_master_unit: resDataUserInCurriculum?.require_learn_master_unit,
            data_lesson: {
              data: [...listDataMap],
              unit_name: listDataMap?.map(item => ({
                list_skill: [],
                ...item
              }))
            },
          },
          curriculumId: unit?.curriculum_id
        });
      }
    });

    // update unit
    getDataUserInUnit(unitId, classId).then(resDataUserInUnit => {
      if (resDataUserInUnit?.status) {
        let isGetNewListLesson = false;
        const listDataUserInUnit = resDataUserInUnit?.skill_list;
        const listSkill = listDataUserInUnit?.map(item => {
          const dataUserInUnit = dataLesson?.data?.find(data => data?.skill == item.skill);
          if (!item?.list_lesson?.every(lesson => dataUserInUnit?.list_lesson?.find(dataUser => dataUser?.lesson_id === lesson?.lesson_id ))) {
            isGetNewListLesson = true;
            return item;
          }
          const list_lesson = item?.list_lesson?.map(lesson => {
            const dataUserLesson = dataUserInUnit?.list_lesson?.find(data => data?.lesson_id == lesson?.lesson_id)
            return {
              ...dataUserLesson,
              ...lesson,
            }
          })
          return {
            ...dataUserInUnit,
            ...item,
            list_lesson
          }
        });
        if (!isGetNewListLesson) {
          dispatch({
            type: curriculumConstants.GET_LESSON_CURRICULUM_STUDENT,
            lessons: {
              ...dataLesson,
              require_learn_master_unit: resDataUserInUnit?.require_learn_master_unit,
              data: listSkill,
            },
          });
          dispatch({
            type: curriculumConstants.SAVE_DETAIL_UNIT_STUDENT,
            payload: {
              base_url: dataLesson?.base_url,
              require_learn_master_unit: resDataUserInUnit?.require_learn_master_unit,
              data: listSkill,
              classId,
              unitId
            },
          });
        } else {
          getLessonByUnit(unitId, classId, authentication?.id).then(resDataLesson => {
            if (resDataLesson?.status) {
              const listSkill = resDataLesson?.skill_list?.map(item => {
                const dataUserInUnit = listDataUserInUnit?.find(data => data?.skill == item.skill);
                const list_lesson = item?.list_lesson?.map(lesson => {
                  const dataUserLesson = dataUserInUnit?.list_lesson?.find(data => data?.lesson_id == lesson?.lesson_id)
                  return {
                    ...lesson,
                    ...dataUserLesson,
                    unit_id: unitId,
                    unit_name: unit?.unit_name,
                    curriculum_id: unit?.curriculum_id,
                    class_id: classId
                  }
                })
                return {
                  ...dataUserInUnit,
                  ...item,
                  list_lesson
                }
              });
              dispatch({
                type: curriculumConstants.GET_LESSON_CURRICULUM_STUDENT,
                lessons: {
                  data: listSkill,
                  base_url: resDataLesson?.base_url,
                  require_learn_master_unit: resDataUserInUnit?.require_learn_master_unit,
                },
              });
              dispatch({
                type: curriculumConstants.SAVE_DETAIL_UNIT_STUDENT,
                payload: {
                  data: listSkill,
                  base_url: resDataLesson?.base_url,
                  require_learn_master_unit: resDataUserInUnit?.require_learn_master_unit,
                  classId,
                  unitId
                },
              });
            } 
          });
        }
      }
    });
    return;
  }
}

export const curriculumActions = {
  getDataMap,
  getDataUserInCurriculum,
  getLessonByUnit,
  getDataUserInUnit,
  getDataLessonCurriculumStudent,
  updateDataUserLearned,
  updateDataUserCurriculum
}