import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { studentActions } from "../../../_actions";
import { studentConstants } from "./../../../_constants";
import { Header } from "../../../_components/Admin/Header";
import { Alert } from "../../../_components/Alert";
import { useHistory } from "react-router-dom";
import { isEmpty } from "lodash";
import { alertActions } from "../../../_actions/alerts";
import { PopUpApplyClass } from "../../../_components/Popup";

function ApplyClassPage() {
  const history = useHistory();
  const alert = useSelector((state) => state.alert);
  const Popup = useSelector((state) => state.Popup);
  const dispatch = useDispatch();
  const [inputs, setInputs] = useState({
    class_code: "",
  });
  const [showError, setShowError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { class_code } = inputs;

  function handleChange(e) {
    setShowError(false);
    if (isEmpty(e.target.value)) {
      dispatch(alertActions.clear());
    }
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }
  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);
    if (validateParam()) {
      dispatch(studentActions.checkClass(inputs));
    }

    !Popup.showFormApplyClass && setShowError(true);
  }

  function validateParam() {
    return class_code?.trim() !== "" ? true : false;
  }

  useEffect(() => {
    if (alert?.type === "ALERT_SUCCESS") {
      setInputs((inputs) => ({ ...inputs, class_code: "" }));
    }
  }, [alert?.type]);

  const authentication = useSelector((state) => state.authentication);

  const actionEnter = (e) => {
    if (e.keyCode === 13) {
      handleSubmit(e);
    }
  };

  return (
    <div className='sunE-right-container add_student_container'>
      <Header
        title={"Đăng ký lớp học"}
        isBack
        clickBack={() => history.go(-1)}
      />
      <div className='sunE-content rel add_student'>
        <img
          className='__bg_layout_parent_and_student'
          src='/assets/images/bg_add_student.png'
        />
        <div
          className='add_code_student-gr w-custom text-center __posistion_custom'
          style={{ top: "69%" }}
        >
          <h2 className='bold sunE-title-medium'>
            Nhập mã lớp học bạn muốn tham gia.
            <br />
            Yêu cầu của bạn sẽ được Thầy/Cô của lớp xét duyệt.
          </h2>
          <div
            className={
              "sunE-input-border-blue-gr rel" +
              (!isEmpty(class_code) &&
              ((submitted && !class_code) ||
                (alert?.type === "ALERT_ERROR" &&
                  alert.message &&
                  showError &&
                  alert.screen === studentConstants.SCREEN_APPLY_CLASS))
                ? " err"
                : "")
            }
          >
            <img
              src='/assets/images/icon/ico_class.png'
              className='ico_input ico_class_po'
              alt='ico_class'
            />
            <input
              type='text'
              name='class_code'
              value={class_code}
              placeholder='Nhập mã lớp'
              onChange={handleChange}
              onKeyUp={(e) => actionEnter(e)}
            />

            {/* {showError && <img
              src="/assets/images/ico_danger.png"
              className="ico_err"
              alt="ico_danger"
            />} */}
          </div>
          {showError &&
            alert.message &&
            alert.screen === studentConstants.SCREEN_APPLY_CLASS && (
              <Alert alert={alert} notShowComplete={true} />
            )}
          <div className='form-sunE-button'>
            <button
              className={
                "btn-line-blue btn-p35" +
                (!validateParam() ? " btn-disable" : "")
              }
              onClick={handleSubmit}
            >
              Kiểm tra
            </button>
          </div>
        </div>
      </div>
      <Alert alert={alert} notShowError={true} />
      {Popup.showFormApplyClass && <PopUpApplyClass width={380} />}
    </div>
  );
}

export { ApplyClassPage };
