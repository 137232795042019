export const curriculumConstants = {
  GET_LIST_CURRICULUM_FAVORITE_START: "GET_LIST_CURRICULUM_FAVORITE_START",
  GET_LIST_CURRICULUM: "GET_LIST_CURRICULUM",
  ADD_CURRICULUM: "ADD_CURRICULUM",
  GET_DETAIL_CURRICULUM_CLASS: "GET_DETAIL_CURRICULUM_CLASS",
  GET_DETAIL_CURRICULUM: "GET_DETAIL_CURRICULUM",
  SCREEN_DETAIL_CURRICULUM: "SCREEN_DETAIL_CURRICULUM",
  CLEAR_DETAIL_CURRICULUM: "CLEAR_DETAIL_CURRICULUM",
  CLEAR_DETAIL_CURRICULUM_CLASS: "CLEAR_DETAIL_CURRICULUM_CLASS",
  GET_LIST_CURRICULUM_FAVORITE: "GET_LIST_CURRICULUM_FAVORITE",
  GET_FILTER_CURRICULUM: "GET_FILTER_CURRICULUM",
  ADD_PARAM_FILTER_CURRICULUM: "ADD_PARAM_FILTER_CURRICULUM",
  CLEAR_PARAM_FILTER_CURRICULUM: "CLEAR_PARAM_FILTER_CURRICULUM",
  CHANGE_VIEW: "CHANGE_VIEW",
  GET_LIST_CURRICULUM_EXERCISE: "GET_LIST_CURRICULUM_EXERCISE",
  GET_DATA_MAP_CURRICULUM_STUDENT_START:
    "GET_DATA_MAP_CURRICULUM_STUDENT_START",
  GET_DATA_MAP_CURRICULUM_STUDENT: "GET_DATA_MAP_CURRICULUM_STUDENT",
  GET_DATA_GRID_CURRICULUM_STUDENT: "GET_DATA_GRID_CURRICULUM_STUDENT",
  GET_LESSON_CURRICULUM_STUDENT: "GET_LESSON_CURRICULUM_STUDENT",
  GET_TOPIC_CURRICULUM_STUDENT: "GET_TOPIC_CURRICULUM_STUDENT",
  GET_LESSON_TOPIC_CURRICULUM_STUDENT: "GET_LESSON_TOPIC_CURRICULUM_STUDENT",
  RESET_DETAIL: "RESET_DETAIL",
  FROM_PAGE_STATUS: "FROM_PAGE_STATUS",
  CLEAR_FROM_PAGE: "CLEAR_FROM_PAGE",
  LESSON_BY_SKILL_TEACHER: "LESSON_BY_SKILL_TEACHER",
  LESSON_BY_TOPIC_TEACHER: "LESSON_BY_TOPIC_TEACHER",
  GET_LESSON_IN_SKILL: "GET_LESSON_IN_SKILL",
  GET_COMPOSITION_CURRICULUM_STUDENT: 'GET_COMPOSITION_CURRICULUM_STUDENT',
  GET_DATA_LIST_CURRICULUM_STUDENT: 'GET_DATA_LIST_CURRICULUM_STUDENT',
  TIME_GET_LIST_CURRICULUM_STUDENT: 'TIME_GET_LIST_CURRICULUM_STUDENT',
  SAVE_DATA_CURRICULUM_TEACHER: 'SAVE_DATA_CURRICULUM_TEACHER',
  TIME_GET_DATA_CURRICULUM_TEACHER: 'TIME_GET_DATA_CURRICULUM_TEACHER',
  SAVE_DETAIL_CURRICULUM_TEACHER: 'SAVE_DETAIL_CURRICULUM_TEACHER',
  SET_DATA_MAP: 'SET_DATA_MAP',
  MUST_UPDATE_DATA_USER: 'MUST_UPDATE_DATA_USER',
  SAVE_DETAIL_UNIT_STUDENT: 'SAVE_DETAIL_UNIT_STUDENT',
  RESET_DATA_CURRICULUM_STUDENT: 'RESET_DATA_CURRICULUM_STUDENT',
  SAVE_DETAIL_UNIT_TEACHER: 'SAVE_DETAIL_UNIT_TEACHER',
  RESET_DATA_CURRICULUM_TEACHER: 'RESET_DATA_CURRICULUM_TEACHER'
};

export const HAS_ESSAY = 'has_essay';
export const NO_ESSAY = 'no_essay';
export const LIST_TYPE_ESSAY = [
  {
    name: "Tất cả",
    id: "",
  },
  {
    name: "Có tự luận",
    id: HAS_ESSAY,
  },
  {
    name: "Không có tự luận",
    id: NO_ESSAY,
  },
]

export const getListYearFilterLessonTopic = () => {
  const currentYear = new Date().getFullYear();
  const listYear = [{
    id: '',
    name: 'Tất cả',
  }]

  for (let i = currentYear + 1; i >= 2010; i--) {
    listYear.push({
      id: `${i - 1}-${i}`,
      name: `${i - 1}-${i}`
    })
  }
  return listYear
}