import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Header } from "../../../../_components/Admin/Header";
import { useEffect, useRef, useState } from "react";
import InputText from "../../../../_components/Auth/InputText";
import './AddStudentIntoClass.style.scss'
import { apiCaller } from "../../../../_helpers";
import { configConstants, teacherConstants } from "../../../../_constants";
import { PopUpYesNo } from "../../../../_components/Popup";
import { Alert } from "../../../../_components/Alert";
import { alertActions, teacherActions } from "../../../../_actions";
import * as XLSX from 'xlsx'
import moment from "moment"
import PopupGiveTurnHomework from "../../../../_components/Popup/PopupGiveTurnHomework";
import PopupListStudentAddedFail from "../../../../_components/Popup/PopupListStudentAddedFail";
import { teacherService } from "../../../../_services";
import SubMenu from "../../../../_components/SubMenu";
import { v4 } from "uuid";
import PopupEditStudent from "../../../../_components/Popup/PopupEditStudent";

export default function AddStudentIntoClass() {
  const history = useHistory();
  const alert = useSelector((state) => state.alert);
  const classes = useSelector((state) => state.classes);
  const authentication = useSelector(state => state.authentication);
  const { id } = useParams();
  const dispatch = useDispatch();
  const refUpload = useRef(null)
  const [searchText, setSearchText] = useState('')
  const [listStudentAdd, setListStudentAdd] = useState([])
  const [listStudentInClass, setListStudentInClass] = useState(classes?.students?.data ? [...classes?.students?.data] : [])
  const [indexStudentSelected, setIndexStudentSelected] = useState()
  const [isShowPopupDelete, setIsShowPopupDelete] = useState(false);
  const [isShowPopupCancel, setIsShowPopupCancel] = useState(false);
  const [listTurnHomework, setListTurnHomework] = useState([]);
  const [listIdStudentAdded, setListIdStudentAdded] = useState([])
  const [isOpenGiveTurnHomework, setIsOpenGiveTurnHomework] = useState(false)
  const [isAddBySearch, setIsAddBySearch] = useState(false);
  const [isOpenPopupListStudentAddedFail, setIsOpenPopupListStudentAddedFail] = useState(false);
  const [listStudentAddedFail, setListStudentAddedFail] = useState([])
  const [listStudentAddedSuccess, setListStudentAddedSuccess] = useState([])
  const [totalStudentAddedSuccess, setTotalStudentAddedSuccess] = useState(0);
  const [totalListStudentAdded, setTotalStudentAdded] = useState(0);
  const [studentEditing,setStudentEditing] = useState()
  const [isOpenModalEdit,setIsOpenModalEdit] = useState(false)

  const handleSearch = async () => {
    if ((!!listStudentAdd?.find(item => item?.email === searchText)) ||
        (!!listStudentAdd?.find(item => item?.phone === searchText)) ||
        ( !!listStudentAdd?.find(item => item?.username === searchText))
    ) {
      dispatch(
        alertActions.error({
          message: 'Học sinh này đã có trong danh sách',
          screen: 'ADD_STUDENT',
          isShowPopup: true
        })
      );
      return;
    }
    if ((!!listStudentInClass?.find(item => item?.email === searchText)) ||
        (!!listStudentInClass?.find(item => item?.phone === searchText)) ||
        ( !!listStudentInClass?.find(item => item?.username === searchText))
    ) {
      dispatch(
        alertActions.error({
          message: 'Học sinh này đã có trong lớp',
          screen: 'ADD_STUDENT',
          isShowPopup: true
        })
      );
      return;
    }
    try {
      const url = "/api_user/code?username=" + searchText;
      const res = await apiCaller(url, 'GET')
      const student = {
        ...res?.user_data,
        name: res?.user_data?.fullname
      }
      setSearchText('');
      // setListStudentAdd(prev => [student,...prev])
      setListStudentAdd([student]);
      setIsAddBySearch(true);
    } catch (err) {
      dispatch(
        alertActions.error({
          message: err.toString(),
          screen: 'ADD_STUDENT',
          isShowPopup: true
        })
      );
    }
  }

  function checkFile(sender) {
    var validExts = new Array(".xlsx", ".xls");
    const fileExt = sender?.substring(sender?.lastIndexOf('.'));
    if (validExts.indexOf(fileExt) < 0) {
      return false;
    }
    else return true;
  }

  const ExcelDateToJSDate = (date) => {
    let converted_date = new Date(Math.round((date - 25569) * 864e5));
    converted_date = String(converted_date).slice(4, 15)
    date = converted_date.split(" ")
    let day = date[1];
    let month = date[0];
    month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1
    if (month.toString().length <= 1)
        month = '0' + month
    let year = date[2];
    return String(day + '-' + month + '-' + year)
}

  const handleUpload = (e) => {
    e.preventDefault();
    if (!!e.target?.files?.[0]?.name && checkFile(e.target?.files?.[0]?.name)) {
      setListStudentAdd([])
      const files = e.target.files;
      const f = files[0];
      const reader = new FileReader();
      reader.onload = (evt) => { // evt = on_file_select event
          /* Parse data */
          const bstr = evt.target.result;
          const wb = XLSX.read(bstr, {type:'binary', cellDates: false, cellText: true, dateNF: 'DD/MM/YYYY'});
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, {header:1, raw: false, dateNF: 'DD/MM/YYYY'});
          const listStudent = data?.slice(1)?.map(item => {
            if (!item?.length) {
              return null;
            };
            return {
              name: item?.[0] || '',
              username: item?.[1] || '',
              password: item?.[2] || '',
              birthday: item?.[3] ? (typeof item[3] === 'number' ? ExcelDateToJSDate(item[3]) : moment(item[3], 'DD/MM/YYYY').format('DD-MM-YYYY'))  : '',
              gender: item?.[4] ? (item?.[4]?.toLowerCase() === 'nam' ? 'male' : (item?.[4]?.toLowerCase() === 'nữ' ? 'female' : '')) : '',
              email: item?.[5] || '',
              phone: item?.[6] || '',
              nick_name: item?.[7] || ''
            }
          })?.filter(item => item!== null)
          // setListStudentAdd(listStudent)
          setIsAddBySearch(false);
          addByExcel(listStudent)
      };
      reader.readAsBinaryString(f);
      e.target.value = null
    }
  }

  const handleBeforeUpload = (e) => {
    const date = new Date()
    if (date.getHours() >= 19 && date.getHours() < 23) {
      e.preventDefault();
      dispatch(alertActions.error({
        screen: 'ADD_STUDENT',
        message: "Vui lòng sử dụng chức năng này trước 19h hoặc sau 23h hàng ngày",
        isShowPopup: true
      }))
    }
  }

  const onDownloadFile = async () => {
    const url = '/api_class/get_file_excel';
    try {
      const res = await apiCaller(url, 'GET')
      if (res?.status) {
        const finalUrl = res?.base_url + res?.file_excel; // Đường dẫn file cần tải
        const anchor = document.createElement("a");
        anchor.href = finalUrl;
        anchor.download = "nhaphocsinh.xlsx"; // Tên file sau khi tải về
        anchor.click();
      }
    } catch (err) {
      dispatch(
        alertActions.error({
          message: err?.toString(),
          screen: 'ADD_STUDENT',
        })
      );
    }
  }

  const handleDeleteStudent = (indexSelected) => {
    setIndexStudentSelected(indexSelected);
    setIsShowPopupDelete(true);
  }

  const onConfirmDeleteStudent = (indexSelected) => {
    let index = indexStudentSelected;
    if(indexSelected >= 0) {
      index = indexSelected;
    }
    const newListStudent = listStudentAdd.filter((item, iStudent) => iStudent !== index)
    setListStudentAdd(newListStudent);
    setIsShowPopupDelete(false);
  }

  const handleCancel = () => {
    if (listStudentAdd?.some(item => item?.status !== 'fail')) {
      setIsShowPopupCancel(true)
      return;
    }
    history.push(
      "/" + authentication.role + "/class/view/" + id + "/student"
    )
  }

  const onConfirmCancel = () => {
    setListStudentAdd([]);
    setIsShowPopupCancel(false);
  }

  const addBySearch = async () => {
    try {
      const data = { class_id: id, username: listStudentAdd[0]?.email || listStudentAdd[0]?.phone || listStudentAdd[0]?.username }
      const res = await apiCaller("/api_class/add_student", "POST", data, null, false)
      if (res?.status) {
        const listStudent = [{...listStudentAdd[0]}];
        setListStudentAdd([])
        await handleAddStudentSuccess(listStudent)
      } else {
        dispatch(
          alertActions.error({
            message: res?.msg,
            screen: 'ADD_STUDENT',
          })
        );
      }
    } catch (error) {
      dispatch(
        alertActions.error({
          message: error.toString(),
          screen: 'ADD_STUDENT',
        })
      );
    }
  }

  const addByExcel = async (list) => {
    try {
      const listStudent = list ? list : listStudentAdd;
      const url = '/api_class/import_user';
      const data = {
        class_id: id,
        student_list: JSON.stringify(listStudent?.map(item => {
          return {
            grade_id: classes?.detail?.grade_id,
            name: item?.name ? item.name : '',
            birthday: item?.birthday ? item.birthday : undefined,
            gender: item?.gender ? item.gender : undefined,
            email: item?.email ? item?.email : undefined,
            phone: item?.phone ? item?.phone : undefined,
            username: item?.username ? item.username : undefined,
            password: item?.password ? String(item?.password) : undefined,
            nick_name: item?.nick_name ? item.nick_name : undefined
          }
        })?.filter(item => item !== null))
      }
      const res = await apiCaller(url, 'POST', data)
      const listAddedSuccess = res?.data?.student_list?.filter(item => item?.status === 'success') || [];
      const listAddedFail = res?.data?.student_list?.filter(item => item?.status === 'fail') || [];
      setListStudentAddedSuccess(prev => [...prev, ...listAddedSuccess])
      if (res?.data?.student_list?.some(item => item?.status === 'success')) {
        await handleAddStudentSuccess([...listStudentAddedSuccess, ...listAddedSuccess], '', listAddedFail)
      } 
      if (!!listAddedFail?.length) {
        // setIsOpenPopupListStudentAddedFail(true);
        setListStudentAddedFail(listAddedFail);
        setTotalStudentAddedSuccess(res?.data?.success_student || 0)
        setTotalStudentAdded(res?.data?.total_student_in_list)
        setListStudentAdd(listAddedFail?.map(item => ({
          id: v4(),
          ...item
        })))
      }
      // setListStudentAdd([])
      // if (!!res?.student_list?.find(item => item?.status === 'success')) {
      //   handleAddStudentSuccess(res?.student_list?.filter(item => item?.status === 'success'))
      // } else {
      //   dispatch(
      //     alertActions.error({
      //       message: res?.msg,
      //       screen: 'ADD_STUDENT',
      //       isShowPopup: true,
      //     })
      //   );
      // }
    } catch (err) {
      dispatch(
        alertActions.error({
          message: err.toString(),
          screen: 'ADD_STUDENT',
          isShowPopup: true
        })
      );
    }
  }

  const addStudent = () => {
    if (isAddBySearch) {
      addBySearch();
      return;
    }
    addByExcel();
  }

  const getDataTurnHomework = async () => {
    try {
      const res = await apiCaller('/api_exercise_report/exercise_turn_class?class_id='+id, 'get')
      if(res?.status) {
        // setListTurnHomework(res?.data || []);
        return res?.data || []
      }
    } catch (err) {
      dispatch(
        alertActions.error({
          message: err,
          screen: 'ADD_STUDENT',
          isShowPopup: true
        })
      );
    }
  }

  const updateDetailClass = () => {
    teacherService.getDetailClass(id).then((res) => {
            if (res?.data) {
              dispatch({
                type: teacherConstants.GET_DETAIL_CLASS,
                class: res.data,
              });
              dispatch({
                type: teacherConstants.SAVE_DATA_CLASS,
                payload: {
                  id,
                  data: res.data
                }
              })
            } else {
              type = "offline";
              dispatch(teacherActions.getDetailClassOffline(id));
            }
          });
          dispatch({
            type: teacherConstants.RESET_DATA_EXERCISE,
          });
  }

  const handleAddStudentSuccess = async (listStudent, message, listStudentFail = []) => {
    try {
      const listIdStudent = listStudent?.map(item => item?.id || item?.user_id)
      const turnHomeworkArr = await getDataTurnHomework();
      updateDetailClass();
      setListTurnHomework(turnHomeworkArr)
      dispatch(
        alertActions.success({
          message: message ? message : `${teacherConstants.ADD_STUDENT_COMPLETE}${!!turnHomeworkArr.length ? '.\nBạn muốn giao các bài tập đã từng giao cho học sinh mới không?' : ''}`,
          screen: 'ADD_STUDENT',
          hasBtnClosePopup: !!turnHomeworkArr.length,
          textComplete: !!turnHomeworkArr.length ? 'Đồng ý' : 'Đóng',
          isAutoClose: !turnHomeworkArr.length,
          onClosePopup: () => {
            if (!listStudentFail?.length) {
              history.push(
                  "/" + authentication.role + "/class/view/" + id + "/student"
              )
            }
          },
          onCompletePopup: () => {
            if (!listStudentFail?.length && !turnHomeworkArr.length) {
              history.push(
                  "/" + authentication.role + "/class/view/" + id + "/student"
              )
            }
            setListIdStudentAdded(listIdStudent);
            setIsOpenGiveTurnHomework(true);
          }
        })
      );
      setListStudentAdd([])
    } catch (err) {
      dispatch(
        alertActions.error({
          message: err?.toString(),
          screen: 'ADD_STUDENT',
          isShowPopup: true
        })
      );
    }
  }

  const validateParams = () => {
    return !!listStudentAdd?.every(item => item?.status !== 'fail')
  }

  useEffect(() => {
    if (!listStudentInClass?.length) {
      teacherService
      .getStudentOfClassV2(id)
      .then((students) => {
        // dispatch({
        //   type: teacherConstants.GET_STUDENT_OFF_CLASS,
        //   students,
        // });
        setListStudentInClass(students?.data)
      });
    }
  }, [])

  const renderIconSearch = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512">
        <path 
          fill="#404041" 
          d="M456.69 421.39L362.6 327.3a173.8 173.8 0 0 0 34.84-104.58C397.44 126.38 319.06 48 222.72 48S48 126.38 48 222.72s78.38 174.72 174.72 174.72A173.8 173.8 0 0 0 327.3 362.6l94.09 94.09a25 25 0 0 0 35.3-35.3M97.92 222.72a124.8 124.8 0 1 1 124.8 124.8a124.95 124.95 0 0 1-124.8-124.8"
        />
      </svg>
    )
  }

  const renderIconDelete = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 256 256">
        <path 
          fill="red" 
          d="M216 48h-40v-8a24 24 0 0 0-24-24h-48a24 24 0 0 0-24 24v8H40a8 8 0 0 0 0 16h8v144a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V64h8a8 8 0 0 0 0-16M96 40a8 8 0 0 1 8-8h48a8 8 0 0 1 8 8v8H96Zm96 168H64V64h128Zm-80-104v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0m48 0v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0"
        />
      </svg>
    )
  }

  const renderIconNopeAvatar = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 24 24">
        <g fill="none" fill-rule="evenodd">
          <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"/>
          <path fill="red" d="m12 14.122l5.303 5.303a1.5 1.5 0 0 0 2.122-2.122L14.12 12l5.304-5.303a1.5 1.5 0 1 0-2.122-2.121L12 9.879L6.697 4.576a1.5 1.5 0 1 0-2.122 2.12L9.88 12l-5.304 5.304a1.5 1.5 0 1 0 2.122 2.12z"/>
        </g>
      </svg>
    )
  }

  const renderIconEdit = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24">
        <path 
          fill="none" 
          stroke="#00bbb5" 
          stroke-linecap="round" 
          stroke-linejoin="round" 
          stroke-width="1.5" 
          d="M3 21h18M12.222 5.828L15.05 3L20 7.95l-2.828 2.828m-4.95-4.95l-5.607 5.607a1 1 0 0 0-.293.707v4.536h4.536a1 1 0 0 0 .707-.293l5.607-5.607m-4.95-4.95l4.95 4.95"
        />
      </svg>
    )
  }

  const handleEditStudent = (data) => {
    const newListStudent = listStudentAdd?.map(item => {
      if (item?.id === data?.id) {
        return {
          ...data,
          msg_error: undefined,
          status: 'edited'
        }
      }
      return item;
    })
    setListStudentAdd(newListStudent);
    setIsOpenModalEdit(false);
    setStudentEditing(null);
  }

  return (
    <div className="sunE-right-container add_student_ic_container ">
      <Header
        title={"Thêm học sinh"}
        isBack
        clickBack={() => history.goBack()}
      />
      <div className="add-student-ic-content">
        <div className="add-student-way">
          <div className="add-student-way-search">
            <p className="add-student-way-title">Cách 1: Tìm học sinh trên hệ thống</p>
            <InputText
              value={searchText}
              setValue={setSearchText}
              type="text"
              name="searchText"
              placeholder="Tìm theo email, SĐT hoặc username học sinh"
              renderLabelIcon={renderIconSearch}
              onKeyUp={(e) => {
                if (e.keyCode === 13) {
                  handleSearch()
                }
              }}
            />
            <div className="add-student-way-action">
              <button
                className={
                  "btn-check-custom btn-line-blue" +
                  (!searchText ? " btn-disable" : "")
                }
                onClick={handleSearch}
              >
                Kiểm tra
              </button>
            </div>
          </div>
          <div className="add-student-way-file">
            <p className="add-student-way-title">Cách 2: Nhập từ excel</p>
            <span className="way-file-step">
              Bước 1: {' '}
              <span 
                className="way-file-step" 
                style={{
                  textDecoration: 'underline', 
                  fontWeight: 700, 
                  cursor: 'pointer',
                  color: "#000"
                }}
                onClick={onDownloadFile}
              >
                Tải file excel mẫu tại đây
              </span>
            </span>
            <div>
              <p className="way-file-step">
                Bước 2: Copy danh sách học sinh vào file mẫu và upload lên hệ thống. Mỗi lớp 1 file.
              </p>
              <p>
              Thầy/Cô cần nhập thông tin bắt buộc vào cột <br></br><span style={{fontSize: 17 ,fontWeight: "bold", color: "#000"}}>Họ và Tên</span> trong file mẫu. Các trường thông tin khác có thể để trống nếu không có thông tin.
              </p>
              <span className="way-file-step" style={{fontWeight: "bold", textIndent: 16, color: "#f00"}}>
                Lưu ý: Thầy/Cô không nhập cột STT vào trong file tải lên.
              </span>
            </div>
            <div className="add-student-way-action">
            <label 
              for="file-upload" 
              class="btn-check-custom btn-line-blue" 
              style={{
                display: 'flex', 
                justifyContent:'center', 
                alignItems: 'center', 
                cursor: 'pointer'
              }}
            >
              Upload file
            </label>
              <input 
                ref={refUpload}
                id="file-upload"
                type="file" 
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={handleUpload} 
                onClick={handleBeforeUpload}
              />
            </div>
          </div>
        </div>
        {!!listStudentAdd?.length && (
          <div className="add-student-list-content">
            <p className="add-student-list-heading">{`Danh sách học sinh (${listStudentAdd?.length})`}</p>
            {!isAddBySearch && <span>{`Đã thêm thành công ${totalStudentAddedSuccess}/${totalListStudentAdded} học sinh vào lớp`}</span>}
            <div className="scrollbar-custom add-student-list">
              {listStudentAdd?.map((item, index) => {
                const isExisted = (!!item?.email && !!listStudentInClass?.find(student => student?.email === item?.email)) ||
                                  (!!item?.phone && !!listStudentInClass?.find(student => student?.phone === item?.phone)) ||
                                  item?.status === 'fail'
                return (
                  <div key={item?.id} className="add-student-item">
                    <div className="flex" style={{gap: 16}}>
                      <div className="add-student-item-image">
                        {item?.msg_error?.length ? renderIconNopeAvatar() : <img
                          src={configConstants.BASE_URL + (item?.avatar ? item.avatar : `assets/img_base/student_${item?.gender ? item.gender : 'male'}_dfa.jpg`)}
                          alt="avatar"
                        />}
                      </div>
                      <div className="add-student-info">
                        <p className="add-student-info-name">{item?.name}</p>
                        {item?.email && <p>{item?.email}</p>}
                        {item?.phone && <p>{item?.phone}</p>}
                        {item?.msg_error?.length && <div style={{display: 'flex', gap: 4}}>
                          <p style={{textWrap: 'nowrap'}}>Lí do:</p>
                          <div>
                            {item?.msg_error?.map((err, iErr) => (
                              <p key={iErr}>{`${item?.msg_error?.length > 1 ? '+ ' : ''}${err}`}</p>
                            ))}
                          </div>
                        </div>}
                      </div>
                    </div>
                    <SubMenu
                      style={{marginLeft: 'auto'}} 
                      renderMenu={
                        <div style={{padding: '8px 16px', display: 'flex', flexDirection: 'column',gap: 8}}>
                          {!isAddBySearch && <div 
                              onClick={() => {
                                setStudentEditing(item);
                                setIsOpenModalEdit(true);
                              }} 
                              style={{display: 'flex', gap: 12, alignItems: 'center', cursor: 'pointer'}}
                          >
                            <div
                              className="btn-remove-student"
                            >
                              {renderIconEdit()}
                            </div>
                            <p style={{textWrap: 'nowrap'}}>Chỉnh sửa</p>
                          </div>}
                          <div 
                            style={{display: 'flex', gap: 12, alignItems: 'center', cursor: 'pointer'}} 
                            onClick={() => {
                              if(isExisted) {
                                onConfirmDeleteStudent(index)
                                return;
                              }
                              handleDeleteStudent(index)
                            }}
                          >
                            <div
                              className="btn-remove-student"
                            >
                              {renderIconDelete()}
                            </div>

                            <p style={{textWrap: 'nowrap'}}>Xóa</p>
                          </div>
                        </div>
                      }
                    />
                    {/* <div 
                      style={{cursor: 'pointer'}} 
                      onClick={() => {
                        if(isExisted) {
                          onConfirmDeleteStudent(index)
                          return;
                        }
                        handleDeleteStudent(index)
                      }}
                    >
                      {renderIconDelete()}
                    </div> */}
                  </div>
              )})}
            </div>
            <div style={{paddingTop: 16, display: 'flex', gap: 16, alignSelf: 'flex-end'}}>
              <button
                className={
                  "btn-default"
                }
                onClick={handleCancel}
              >
                Hủy
              </button>
              <button
                disabled={!validateParams()} 
                className={"btn-check-custom btn-line-blue " + (!validateParams() ? 'btn-disable' : '')}
                style={{padding: '0 44px'}}
                onClick={addStudent}
              >
                Thêm học sinh
              </button>
            </div>
          </div>
        )}
      </div>
      {(isShowPopupDelete || isShowPopupCancel) && (
        <PopUpYesNo
          onClickYes={isShowPopupDelete ? onConfirmDeleteStudent : onConfirmCancel}
          onClickNo={() => {
            setIsShowPopupDelete(false);
            setIsShowPopupCancel(false);
          }}
          labelNo="Không"
          laelYes="Có"
          message={[
            isShowPopupDelete ? `Bạn có chắc muốn xóa học sinh này?` : 'Bạn có chắc muốn xóa danh sách học sinh này?',
          ]}
        />
      )}
      {/* <PopupListStudentAddedFail 
        open={isOpenPopupListStudentAddedFail} 
        setOpen={setIsOpenPopupListStudentAddedFail}
        listData={listStudentAddedFail} 
        totalStudentAddedSuccess={totalStudentAddedSuccess}
        totalStudentAdded={totalListStudentAdded}
        onAddStudent={addByExcel}
        onClose={() => {
          setIsOpenPopupListStudentAddedFail(false);
          if (!!listStudentAddedSuccess?.length) {
            handleAddStudentSuccess(listStudentAddedSuccess, 'Bạn muốn giao các bài tập đã từng giao cho học sinh mới không?');
            return;
          }
          history.push(
            "/" + authentication.role + "/class/view/" + id + "/student"
          )
        }}
        onComplete={() => {
          setIsOpenPopupListStudentAddedFail(false)
          // history.push(
          //   "/" + authentication.role + "/class/view/" + id + "/student"
          // )
        }}
        screen='ADD_STUDENT'
      /> */}
      <PopupEditStudent 
        open={isOpenModalEdit} 
        data={studentEditing}
        onClose={() => {
          setIsOpenModalEdit(false);
          setStudentEditing(null)
        }}
        onSubmit={handleEditStudent}
        isEditStudentFail
        formatBirthday="DD-MM-YYYY"
      />
      <PopupGiveTurnHomework 
        open={isOpenGiveTurnHomework && !!listTurnHomework.length} 
        listData={listTurnHomework} 
        classId={id} 
        listStudent={listIdStudentAdded} 
        onClose={() => {
          setIsOpenGiveTurnHomework(false)
          if (!listStudentAdd.length) {
            history.push(
              "/" + authentication.role + "/class/view/" + id + "/student"
            )
          }
        }}
        onComplete={() => {
          setIsOpenGiveTurnHomework(false)
          if (!listStudentAdd.length) {
            history.push(
              "/" + authentication.role + "/class/view/" + id + "/student"
            )
          }
        }}
        screen='ADD_STUDENT'
      />
      {alert.message && alert.screen === 'ADD_STUDENT' && 
        <Alert alert={alert} />
      }
    </div>
  )
}