import _, { includes, isEmpty } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { alertActions, teacherActions } from "../../../_actions";
import { Wish } from "../../../_components/Admin/Teacher";
import { configConstants, teacherConstants } from "../../../_constants";
import { teacherService } from "../../../_services";
import DoTestExamModal from "../../StudentPage/exam-test/DoTestExam";
import LessonBase from "../../../_base/LessonBase";
import { TypeAssignExercise } from "../../../_constants/typeAssignExercise";
import { floatObjectToArray } from "../../../_base/Validate";
import $ from "jquery";
import LazyLoad from "react-lazyload";
import { ReviewExam } from "../../StudentPage/exam-test/ReviewExam";
import { formatExamDataQuestions, formatLessonDataQuestion } from "../../../_helpers/formatDataLesson";
import { apiCaller } from "../../../_helpers";
import { PopUpYesNo } from "../../../_components/Popup";

function ContentSkill(props) {
  let {
    data,
    filters,
    btn,
    fullWish,
    textEmpty,
    disableGetDetailCurriculumById,
    screen,
    typeAssign,
    userID,
    filterCurriculumStatus,
    typeCurriculum,
    fromViewCurriculumPersonal,
    setSaveListExercise,
    scrollCurriculumFavorite,
    handleScrollFavorite,
  } = props;
  const { id, skill } = useParams();
  const history = useHistory();
  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const fromPage = params?.get("page");
  const stt = params.get('stt')
  const authentication = useSelector((state) => state.authentication);

  const dataAssignProposal = useSelector(
    (state) => state.classes.data_assign_proposal
  );

  dataAssignProposal.map(
    (item) =>
      (item.exercise_suggest = floatObjectToArray(item.exercise_suggest))
  );

  const [dataProposal, setDataProposal] = useState(dataAssignProposal);

  const handlepageshow = () => {
    $(".loading").addClass("hide");
  };

  useEffect(() => {
    window.addEventListener("pageshow", handlepageshow);

    return () => {
      window.removeEventListener("pageshow", handlepageshow);
    };
  }, []);

  useEffect(() => {
    if (
      id &&
      disableGetDetailCurriculumById &&
      disableGetDetailCurriculumById != "disabled" &&
      authentication.role != "teacher"
    ) {
      dispatch(teacherActions.getDetailCurriculumById(id));
    }
  }, []);
  const dispatch = useDispatch();
  const exercises = useSelector((state) => state.classes.exercises);
  const curriculumsDetail = useSelector((state) => state.curriculums.detail);
  const loading = useSelector((state) => state.curriculums.loading);
  const [listExerciseId, setListExerciseId] = useState(exercises.data_exercise);
  const [listExercisesSelected, setListExerciseSelected] = useState(
    exercises.data_lessons_selected || []
  );
  const [examSelected, selectExam] = useState(null);
  const [isLoadMore, setLoadMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [dataFilterCurriculum, setDataFilterCurriculum] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const paramsFilter = useSelector((state) => state.curriculums.filters.param);
  const [lessonInSkill] = useState(
    useSelector((state) => state.curriculums.lessonInSkill)
  );
  const [isShowReview, setIsShowReview] = useState(false);
  const [isShowReviewLesson, setIsShowReviewLesson] = useState(false);
  const [dataQuestion, setDataQuestion] = useState([]);
  const [infoQuestion, setInfoQuestion] = useState();
  const [isOpenModalLocked, setIsOpenModalLocked] = useState(false);

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
  }, [dataFilterCurriculum]);

  const handleReview = (item) => {
    const type = item?.lesson_type;
    if(type === 'exam' || type === 'mini_test') {
        getDataExam(item,type)
        return;
    }
    getDataLesson(item)
    return;
  }

  const getDataLesson = (item) => { 
    const url = `/api_lesson/lesson?id=${item?.lesson_id}&unit_id=${item?.unit_id}&curriculum_id=${item?.curriculum_id}`;
    apiCaller(url, 'GET')
    .then(res=>{
      if(res.status){
        const convertDataQuestion = formatLessonDataQuestion(res)
          setDataQuestion(convertDataQuestion);
          setInfoQuestion(res?.lesson);
          setIsShowReviewLesson(true);
          setIsShowReview(true);
      } else {
        dispatch(
          alertActions.error({
            message: res?.msg?.toString(),
            screen: '',
            isShowPopup: true
          })
        );
      }
    })
    .catch (err => {
      dispatch(
        alertActions.error({
          message: err?.toString(),
          screen: '',
          isShowPopup: true
        })
      );
    })
  }
  const getDataExam = (item, type) => {
    const url = `/student/api_exam/exam_v2?id=${item?.lesson_id || item?.exam_id}&type=${type}&class_id=${exercises?.class_id||0}&unit_id=${item?.unit_id||0}&is_homework=0&curriculum_id=${item?.curriculum_id}`;
    apiCaller(url, 'GET')
    .then(res=>{
        if(res.status){
          const convertDataQuestion = formatExamDataQuestions(res)
          setDataQuestion(convertDataQuestion);
          setInfoQuestion(res?.lesson);
          setIsShowReview(true)
        } else {
          dispatch(
            alertActions.error({
              message: res?.msg?.toString(),
              screen: '',
              isShowPopup: true
            })
          );
        }
    })
    .catch (err => {
      dispatch(
        alertActions.error({
          message: err?.toString(),
          screen: '',
          isShowPopup: true
        })
      );
    })
  }

  const handleSaveDataLesson = (changeExercise) => {
    if (
      typeAssign &&
      [TypeAssignExercise.DEMAND, TypeAssignExercise.CAPACITY].includes(
        typeAssign
      )
    ) {
      // Get DATA of Selected Student
      const studentLessonAdd = dataProposal.filter(
        (item) => item.user_id == userID
      );

      const [objStudentLessonAdd] = studentLessonAdd;

      // Get date exercise_suggest after checked action
      const listExerciseUser = listExerciseId.filter(
        (item) => item.user_id == userID
      );

      const detailFilterNewArray = listExerciseUser?.map(
        (item) => item?.detail ?? item
      );

      objStudentLessonAdd["exercise_suggest"] = detailFilterNewArray;
      if(changeExercise) {
        objStudentLessonAdd["isRoot"] = false;
      }

      const newDataProposal = dataProposal;
      newDataProposal.forEach((item, index, dataOriginal) => {
        if (item.user_id == objStudentLessonAdd.user_id) {
          dataOriginal[index] = objStudentLessonAdd;
        }
      });

      dispatch({
        type: teacherConstants.GET_DATA_ASSIGN_SPECIFIC,
        proposal: newDataProposal,
      });
      // history.replace(
      //   "/" +
      //     authentication.role +
      //     "/class/view/" +
      //     exercises.class_id +
      //     "/exercise/student/" +
      //     typeAssign +
      //     "/list_proposals",
      //   { userIDSelected: userID }
      // );
    }
  };

  function hanldeChecked(data) {
    var index = [
      TypeAssignExercise.DEMAND,
      TypeAssignExercise.CAPACITY,
    ].includes(typeAssign)
      // ? _.findIndex(listExerciseId, {
      //     lesson_id: data.lesson_id || data?.exam_id,
      //     user_id: userID,
      //   })
      // : _.findIndex(listExerciseId, {
      //     lesson_id: data.lesson_id || data?.exam_id,
      //   });
      ? listExerciseId?.findIndex(item => (item?.lesson_id == data?.lesson_id || item?.lesson_id == data?.exam_id) && item?.user_id == userID)
      : listExerciseId?.findIndex(item => item?.lesson_id == data?.lesson_id || item?.lesson_id == data?.exam_id)

    if (index === -1) {
      let parent = [];
      parent[0] = data.curriculum_name
        ? data.curriculum_name
        : curriculumsDetail.course?.name;
      parent[1] = data.unit_name
        ? data.unit_name
        : curriculumsDetail.course?.name;
      let data_exercise = {
        lesson_id: data.lesson_id || data?.exam_id,
        exercise_id: data.lesson_id || data?.exam_id,
        list_guide_id: [],
        exercise_type: data?.lesson_type,
        parent: parent,
        detail: {...data, stt},
        curriculum_name: parent[0],
        lesson_name: parent[1],
        user_id: userID,
        total_time:
          data?.total_time ||
          lessonInSkill?.find((item) => item?.lesson_id == data?.lesson_id)
            ?.total_time ||
          0,
      };
      const dataWithParent = data;
      dataWithParent.curriculum_name = parent[0];

      listExerciseId.push(data_exercise);
      // listExercisesSelected.push(dataWithParent);
    } else {
      listExerciseId.splice(index, 1);
      // listExercisesSelected.splice(index, 1);
    }
    setSaveListExercise && setSaveListExercise([...listExerciseId]);
    setListExerciseId([...listExerciseId]);
    handleSaveDataLesson(true);
    dispatch({
      type: teacherConstants.ADD_DATA_EXERCISE,
      data: {
        ...exercises,
        data_exercise: listExerciseId,
      },
    });
  }

  function hasDuplicates(array1, array2) {
    var combinedArray = array1.concat(array2);
    return new Set(combinedArray).size !== combinedArray.length;
  }

  const fetchDataFilterCurriculum = (offset_FilterCurriculum) => {
    if (
      !isLoading &
      (offset != offset_FilterCurriculum || offset_FilterCurriculum == 0)
    ) {
      setLoading(true);
      dispatch(
        teacherActions.filterSkill(
          paramsFilter,
          null,
          configConstants.DEFAULT_LIMIT,
          offset_FilterCurriculum,
          "filterCurriculum",
          dataFilterCurriculum
        )
      )
        .then((res) => {
          if (!hasDuplicates(res.list_data_result, dataFilterCurriculum)) {
            setLoading(false);
            setDataFilterCurriculum((prev) => [
              ...prev,
              ...res.list_data_result,
            ]);
            setOffset(offset_FilterCurriculum);
            setLoadMore(res.list_data_result == configConstants.DEFAULT_LIMIT);
          }
        })
        .catch((e) => {
          setLoading(false);
        });
    }
  };

  const handleScroll = (e) => {
    if (
      _.isEmpty(paramsFilter.level) &&
      _.isEmpty(paramsFilter.skill) &&
      _.isEmpty(paramsFilter.grade_id)
    ) {
      if (
        e.target.clientHeight + e.target.scrollTop + 5 >=
          e.target.scrollHeight &&
        isLoadMore &&
        !isLoading
      ) {
        fetchDataFilterCurriculum(offset + configConstants.DEFAULT_LIMIT);
      }
    }
  };

  function handleSubmit() {
    if (validateParam()) {
      exercises.data_exercise = listExerciseId;
      // exercises.data_lessons_selected = listExercisesSelected;
      if (screen === "CURRICULUM_PERSONAL_FAVORITE") {
        dispatch({
          type: teacherConstants.ADD_DATA_EXERCISE,
          data: { ...exercises, students: [] },
        });
        dispatch({
          type: teacherConstants.SOURCE_PAGE_ADD_EXCERCISE,
          sourcePageAddExcercise: "PAGE_CURRICULUM",
        });
        // dispatch(teacherActions.getListClass(true));
        fromPage == "curriculum"
          ? history.push(
              `/teacher/curriculum/exercise/class` +
                (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
            )
          : history.push(`/teacher/curriculum/exercise/class`);
      } else if (!_.isEmpty(exercises.students)) {
        if (
          typeAssign &&
          [TypeAssignExercise.DEMAND, TypeAssignExercise.CAPACITY].includes(
            typeAssign
          )
        ) {
          // Get DATA of Selected Student
          handleSaveDataLesson();
          history.replace(
            "/" +
              authentication.role +
              "/class/view/" +
              exercises.class_id +
              "/exercise/student/" +
              typeAssign +
              "/list_proposals",
            { userIDSelected: userID }
          );
        } else {
          if (fromPage == "curriculum") {
            history.push(
              "/teacher/curriculum/exercise/class" +
                (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
            );
          } else {
            history.push(
              "/" +
                authentication.role +
                "/class/view/" +
                exercises.class_id +
                `/exercise/review` +
                (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
            );
          }
        }
      } else {
        // dispatch(teacherActions.getListClass(true));
        if (fromPage == "curriculum") {
          history.push(
            "/" +
              authentication.role +
              "/curriculum/exercise/class" +
              (!isEmpty(fromPage) ? `?page=${fromPage}` : "")
          );
        } else {
          history.push("/teacher/curriculum/exercise/class");
        }
        dispatch({
          type: teacherConstants.SOURCE_PAGE_ADD_EXCERCISE,
          sourcePageAddExcercise: "PAGE_CURRICULUM",
        });
      }
    }
  }

  function validateParam() {
    return _.isEmpty(listExerciseId) ? false : true;
  }

  function learn(lesson_id, itemExam) {
    if(!!itemExam?.is_lock_lesson) {
      setIsOpenModalLocked(true)
      return;
    }
    if (
      ["exam", "mini_test"].includes(skill) ||
      ["exam", "mini_test"].includes(itemExam.lesson_type)
    ) {
      selectExam(itemExam);
    } else {
      $(".loading").removeClass("hide");
      LessonBase._moveLesson(
        LessonBase.Type.teacher,
        authentication,
        itemExam,
        null,
        9
      );
    }
  }
  
  function showContent(data) {
    if (loading) {
      return null;
    }

    return data.map((item, i) => {
      return (
        <Fragment key={i}>
          <div
            className='sunE-giaotrinh-item flex-m rel pointer_cursor'
            key={i}
          >
            <div className='img'>
              <img
                src={
                  "/assets/images/teacher/giaobai/" +
                  item.lesson_type?.toLocaleLowerCase() +
                  ".png"
                }
                alt={item.lesson_type}
              />
            </div>
            <div
              className='content rel flex-1'
              style={{paddingTop: 2.6}}
              onClick={() => learn(item.lesson_id, item)}
            >
              <h2 className='title two-line'>
                {item.level &&
                  item?.sub_lesson_type !== "grammar" &&
                  item?.sub_lesson_type !== "vocabulary" && (
                    <span className={item.level}>
                      {item.level === "normal" ? "medium" : item.level}
                    </span>
                  )}{" "}
                {item.lesson_topic || item.topic}
              </h2>
              <h3 className='desc two-line'>
                {item.lesson_name || item.exercise_name}
              </h3>
              {/* {fromViewCurriculumPersonal ? ( */}
                <p>
                  {item.curriculum_name || curriculumsDetail.course?.name}
                </p>
              {/* ) : (
                <p>{item.lesson_topic || item.topic}</p>
              )} */}
              <div className="flex flex-m" style={{gap: 12}}>
                <span style={{margin: 0}}>{`${item?.unit ? `Unit ${item.unit} - ` : ''}${item?.unit_name ? item.unit_name : ''}`}</span>
                {/* {item.lesson_type !== "skill_guide" ? <span 
                  className="review-lesson-text"
                  style={{margin: 0, lineHeight: 'unset'}}
                  onClick={(e) => {
                    e.stopPropagation();
                    if(!!item?.is_lock_lesson) {
                      setIsOpenModalLocked(true)
                      return;
                    }
                    handleReview(item)
                  }}
                >
                  Xem đáp án
                </span> : <span />} */}
                <div className='chk-gr' style={{alignSelf: 'flex-end'}}>
                  <Wish
                    lesson_id={item?.lesson_id || item?.exam_id}
                    lesson_type={item.lesson_type}
                    curriculum_id={item.curriculum_id}
                    wish={fullWish == true ? true : item.is_in_wishlist}
                    unit_id={item?.unit_id}
                  />
                  {item.lesson_type !== "skill_guide" && (
                    <span
                      className='chk-custom-gr size-27 top-1'
                      style={{margin: '0 0 0 5px'}}
                      onClick={(e) => {
                        e.stopPropagation();
                        hanldeChecked(item)
                      }}
                    >
                      <input
                        type='checkbox'
                        defaultChecked={
                          [
                            TypeAssignExercise.DEMAND,
                            TypeAssignExercise.CAPACITY,
                          ].includes(typeAssign)
                            ? _.findIndex(listExerciseId, {
                                exercise_id: item.lesson_id,
                                user_id: userID,
                              }) !== -1 ||
                              _.findIndex(listExerciseId, {
                                lesson_id: item.lesson_id,
                                user_id: userID,
                              }) !== -1
                              ? true
                              : false
                            : _.findIndex(listExerciseId, {
                                exercise_id: item.lesson_id,
                              }) !== -1 ||
                              _.findIndex(listExerciseId, {
                                lesson_id: item.lesson_id,
                              }) !== -1
                            ? true
                            : false
                        }
                        checked={
                          [
                            TypeAssignExercise.DEMAND,
                            TypeAssignExercise.CAPACITY,
                          ].includes(typeAssign)
                          ? !!listExerciseId?.find(
                            (exercise) =>
                              (exercise?.lesson_id === item?.lesson_id ||
                              exercise?.lesson_id === item?.exam_id) && exercise?.user_id == userID
                          )
                          : !!listExerciseId?.find(
                            (exercise) =>
                              exercise?.lesson_id === item?.lesson_id ||
                              exercise?.lesson_id === item?.exam_id
                          )
                        }
                        disabled
                        id={item?.lesson_id || item?.exam_id}
                      />
                      <label htmlFor={item.lesson_id}></label>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      );
    });
  }

  return (
    <Fragment>
      {data?.length > 0 ? (
        <div className='sunE-container-box giaotrinh filter pad-t-0 flex-1'>
          <div
            className={`sunE-giaotrinh-resuft-filter box-shadow scrollbar-custom flex-1 ldjas 
          ${
            typeCurriculum == "favorite"
              ? "style_scroll_overflowing_curriculum_favorite"
              : ""
          }
          `}
            onScroll={(e) => {
              typeCurriculum == "personal"
                ? {}
                : typeCurriculum == "favorite"
                ? handleScrollFavorite(e)
                : fromPage == "curriculum"
                ? {}
                : fromPage == "classmanage" || fromPage == "classmanager"
                ? {}
                : handleScroll(e);
            }}
            ref={scrollCurriculumFavorite}
          >
            {!filters
              ? showContent(data || [])
              : filters.map((data, i) => {
                  return (
                    <Fragment key={i}>
                      {!btn && (
                        <span className='span-title'>
                          {data.curriculum_name}
                        </span>
                      )}
                      {showContent(data.list_lesson)}
                    </Fragment>
                  );
                })}
          </div>
        </div>
      ) : (
        <div className='__text_empty_curiclum_personal'>{textEmpty}</div>
      )}
      {!btn && data?.length > 0 && (
        <div className='form-sunE-button text-right father_btn_assign_assignments'>
          <button
            className={
              "responsive_btn_assign_assignments btn-bg-blue btn-default pos_rel" +
              (!validateParam() ? " btn-disable" : "")
            }
            onClick={handleSubmit}
          >
            <div className='pos_abs quantity_from_teacher_choose'>
              {[
                TypeAssignExercise.DEMAND,
                TypeAssignExercise.CAPACITY,
              ].includes(typeAssign)
                ? listExerciseId?.filter((item) => item.user_id == userID)
                    .length || 0
                : listExerciseId?.length || 0}
            </div>
            Giao bài
          </button>
        </div>
      )}
      {examSelected && (
        <DoTestExamModal
          setExamSelected={selectExam}
          isShow={examSelected !== null}
          onClose={() => selectExam(null)}
          mockTestId={
            examSelected?.lesson_id ||
            examSelected?.exercise_id ||
            examSelected?.exam_id
          }
          type={examSelected.lesson_type}
          examSelected={examSelected}
          heading={examSelected?.exam_name || examSelected?.name || examSelected?.lesson_name || examSelected?.exercise_name || ''}
        />
      )}
      <ReviewExam
        examInfo={infoQuestion}
        isShow={isShowReview}
        onClose={() => setIsShowReview(false)}
        // examId={exercises.data_exercise[skill_key - 1]?.detail?.lesson_id}
        listQuestions={dataQuestion}
        isShowResultLesson={isShowReviewLesson}
        isReview={true}
      />
      {isOpenModalLocked && (
        <PopUpYesNo
          width={"360px"}
          onClickYes={() => {
            history.push(`/${authentication?.role}/more/license`);
            setIsOpenModalLocked(false);
          }}
          onClickNo={() => setIsOpenModalLocked(false)}
          message={
            "Bạn không thể truy cập nội dung này. Vui lòng nâng cấp tài khoản để tiếp tục!"
          }
          labelNo={"Đóng"}
          labelYes={"Nâng cấp"}
          hideButtonNo={false}
        />
      )}
    </Fragment>
  );
}

export { ContentSkill };
