import React, { useState } from "react";
import { CheckBoxCustom, renderStatusCheckBox } from "../../../_components/exam-test/CheckBoxCustom";
import { validWord } from "../../../_helpers/utils";

const formatScript=(s)=>{
  if(!s)return '';
  let idx = 1;
  return s.replace(/{.+?}/g,function(a,v){
      return `______`
  })
}

export default function ResultQuestionChooseHeading({dataQuestion, answer, skill, question_type, listOptionChoose}) {
  let finalAnswer = JSON.parse(answer);
  const listParagraph = dataQuestion[0]?.list_option?.[0]?.question_content?.split("\/");

  const listData = dataQuestion[0]?.list_option?.map((item, index) => {
    const userChoice = finalAnswer[index]
    return {
      ...item,
      userChoice,
      option_text: skill == 'reading' && question_type == 9 ? listParagraph[index] : item?.option_text,
    }
  }) || [];

  const listOption = listOptionChoose || [];
  listData?.map(item => {
    if(!listOption?.find(op => item?.match_option_text?.includes(op))) {
      listOption.push(item?.match_option_text?.[0])
    }
    if(!listOption?.find(op => item?.jamming_answer_parse?.includes(op))) {
      listOption.push(item?.jamming_answer_parse?.[0])
    }
  })

  const renderItem = (item, index) => {
  
    return (
      <div style={{paddingLeft: 6}}>
        <p style={{display: 'flex', gap: 4}}>
          <p style={{fontWeight: 'bold', color: '#000'}}>{index + 1 + '. '}</p>
          {formatScript(item?.option_text)}
        </p>
        {listOption?.map((option, i) => {
          return (
            <div style={{display: 'flex', gap: 8}}>
              <div style={{width: 24}}>
                {item?.match_option_text?.includes(option) && !finalAnswer[index]?.score && <img
                  src="/assets/images/ico_hover_answer.png"
                  style={{
                    width: 24,
                    height: 20,
                  }}
                />}
              </div>
              <CheckBoxCustom
                status={renderStatusCheckBox(
                validWord(option) === validWord(finalAnswer[index]?.user_choice),
                item?.match_option_text?.includes(option),
                true
                )}
                label={option}
              />
            </div>
          )
        })}
      </div>
    )
  }
  return (
    <div style={{flex: 1, marginTop: 16, display: 'flex', flexDirection: 'column', gap: 32}}>
      {listData?.map((item,index) => {
        return (
          <div key={index}>
            {renderItem(item, index)}
          </div>
        )
      })}
    </div>
  )
}
