import { useSelector } from "react-redux";
import { history } from "../../../_helpers";
import { useEffect } from "react";
import { teacherActions } from "../../../_actions";
import { useState } from "react";
import { userConstants } from "../../../_constants";

export const coursesPageLogic = () => {
  const authentication = useSelector((state) => state.authentication);

  // Data courses
  const [dataContentCourses, setDataContentCourses] = useState([
    {
      id: 1,
      name: "KHÓA HỌC HỘI NHẬP",
      type: "integrate",
      data: [
        {
          id: 1,
          title: "Đối tượng",
          content:
            "Dành cho học sinh lớp 6, 7, 8, 9 mất gốc Tiếng Anh, có học lực Tiếng Anh ở mức",
          tail_content: " Trung bình hoặc thấp hơn.",
        },
        {
          id: 2,
          title: "Mục tiêu",
          content: "Lấy lại nền tảng kiến thức Tiếng Anh trong 3 - 6 tháng.",
        },
        {
          id: 3,
          title: "Nội dung",
          list_detail: [
            {
              id: 1,
              content:
                "Tập trung phát triển kỹ năng Ngữ pháp cơ bản và xây dựng vốn Từ vựng cốt lõi.",
            },
            {
              id: 2,
              content:
                "Giáo trình đào tạo được thiết kế riêng biệt và hiệu quả, tập trung xây dựng kiến thức nền tảng cho học sinh trong khi vẫn đảm bảo khả năng hội nhập với các buổi học và bài kiểm tra chính khoá trên lớp, giúp học sinh cải thiện điểm số và đạt học lực cao hơn trong thời gian nhanh nhất.",
            },
            {
              id: 3,
              content:
                "Áp dụng trí tuệ nhân tạo AI cùng với phương pháp học thuật tiên tiến cho phép học sinh ",
              tail_content:
                "tiến bộ nhanh nhờ cá nhân hóa giáo trình đào tạo theo học lực của mỗi học sinh.",
            },
            {
              id: 4,
              content: "Trong thời gian tham gia khoá học, học sinh được",
              content_1:
                "miễn phí học tiếp khóa học Nâng cao Năng lực Tiếng Anh",
              content_2: "nhằm bứt phá lên mức",
              tail_content: "điểm Xuất Sắc 9, 10.",
            },
          ],
        },
        {
          id: 4,
          title: "Kết quả",
          list_detail: [
            {
              id: 1,
              content: "Tự tin giao tiếp Tiếng Anh ở mức độ",
              tail_content: "cơ bản.",
            },
            {
              id: 2,
              content:
                "Cải thiện học lực và điểm số bài kiểm tra môn Tiếng Anh trên trường.",
            },
            {
              id: 3,
              content:
                "Xây dựng được nền tảng Ngữ pháp cơ bản và tích luỹ vốn Từ vựng cần thiết để hội nhập với giáo trình giảng dạy chính khoá trên trường.",
            },
            {
              id: 4,
              content:
                "Tự tin tham gia các khoá học nâng cao năng lực tiếng Anh để đạt điểm tối đa trong các bài kiểm tra Tiếng Anh trên trường.",
            },
          ],
        },
      ],
    },
    {
      id: 2,
      name: "KHÓA HỌC NÂNG CAO NĂNG LỰC",
      type: "advanced",
      data: [
        {
          id: 1,
          title: "Đối tượng",
          content:
            "Dành cho học sinh lớp 6, 7, 8, 9 có học lực Tiếng Anh ở mức",
          tail_content: " Trung bình khá, Khá hoặc cao hơn.",
        },
        {
          id: 2,
          title: "Mục tiêu",
          content:
            "Nâng cao năng lực Tiếng Anh và đạt điểm tối đa bài kiểm tra môn Tiếng Anh trên trường.",
        },
        {
          id: 3,
          title: "Nội dung",
          list_detail: [
            {
              id: 1,
              content: "Phát triển toàn diện 4 kỹ năng:",
              tail_content: " Nghe – Nói - Đọc - Viết.",
            },
            {
              id: 2,
              content:
                "Giáo trình đào tạo được xây dựng theo tiêu chuẩn Quốc tế, bám sát chương trình SGK của Bộ GDĐT.",
            },
            {
              id: 3,
              content:
                "Áp dụng trí tuệ nhân tạo AI cùng với phương pháp học thuật tiên tiến cho phép học sinh ",
              tail_content:
                "tiến bộ nhanh nhờ cá nhân hóa giáo trình đào tạo theo học lực của mỗi học sinh.",
            },
            {
              id: 4,
              content:
                "Hàng ngàn bài luyện tập với trên 50 dạng bài kèm lời giải chi tiết được Thầy/Cô sử dụng trong các bài kiểm tra trên lớp.",
            },
            {
              id: 5,
              content: "Luyện kỹ năng nói chuẩn bản xứ với công nghệ",
              tail_content: "trí tuệ nhân tạo AI tiên tiến.",
            },
            {
              id: 6,
              content:
                "Phát triển kỹ năng viết theo chủ đề với sự hỗ trợ của AI và Thầy cô.",
            },
            {
              id: 7,
              content: "Trong thời gian tham gia khóa học, học sinh được",
              content_1: "miễn phí",
              content_2: "tham gia khóa học",
              tail_content: "hội nhập Tiếng Anh",
              content_3: "giúp bổ sung nhanh kiến thức nền tảng khi cần.",
            },
          ],
        },
        {
          id: 4,
          title: "Kết quả",
          list_detail: [
            {
              id: 1,
              content: "Tự tin giao tiếp Tiếng Anh",
              tail_content: "chuẩn bản xứ.",
            },
            {
              id: 2,
              content: "Thành thạo 4 kỹ năng Nghe – Nói – Đọc – Viết.",
            },
            {
              id: 3,
              content:
                "Đạt điểm tối đa bài kiểm tra môn Tiếng Anh trên trường.",
            },
            {
              id: 4,
              content: null,
              tail_content: "Đạt học sinh Giỏi môn Tiếng Anh.",
            },
            {
              id: 5,
              content:
                "Tự tin tham gia các khoá học IELTS mà không phải học các khoá IELTS foundation hoặc Pre IELTS.",
            },
          ],
        },
      ],
    },
  ]);

  // useEffect(() => {
  //   window.scrollTo({ top: 0 });
  // }, []);

  // Scroll To View
  useEffect(() => {
    let hash = window.location.hash;
    if (hash) {
      var ids = hash.split("#");
      for (var i = 1; i < ids.length; i++) {
        var element = document.getElementById(ids[i]);
        // console.log(element);
        if (element) {
          element.scrollIntoView({ behavior: "instant" });
        }
      }
    } else {
      window.scrollTo({ top: 0, behavior: "instant" });
    }
  }, [window.location]);

  /// Scroll Sticky
  useEffect(() => {
    const title1 = document.querySelector(".course_title_1");
    const title2 = document.querySelector(".course_title_2");
    const image = document.querySelector(".content_course_list");

    window.addEventListener("scroll", () => {
      title1?.classList.add("pos_sticky");
      title1?.classList.add("filter_shadow");
      title2?.classList.add("filter_shadow");
      title2?.classList.add("pos_sticky");
      const imageBottom = image?.getBoundingClientRect().bottom;
      const titleBottom1 = title1?.getBoundingClientRect().bottom;
      const titleBottom2 = title2?.getBoundingClientRect().bottom;

      if (titleBottom1 > imageBottom) {
        title1?.classList.remove("pos_sticky");
        title1?.classList.remove("filter_shadow");
      } else {
        title1?.classList.add("pos_sticky");
        title1?.classList.add("filter_shadow");
      }

      if (titleBottom2 > imageBottom) {
        title2?.classList.remove("pos_sticky");
        title2?.classList.remove("filter_shadow");
      } else {
        title2?.classList.add("pos_sticky");
        title2?.classList.add("filter_shadow");
      }
    });
  }, []);

  return { dataContentCourses };
};
