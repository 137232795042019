import { useRef, useState } from 'react'
import './SubMenu.style.scss'
import useOutsideClick from '../../_helpers/customHook/useOutsideClick';

export default function SubMenu({renderMenu, ...other}) {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const ref = useRef(null)
  useOutsideClick(ref, () => {
    if (ref.current) {
      setIsOpenMenu(false);
    }
  })

  const renderIconMenu = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 32 32">
        <circle cx="16" cy="8" r="2" fill="#999999"/>
        <circle cx="16" cy="16" r="2" fill="#999999"/>
        <circle cx="16" cy="24" r="2" fill="#999999"/>
      </svg>
    )
  }

  return (
    <>
      <div ref={ref} className="sub-menu-button" onClick={() => setIsOpenMenu(!isOpenMenu)} {...other}>
        {renderIconMenu()}
        {isOpenMenu && <div className='menu-container'>
          {renderMenu}
        </div>}
      </div>
    </>
  )
}