import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { studentActions } from "../../_actions";
import moment from "moment";
import { Header } from "../../_components/Admin/Header";
import { SideBarAssessment } from "../../_components/Admin/Student";
import { Line } from "react-chartjs-2";
import { Link } from "react-router-dom";
import { studentService } from "../../_services";
import { studentConstants } from "../../_constants";
import { forEach } from "lodash";
import { SelectAsDiv } from "../../_components/Select";
import { intervalToDuration, subDays, format } from "date-fns";
import { scheduleConstants } from "../../_constants";
import { isEmpty } from "lodash";
import ChartDataLabels from 'chartjs-plugin-datalabels';

const DEFAULT_BADGE = [
  { alias: "rank1", name: "Nhập môn", img: "", required_score: "0" },
  { alias: "rank2", name: "Cơ bản", img: "", required_score: "200" },
  { alias: "rank3", name: "Trung cấp", img: "", required_score: "1000" },
  { alias: "rank4", name: "Cao cấp", img: "", required_score: "5000" },
  { alias: "rank5", name: "Lưu loát", img: "", required_score: "10000" },
  { alias: "rank5", name: "Tinh thông", img: "", required_score: "15000" },
];

function HomePageAssessment() {
  const dispatch = useDispatch();

  const authentication = useSelector((state) => state.authentication);
  const assessmentsData = useSelector((state) => state.assessments);
  const assessments = useSelector((state) => state.assessments.online);
  const log_learnings = useSelector((state) => state.assessments.logLearnings);
  const learning_by_date = log_learnings?.data?.recent_activity;

  let today = moment().format("YYYY-MM-DD");
  let defaultLabel = [];
  const [lengthDatasets, setLengthDatasets] = useState();
  const [dataChart, setDataChart] = useState({
    labels: [],
    datasets: [],
  });
  const [highesValue, setHighesValue] = useState(5)

  const [larningHistory, setLarningHistory] = useState({
    data_detail: {
      data_chart_duration: [],
      data_chart_lesson: [],
      data_chart_diamond: [],
    },
  });

  useEffect(() => {
    if (!!assessmentsData?.timeGetData?.assessmentOnline &&
      moment(new Date()).diff(moment(assessmentsData?.timeGetData?.assessmentOnline), 'hours') < 1
    ) {
      handleDataLearningHistory(assessmentsData?.dataLearningHistory?.day, 'lesson', 'day', filterDateChart)
    } else {
      dispatch(studentActions.getAssessmentsOnline(authentication.id));
      dispatch(
        studentActions.getListLogLearning(
          moment().format("YYYY-MM-DD"),
          authentication.id
        )
      );
      getLarningHistoryByTime(
        format(subDays(new Date(), 4), "yyyy-MM-dd HH:mm:ss"),
        moment().format("YYYY-MM-DD HH:mm:ss"),
        "lesson",
        "day"
      );
      dispatch({
        type: studentConstants.GET_TIME_ASSESSMENT_ONLINE,
        payload: new Date()
      })
    }
  }, []);
  
  function getLarningHistoryByTime(
    from_time,
    to_time,
    type,
    time,
    filterDateChart = []
  ) {
    studentService
      .getLarningHistory(authentication.id, from_time, to_time)
      .then((data) => {
        // convertDataChart(data.data_detail, type, time, filterDateChart);
        // setLarningHistory({
        //   ...larningHistory,
        //   data_detail: data.data_detail,
        // });
        handleDataLearningHistory(data, type, time, filterDateChart)
        dispatch({
          type: studentConstants.GET_DATA_LEARNING_HISTORY_ASSESSMENT,
          payload: {
            data,
            type: time
          },
        })
      });
  }

  const handleDataLearningHistory = (
    data,
    type,
    time,
    filterDateChart = []
  ) => {
    convertDataChart(data.data_detail, type, time, filterDateChart);
    setLarningHistory({
      ...larningHistory,
      data_detail: data.data_detail,
    });
  }

  function convertDataChart(data, type = filter.type, time, filterDateChart) {
    let key_name = "data_chart_" + type;
    let dataOfDataSet = [];

    if (time === "day") {
      defaultLabel = [today];
      for (let i = 1; i <= 4; i++) {
        defaultLabel.unshift(
          moment(today, "YYYY-MM-DD").subtract("days", i).format("YYYY-MM-DD ")
        );
      }
      forEach(data[key_name], function (value) {
        for (let key of defaultLabel) {
          if (key.trim() == value.time.trim()) {
            type === "duration"
              ? dataOfDataSet.push(
                  parseFloat(parseFloat(value.value / 60).toFixed()) || 0
                )
              : dataOfDataSet.push(value.value || 0);
          }
        }
        // if (defaultLabel.indexOf(value.time.trim()) !== -1) {
        //   type === "duration"
        //   ? dataOfDataSet.push(parseFloat(parseFloat(value.value / 60).toFixed()) || 0)
        //   : dataOfDataSet.push((value.value) || 0);
        // }
        // if (value.value == 0) {
        //   dataOfDataSet.push(0)
        // }
      });

      dataOfDataSet = dataOfDataSet.reverse();
      defaultLabel = defaultLabel?.map(item => moment(new Date(item), "YYYY-MM-DD").format("DD/MM/YYYY "))
    } else if (time === "week") {
      let listDataWeek = [];
      let numDataPoints = 0;

      let separatedFiltersData = [];

      // Iterate over original filters
      filterDateChart.forEach((filter) => {
        // Get start and end dates
        let startDate = moment(filter.start_time);
        let endDate = moment(filter.to_time);

        // If the range spans across February and March
        if (startDate.month() !== endDate.month()) {
          // Clone the filter for February
          let febFilter = { ...filter };
          // Adjust the end date to the last day of February
          febFilter.to_time = moment(startDate)
            .endOf("month")
            .format("YYYY-MM-DD HH:mm:ss");
          separatedFiltersData.push(febFilter);

          // Clone the filter for March
          let marFilter = { ...filter };
          // Adjust the start date to the first day of March
          marFilter.start_time = moment(endDate)
            .startOf("month")
            .format("YYYY-MM-DD HH:mm:ss");
          separatedFiltersData.push(marFilter);
        } else {
          // If the range is within the same month, just push the original filter
          separatedFiltersData.push(filter);
        }
      });

      separatedFiltersData = separatedFiltersData.slice(-5);

      forEach(separatedFiltersData, function (value) {
        // Filter the data for the current date range

        // console.log(" data[key_name] ======", data[key_name]);

        const temp_data = data[key_name].filter((item) => {
          const toDate = moment(value.to_time, "YYYY-MM-DD HH:mm").startOf(
            "day"
          );
          const startDate = moment(value.start_time, "YYYY-MM-DD").startOf(
            "day"
          );
          return (
            moment(item.time).isSameOrBefore(toDate) &&
            moment(item.time).isSameOrAfter(startDate)
          );
        });

        // Calculate the data for the current date range
        const dataValue =
          type === "duration"
            ? (
                temp_data.reduce((n, { value }) => n + parseFloat(value), 0) /
                60
              ).toFixed(1)
            : temp_data
                .reduce((n, { value }) => n + parseFloat(value), 0)
                .toFixed(1);

        // Push the filtered data into the list
        listDataWeek.push(temp_data);

        // Increment the counter for total data points
        numDataPoints++;

        // Push the calculated data into the data set
        dataOfDataSet.push(dataValue);
      });

      forEach(separatedFiltersData, function (value) {
        defaultLabel.push(getNumberOfWeek(value.start_time));
      });
    } else if (time === "month") {
      defaultLabel = [
        "T" + moment().format("M") + "/" + moment().format("YYYY"),
      ];
      for (let index = 1; index <= 4; index++) {
        let previewMonth = moment(today, "YYYY-MM-DD").subtract("month", index);
        let previewMonthLabel = previewMonth.format("M");
        let previewYearLabel = previewMonth.format("YYYY");
        defaultLabel.unshift("T" + previewMonthLabel + "/" + previewYearLabel);
      }
      forEach(filterDateChart, function (value) {
        const temp_data = data[key_name].filter(
          (item) =>
            moment(item.time).startOf("hour") <=
              moment(value.to_time).startOf("hour") &&
            moment(item.time).startOf("hour") >=
              moment(value.start_time).startOf("hour")
        );

        type === "duration"
          ? dataOfDataSet.push(
              (
                temp_data.reduce(
                  (n, { value }) => parseFloat(n) + parseFloat(value),
                  0
                ) / 60
              ).toFixed()
            )
          : dataOfDataSet.push(
              temp_data.reduce(
                (n, { value }) => parseFloat(n) + parseFloat(value),
                0
              )
            );
      });
      setLengthDatasets(dataOfDataSet);
    }
    let maxDataValue = 5;
    dataOfDataSet?.forEach(item => {
      if (Number(item) > maxDataValue) {
        maxDataValue = Number(item)
      }
    })
    if(maxDataValue > 5) {
      if(maxDataValue.toString().includes('9')) {
        setHighesValue(Math.ceil(maxDataValue/10)*10 + 2)
      } else {
        setHighesValue(Math.ceil(maxDataValue/10)*10)
      }
    }
    setDataChart({
      ...dataChart,
      labels: defaultLabel,
      datasets: [
        {
          label: "",
          data: dataOfDataSet,
          backgroundColor: "rgba(0, 210, 225,0.4)",
          borderColor: "rgb(176, 158, 255)",
          pointBackgroundColor: "rgb(176, 158, 255)",
          lineTension: 0,
          fill: true,
        },
      ],
    });
  }

  function showUpDownScore(score) {
    // if (score < 0) {
    //   return <img src="/assets/images/thanhtich/down.png" alt="down" />;
    // } else {
    //   return <img src="/assets/images/thanhtich/up.png" alt="down" />;
    // }
  }
  
  const options = {
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: highesValue > 5 ? 2 : 1,
            suggestedMax: highesValue,
          },
        },
      ],
    },
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    bezierCurve: false,
    plugins: {
      datalabels: {
        display: true,
        color: "black",
        clamp: true,
        align: 'top'
      }
    }
  };

  const total_diamond = assessments.data_assessment.total_diamond;
  let temp_required_score = 0;

  const [filter, setFilter] = useState({
    type: "lesson",
    time: "day",
  });

  function changeFilter(type) {
    setFilter({
      ...filter,
      type,
    });
    convertDataChart(
      larningHistory.data_detail,
      type,
      filter.time,
      filterDateChart
    );
  }
  const [filterDateChart, setFilterDateChart] = useState([]);

  const [textDefaultSelect, setTextDefaultSelect] = useState("Lọc theo ngày");

  function onChangeSelect(time) {
    setFilter({
      ...filter,
      time,
    });

    const today = moment();

    if (time === "day") {
      setTextDefaultSelect("Lọc theo ngày");
      if (!!assessmentsData?.dataLearningHistory?.day && !!assessmentsData?.timeGetData?.assessmentOnline &&
        moment(new Date()).diff(moment(assessmentsData?.timeGetData?.assessmentOnline), 'hours') < 1
      ) {
        handleDataLearningHistory(assessmentsData?.dataLearningHistory?.day, 'lesson', 'day', filterDateChart)
      } else {
        getLarningHistoryByTime(
          `${format(subDays(new Date(), 4), "yyyy-MM-dd HH:mm:ss")}`,
          `${moment().format("YYYY-MM-DD HH:mm:ss")}`,
          filter.type,
          time
        );
      }
    } else if (time === "week") {
      setTextDefaultSelect("Lọc theo tuần");

      let listFilterDateChart = [];

      for (let index = 0; index <= 4; index++) {
        let startOfWeek = moment(today).subtract(index, "weeks").isoWeekday(1); // Set to Monday of the week
        let endOfWeek = moment(today)
          .subtract(index, "weeks")
          .isoWeekday(7)
          .endOf("day"); // Set to Sunday of the week

        listFilterDateChart.unshift({
          start_time: startOfWeek.format("YYYY-MM-DD HH:mm:ss"),
          to_time: endOfWeek.format("YYYY-MM-DD HH:mm:ss"),
        });
      }

      setFilterDateChart([...listFilterDateChart]);

      let lastPrevMonth = moment(today, "YYYY-MM-DD")
        .subtract("week", 4)
        .format("YYYY-MM-DD");
      if (!!assessmentsData?.dataLearningHistory?.week && !!assessmentsData?.timeGetData?.assessmentOnline &&
        moment(new Date()).diff(moment(assessmentsData?.timeGetData?.assessmentOnline), 'hours') < 1
      ) {
        handleDataLearningHistory(assessmentsData?.dataLearningHistory?.week, filter.type, 'week', listFilterDateChart)
      } else {
        getLarningHistoryByTime(
          moment(lastPrevMonth)
            .isoWeekday(1)
            .set({
              hour: today.hours(),
              minute: today.minutes(),
              second: today.seconds(),
            })
            .format("YYYY-MM-DD HH:mm:ss"),
          moment(today)
            .isoWeekday(7)
            .set({
              hour: today.hours(),
              minute: today.minutes(),
              second: today.seconds(),
            })
            .format("YYYY-MM-DD HH:mm:ss"),
          filter.type,
          time,
          listFilterDateChart
        );
      }
    } else if (time === "month") {
      setTextDefaultSelect("Lọc theo tháng");
      let listFilterDateChart = [
        {
          start_time: moment(today)
            .startOf("month")
            .set({
              hour: today.hours(),
              minute: today.minutes(),
              second: today.seconds(),
            })
            .format("YYYY-MM-DD HH:mm:ss"),
          to_time: moment(today)
            .endOf("month")
            .set({
              hour: today.hours(),
              minute: today.minutes(),
              second: today.seconds(),
            })
            .format("YYYY-MM-DD HH:mm:ss"),
        },
      ];
      for (let index = 1; index <= 4; index++) {
        let previewMonth = moment(today, "YYYY-MM-DD").subtract("month", index);
        listFilterDateChart.unshift({
          start_time: moment(previewMonth.format("YYYY-MM-DD"))
            .startOf("month")
            .format("YYYY-MM-DD HH:mm:ss"),
          to_time: moment(previewMonth.format("YYYY-MM-DD"))
            .endOf("month")
            .format("YYYY-MM-DD HH:mm:ss"),
        });
      }
      setFilterDateChart([...listFilterDateChart]);
      let lastPrevMonth = moment(today, "YYYY-MM-DD")
        .subtract("month", 4)
        .format("YYYY-MM-DD");
      if (!!assessmentsData?.dataLearningHistory?.month && !!assessmentsData?.timeGetData?.assessmentOnline &&
        moment(new Date()).diff(moment(assessmentsData?.timeGetData?.assessmentOnline), 'hours') < 1
      ) {
        handleDataLearningHistory(assessmentsData?.dataLearningHistory?.month, filter.type, 'month', listFilterDateChart)
      } else {
        getLarningHistoryByTime(
          moment(lastPrevMonth)
            .startOf("month")
            .set({
              hour: today.hours(),
              minute: today.minutes(),
              second: today.seconds(),
            })
            .format("YYYY-MM-DD HH:mm:ss"),
          moment(today)
            .endOf("month")
            .set({
              hour: today.hours(),
              minute: today.minutes(),
              second: today.seconds(),
            })
            .format("YYYY-MM-DD HH:mm:ss"),
          filter.type,
          time,
          listFilterDateChart
        );
      }
    }
  }

  function getNumberOfWeek(time) {
    const momentTime = moment(time);

    const startOfMonth = momentTime.clone().startOf("month");
    const startOfWeek = startOfMonth.clone().startOf("isoWeek");

    // Calculate the difference in days between the current date and the start of the week within the month
    const diffInDays = momentTime.diff(startOfWeek, "days");

    // Calculate the week of the month for the current month
    let weekOfMonth =
      Math.floor((diffInDays + startOfWeek.isoWeekday()) / 7) + 1;

    // If the week is from the previous month, reset it to the first week of the current month
    if (momentTime.month() != startOfMonth.month()) {
      weekOfMonth = 1;
    }

    const month = momentTime.month() + 1;

    return "Tuần " + weekOfMonth + "/" + month;
  }

  // Handle Change Select Date
  const handleChangeDateSelected = () => {
    dispatch({
      type: scheduleConstants.SET_SELECT_DATE,
      time: moment().format("YYYY-MM-DD"),
    });
    dispatch({
      type: scheduleConstants.SET_DATE_SELECTED_CALENDAR,
      time: moment().format("YYYY-MM-DD"),
    });
  };

  function textPro(number) {
    return (
      <p className='txt-desc'>
        {(() => {
          switch (number) {
            case 0:
              return "Nhập môn";
            case 1:
              return "Cơ bản";
            case 2:
              return "Trung cấp";
            case 3:
              return "Cao cấp";
            case 4:
              return "Lưu loát";
            case 5:
              return "Tinh thông";
            default:
              return "";
          }
        })()}
      </p>
    );
  }

  // Handle Convert Time
  const convertTime = (seconds) => {
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
    const hours = duration?.hours ? `${duration?.hours} giờ` : "";
    const minutes = duration?.minutes ? `${duration?.minutes} phút` : "";
    const second = duration?.seconds ? `${duration?.seconds} giây` : "";

    return `${hours} ${minutes} ${second}`;
  };

  const currentDiamonGetIndex = useMemo(() => {
    if (assessments?.list_medal?.length > 0) {
      return assessments.list_medal.findIndex(
        (it, i) =>
          (Number(it.required_score) <=
            Number(assessments?.data_assessment?.total_diamond) &&
            Number(
              assessments.list_medal[i + 1]?.required_score ||
                assessments.list_medal[assessments.list_medal.length - 1]
                  .required_score
            ) >= Number(assessments?.data_assessment?.total_diamond)) ||
          (Number(assessments.list_medal[i].required_score) <=
            Number(assessments?.data_assessment?.total_diamond) &&
            assessments.list_medal.length - 1 === i)
      );
    }
    return 0;
  }, [assessments]);

  const percentTotalDiamonGet = useMemo(() => {
    if (assessments?.list_medal?.length > 0) {
      if (currentDiamonGetIndex === assessments.list_medal.length - 1) {
        return 100;
      }
      const spaceTwoNearTarget =
        Number(
          assessments.list_medal[currentDiamonGetIndex + 1]?.required_score
        ) -
        Number(assessments.list_medal[currentDiamonGetIndex]?.required_score);
      return (
        ((Number(assessments?.data_assessment?.total_diamond) -
          Number(
            assessments.list_medal[currentDiamonGetIndex]?.required_score
          )) /
          spaceTwoNearTarget) *
        100
      );
    }
    return 0;
  }, [assessments, currentDiamonGetIndex]);

  const upperFirstCase = useCallback(
    (actionName) => {
      if (!actionName) {
        return "";
      }
      const actionReplaceName = actionName.replace("Học ", "");
      return (
        actionReplaceName.charAt(0).toUpperCase() + actionReplaceName.slice(1)
      );
    },
    [learning_by_date]
  );
  const handleReload = () => {
    dispatch(studentActions.getAssessmentsOnline(authentication.id));
    dispatch(
      studentActions.getListLogLearning(
        moment().format("YYYY-MM-DD"),
        authentication.id
      )
    );
    const today = moment();
    let fromTime = `${format(subDays(new Date(), 4), "yyyy-MM-dd HH:mm:ss")}`;
    let toTime = `${moment().format("YYYY-MM-DD HH:mm:ss")}`;
    if (filter.time == 'week') {
      let lastPrevMonth = moment(today, "YYYY-MM-DD")
        .subtract("week", 4)
        .format("YYYY-MM-DD");
      fromTime = moment(lastPrevMonth)
            .isoWeekday(1)
            .set({
              hour: today.hours(),
              minute: today.minutes(),
              second: today.seconds(),
            })
            .format("YYYY-MM-DD HH:mm:ss");
      toTime = moment(today)
        .isoWeekday(7)
        .set({
          hour: today.hours(),
          minute: today.minutes(),
          second: today.seconds(),
        })
        .format("YYYY-MM-DD HH:mm:ss")
    } else if (filter.time == 'month') {
      let lastPrevMonth = moment(today, "YYYY-MM-DD")
      .subtract("month", 4)
      .format("YYYY-MM-DD");
      fromTime = moment(lastPrevMonth)
            .startOf("month")
            .set({
              hour: today.hours(),
              minute: today.minutes(),
              second: today.seconds(),
            })
            .format("YYYY-MM-DD HH:mm:ss");
      toTime = moment(today)
            .endOf("month")
            .set({
              hour: today.hours(),
              minute: today.minutes(),
              second: today.seconds(),
            })
            .format("YYYY-MM-DD HH:mm:ss");
    }
    getLarningHistoryByTime(
      fromTime,
      toTime,
      filter.type,
      filter.time,
      filterDateChart
    );
    dispatch({
      type: studentConstants.GET_TIME_ASSESSMENT_ONLINE,
      payload: new Date()
    })
  }

  let listMedal = _.isEmpty(assessments.list_medal)
    ? DEFAULT_BADGE
    : assessments.list_medal;

  return (
    <div className='sunE-right-container flex-column'>
      <Header title={"Thành tích"} component={<>
        <div className="refresh-curriculum-btn-teacher-header" style={{width: 40, height: 40, marginRight: 8}} onClick={handleReload}>
          <img src="/assets/images/icon_refresh_curriculum.png" style={{width: '100%', height: '100%', borderRadius: '50%'}}/>
        </div>
        </>} />
      <div className='flex-1 flex-m sunE-content thanhtich-container'>
        <SideBarAssessment />
        <div className='thanhtich-box-content width-height-thanhtich-box-content'>
          <div className='thanhtich-online-box scrollbar-custom'>
            <div className='bg-line-op'>
              <div className='text-center'>
                <img
                  src='/assets/images/student/thanhtich/bg_top_tt.png'
                  alt='bg_top_tt'
                  className='bg_top_tt'
                />
              </div>
              <div className='box-skill-process'>
                <div className='box-skill-title flex-m'>
                  <div className='flex-1 ml-15'>
                    <div className='mte-gr rel center-flex'>
                      <Link
                        to={
                          "/" + authentication.role + "/assessment/curriculum/"
                        }
                      >
                        <div className='mte-thumb'>
                          <img
                            src='/assets/images/student/thanhtich/ico_book.png'
                            className='ico_book'
                            alt='images'
                          />
                        </div>
                        <div>
                          <h2>{assessments.number_vocab}</h2>
                          <p>Từ đạt được</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className='mte-gr rel center-flex'>
                    <div className='mte-thumb'>
                      <img
                        src='/assets/images/student/thanhtich/ico_hc.png'
                        className='ico_hc'
                        alt='images'
                      />
                    </div>
                    <div>
                      <h2>{assessments.number_medal}</h2>
                      <p>Huân chương</p>
                    </div>
                  </div>
                </div>
                <div className='skill-process-list'>
                  <div className='skill-process-item flex-m'>
                    <div className='flex-1'>
                      <p className='title'>Grammar</p>
                      <div className='process-100 rel'>
                        <div className='process-thumb'>
                          <img
                            src='/assets/images/student/thanhtich/ico_grammar.png'
                            className='ico_grammar'
                            alt='images'
                          />
                        </div>
                        <div
                          className='process-due'
                          style={{
                            width:
                              (assessments.data_assessment.grammar_score / 10) *
                                100 +
                              "%",
                          }}
                        />
                      </div>
                    </div>
                    <div className='sco rel text-center'>
                      <span className='slp'>
                        {Number.isInteger(
                          Number.parseFloat(
                            assessments.data_assessment.grammar_score
                          )
                        )
                          ? assessments.data_assessment.grammar_score
                          : Number.parseFloat(
                              assessments.data_assessment.grammar_score
                            ).toFixed(1)}
                      </span>
                      {/* {showUpDownScore(
                          Number.isInteger(
                            Number.parseFloat(
                              assessments.data_assessment.reading_score_change
                            )
                          )
                            ? assessments.data_assessment.reading_score_change
                            : Number.parseFloat(
                              assessments.data_assessment.reading_score_change
                            ).toFixed(2)
                        )} */}
                    </div>
                  </div>
                  <div className='skill-process-item flex-m'>
                    <div className='flex-1'>
                      <p className='title'>Reading</p>
                      <div className='process-100 rel'>
                        <div className='process-thumb'>
                          <img
                            src='/assets/images/student/thanhtich/ico_reading.png'
                            className='ico_reading'
                            alt='images'
                          />
                        </div>
                        <div
                          className='process-due'
                          style={{
                            width:
                              (assessments.data_assessment.reading_score / 10) *
                                100 +
                              "%",
                          }}
                        />
                      </div>
                    </div>
                    <div className='sco text-center'>
                      <span className='slp rel'>
                        {Number.isInteger(
                          Number.parseFloat(
                            assessments.data_assessment.reading_score
                          )
                        )
                          ? assessments.data_assessment.reading_score
                          : Number.parseFloat(
                              assessments.data_assessment.reading_score
                            ).toFixed(1)}
                        {/* {showUpDownScore(
                          Number.isInteger(
                            Number.parseFloat(
                              assessments.data_assessment.reading_score_change
                            )
                          )
                            ? assessments.data_assessment.reading_score_change
                            : Number.parseFloat(
                              assessments.data_assessment.reading_score_change
                            ).toFixed(2)
                        )} */}
                      </span>
                    </div>
                  </div>
                  <div className='skill-process-item flex-m'>
                    <div className='flex-1'>
                      <p className='title'>Speaking</p>
                      <div className='process-100 rel'>
                        <div className='process-thumb'>
                          <img
                            src='/assets/images/student/thanhtich/ico_speaking.png'
                            className='ico_speaking'
                            alt='images'
                          />
                        </div>
                        <div
                          className='process-due'
                          style={{
                            width:
                              (assessments.data_assessment.speaking_score /
                                10) *
                                100 +
                              "%",
                          }}
                        />
                      </div>
                    </div>
                    <div className='sco text-center'>
                      <span className='slp rel'>
                        {Number.isInteger(
                          Number.parseFloat(
                            assessments.data_assessment.speaking_score
                          )
                        )
                          ? assessments.data_assessment.speaking_score
                          : Number.parseFloat(
                              assessments.data_assessment.speaking_score
                            ).toFixed(1)}
                        {/* {showUpDownScore(
                          Number.isInteger(
                            Number.parseFloat(
                              assessments.data_assessment.speaking_score_change
                            )
                          )
                            ? assessments.data_assessment.speaking_score_change
                            : Number.parseFloat(
                              assessments.data_assessment
                                .speaking_score_change
                            ).toFixed(2)
                        )} */}
                      </span>
                    </div>
                  </div>
                  <div className='skill-process-item flex-m'>
                    <div className='flex-1'>
                      <p className='title'>Listening</p>
                      <div className='process-100 rel'>
                        <div className='process-thumb'>
                          <img
                            src='/assets/images/student/thanhtich/ico_listening.png'
                            className='ico_listening'
                            alt='images'
                          />
                        </div>
                        <div
                          className='process-due'
                          style={{
                            width:
                              (assessments.data_assessment.listening_score /
                                10) *
                                100 +
                              "%",
                          }}
                        />
                      </div>
                    </div>
                    <div className='sco text-center'>
                      <span className='slp rel'>
                        {Number.isInteger(
                          Number.parseFloat(
                            assessments.data_assessment.listening_score
                          )
                        )
                          ? assessments.data_assessment.listening_score
                          : Number.parseFloat(
                              assessments.data_assessment.listening_score
                            ).toFixed(1)}
                        {/* {showUpDownScore(
                          assessments.data_assessment.listening_score_change
                        )} */}
                      </span>
                    </div>
                  </div>
                  <div className='skill-process-item flex-m'>
                    <div className='flex-1'>
                      <p className='title'>Writing</p>
                      <div className='process-100 rel'>
                        <div className='process-thumb'>
                          <img
                            src='/assets/images/student/thanhtich/ico_writing.png'
                            className='ico_writing'
                            alt='images'
                          />
                        </div>
                        <div
                          className='process-due'
                          style={{
                            width:
                              (assessments.data_assessment.writing_score / 10) *
                                100 +
                              "%",
                          }}
                        />
                      </div>
                    </div>
                    <div className='sco text-center'>
                      <span className='slp rel'>
                        {Number.isInteger(
                          Number.parseFloat(
                            assessments.data_assessment.writing_score
                          )
                        )
                          ? assessments.data_assessment.writing_score
                          : Number.parseFloat(
                              assessments.data_assessment.writing_score
                            ).toFixed(1)}
                        {/* {showUpDownScore(
                          Number.isInteger(
                            Number.parseFloat(
                              assessments.data_assessment.writing_score_change
                            )
                          )
                            ? assessments.data_assessment.writing_score_change
                            : Number.parseFloat(
                              assessments.data_assessment.writing_score_change
                            ).toFixed(2)
                        )} */}
                      </span>
                    </div>
                  </div>
                  {/* <div className="skill-process-item flex-m">
                    <div className="flex-1">
                      <p className="title">Điểm thi</p>
                      <div className="process-100 rel">
                        <div className="process-thumb">
                          <img
                            src="/assets/images/student/thanhtich/ico_diemthi.png"
                            className="ico_diemthi"
                            alt="images"
                          />
                        </div>
                        <div
                          className="process-due"
                          style={{
                            width:
                              (assessments.data_assessment.test_score / 10) *
                                100 +
                              "%",
                          }}
                        />
                      </div>
                    </div>
                    <div className="sco text-center">
                      <span className="slp rel">
                        {Number.isInteger(
                          Number.parseFloat(
                            assessments.data_assessment.test_score
                          )
                        )
                          ? assessments.data_assessment.test_score
                          : Number.parseFloat(
                              assessments.data_assessment.test_score
                            ).toFixed(1)}
                        {/* {showUpDownScore(
                          Number.isInteger(
                            Number.parseFloat(
                              assessments.data_assessment.test_score_change
                            )
                          )
                            ? assessments.data_assessment.test_score_change
                            : Number.parseFloat(
                              assessments.data_assessment.test_score_change
                            ).toFixed(2)
                        )} *
                      </span>
                    </div>
                  </div> */}
                </div>
              </div>
              <div className='box-diem-gr flex-m'>
                <div className='flex-1'>
                  <div className='box-diem'>
                    <div className='top-o center-flex'>
                      <p>
                        Điểm học tập
                        <br />
                        trung bình
                      </p>
                    </div>
                    <h2>
                      {parseFloat(
                        parseFloat(
                          assessments.data_assessment.learning_score
                        ).toFixed(1)
                      ) || 0}
                    </h2>
                  </div>
                </div>
                <div className='flex-1'>
                  <div className='box-diem'>
                    <div className='top-o center-flex'>
                      <p>
                        Điểm thi
                        <br />
                        trung bình
                      </p>
                    </div>
                    <h2>
                      {parseFloat(
                        parseFloat(
                          assessments.data_assessment.test_score
                        ).toFixed(1)
                      ) || 0}
                    </h2>
                  </div>
                </div>
              </div>
              <div className='box-danhhieu-process'>
                <div className='top-t'>
                  <p>DANH HIỆU</p>
                </div>
                <div className='text-center center-flex'>
                  <span
                    style={{ marginRight: 5, fontSize: 18, fontWeight: "bold" }}
                  >
                    {"Số kim cương hiện tại:  " +
                      assessments?.data_assessment?.total_diamond}
                  </span>
                  <img
                    src='/assets/images/student/thanhtich/ico_dimo.png'
                    alt='ico_dimo'
                  />
                </div>
                {/* <div className="center-flex" style={{marginTop: 20}}>
                  <div className="process-ve">
                    <div className="center-flex">
                      <div className="process-k">
                        {listMedal.map((data, i) => {
                          return (
                            <Fragment key={i}>
                              <div style={{position: "relative"}}>
                                {Number(assessments?.data_assessment?.total_diamond) >= Number(data.required_score) && Number(assessments?.data_assessment?.total_diamond) <= Number(listMedal[i + 1]?.required_score || listMedal[listMedal.length-1]?.required_score) &&  
                                  <img style={{position: 'absolute', top: -20, left: 3}} src="/assets/images/thanhtich/down.png"/>
                                }
                                <div className={"circle-k" + (Number(assessments?.data_assessment?.total_diamond) >= Number(data.required_score) ? " active" : "")}></div>
                              </div>
                              {i < listMedal.length - 1 ? (
                                <div className="line-k" style={{ zIndex: listMedal.length - i}}>
                                  <div
                                    className={
                                      (currentDiamonGetIndex) > i &&
                                      `process-line`
                                    }
                                    style={{
                                      width: `calc(${percentTotalDiamonGet + "%"} + 22px)`,
                                    }}
                                  />
                                </div>
                              ) : null}
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                    <div className="process-info-k">
                      {(_.isEmpty(assessments.list_medal) ? DEFAULT_BADGE : assessments.list_medal).map((data, i) => {
                        return (
                          <div className="space" key={i}>
                            <p>{data.name}</p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div> */}
                <div className='process-hz flex-m'>
                  <div className='flex-1'>
                    <div className='top-h'>
                      <p>&nbsp;</p>
                    </div>

                    <div className='process-h'>
                      {assessments.list_medal.map((data, i) => {
                        let height = 0;
                        temp_required_score = data.required_score;
                        if (assessments.list_medal[i + 1]) {
                          if (
                            total_diamond >=
                            assessments.list_medal[i + 1].required_score
                          ) {
                            height = 100;
                          } else {
                            if (total_diamond > temp_required_score) {
                              height =
                                (total_diamond /
                                  assessments.list_medal[i + 1]
                                    .required_score) *
                                100;
                            } else {
                              height = 0;
                            }
                          }
                        }

                        return (
                          <Fragment key={i}>
                            <div
                              className={
                                "rel" +
                                (Number(
                                  assessments?.data_assessment?.total_diamond
                                ) >= Number(data.required_score)
                                  ? " active"
                                  : "")
                              }
                            >
                              <div
                                className={
                                  "circle-k" +
                                  (Number(
                                    assessments?.data_assessment?.total_diamond
                                  ) >= Number(data.required_score)
                                    ? " active"
                                    : "")
                                }
                              ></div>
                              {textPro(i)}
                            </div>
                            {i < assessments.list_medal.length - 1 ? (
                              <div
                                className='line-k'
                                style={{
                                  zIndex: listMedal.length - i,
                                  display: "flex",
                                }}
                              >
                                <div
                                  className={
                                    currentDiamonGetIndex >= i
                                      ? `process-line`
                                      : ""
                                  }
                                  style={{
                                    height:
                                      (currentDiamonGetIndex === i
                                        ? percentTotalDiamonGet
                                        : 100) + "%",
                                  }}
                                />
                              </div>
                            ) : null}
                          </Fragment>
                        );
                      })}
                    </div>
                  </div>
                  <div className='flex-1'>
                    <div className='top-h'>
                      <p className='text-right'>Danh hiệu</p>
                    </div>
                    <div className='process-info-h'>
                      {assessments.list_medal.map((data, i) => {
                        return (
                          <div
                            className={
                              "space flex-m" +
                              (Number(
                                assessments?.data_assessment?.total_diamond
                              ) >= Number(data.required_score)
                                ? " active"
                                : "")
                            }
                            key={i}
                          >
                            <div className='flex-1' />
                            <div className='flex-m scoe'>
                              <p>{data.required_score}</p>
                              <div>
                                <img
                                  src='/assets/images/student/thanhtich/ico_dimo.png'
                                  alt='ico_dimo'
                                />
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className='box-nhatkyhoctap-graph'>
                <div className='top-t flex-m box-s select-chart'>
                  <div className='flex-1'>
                    <p>NHẬT KÝ HỌC TẬP</p>
                  </div>
                  <SelectAsDiv
                    keyName={"name"}
                    textDefault={textDefaultSelect}
                    data={studentConstants.TEXT_LIST_FILTER_DAY}
                    onChangeSelect={(key) => onChangeSelect(key)}
                    ico_filter={
                      "./../assets/images/student/thanhtich/ico_search.png"
                    }
                    className={"filter-box-nhatkyhoctap-graph"}
                    fix_text_cut_chart
                  />
                </div>
                <div className='bangdiem-menu-gr flex-m'>
                  <div
                    className={
                      "flex-1 text-center btn-gr-menu" +
                      (filter.type === "lesson" ? " active" : "")
                    }
                    onClick={() => changeFilter("lesson")}
                  >
                    <span className='active'>
                      Số bài
                      <br />
                      hoàn thành
                    </span>
                    <div className='line-active' />
                  </div>
                  <div
                    className={
                      "flex-1 text-center btn-gr-menu" +
                      (filter.type === "diamond" ? " active" : "")
                    }
                    onClick={() => changeFilter("diamond")}
                  >
                    <span>
                      Số
                      <br />
                      kim cương
                    </span>
                    <div className='line-active' />
                  </div>
                  <div
                    className={
                      "flex-1 text-center btn-gr-menu" +
                      (filter.type === "duration" ? " active" : "")
                    }
                    onClick={() => changeFilter("duration")}
                  >
                    <span>
                      Thời gian
                      <br />
                      học tập
                    </span>
                    <div className='line-active' />
                  </div>
                </div>
                {filter.type === "duration" && (
                  <p className='mr-5'>Đơn vị: Phút</p>
                )}
                <div className='box-graph-content'>
                  <Line data={dataChart} plugins={[ChartDataLabels]} options={options} />
                </div>
              </div>
              <div className='box-nhatkyhoatdong'>
                <div className='top-t flex-m box-s'>
                  <div className='flex-1'>
                    <p>NHẬT KÝ HOẠT ĐỘNG</p>
                  </div>
                  <Link
                    to={"/" + authentication.role + "/assessment/learning"}
                    onClick={() => handleChangeDateSelected()}
                  >
                    <span className='rel'>Chi tiết</span>
                  </Link>
                </div>
                <div className='his-hd scrollbar-custom'>
                  <h2 className='b-26-black'>
                    {moment().format("DD/MM/YYYY")}
                  </h2>
                  {learning_by_date?.length > 0 ? (
                    learning_by_date.map((data_learning_by_date) => {
                      return data_learning_by_date.data_log.map((data, i) => {
                        return (
                          <>
                            <div className='flex-m his-item' key={i}>
                              <p className='hours'>
                                {moment(data.time).format("HH:mm")}
                              </p>
                              <div className='flex-1'>
                                <p className='bold'>{data.lesson_name}</p>
                                <p className='time-hs white-space-nowrap'>
                                  {data.curriculum_name}
                                </p>
                                <p
                                  className='unit'
                                  style={{ wordBreak: "break-word" }}
                                >
                                  {upperFirstCase(data.unit_name)}&nbsp;-&nbsp;
                                  {upperFirstCase(
                                    data?.skill?.replace("_", " ")?.capitalize()
                                  )}
                                </p>
                                <p className='time-hs white-space-nowrap'>
                                  Thời gian học: {convertTime(data.duration)}
                                </p>
                                {data.status == 1 || data.score > 0 ? (
                                  <p className='scor white-space-nowrap font-family-bold'>
                                    Điểm số:{" "}
                                    {parseFloat(
                                      parseFloat(data.score).toFixed(1)
                                    )}
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          </>
                        );
                      });
                    })
                  ) : (
                    <span>Bạn chưa có hoạt động gì cho ngày hôm nay.</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export { HomePageAssessment };
