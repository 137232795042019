import { useEffect, useState } from "react";

export const feePolicyPageLogic = (props) => {
  // Data Rule 4 Page in Fee Policy
  const [dataRule4, setDataRule4] = useState({
    nameRule: "CHÍNH SÁCH ĐỔI, TRẢ HÀNG VÀ HOÀN TIỀN",
    rule_id: 4,
    data: [
      {
        id: 1,
        content:
          "- Khi khách hàng đăng ký mua một khóa học tiếng Anh bất kỳ trên hệ thống Sunday English, có nghĩa rằng khách hàng đã tìm hiểu kỹ lưỡng về toàn bộ sản phẩm trước khi mua và sử dụng. Sunday English chịu trách nhiệm đảm bảo các cam kết dịch vụ đã nêu ra và sẽ làm hết khả năng để khắc phục trong những trường hợp bất khả kháng.",
      },
      {
        id: 2,
        content:
          "- Sunday English không có trách nhiệm hoàn trả học phí mà khách hàng đã đăng ký.",
      },
    ],
  });

  // useEffect(() => {
  //   window.scrollTo({ top: 0 });
  // }, []);

  // Data policy Payment
  // const dataPolicyPayment = {
  //   nameRule: "HƯỚNG DẪN THANH TOÁN",

  //   data1: {
  //     title:
  //       "Sau khi chọn gói học muốn mua tại mục “Học phí”, khách hàng tiến hành thanh toán bằng cách chọn một trong các hình thức thanh toán sau:",
  //     data: [
  //       {
  //         id: 1,
  //         title: "1. Thanh toán trực tuyến (OnePay) ",
  //         list_1: [
  //           {
  //             id: 1,
  //             content:
  //               "Khách hàng có thể sử dụng hình thức thanh toán trực tuyến với hầu hết các loại thẻ tín dụng và thẻ ATM do các ngân hàng nội địa phát hành. Trước khi tiến hành lựa chọn hình thức thanh toán trực tuyến, khách hàng phải kiểm tra kĩ lại các thông tin về đơn hàng, thông tin liên lạc, số tiền cần thanh toán. Sau đó tiến hành thanh toán trực tuyến. Khách hàng sẽ nhận mã kích hoạt (qua email) khi giao dịch thành công.",
  //           },
  //           {
  //             id: 2,
  //             content_note: "LƯU Ý:",
  //             content_note_1:
  //               "Sau khi hoàn tất các bước thanh toán theo hướng dẫn, nếu sau quá 30 phút khách hàng không nhận được bất cứ thông báo nào, khách hàng vui lòng liên hệ với GK Corp để nhận được kết quả giao dịch. Khách hàng không nên thực hiện tiếp giao dịch để tránh trường hợp bị trừ tiền thêm một lần.",
  //           },
  //         ],
  //       },
  //       {
  //         id: 2,
  //         title:
  //           "2. Thanh toán trong ứng dụng bằng thẻ tín dụng đăng ký với Apple/ Google (In-App Purchase)",
  //         list_1: [
  //           {
  //             id: 1,
  //             content:
  //               "Với hình thức giao dịch này, khách hàng thực hiện được qua app Sunday English, Sunday Teacher hoặc Sunday Parent. Khách hàng sẽ nhận mã kích hoạt (qua email) khi giao dịch thành công. ",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // };
  const dataPolicyPayment = {
    nameRule: "HƯỚNG DẪN THANH TOÁN",

    data1: {
      title:
        "Để thanh toán các gói học trong Sunday English và Sunday Teacher, bạn có thể thực hiện theo hướng dẫn sau đây.",
      data: [
        {
          id: 1,
          title: "1. Các hình thức thanh toán",
          list_1: [
            {
              id: 1,
              content:
                "Để tiến hành thanh toán, khách hàng vui lòng chọn gói học muốn mua tại mục “Bảng giá” và chọn một trong các hình thức thanh toán sau:",
            },
            {
              id: 2,
              content_bold: "a. Thanh toán trực tuyến (OnePay)",
              lists_1: [
                {
                  id: 1,
                  content:
                    "Khách hàng có thể sử dụng hình thức thanh toán trực tuyến với hầu hết các loại thẻ tín dụng và thẻ ATM do các ngân hàng nội địa phát hành. Trước khi tiến hành lựa chọn hình thức thanh toán trực tuyến, khách hàng phải kiểm tra kĩ lại các thông tin về đơn hàng, thông tin liên lạc, số tiền cần thanh toán. Sau đó tiến hành thanh toán trực tuyến. Khách hàng sẽ nhận mã kích hoạt (qua email) khi giao dịch thành công.",
                },
                {
                  id: 2,
                  content_note: "LƯU Ý:",
                  content_note_1:
                    "Sau khi hoàn tất các bước thanh toán theo hướng dẫn, nếu sau quá 30 phút khách hàng không nhận được bất cứ thông báo nào, khách hàng vui lòng liên hệ với GK Corp để nhận được kết quả giao dịch. Khách hàng không nên thực hiện tiếp giao dịch để tránh trường hợp bị trừ tiền thêm một lần.",
                },
              ],
            },
            {
              id: 3,
              content_bold: "b. Thanh toán trong ứng dụng bằng thẻ tín dụng đăng ký với Apple/ Google (In-app purchase):",
              lists_1: [
                {
                  id: 1,
                  content:
                    "Với hình thức giao dịch này, khách hàng thực hiện được qua app Sunday English hoặc Sunday Teacher. Khách hàng sẽ nhận mã kích hoạt (qua email) khi giao dịch thành công.",
                },
                {
                  id: 2,
                  content_note: "LƯU Ý:",
                  content_note_1:
                    "Sau khi thực hiện giao dịch qua app như trên nếu không nhận được thông báo hay không sử dụng được ứng dụng, khách hàng vui lòng liên hệ tới tổng đài 024 6281 3888 hoặc gửi phản hồi về mail: <a href=\"mailto:support@sundayenglish.com\">support@sundayenglish.com</a> để được hỗ trợ tốt nhất.",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          title: "2. Thay đổi hình thức thanh toán",
          list_1: [
            {
              id: 1,
              content:
                "Sau khi xác nhận đơn hàng cũng như hình thức thanh toán gói học, nếu có nhu cầu chuyển đổi hình thức thanh toán, khách hàng có thể thực hiện bằng 2 cách:",
            },
            {
              id: 2,
              content: "- Cách 1: Liên hệ trực tiếp tới tổng đài 024 6281 3888",
            },
            {
              id: 3,
              content: "- Cách 2: Gửi phản hồi về email: <a href=\"mailto:support@sundayenglish.com\">support@sundayenglish.com</a>.",
            },
          ],
        },
      ],
    },

    data2: {
      title: "Lưu ý: ",
      data: [
        {
          id: 1,
          content:
            "Sau khi thanh toán thành công, quý khách vui lòng chụp lại màn hình thanh toán và liên hệ tổng đài 024 6281 3888 để được xác nhận thanh toán. ",
        },
        {
          id: 1,
          content:
            "Bộ sản phẩm sẽ được chuyển đến địa chỉ nhận hàng trong thời gian sớm nhất, chậm nhất 5 ngày kể từ thời điểm hoàn tất giao dịch. ",
        },
      ],
    },
  };

  // Scroll
  useEffect(() => {
    let hash = window.location.hash;
    if (hash) {
      var ids = hash.split("#");
      for (var i = 1; i < ids.length; i++) {
        var element = document.getElementById(ids[i]);
        // console.log(element);
        if (element) {
          element.scrollIntoView({ behavior: "instant" });
        }
      }
    } else {
      window.scrollTo({ top: 0, behavior: "instant" });
    }
  }, [window.location]);

  return { dataRule4, dataPolicyPayment };
};
