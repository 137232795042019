import { useState, useEffect } from "react";
import {policy} from '../../_constants/policy'

export const policyPageV2Logic = () => {
  let currentUrl = window.location.href;

  // Init Vartiable page selected
  const [pageSelected, setPageSelected] = useState(1);
  const [policySelected, setPolicySelected] = useState(policy.term)
  const [categorySelected, setCategorySelected] = useState(policy.term.data[0])
  const [isShowAds, setIsShowAds] = useState(false)
  const [positionLeftContent, setPositionLeftContent] = useState(0)

  // Data Pagination
  const [dataPagination, setDataPagination] = useState([
    {
      id: policy.term.id,
      number: 1,
    },
    {
      id: policy.security.id,
      number: 2,
    },
    {
      id: policy.license.id,
      number: 3,
    },
    {
      id: policy.payment.id,
      number: 4,
    }
  ]);

  // Handle Click Page
  const handleClickPage = (item) => {
    const newPolicy = policy[item.id]
    setPolicySelected(newPolicy)
    setCategorySelected(newPolicy.data[0])
    setPageSelected(item?.number);
    window.scrollTo({top: 0, behavior: 'instant'})
  };

  const handleChangeCategory = (category) => {
    setCategorySelected(category)
    document.getElementById(category?.id).scrollIntoView({
      behavior: 'smooth'
    });
  }

  useEffect(() => {
    const handleScroll = () =>{
      let category = {...categorySelected}
      const currentScrollPosition = window.scrollY;
      if(Number.isInteger(currentScrollPosition)) {
        policySelected.data.forEach(item => {
          const elementOffsetTop = document.getElementById(item?.id)?.getBoundingClientRect()?.top + window.scrollY
          if (elementOffsetTop && (currentScrollPosition + 90 > elementOffsetTop)){
            category = {...item}
          }
        }) 
        setCategorySelected(category)
      }
      const firstElementOffsetTop = document.getElementById(policySelected.data[0].id)?.getBoundingClientRect()?.top + window.scrollY
      if(firstElementOffsetTop && currentScrollPosition > firstElementOffsetTop) {
        setIsShowAds(true)
      } else {
        setIsShowAds(false)
      }
    }
    window.addEventListener('scroll', handleScroll)
  }, [pageSelected])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "instant" });
    const ele = document.getElementById(policySelected.id)
    if(ele) {
      setPositionLeftContent(ele.getBoundingClientRect().left + window.scrollX + 'px')
    }
  }, [window.location.hash])
  
  useEffect(() => {
    const getCurrentPage = () => {
      switch (true) {
        case currentUrl.includes("term"):
          setPolicySelected(policy.term)
          setCategorySelected(policy.term.data[0])
          return 1;
        case currentUrl.includes("security"):
          setPolicySelected(policy.security)
          setCategorySelected(policy.security.data[0])
          return 2;
        case currentUrl.includes("license"):
          setPolicySelected(policy.license)
          setCategorySelected(policy.license.data[0])
          return 3;
        case currentUrl.includes("payment"):
          setPolicySelected(policy.payment)
          setCategorySelected(policy.payment.data[0])
          return 4;
        default:
          setPolicySelected(policy.term)
          setCategorySelected(policy.term.data[0])
          return 1;
      }
    };

    setPageSelected(getCurrentPage());
  }, [currentUrl]);

  return {
    pageSelected,
    dataPagination,
    handleClickPage,
    setDataPagination,
    policySelected,
    handleChangeCategory,
    categorySelected,
    isShowAds,
    positionLeftContent
  };
};
