import { useEffect, useState } from 'react';
import RootCheckBox from '../RootCheckBox';
import './style.css';
import moment from 'moment';
import { apiCaller, history } from '../../_helpers';
import InputDate from '../Auth/InputDate';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { alertActions } from '../../_actions';
import { configConstants, getListYearFilterLessonTopic, LIST_TYPE_ESSAY, teacherConstants } from '../../_constants';
import InputText from '../Auth/InputText';
import { SelectAsDiv } from '../Select';

export default function PopupFilterLessonTopic({ open = false, onClose, onSubmit, typeEssayFilter, provinceIdFilter, yearFilter, isFiltered = false, setIsFiltered}) {
  if(!open) {
    return null;
  }
  const [listProvince, setListProvince] = useState([])
  const [typeEssay, setTypeEssay] = useState(typeEssayFilter)
  const [provinceIdSelected, setProvinceIdSelected] = useState(provinceIdFilter)
  const [yearSelected, setYearSelected] = useState(yearFilter)


  const handleSubmit = async () => {
    if(!validateParams()) {
      return;
    }
    setIsFiltered && setIsFiltered(true)
    onSubmit && onSubmit(typeEssay,provinceIdSelected,yearSelected);
    onClose();
  }

  const handleDeleteFilter = () => {
    if(!validateParams()) {
      return;
    }
    setTypeEssay('');
    setProvinceIdSelected('');
    setYearSelected('');
    setIsFiltered && setIsFiltered(false)
    // onSubmit && onSubmit('','','');
    // onClose();
  }

  const validateParams = () => {
    if (isFiltered) {
      return true;
    }
    return !!typeEssay || !!provinceIdSelected || !!yearSelected
  }

  const getProvinceList = () => {
    apiCaller(
      "/web_advertise/api_lecture/list_address",
      "GET",
    ).then((res) => {
      if (res.status) {
        const listData = res.list_address?.map(item => ({
          id: item?.id,
          name: item?.address,
        }));
        setListProvince([{id: '', name: 'Tất cả'}, ...listData]);
      }
    });
  };

  useEffect(() => {
      getProvinceList()
  }, [])

  return (
    <div id="modal-center" className="uk-flex-top uk-modal uk-flex uk-open filter-lesson-topic-modal" uk-modal="">
      <div className="uk-modal-dialog uk-modal-body uk-margin-auto-vertical" 
        style={{ 
          width: 600, 
          height: '80vh',
          paddingLeft: 0, 
          paddingRight: 0, 
          display: 'flex', 
          flexDirection: 'column' 
        }}
      >
        <div className='filter-lesson-topic-header'>
          <span>Lọc bài</span>
          <div onClick={onClose} className="pointer_cursor">
            <img
              src="/assets/images/icon/ico_close_popup.png"
              alt="Icon Delete"
              className="contain_image img_delete_modal"
            />
          </div>
        </div>
        <div className='filter-lesson-topic-body scrollbar-custom'>
          <div>
            <label className='filter-lesson-topic-label'>Chọn thành phần đề thi</label>
            <div style={{marginTop: 4}}>
              <SelectAsDiv
                keySelected={typeEssay}
                textDefault={"Tất cả"}
                data={LIST_TYPE_ESSAY}
                onChangeSelect={(key) => setTypeEssay(key)}
                styleBox={{
                  paddingLeft: 24,
                  zIndex: 10,
                }}
                styleOption={{
                  zIndex: 9,
                }}
              />
            </div>
          </div>
          <div>
            <label className='filter-lesson-topic-label'>Chọn năm</label>
            <div style={{marginTop: 4}}>
              <SelectAsDiv
                keySelected={yearSelected}
                textDefault={"Tất cả"}
                data={getListYearFilterLessonTopic()}
                onChangeSelect={(key) => setYearSelected(key)}
                styleBox={{
                  paddingLeft: 24,
                  zIndex: 8
                }}
                styleOption={{
                  maxHeight: '40vh',
                  zIndex: 7
                }}
              />
            </div>
          </div>
          <div>
            <label className='filter-lesson-topic-label'>Chọn tỉnh/thành phố</label>
            <div style={{marginTop: 4}}>
              <SelectAsDiv
                keySelected={provinceIdSelected}
                textDefault={"Tất cả"}
                data={listProvince}
                onChangeSelect={(key) => setProvinceIdSelected(key)}
                styleBox={{
                  paddingLeft: 24,
                  zIndex: 6
                }}
                styleOption={{
                  maxHeight: '32vh',
                  zIndex: 5
                }}
              />
            </div>
          </div>
        </div>
        <div className='filter-lesson-topic-action'>
          <button className={"btn-default mr-20 " + (!validateParams() ? 'btn-disable' : '')} style={{width: 'unset'}} onClick={handleDeleteFilter}>Bỏ lọc</button>
          <button 
            disabled={!validateParams()} 
            className={"btn-bg-blue btn-default " + (!validateParams() ? 'btn-disable' : '')} 
            onClick={handleSubmit} 
          >
            Lọc
          </button>
        </div>
      </div>
    </div>
  )
}