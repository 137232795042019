import "./PolicyPageV2.style.scss";
import { HeaderNews } from "../../_components/Header";
import {
  TypeHeaderNewsItem,
} from "../../_constants/headerNews";
import FooterNews from "../../_components/FooterNews/FooterNews";
import Pagination from "../../_components/Pagination/Pagination";
import ScrollFixed from "../../_components/ScrollFixed/ScrollFixed";
import LazyLoad from "react-lazyload";
import { policyPageV2Logic } from "./PolicyPageV2.logic";
import { renderContentPolicy, renderListPage1, renderListPolicy } from "../../_base/DetailListNews";
import { Fragment } from "react";

const PolicyPageV2 = (props) => {
  let { 
    pageSelected, 
    dataPagination, 
    handleClickPage, 
    policySelected, 
    handleChangeCategory, 
    categorySelected, 
    isShowAds,
    positionLeftContent
  } 
    = policyPageV2Logic();

    const renderPolicyName = () => {
      let uriImg = 'ico_rule_1';
      let ordinal = 'I'
      switch (pageSelected) {
        case 1: 
          uriImg = 'ico_rule_1';
          ordinal = 'I';
          break;
        case 2:
          uriImg = 'ico_rule_2';
          ordinal = 'II';
          break;
        case 3: 
          uriImg = 'ico_rule_3';
          ordinal = 'III';
          break;
        case 4: 
          uriImg = 'ico_rule_4';
          ordinal = 'IV';
          break;
        default: 
          break;
      }
      return (
        <div className="flex-align bg_white pd-hor-11-mobile">
          <img
            src={`/assets/images/introNews/icon/${uriImg}.png`}
            alt="Icon policy"
            className="contain_image img_policy"
          />
          <span
            id="rule1"
            className="font_news_bold fz-1-mobile fz-11 mg-left-0-5"
          >
            {`${ordinal}. ${policySelected?.name}`}
          </span>
        </div>
      )
    }

  const renderPage = () => {
    return (
      <div id={policySelected.id} className="content_policy">
          <div className="content_1">
            <div className="content_rules mg-top-25">
              <div className="flex-column">
                {renderPolicyName()}
                <LazyLoad className="text-align-justify ">
                  {!!policySelected?.contents?.length && policySelected.contents.map((item) => (
                    <div key={item.id} className="pd-left-05-pc pd-top-05-pc pd-hor-11-mobile bg_white first-contents">
                      {renderContentPolicy(item)}
                    </div>
                  ))}
                  {policySelected.data?.map((data, index) => {
                    return (
                      <Fragment key={data.id}>
                        {renderListPolicy(data)}
                        {index + 1 === Math.ceil(policySelected.data?.length / 2) && <div className="transparent_side"></div>}
                      </Fragment>
                    )
                  })}
                </LazyLoad>
              </div>
            </div>
          </div>
        </div>
    )
  };

  const renderColumnPolicy = (category) => {
    return (
      <div
        onClick={() => handleChangeCategory(category)}
        key={category.id}
        className={`pointer_cursor category_item pos_rel ${
          categorySelected.id === category.id
            ? " category_item--selected "
            : ""
        }`}
      >
        <div className="category_item-title">{category.title}</div>

        {categorySelected.id === category.id ? (
          <div className="border--selected"></div>
        ) : null}
      </div>
    );
  };
  
  return (
    <div className="policyPage commondPage">
      <HeaderNews type={TypeHeaderNewsItem.INTRODUCTION} />
      <LazyLoad className="bg_white" style={{position: 'relative'}}>
        <img
          src="/assets/images/introNews/intro_banner.png"
          alt="Slider Bg"
          className="slider_bg contain_image display_none_mobile zIndex5"
        />
        <div className="policy-name-absolute display_none_mobile">
          <p>ĐIỀU KHOẢN</p>
          <p>VÀ CHÍNH SÁCH DỊCH VỤ</p>
          </div>
      </LazyLoad>
      <LazyLoad className="bg_white">
        <img
          src="/assets/images/introNews/intro_banner_mobile.png"
          alt="Slider Bg"
          className="slider_bg contain_image hide display_flex_mobile zIndex5"
        />
      </LazyLoad>
      <div
        className="hide display_flex_mobile fz-12-5-mobile pd-hor-11-mobile pd-ver-11-mobile bg-white pd-bottom-1 color-primary-green fz-14-mobile fz-16 flex-column-mobile"
      >
        <span className="fz-14-mobile color-primary-green font_news_bold fz-20 col-80-mobile">
          ĐIỀU KHOẢN VÀ CHÍNH SÁCH DỊCH VỤ
        </span>{" "}
      </div>
      <div className="content zIndex5 pos_rel">
       <div className="d-flex">
          <div className="content_categories border_1_light bg_white box-shadow display_none_mobile">
            <h2 className="font_news_bold color-primary-green content_categories-header">
              {policySelected.name}
            </h2>
            <div className="categories_list display_none_mobile">
              {policySelected.data.map((category) => renderColumnPolicy(category))}
            </div>
          </div>
          {renderPage()}
       </div>
        <Pagination
          pageSelected={pageSelected}
          dataPagination={dataPagination}
          handleClickPage={handleClickPage}
          isPickId
        />
      </div>
      {isShowAds && <LazyLoad
            className="bg_side display_none_mobile"
            style={{
              background: `url(
                  /assets/images/banner_contact.png
                ) no-repeat top center /contain`,
              // paddingTop: "50%",
              backgroundAttachment: "scroll",
              left: positionLeftContent
            }}
          ></LazyLoad>}
      <LazyLoad
        className="bg_side hide display_flex_mobile"
        style={{
          background: `url(
              /assets/images/banner_contact_mobile.png
            ) no-repeat top center /contain`,
          // paddingTop: "50%",
          backgroundAttachment: "scroll",
        }}
      ></LazyLoad>
      <FooterNews />
      <ScrollFixed />
    </div>
  );
};

export default PolicyPageV2;
