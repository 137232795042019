import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { userConstants } from '../../../_constants';

function EmptyClassBlock({messagEmpty}) {
    const authentication = useSelector(state => state.authentication);

    return (
        <Fragment>
            {
                authentication.role === userConstants.ROLE_TEACHER ?
                    <div className="sunE-no-class text-center flex-1">
                        <img src="/assets/images/img_no_class_big.png" alt="img_no_class_big" />
                        {messagEmpty ? <p className="text-light">
                            {messagEmpty}
                            </p> :
                            <p className="text-light">Bạn chưa có lớp học nào, hãy ấn <span className="bold">“TẠO LỚP MỚI”</span> để bắt đầu.</p>
                        }
                    </div>
                    :
                    <div className="sunE-no-class text-center flex-1">
                        <img src="/assets/images/img_no_class_big.png" alt="img_no_class_big" />
                        {messagEmpty ? <p className="text-light">
                            {messagEmpty}
                            </p> :
                            <p className="text-light">Bạn chưa có lớp học nào, hãy ấn <span className="bold">“Xin vào lớp mới”</span> để bắt đầu.</p>
                        }
                    </div>
            }
        </Fragment>
    );
}

export { EmptyClassBlock };