import React, { useEffect, useState } from 'react';
import { Header } from '../../_components/Admin/Header';
import { studentService } from '../../_services';
import { isEmpty } from 'lodash';
import { useParams } from "react-router-dom";

function HomePageIngredientScore() {
    const { id } = useParams();
    const [statusHk1, setStatusHk1] = useState(true);
    const [statusHk2, setStatusHk2] = useState(false);
    const [statusYear, setStatusYear] = useState(false);
    const [dataFilter, setDataFilter] = useState({});
    const [dataScore, setDataScore] = useState({});


    useEffect(() => {
        studentService.getOfflineAssessment().then(
            (infoScore) => {
                setDataFilter(infoScore.data.filter(dataFilter => dataFilter.id === id));
            }
        );
    }, [statusHk1, statusHk2, statusYear]);

    useEffect(() => {
        if (!isEmpty(dataFilter)) {
            if (statusHk1) {
                setDataScore(dataFilter[0].detail_score[0]);
            } else if (statusHk2) {
                setDataScore(dataFilter[0].detail_score[1]);
            } else {
                setDataScore(dataFilter[0].detail_score[2]);
            }
        }
    }, [dataFilter]);

    function handleTabHk1Active(e) {
        e.preventDefault();
        setStatusHk1(true)
        setStatusHk2(false)
        setStatusYear(false)
    }
    function handleTabHk2Active(e) {
        e.preventDefault();
        setStatusHk2(true)
        setStatusHk1(false)
        setStatusYear(false)
    }
    function handleTabYearActive(e) {
        e.preventDefault();
        setStatusYear(true)
        setStatusHk1(false)
        setStatusHk2(false)
    }

    function showScore(type, data) {
        let hk1 = [];
        let hk2 = [];
        let hk3 = [];
        data.map(item => {
            if (item?.score_percent === '1') {
                hk1.push(item?.score);
            } else if (item?.score_percent === '2') {
                hk2.push(item?.score);
            } else {
                hk3.push(item?.score);
            }
        })
        return (
            <tr>
                <td>{type}</td>
                <td>{hk1.join(', ')}</td>
                <td>{hk2.join(', ')}</td>
                <td>{hk3.join(', ')}</td>
            </tr>
        )
    }

    let listTable = (isEmpty(dataScore.list_score) ? [] : dataScore.list_score)
    return (
        <div className="sunE-right-container flex-column">
            {!isEmpty(dataFilter) && <Header title={`Bảng điểm lớp "${dataFilter[0].class_name}"`} isBack />}
            <div className="flex-1 sunE-content tuvung-container">
                <div className="sunE-container-box bangdiem">
                    <div className="bangdiem-menu-gr">
                        <span name='hk1' className={statusHk1 ? 'active' : ''} onClick={handleTabHk1Active}>Học kỳ 1</span>
                        <span name='hk2' className={statusHk2 ? 'active' : ''} onClick={handleTabHk2Active}>Học kỳ 2</span>
                        <span name='year' className={statusYear ? 'active' : ''} onClick={handleTabYearActive}>Cả năm</span>
                    </div>
                    {!isEmpty(dataScore) && <div className="table-box-content">
                        {!statusYear && <table>
                            <tbody>
                                <tr>
                                    <td />
                                    <td>Hệ số 1</td>
                                    <td>Hệ số 2</td>
                                    <td>Hệ số 3</td>
                                </tr>
                                {showScore('KT miệng', listTable.filter(data => data.type === 'oral'))}
                                {showScore('KT 15 phút', listTable.filter(data => data.type === '15'))}
                                {showScore('KT 45 phút', listTable.filter(data => data.type === '45'))}
                                {showScore('KT học kỳ', listTable.filter(data => data.type === 'semester'))}
                                <tr>
                                    <td>Điểm TB</td>
                                    <td colSpan={3}>{dataScore.avg_score}</td>
                                </tr>
                            </tbody></table>}
                        {statusYear && <table>
                            <tbody>
                                <tr>
                                    <td />
                                    <td>Học kỳ 1</td>
                                    <td>Học kỳ 2</td>
                                    <td>Cả năm</td>
                                </tr>
                                <tr>
                                    <td>Điểm</td>
                                    <td>{dataScore.avg_score_1}</td>
                                    <td>{dataScore.avg_score_2}</td>
                                    <td>{dataScore.avg_score}</td>
                                </tr>
                            </tbody></table>}
                    </div>}
                </div>
            </div>

        </div >
    );
}

export { HomePageIngredientScore };