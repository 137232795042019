import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { userConstants } from "../../../_constants";
import { Header } from "../../../_components/Admin/Header";
import "./UpgradeAccount.style.scss";
import { upgradeAccountLogic } from "./UpgradeAccount.logic";
import InputText from "../../../_components/Auth/InputText";
import { PopUpYesNo } from "../../../_components/Popup";
import LazyLoad from "react-lazyload";

const UpgradeAccount = (props) => {
  const history = useHistory();
  const authentication = useSelector((state) => state.authentication);
  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const fromPage = params?.get("from");

  let {
    msgSuccess,
    msgText,
    setMsgText,
    handleUpgrade,
    lsCode,
    setLsCode,
    changeLsCode,
    isActived,
    setIsActived,
  } = upgradeAccountLogic(props);

  return (
    <div className="sunE-container flex ">
      <div className="sunE-right-container flex-column">
        <Header
          title={"Kích hoạt gói"}
          isBack
          // clickBack={() => history.push("/" + authentication.role + "/more")}
        />

        <div className="upgrade_container flex-1 rel flex-center-column">
          <LazyLoad once className="img_upgrade_account">
            <img
              src={`/assets/images/${
                authentication.role == "teacher"
                  ? "banner_upgrade_account_teacher"
                  : "banner_upgrade_account"
              }.png`}
              alt="Banner Upgrade Account"
              className="contain_image"
            />
          </LazyLoad>

          <form
            onSubmit={handleUpgrade}
            className="form_upgrade flex-center-column"
          >
            <div className="form_upgrade_wrapper flex-center-column">
              <div
                className={`bold ${
                  msgText ? "title_upgrade_no_padding" : "title_upgrade"
                }`}
              >
                {authentication?.role == "student"
                  ? "Vui lòng nhập mã để kích hoạt gói học"
                  : "Vui lòng nhập mã để kích hoạt gói"}
              </div>
              <InputText
                value={lsCode}
                setValue={changeLsCode}
                type="text"
                name="lsCode"
                placeholder={
                  authentication?.role == "student"
                    ? "Nhập mã gói học"
                    : "Nhập mã gói"
                }
                required={true}
                errorText={msgText}
                setErrorText={setMsgText}
                errorAbsolute={false}
                autoFocus={true}
              />
            </div>

            <div className="form-sunE-button btn-active">
              <button
                disabled={!lsCode ?? !!msgText}
                className={
                  lsCode && !msgText
                    ? "btn-line-blue"
                    : "btn-line-blue btn-disable"
                }
                onClick={handleUpgrade}
              >
                Kích hoạt
              </button>
            </div>

            <div className="flex-jus-between flex-align col-100 pd-top-3-pc pd-bottom-0-5">
              <div className="flex-align">
                <div className="flex-align color-text mg-right-0-5 fz-11-25">
                  Hotline:
                </div>
                <a className="color_hover fz-11-25" href="tel:024 6281 3888">
                  024 6281 3888
                </a>
              </div>
              <div className="flex-align">
                <div className="flex-align color-text mg-right-0-5 fz-11-25">
                  Email:
                </div>
                <a
                  className=" color_hover fz-11-25"
                  href="mailto:support@sundayenglish.com"
                >
                  support@sundayenglish.com
                </a>
              </div>
            </div>
          </form>
        </div>
      </div>

      {isActived ? (
        <PopUpYesNo
          onClickYes={() => {
            setIsActived(false);
            setLsCode("");
            fromPage == "license"
              ? history.goBack()
              : history.push(`/${authentication?.role}/more/license`);
          }}
          message={msgSuccess}
          labelYes={"Đóng"}
          hideButtonNo={true}
        />
      ) : null}
    </div>
  );
};

export default UpgradeAccount;
