import "./BenefitTeacher.style.scss";
import { HeaderNews } from "../../_components/Header";
import FooterNews from "../../_components/FooterNews/FooterNews";
import Slogan from "../../_components/Slogan/Slogan";
import ScrollFixed from "../../_components/ScrollFixed/ScrollFixed";
import { renderListDetail } from "../../_base/DetailListNews";
import { benefitTeacherLogic } from "./BenefitTeacher.logic";
import { benefitStudentLogic } from "../BenefitStudentPage/BenefitStudent.logic";
import { homePageLogic } from "../HomePage/HomePage.logic";
import VideoBanner from "../../_components/ContentBanner/VideoBanner";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";
import AdvisementForm from "../../_components/AdvisementForm/AdvisementForm";
import ButtonNews from "../../_components/Button/ButtonNews";
import { userConstants } from "../../_constants";
import LazyLoad from "react-lazyload";
import AdvisementFormTeacher from "../../_components/AdvisementForm/AdvisementFormTeacher";
import FormTrial from "../../_components/AdvisementForm/FormTrial/FormTrial";

const BenefitTeacher = (props) => {
  let { dataContentTeacher, dataDetailAssistant, handleReverseMobile } =
    benefitTeacherLogic();
  let { handleCheckEven } = benefitStudentLogic();
  let { handleNavigateCurriculum } = homePageLogic();

  let isFreeTrial = props.isFreeTrial;

  const renderContentList = (data, dataParent) => {
    let dataParentLength = dataParent.length;
    return (
      <LazyLoad
        key={data.id}
        className={`pos_rel list_detail_teacher flex-jus-between `}
        style={{
          borderBottom: data.id != dataParentLength && "1px solid #c4c4c4",
          flexDirection: handleCheckEven(data.id) && "row-reverse",
        }}
      >
        <img
          src={`/assets/images/homeNews/content/teacher/item_${data.id}.png`}
          alt="Image Item"
          className={`contain_image img_item_detail col-30 `}
        />

        <div
          className={`flex-column fz-16-25 col-60 text-align-justify col-100-mobile detail_teacher_column`}
          style={{
            paddingLeft: !handleCheckEven(data.id) && "2.2rem",
            paddingRight: handleCheckEven(data.id) && "2.2rem",
          }}
        >
          <div className="fz-16-25 fz-11-5-mobile mg-bottom-1 text_title_benefit text-align-center-mobile">
            <span className="color-red font_news_bold fz-16-25 fz-11-5-mobile text_title_benefit">
              {data.outstanding.toUpperCase()}
            </span>{" "}
            {data.content}
          </div>

          {data?.items &&
            data?.items.map((item) => renderListDetail(item, 2, "smaller"))}
        </div>
      </LazyLoad>
    );
  };

  return (
    <div className="pos_rel homepage bg-white commondPage">
      <HeaderNews type={TypeHeaderNewsItem.TEACHER} />

      <div className="slider slider_teacher">
        <LazyLoad
          className="slider_bg_teacher flex-jus-between display_none_mobile pos_rel"
          style={{
            background: `url(
              /assets/images/homeNews/slider/slider_bg_teacher.png
            ) no-repeat top center /cover`,
            backgroundAttachment: "scroll",
          }}
        >
          <LazyLoad className="slider_3_container">
            <div className="title_container_teacher flex-center-column">
              <div className="title_detail_1_teacher font_news_bold">
                GIẢI PHÁP ƯU VIỆT
              </div>

              <div className="text-align-center title_detail_2_teacher font_news_bold fz-24">
                giúp thầy cô giảng dạy Tiếng Anh
              </div>
              <span className="color-primary-green title_detail_3_teacher font_news_bold fz-24">
                HIỆU QUẢ
              </span>
            </div>
            <div className="slider_3 ">
              <img
                src="/assets/images/homeNews/slider/slider_3.png"
                alt="Slider 3"
                className="slider_3_img contain_image"
              />
            </div>
          </LazyLoad>

          <LazyLoad className="video_slider_container pos_rel">
            <img
              src="/assets/images/homeNews/slider/circle_bg_phone.png"
              alt="Img Bg Circle"
              className="bg_circle_img pos_abs contain_image"
            />
            <video
              autoPlay
              muted
              controls={false}
              playsInline
              loop
              poster="/assets/images/homeNews/slider/teacher_poster.jpg"
              src="/assets/videos/teacher_video.mp4"
              // alt="Video Slider Teacher"
              className=" video_slider box-shadow"
            />
          </LazyLoad>
        </LazyLoad>

        {/* MOBILE */}
        <LazyLoad className="slider_bg_teacher pos_rel flex-jus-between hide display_flex_mobile">
          <img
            src="/assets/images/homeNews/slider/slider_bg_teacher_mobile.png"
            alt="Img Slider Mobile"
            className="contain_image pos_abs col-100 img_slider_mobile"
          />
          <div className="slider_3_container">
            <div className="title_container_teacher flex-center-column">
              <div className="title_detail_1_teacher font_news_extra_bold">
                GIẢI PHÁP ƯU VIỆT
              </div>

              <div className="text-align-center title_detail_2_teacher font_news_bold fz-24">
                giúp thầy cô giảng dạy Tiếng Anh
              </div>
              <span className="color-primary-green title_detail_3_teacher font_news_bold font_news_extra_bold_mobile fz-24">
                HIỆU QUẢ
              </span>
            </div>
            <LazyLoad className="slider_3">
              <img
                src="/assets/images/homeNews/slider/slider_3_mobile.png"
                alt="Slider 3"
                className="slider_3_img"
              />
            </LazyLoad>
          </div>

          <LazyLoad className="video_slider_container ">
            {/* <img
              src="/assets/images/homeNews/slider/video_slider_teacher.png"
              alt="Video Slider Teacher"
              className="contain_image video_slider"
            /> */}
            <video
              autoPlay
              muted
              controls={false}
              playsInline
              loop
              src="/assets/videos/teacher_video.mp4"
              poster="/assets/images/homeNews/slider/teacher_poster.jpg"
              // alt="Video Slider Teacher"
              className=" video_slider box-shadow"
            />
          </LazyLoad>
        </LazyLoad>
      </div>

      <div className="content flex-center-column">
        <div className="pos_rel content_bg_benefit content_bg_benefit_teacher flex-center-column">
          <div className="flex-center-column img_benefits_teacher">
            <h2 className="title-benefit-st">SUNDAY TEACHER</h2>
            <img
              src="/assets/images/homeNews/content/teacher/list_benefits_teacher.png"
              alt="List Benefits Teacher"
              className="contain_image display_none_mobile"
            />

            <img
              src="/assets/images/homeNews/content/teacher/list_benefits_teacher_mobile_v2.png"
              alt="List Benefits Teacher"
              className="contain_image hide display_block_mobile"
            />
          </div>

          <div
            className="flex-center-column col-100 pos_rel"
            style={{
              backgroundColor: "#eefdf9",
            }}
          >
            <div className="list_content_teacher flex-center-column">
              {dataContentTeacher.map((data) =>
                renderContentList(data, dataContentTeacher)
              )}
            </div>

            <div className="display_none_mobile list_icon_bg_teacher">
              {/* <LazyLoad className="img_left_top_teacher pos_abs">
                <img
                  src="/assets/images/homeNews/content/icon/ico_left_trial.png"
                  alt="Icon"
                  className="contain_image"
                />
              </LazyLoad> */}

              <LazyLoad className="img_left_center pos_abs">
                <img
                  src="/assets/images/homeNews/content/teacher/ico_left_teacher.png"
                  alt="Icon"
                  className="contain_image"
                />
              </LazyLoad>

              <LazyLoad className="img_right_top pos_abs">
                <img
                  src="/assets/images/homeNews/content/teacher/ico_right_teacher.png"
                  alt="Icon"
                  className="contain_image"
                />
              </LazyLoad>

              <LazyLoad className="img_right_bottom pos_abs">
                <img
                  src="/assets/images/homeNews/content/icon/ico_right_trial.png"
                  alt="Icon"
                  className="contain_image"
                />
              </LazyLoad>
            </div>
          </div>

          <div className="flex-center-column detail_assistant_container">
            <div className="img_detail_assistant flex-align-end hide display_flex_mobile">
              <img
                src="/assets/images/homeNews/content/teacher/img_detail_assistant_mobile.png"
                alt="Img Assistant"
              />
            </div>
            <div className="col-100  pos_rel text_uppercase text-align-center color-red title_detail_assistant font_news_extra_bold">
              SUNDAY TEACHER - TRỢ GIẢNG ĐẮC LỰC CỦA THẦY CÔ
              <div className="display_none_mobile">
                <div className="pos_abs img_side_left">
                  <img
                    src="/assets/images/homeNews/content/teacher/ico_side_assistant.png"
                    alt="Ico Side Assistant"
                    className="contain_image"
                  />
                </div>
                <div className="pos_abs img_side_right">
                  <img
                    src="/assets/images/homeNews/content/teacher/ico_side_assistant.png"
                    alt="Ico Side Assistant"
                    className="contain_image"
                  />
                </div>
              </div>
            </div>

            <div className=" detail_assistant_content  flex-center-column-mobile">
              <div className="img_detail_assistant flex-align-end display_none_mobile">
                <img
                  src="/assets/images/homeNews/content/teacher/img_detail_assistant.png"
                  alt="Img Assistant"
                />
              </div>

              <div className="list_detail_assistant text-align-justify ">
                {dataDetailAssistant.map((data) =>
                  renderListDetail(data, 2, "tiny")
                )}
              </div>
            </div>
          </div>
        </div>

        {/* <Divider /> */}
        <Slogan type="teacher" />

        <div className="hide display_flex_mobile col-100 flex-center pd-bottom-15">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="94%"
            height="2"
            viewBox="0 0 344 2"
            fill="none"
          >
            <path
              d="M0 1H343.5"
              stroke="black"
              strokeWidth="0.6"
              strokeDasharray="4 4"
            />
          </svg>
        </div>

        {/* <div className="flex-center-column col-100 trial_teacher_container">
          <ButtonNews
            width="26rem"
            border={"1px solid#eb5757"}
            backgroundColor={"#eb5757"}
            className="text-align-center color-red font_news_bold  pointer_cursor"
            onClick={() => handleNavigateCurriculum()}
          >
            <div className="text-align-center color-white font_news_bold text_btn_test pointer_cursor">
              DÙNG THỬ MIỄN PHÍ
            </div>
          </ButtonNews>

          <div className="col-100 display_none_mobile">
            <div className="icon_left_trial_container ">
              <img
                src="/assets/images/homeNews/content/icon/ico_left_trial.png"
                alt="Icon Text Center"
                className="contain_image "
              />
            </div>
            <div className="icon_right_trial_container ">
              <img
                src="/assets/images/homeNews/content/icon/ico_right_trial.png"
                alt="Icon Text Right"
                className="contain_image "
              />
            </div>
          </div>
        </div> */}

        <AdvisementFormTeacher
          placeholder="Họ và tên thầy cô"
          type={userConstants.ROLE_TEACHER}
        />
      </div>

      <FooterNews typeFee={TypeHeaderNewsItem.TEACHER} />

      <ScrollFixed />

      {!!isFreeTrial ? <FormTrial type="teacher" /> : null}
    </div>
  );
};

export default BenefitTeacher;
