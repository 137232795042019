import { apiCaller } from "./../_helpers";
import { alertActions } from "./";
import { licenseConstants } from "./../_constants";
import { configConstants } from "./../_constants";
import API from "../_apis/APIConstants";
import APIBase from "../_base/APIBase";

export const licenseActions = {
  getCurrentLicense,
  addCodeLicense,
  getHistoryLicense,
};

function getCurrentLicense() {
  let urlApi = `${API.baseURL}${API.get_packages_available}`;
  return (dispatch) => {
    return APIBase.apiBaseCaller("GET", urlApi, {}).then(
      (licenses) => {
        dispatch({
          type: licenseConstants.GET_CURRENT_LICENSE,
          listCurentLicenses: licenses.data ?? [],
        });
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: licenseConstants.SCREEN_GET_CURREN_LICENSE,
          })
        );
      }
    );
  };
}

function addCodeLicense(data) {
  return (dispatch) => {
    return apiCaller(
      "/apilicense_management/api/license/active_license_code",
      "POST",
      data,
      null,
      true,
      configConstants.API_URL_SELICENSE
    ).then(
      () => {
        dispatch(
          alertActions.success({
            message: licenseConstants.MESSAGE_LICENSE_ADD_CODE_COMPLETE,
            screen: licenseConstants.SCREEN_LICENSE_ADD_CODE,
          })
        );
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: licenseConstants.SCREEN_LICENSE_ADD_CODE,
          })
        );
      }
    );
  };
}

function getHistoryLicense() {
  let urlApi = `${API.baseURL}${API.get_package_history}`;
  return (dispatch) => {
    return APIBase.apiBaseCaller("GET", urlApi, {}).then(
      (licenses) => {
        dispatch({
          type: licenseConstants.GET_HISTORY_LICENSE,
          listHistoryLicenses: licenses.data ?? [],
        });
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: licenseConstants.SCREEN_GET_HISTORY_LICENSE,
          })
        );
      }
    );
  };
}
