import { HeaderNews } from "../../_components/Header";
import "./FAQsPage.style.scss";
import { TypeHeaderNewsItem } from "../../_constants/headerNews";
import FooterNews from "../../_components/FooterNews/FooterNews";
import ScrollFixed from "../../_components/ScrollFixed/ScrollFixed";
import { faqsLogic } from "./FAQsPage.logic";
import SelectQuestion from "../../_components/Select/SelectQuestion/SelectQuestion";
import LazyLoad from "react-lazyload";
import { Fragment } from "react";

const FAQsPage = () => {
  let {
    categorySelected,
    dataCategories,
    titleSelected,
    handleChangeCategory,
    handleSelectQuestion,
    dataQuestion,
    openSelectMobile,
    handleOpenSelect,
  } = faqsLogic();

  // Render Side bar FAQs
  const renderColumnFAQ = (category) => {
    return (
      <div
        onClick={() => handleChangeCategory(category)}
        key={category.id}
        className={`pointer_cursor category_item pos_rel ${
          categorySelected.type == category.type
            ? " category_item--selected "
            : ""
        }`}
      >
        <div className="category_item-title">{category.title}</div>

        {categorySelected.type == category.type ? (
          <div className="border--selected"></div>
        ) : null}
      </div>
    );
  };

  // Render List Questions
  const renderListQuestions = (question) => {
    return (
      <Fragment key={question?.id}>
        <SelectQuestion
          title_2={question?.title_2}
          title={question.title}
          data={question.data}
          typeQuestion={categorySelected.type}
          titleSelected={titleSelected}
          handleSelectQuestion={handleSelectQuestion}
        />
      </Fragment>
    );
  };

  // Render List Categories Mobile
  const renderListCategoryMobile = (category) => {
    return (
      <div
        key={category.id}
        onClick={() => handleChangeCategory(category)}
        className=" category_item pos_rel pointer_cursor"
      >
        <div className="category_item-title">{category.title}</div>
      </div>
    );
  };

  return (
    <div id="faq" className="faqsPage bg_white commondPage">
      <HeaderNews type={TypeHeaderNewsItem.HOME} />

      <LazyLoad once className="slider">
        <img
          src="/assets/images/faqNews/slide.png"
          alt="Slide FAQs"
          className="contain_image slider_bg col-100 display_none_mobile"
        />
        <img
          src="/assets/images/faqNews/slide_mobile.png"
          alt="Slide FAQs"
          className="contain_image slider_bg col-100 hide display_block_mobile"
        />
      </LazyLoad>
      <div className="content flex-center-column">
        <div className="container flex-align-start flex-column-mobile">
          <div className="hide display_flex_mobile font_news_bold title_mobile flex-center-mobile col-100 text-align-center">
            Câu hỏi thường gặp | Sunday English
          </div>
          <div className="content_categories  border_1_light bg_white box-shadow">
            <h2 className="font_news_bold color-primary-green content_categories-header">
              Chuyên mục
            </h2>

            <div
              className="flex-jus-between pos_rel category_header pos_rel hide display_flex_mobile"
              onClick={handleOpenSelect}
            >
              <div className=" category_title line_height_normal">
                {categorySelected.title}
              </div>
              <div className="flex-center">
                <img
                  src={`/assets/images/icon/${
                    openSelectMobile ? "ico_circle_up" : "ico_circle_down"
                  }.png`}
                  alt="Icon Dropdown"
                  className="contain_image img_dropdown"
                />
              </div>
              {!!openSelectMobile && (
                <div className="col-100 bg_white categories_list modal_category_mobile box-shadow border_1_light pos_abs">
                  {dataCategories.map((item) => renderListCategoryMobile(item))}
                </div>
              )}
            </div>

            <div className="categories_list display_none_mobile">
              {dataCategories.map((category) => renderColumnFAQ(category))}
            </div>
          </div>

          <div className="content_questions">
            <h2 className="font_news_bold content_questions-title">
              {categorySelected.title}
            </h2>
            <div className="flex-column">
              {dataQuestion[categorySelected.type]?.map((question) =>
                renderListQuestions(question)
              )}
            </div>
          </div>
        </div>
      </div>

      <FooterNews />
      <ScrollFixed />
    </div>
  );
};

export default FAQsPage;
