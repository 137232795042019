import React, { Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions } from "../../../_actions";
import { useParams, Link } from "react-router-dom";
import { Header } from "../../../_components/Admin/Header";
import { isEmpty } from "lodash";
import { userConstants, studentConstants } from "../../../_constants";
import { apiCaller } from "../../../_helpers";
import moment from "moment";

function ViewStudentRank() {
  const { id } = useParams();
  const authentication = useSelector((state) => state.authentication);

  const dispatch = useDispatch();

  // const dataRanking = useSelector((state) => state.classes.student_ranking);
  // const topUsers = dataRanking.data.top_users;
  const rankingStudent = useSelector(state => state.classesStudent.rankingStudent)
  const dataRanking = rankingStudent?.[id]?.data;
  const timeGetData = rankingStudent?.[id]?.timeGetData;
  const topUsers = dataRanking?.data?.top_users;

  const getData = () => {
    apiCaller("/api_student/ranking?type=class&class_id=" + id, "GET")
    .then(data => {
      dispatch({
        type: studentConstants.GET_RANKING_STUDENT_CLASS,
        payload: {
          classId: id,
          data
        }
      })
    })
    .catch (err => {
      console.log(err);
    }) 
  }

  useEffect(() => {
    // dispatch(teacherActions.getStudentRanking("class", id));
    if (!!dataRanking && !!timeGetData && moment(new Date()).diff(moment(timeGetData), 'hours') < 1) {
      return;
    }
    getData();
  }, []);

  return (
    <div className="sunE-container flex flex bg-main-color">
      <div className="sunE-right-container bangvang">
        <Header
          title="Bảng Vàng"
          component={
            <>
              <div className="refresh-curriculum-btn-teacher-header" style={{marginRight: 16}} onClick={getData}>
                <img src="/assets/images/icon_refresh_curriculum.png" style={{width: '100%', height: '100%', borderRadius: '50%'}}/>
              </div>
              <Link
                to={"/" + authentication.role + "/class/view/" + id + "/ranking"}
                className="mr-10"
              >
                {authentication.role === userConstants.ROLE_TEACHER && (
                  <div className="form-sunE-button mr-10">
                    <button className="btn-line-blue">Vinh danh</button>
                  </div>
                )}
              </Link>
            </>
          }
          isBack
        />
        {!isEmpty(topUsers) ? (
          <div className="flex-m bangvang-content block_golden_board">
            <div className="top box-shadow mr-10 scrollbar-custom height_top_3_golden_board">
              {!isEmpty(topUsers) && (
                <div className="top-box top1">
                  <div className="top1">
                    <span>
                      {parseFloat(
                        Number.parseFloat(topUsers[0].total_score).toFixed(1)
                      )}
                    </span>
                  </div>
                  <div className="top-hs rel width_block_top_golden_board">
                    <img
                      src="/assets/images/thanhtich/top1.png"
                      alt="top1"
                      className="_img"
                    />
                    <img
                      src={dataRanking.base_url + topUsers[0].avatar}
                      alt="student"
                      className="avt top_1_avatar"
                    />
                    <img
                      src="/assets/images/thanhtich/gold.png"
                      alt="gold"
                      className="gold top_1_crown"
                    />
                    <div className="__top_number __top_number_responsive __top_1">
                      1
                    </div>
                  </div>
                  <p>{topUsers[0].fullname}</p>
                </div>
              )}
              {!isEmpty(topUsers) && (
                <div className="top-box top2">
                  {!isEmpty(topUsers[1]) && (
                    <Fragment>
                      <div className="top2">
                        <span>
                          {parseFloat(
                            Number.parseFloat(topUsers[1].total_score).toFixed(
                              1
                            )
                          )}
                        </span>
                      </div>
                      <div className="top-hs rel width_block_top_golden_board">
                        <img
                          src="/assets/images/thanhtich/top2.png"
                          alt="top2"
                          className="_img"
                        />
                        <img
                          src={dataRanking.base_url + topUsers[1].avatar}
                          alt="student"
                          className="avt"
                        />
                        <div className="__top_number __top_2">2</div>
                      </div>
                      <p>{topUsers[1].fullname}</p>
                    </Fragment>
                  )}
                </div>
              )}

              {!isEmpty(topUsers) && (
                <div className="top-box top3" style={{ margin: 0 }}>
                  {!isEmpty(topUsers[2]) && (
                    <Fragment>
                      <div className="top3">
                        <span>
                          {parseFloat(
                            Number.parseFloat(topUsers[2].total_score).toFixed(
                              1
                            )
                          )}
                        </span>
                      </div>
                      <div className="top-hs rel width_block_top_golden_board">
                        <img
                          src="/assets/images/thanhtich/top3.png"
                          alt="top3"
                          className="_img"
                        />
                        <img
                          src={dataRanking.base_url + topUsers[2].avatar}
                          alt="student"
                          className="avt"
                        />
                        <div className="__top_number __top_3">3</div>
                      </div>
                      <p>{topUsers[2].fullname}</p>
                    </Fragment>
                  )}
                </div>
              )}
            </div>
            <div className="class_student_list flex-1">
              <div className="box-shadow custom-list-student class_student_list_box ranks height_top_3_golden_board padding_tops_golden_board">
                <div className="list-student scrollbar-custom">
                  {topUsers &&
                    topUsers.map((data, i) => {
                      if (i > 2) {
                        return (
                          <div className="item-student d-flex" key={i}>
                            <div className="d-flex item-student-name-container">
                              <div className="stt">{i + 1}</div>
                              <div className="item-student-img">
                                <img
                                  src={
                                    data.avatar
                                      ? dataRanking.base_url + data.avatar
                                      : "/assets/images/teacher/student.png"
                                  }
                                  alt=""
                                />
                              </div>
                              <div className="flex-1 item-student-name max-w-1 center-flex-hz">
                                <div className="two-line">{data.fullname}</div>
                              </div>
                            </div>

                            <div className="d-flex">
                              {data.rank_change_status ===
                                studentConstants.RANK_UP &&
                                data.rank_change != 0 && (
                                  <div className="lv-up center-flex ">
                                    <div className="mr-20 flex-align">
                                      <img
                                        src="/assets/images/thanhtich/up.png"
                                        alt="up"
                                      />
                                      <div className="flex-align">
                                        + {data.rank_change}
                                      </div>
                                    </div>
                                  </div>
                                )}

                              {data.rank_change_status ===
                                studentConstants.RANK_DOWN && (
                                <div className="lv-down center-flex">
                                  <div className="mr-20">
                                    <img
                                      src="/assets/images/thanhtich/down.png"
                                      alt="down"
                                    />
                                    <span> {data.rank_change}</span>
                                  </div>
                                </div>
                              )}

                              <div className="score-tb-student center-flex">
                                <span>
                                  {parseFloat(
                                    Number.parseFloat(data.total_score).toFixed(
                                      1
                                    )
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      } else {
                        return false;
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
        ) : (
          topUsers && (
            <div className="empty-leaderboard-custom">
              <img src="/assets/images/empty_leaderboard.png" alt="top3" />
              <h5 className="title-empty">
                Thành tích học tập của lớp còn trống!
              </h5>
              <p className="detail-empty">
                Hiện tại lớp học chưa có kết quả xếp hạng.
              </p>
            </div>
          )
        )}
      </div>
    </div>
  );
}

export { ViewStudentRank };
