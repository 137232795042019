import React from 'react';

function HomePageParent() {

    return (
        <div className="col-lg-8 offset-lg-2 text-center">
            <h2>TRANG CHỦ DÀNH CHO PHỤ HUYNH ĐANG PHÁT TRIỂN</h2>
        </div>
    );
}

export { HomePageParent };