import React from 'react';

function EmptyStudent() {
    return (
        <div className="sunE-content rel add_student sunE-no-student">
            <img className='__bg_layout_parent_and_student' src="/assets/images/bg_add_student.png"/>
            <div className="add_code_student-gr text-center err __posistion_custom" style={{top: '69%'}}>
                <h2 className="bold sunE-title-medium">Lớp học hiện tại chưa có thành viên nào.</h2>
            </div>
        </div>
    );
}

export { EmptyStudent };