import "./BoxTitle.style.scss";

export const BoxTitlte = ({ title, id }) => {
  return (
    <div
      className={`pos_abs pos_rel_mobile box_title box_title_${id} text-align-center`}
    >
      <div className="color-white font_news_bold font_news_bold_mobile fz-13 fz-1-mobile text-align-center">
        {title}
      </div>
    </div>
  );
};
