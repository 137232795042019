import React from 'react';
import { useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';

function SideBar() {
    const authentication = useSelector(state => state.authentication);

    return (
        <div className="list-menu-custom min-width-custom-200">
            <NavLink className="btn-gr flex-m" exact activeClassName="active" to={'/' + authentication.role + "/more/schedule"} >
                <div className="flex-1 btn-gr-text text-center">
                    <p>Thời khóa biểu giảng dạy</p>
                </div>
            </NavLink>
            <NavLink className="btn-gr flex-m" activeClassName="active" to={'/' + authentication.role + "/more/schedule/day"} >
                <div className="flex-1 btn-gr-text text-center">
                    <p>Lịch làm việc ngày</p>
                </div>
            </NavLink>
            <NavLink className="btn-gr flex-m" activeClassName="active" to={'/' + authentication.role + "/more/schedule/year"} >
                <div className="flex-1 btn-gr-text text-center">
                    <p>Lịch làm việc năm</p>
                </div>
            </NavLink>
        </div>
    );
}

export { SideBar };