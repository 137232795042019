import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import { teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import { teacherService } from "../../../_services";
import { teacherConstants } from "../../../_constants";

function AddClassExercise() {
  const dispatch = useDispatch();
  const history = useHistory();
  const authentication = useSelector((state) => state.authentication);
  const classes = useSelector((state) => state.classes);
  const [isLoading, setLoading] = useState(false);

  const search = history?.location?.search;
  const params = new URLSearchParams(search);
  const page = params?.get("page");

  useEffect(() => {
    // dispatch(teacherActions.getListClass());
    setLoading(true);
    teacherService.getListClass().then((classes) => {
      if (classes) {
        if (classes.data.length === 1 && page !== "curriculum") {
          history.push(
            `/teacher/class/view/${classes.data[0].id}/exercise/student${
              page == "morepage" ? "?page=morepage" : ""
            }`
          );
        }
        dispatch({
          type: teacherConstants.GET_LIST_CLASS,
          classes: classes,
        });
      }
      setLoading(false);
    });
  }, []);

  if (isLoading) return null;

  return (
    <div className="sunE-right-container rel">
      <Header title={"Chọn lớp"} isBack />
      <div className="sunE-content sunE-class-content">
        <div className="sunE-class-list uk-container">
          <div className="uk-grid">
            {classes?.data?.length > 0 ? (
              classes?.data.filter((i) => i.count_student != 0)?.length > 0 ? (
                classes.data.map((data, i) => {
                  return (
                    data.count_student > 0 && (
                      <div className=" uk-width-1-2" key={i}>
                        <Link
                          to={
                            "/" +
                            authentication.role +
                            "/class/view/" +
                            data.id +
                            `/exercise/student?${
                              page != null ? `page=${page}` : ""
                            }`
                          }
                          style={{ height: "100%" }}
                        >
                          <div className="class-box box-shadow">
                            <div className="flex-m">
                              <div className="class-box-img">
                                <img
                                  src={
                                    classes.base_url + "/" + data.class_avatar
                                  }
                                  alt="avatar"
                                />
                              </div>
                              <div className="flex-1 class-box-desc">
                                <h2 className="title-line-blue two-line">
                                  {data.class_name}
                                </h2>
                                <p className="two-line">
                                  {data.organization_name}
                                </p>
                                {/* <div>
                                {moment(data.start_time).format("DD/MM/YYYY")}-
                                {moment(data.end_time).format("DD/MM/YYYY")}
                              </div> */}
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    )
                  );
                })
              ) : (
                <div className="text-align-center col-100">
                  Lớp học online chưa có thành viên. Bạn vui lòng thêm học sinh
                  vào lớp để giao bài.
                </div>
              )
            ) : (
              <div className="text-align-center col-100">
                Bạn chưa có lớp học nào. Hãy tạo lớp mới và thêm học sinh để
                giao bài.
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export { AddClassExercise };
