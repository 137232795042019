import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, Link } from "react-router-dom";
import { teacherActions } from "../../../_actions";
import { Header } from "../../../_components/Admin/Header";
import { PopUpAddRollUp } from "../../../_components/Popup";
import { isEmpty } from "lodash";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { PopUpYesNo } from "../../../_components/Popup";
import { teacherService } from "../../../_services";
import $ from "jquery";

function HomePageRollUp() {
  const history = useHistory();

  const dispatch = useDispatch();
  const { id } = useParams();
  const authentication = useSelector((state) => state.authentication);
  const classes = useSelector((state) => state.classes);
  const [isLoading, setLoading] = useState(false);

  // Variable state for delete roll up
  const [isShowPopupDelete, setIsShowPopupDelete] = useState(false);
  const [idCardSelected, setIdCardSelected] = useState();
  const [isShowPopupAlert, setIsShowPopupAlert] = useState(false);
  const [messageAlert, setMessageAlert] = useState("");

  useEffect(() => {
    let isLoading = !$(".loading").hasClass("hide");
    setLoading(isLoading);
  }, [classes]);

  useEffect(() => {
    setLoading(true);
    dispatch(teacherActions.getDetailClassOffline(id));
  }, []);

  function componentAdd() {
    return (
      <div
        className="form-sunE-button btn-create-diemdanh rel mr-10"
        onClick={() => setShowPopupAdd(true)}
      >
        <img
          src="/assets/images/teacher/lichlamviec/ico_add_small.png"
          alt="ico_add"
        />
        <button className="btn-line-blue">Tạo điểm danh</button>
      </div>
    );
  }

  const [showPopupAdd, setShowPopupAdd] = useState(false);

  // Function for delete roll up
  const handleOpenPopUp = (id) => {
    setIsShowPopupDelete(true);
    // console.log(id);
    setIdCardSelected(id);
  };

  const handleDeletedCard = async () => {
    try {
      if (idCardSelected) {
        const res = await teacherService.deleteRollUp(idCardSelected);
        // console.log(res);
        if (res?.status) {
          setMessageAlert("Xóa phiểu điểm thành công!");
          setIsShowPopupDelete(false);
          setIsShowPopupAlert(true);
        } else {
          setMessageAlert(res?.data?.message);
        }
      }
    } catch (e) {
      setMessageAlert(e.toString());
    }
  };

  const handleAcceptAlert = () => {
    setIsShowPopupAlert(false);
    dispatch(teacherActions.getDetailClassOffline(id));
  };

  return (
    <div className="sunE-right-container flex-column">
      <Header
        title={"Quản lý điểm danh"}
        component={componentAdd()}
        isBack
        clickBack={() =>
          history.push("/" + authentication.role + "/class/view/" + id)
        }
      />
      {showPopupAdd && (
        <PopUpAddRollUp onClickNo={() => setShowPopupAdd(false)} width={370} />
      )}
      <div className="sunE-container-box diemdanh offline flex-1">
        {!isLoading &&
          (isEmpty(classes.roll_up) ? (
            <div className="flex-m">
              <div className="diemdanh-no-item text-center">
                <img
                  src="/assets/images/teacher/diemdanh/bg_diemdanh_no_item.png"
                  alt="bg_diemdanh_no_item"
                />
                <p>
                  Bạn chưa có phiếu điểm danh nào,
                  <br />
                  Hãy bắt đầu điểm danh lớp của bạn!
                </p>
              </div>
            </div>
          ) : (
            <div className="diemdanh-container">
              <h2 className="medium-title f-24">Danh sách điểm danh</h2>
              <div className=" diemdanh-list scrollbar-custom">
                {classes.roll_up.map((data, i) => {
                  return (
                    <div key={i} style={{ position: "relative" }}>
                      <Link
                        to={
                          "/" +
                          authentication.role +
                          "/class/view/" +
                          id +
                          "/roll_up/" +
                          data.id
                        }
                        key={i}
                      >
                        <div
                          className="diemdanh-item box-shadow flex-m"
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <div
                            className="flex-1"
                            style={{
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div
                              className="flex-m"
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div>
                                <img
                                  src="/assets/images/teacher/diemdanh/ico_date.png"
                                  alt="ico_date"
                                />
                              </div>
                              <p className="flex-1">
                                {moment(data?.date).format("DD/MM/YYYY")}
                              </p>
                            </div>
                            {/* <div className="flex-m">
                                                  <div>
                                                      <img src="/assets/images/teacher/diemdanh/ico_clock.png" alt="ico_clock" />
                                                  </div>
                                                  <p className="flex-1">{moment(data.date).format('HH:mm')}</p>
                                              </div> */}
                          </div>
                          <div className="flex-1 diemdanh-ss">
                            <div className="flex-m">
                              <div>
                                <img
                                  src="/assets/images/teacher/diemdanh/ico_users.png"
                                  alt="ico_users"
                                  className="ico_user_class"
                                />
                              </div>
                              <span className="flex-1">
                                {parseInt(data.total_student) -
                                  parseInt(data.number_absence)}
                                /{data.total_student}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <div
                        style={{
                          position: "absolute",
                          bottom: "1.25rem",
                          top: "1.25rem",
                          right: "1.5rem",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOpenPopUp(data.id)}
                      >
                        <img
                          src="/assets/images/icon/ico_remove.png"
                          alt="ico_remove"
                          style={{
                            width: "1.3rem",
                            height: "auto",
                          }}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
      </div>

      {isShowPopupDelete && (
        <PopUpYesNo
          onClickYes={() => handleDeletedCard()}
          onClickNo={() => setIsShowPopupDelete(false)}
          labelNo="Không"
          laelYes="Có"
          message={["Bạn có chắc chắn muốn xóa phiếu điểm danh này?"]}
        />
      )}

      {isShowPopupAlert && (
        <PopUpYesNo
          hideButtonNo={true}
          onClickYes={() => handleAcceptAlert()}
          labelYes="Đóng"
          message={messageAlert}
        />
      )}
    </div>
  );
}

export { HomePageRollUp };
