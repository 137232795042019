import { useEffect, useRef, useState } from 'react';
import './OtpInput.style.scss'

export default function OtpInput({ length = 6, onComplete, setValue, onSubmit, ...other }) {

  const inputRef = useRef(Array(length).fill(null));
  const [OTP, setOTP] = useState(Array(length).fill(''));


  const handleTextChange = (value, index) => {
    const isNumber = /^-?\d+$/.test(value);
    if (value?.length > 1 || (!isNumber && !!value?.length)) {
      return;
    }
    const newPin = [...OTP];
    newPin[index] = value;
    setOTP(newPin);
    setValue && setValue(newPin.join(''));

    if (value.length === 1 && index < length - 1) {
        inputRef.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    const value = e.target?.value;
    if (e.which == 8 && index > 0 && !value?.length) {
      inputRef.current[index - 1]?.focus();
    }
    if (e.which == 13 && OTP.every((digit) => digit !== '')) {
      onSubmit && onSubmit()
    }
  }

  return (
    <div className='otp-input-container'>
        {Array.from({ length }, (_, index) => (
            <input
                {...other}
                key={index}
                type='text'
                maxLength={1}
                className={'otp-input'}
                value={OTP[index]}
                onChange={(e) => handleTextChange(e.target.value, index)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                ref={(ref) => (inputRef.current[index] = ref)}
                autoFocus={index === 0}
            />
        ))}
    </div>
  )
}