import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { teacherActions } from "./../../../_actions";
import { teacherService } from "./../../../_services";
import { PopUpRemoveStudentInClass } from "../../Popup";
import { Alert } from "./../../../_components/Alert";
import {
  teacherConstants,
  configConstants,
  userConstants,
} from "././../../../_constants";
import Pagination from "react-js-pagination";
import { useParams } from "react-router-dom";
import { alertActions } from "../../../_actions/alerts";
import { hasDuplicates } from "../../../_base/Validate";
import moment from "moment";
import PopupEditStudent from "../../Popup/PopupEditStudent";
import SubMenu from "../../SubMenu";
import { apiCaller, history } from "../../../_helpers";
import { exportExcel } from "../../../_helpers/utils";

function ListStudent(props) {
  const { id } = useParams();

  const [pagination] = useState({
    limit: 20,
    offset: 0,
  });
  const [isLoadMore, setLoadMore] = useState(true);
  const [isLoading, setLoading] = useState(false);

  const alert = useSelector((state) => state.alert);
  const authentication = useSelector((state) => state.authentication);

  const dispatch = useDispatch();
  const [dataStudent, setDataStudent] = useState({
    id: "",
    name: "",
    member_id: "",
  });

  const [showPopup, setShowPopup] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [studentEditing, setStudentEditing] = useState()
  const [isOpenModalEdit, setIsOpenModalEdit] = useState(false)
  const [isViewDetail, setIsViewDetail] = useState(false)

  let { students, class_detail } = props;
  // console.log(class_detail);

  useEffect(() => {
    if (
      alert.message &&
      alert.screen === teacherConstants.SCREEN_LIST_STUDENT
    ) {
      setCurrentPage(1);
    }
  }, [alert]);

  useEffect(() => {
    // return () => {
    //   dispatch({
    //     type: teacherConstants.GET_STUDENT_OFF_CLASS,
    //     students: {
    //       data: [],
    //       base_url: "",
    //     },
    //   });
    // };
  }, []);

  function showPopUpRemove(data) {
    setShowPopup(true);
    setDataStudent(data);
  }

  function removeStudent(id) {
    setShowPopup(false);
    teacherActions
      .removeStudent({
        id: props.class_id,
        students: id,
      })
      .then(
        (res) => {
          dispatch(
            alertActions.success({
              message: "Xoá học sinh thành công",
              screen: teacherConstants.SCREEN_LIST_STUDENT,
            })
          );
          let list = students.data.filter((item) => item.id !== id);
          dispatch({
            type: teacherConstants.GET_STUDENT_OFF_CLASS,
            students: {
              data: list,
              base_url: students.base_url,
            },
          });
        },
        (error) => {
          dispatch(
            alertActions.error({
              message: error.toString(),
              screen: teacherConstants.SCREEN_LIST_STUDENT,
            })
          );
        }
      );
  }

  // console.log(students.data);

  function removeStudentOffline(class_member_id) {
    setShowPopup(false);
    teacherActions.removeStudentOffline(class_member_id).then(
      (res) => {
        dispatch(
          alertActions.success({
            message: "Xoá học sinh thành công",
            screen: teacherConstants.SCREEN_LIST_STUDENT,
          })
        );
        let list = students.data.filter(
          (item) => item.member_id !== class_member_id
        );
        dispatch({
          type: teacherConstants.GET_STUDENT_OFF_CLASS,
          students: {
            data: list,
            base_url: students.base_url,
          },
        });
      },
      (error) => {
        dispatch(
          alertActions.error({
            message: error.toString(),
            screen: teacherConstants.SCREEN_LIST_STUDENT,
          })
        );
      }
    );
  }

  function handlePageChange(pageNumber, isLoadingSide) {
    if (currentPage != pageNumber && !isLoading) {
      setLoading(true);
      teacherService
        .getStudentOfClass(
          id,
          pagination.limit,
          pagination.limit * (pageNumber - 1),
          isLoadingSide
        )
        .then((res) => {
          setLoading(false);
          if (!hasDuplicates(students.data, res.data)) {
            setCurrentPage(pageNumber);
            setLoadMore(res.data?.length == 20);
            dispatch({
              type: teacherConstants.GET_STUDENT_OFF_CLASS,
              students: {
                data: [...students.data, ...res.data],
                base_url: students.base_url,
              },
            });
          }
        });
    }
  }

  // Handle Scroll
  const handleScroll = (e) => {
    // if (
    //   e.target.scrollHeight - e.target.scrollTop < e.target.clientHeight + 5 &&
    //   isLoadMore &&
    //   !isLoading
    // ) {
    //   // console.log('Scroll to bottom load more data');
    //   handlePageChange(currentPage + 1, true);
    // }
  };
// console.log('students: ', students)
  const handleExportListStudent = async () => {
    // if (authentication?.role === 'teacher') {
      // try {
      //   const url = '/api_class/export_student_class?class_id=' + id;
      //   const res = await apiCaller(url, "GET");
      //   window.open(res?.base_url + res?.file_excel, '_blank')
      // } catch (err) {
      //   dispatch(
      //     alertActions.error({
      //       message: err.toString(),
      //       screen: teacherConstants.SCREEN_LIST_STUDENT,
      //       isShowPopup: true
      //     })
      //   );
      // }
    // }
      const listHeader = [
        {
          label: "STT",
          width: 40
        },
        {
          label: "Họ và tên",
          width: 140
        },
        {
          label: "Ngày sinh",
          width: 80
        },
        {
          label: "Giới tính",
          width: 40
        },
        {
          label: "Username",
          width: 100
        },
        {
          label: "Password",
          width: 100
        },
      ];
      const listData = students?.data?.map((item, index) => [
        index + 1,
        item?.name,
        item?.birthday ? moment(item.birthday, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY') : '',
        item?.gender ? item.gender === 'male' ? "Nam" : "Nữ" : '',
        item?.username || '',
        item?.password || '',
      ]);
      exportExcel(
        listHeader,
        listData,
        `Danh sách lớp.xlsx`,
      );
  }

  const renderIconEdit = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24">
        <path 
          fill="none" 
          stroke="#00bbb5" 
          stroke-linecap="round" 
          stroke-linejoin="round" 
          stroke-width="1.5" 
          d="M3 21h18M12.222 5.828L15.05 3L20 7.95l-2.828 2.828m-4.95-4.95l-5.607 5.607a1 1 0 0 0-.293.707v4.536h4.536a1 1 0 0 0 .707-.293l5.607-5.607m-4.95-4.95l4.95 4.95"
        />
      </svg>
    )
  }

  const renderIconDelete = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 256 256">
        <path 
          fill="red" 
          d="M216 48h-40v-8a24 24 0 0 0-24-24h-48a24 24 0 0 0-24 24v8H40a8 8 0 0 0 0 16h8v144a16 16 0 0 0 16 16h128a16 16 0 0 0 16-16V64h8a8 8 0 0 0 0-16M96 40a8 8 0 0 1 8-8h48a8 8 0 0 1 8 8v8H96Zm96 168H64V64h128Zm-80-104v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0m48 0v64a8 8 0 0 1-16 0v-64a8 8 0 0 1 16 0"
        />
      </svg>
    )
  }

  const renderIconDetail = () =>{
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24">
        <path 
          fill="#707070" 
          d="M15 11h7v2h-7zm1 4h6v2h-6zm-2-8h8v2h-8zM4 19h10v-1c0-2.757-2.243-5-5-5H7c-2.757 0-5 2.243-5 5v1zm4-7c1.995 0 3.5-1.505 3.5-3.5S9.995 5 8 5S4.5 6.505 4.5 8.5S6.005 12 8 12"
        />
      </svg>
    )
  }

  const renderIconLicense = () => {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24">
        <path 
          fill="#666" 
          d="M12 12q-.846 0-1.423-.577T10 10t.577-1.423T12 8t1.423.577T14 10t-.577 1.423T12 12m-5 9.462v-6.59q-.95-.935-1.475-2.193T5 10q0-2.931 2.034-4.966Q9.067 3 11.997 3t4.967 2.034T19 10q0 1.421-.525 2.679T17 14.87v6.59l-5-1.5zM12 16q2.5 0 4.25-1.75T18 10t-1.75-4.25T12 4T7.75 5.75T6 10t1.75 4.25T12 16m-4 4.044l4-1.121l4 1.121v-4.33q-.836.615-1.863.95q-1.026.336-2.141.336t-2.137-.335T8 15.714zm4-2.165"
        />
      </svg>
    )
  }

  return (
    <Fragment>
      {alert.message &&
        alert.screen === teacherConstants.SCREEN_LIST_STUDENT && (
          <Alert alert={alert} />
        )}
      {showPopup && (
        <PopUpRemoveStudentInClass
          onClickYes={() =>
            class_detail.type == "offline"
              ? removeStudentOffline(dataStudent.member_id)
              : removeStudent(dataStudent.id)
          }
          onClickNo={() => setShowPopup(false)}
          message={dataStudent.name}
        />
      )}
      <div className="class_student_list">
        <div style={{display:'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16}}>
          <div style={{display: 'flex', gap: 16}}>
            <h2 className="bold sunE-title-medium" style={{padding: 0}}>Danh sách lớp</h2>
            {authentication?.role === 'teacher' && <a 
              href={ "/" + userConstants.ROLE_TEACHER + `/class/view/${id}/student/active-license`} 
              className="bold hover-primary" 
              style={{
                color: 'orange', 
                textDecoration: 'underline', 
                cursor: 'pointer'
              }}
            >
              Kích hoạt gói
            </a>}
          </div>
          {authentication?.role === 'teacher' && <button className={"btn-bg-blue btn-default"} style={{width:'unset', display: 'flex', alignItems:'center', gap: 8, lineHeight: 'unset'}} onClick={handleExportListStudent}>
            <img src="/assets/images/export_excel.png"  style={{width: 24, height: 24, objectFit: 'cover'}}/>
            Xuất file
          </button>}
        </div>
        <div className="custom-list-student-2">
          <div
            className="class_student_list_box scrollbar-custom flex-column"
            // ref={refsScroll}
            onScroll={(e) => handleScroll(e)}
          >
            <div className="flex-1">
              {students.data.map((data, i) => {
                return (
                  <div
                    className="list-student"
                    key={data?.id + "_" + i?.toString()}
                    style={{borderBottom: i < students?.data?.length - 1 ? '1px solid #666' : 'unset', overflow: 'unset'}}
                  >
                    <div className="item-student flex-m align-item-center">
                      {/* <span className="stt">{i + 1}</span> */}
                      <div className="item-student-img" style={{alignSelf: 'flex-start'}}>
                        <img
                          src={configConstants.BASE_URL + (data?.avatar ? data.avatar : `assets/img_base/student_${data?.gender ? data.gender : 'male'}_dfa.jpg`)}
                          alt="avatar"
                        />
                      </div>
                      <div className="flex-1 item-student-name">
                        <p className="one-line" style={{fontWeight: 700}}>
                          {data?.name}
                          {data?.nick_name && <span style={{fontSize: 16}}>{`( ${data.nick_name} )`}</span>}
                        </p>
                        {authentication?.role === 'student' && <>
                          {(data?.birthday || data?.gender) && <div style={{display: 'flex'}}>
                            {data?.birthday && <p className="one-line" style={{width: '50%'}}>
                                {'Ngày sinh: ' + moment(data.birthday, 'YYYY-MM-DD hh:mm:ss').format('DD-MM-YYYY')}
                            </p>
                            }
                            {data?.gender && <p className="one-line" style={{width: '50%'}}>
                                {'Giới tính: ' + (data.gender === 'male' ? 'Nam' : 'Nữ')}
                            </p>}
                          </div>}
                        </>}
                        {/* {(data?.email || data?.phone) && <div style={{display: 'flex'}}>
                          {data?.email && <p className="one-line" style={{width: '50%'}}>
                              {'Email: ' + data.email}
                          </p>}
                          {data?.phone && <p className="one-line" style={{width: '50%'}}>
                              {'Số điện thoại: ' + data.phone}
                          </p>}
                        </div>} */}
                        {authentication?.role === 'teacher' && <>
                          {(data?.license_type || data?.license_duration) && <div style={{display: 'flex'}}>
                            {data?.license_type && <p className="one-line" style={{width: '50%'}}>
                                {'Loại license: ' + data.license_type}
                            </p>}
                            {data?.license_duration && data?.license_duration >= 0 && <p className="one-line" style={{width: '50%'}}>
                                {'Thời hạn license: ' + data.license_duration + ' ngày'}
                            </p>}
                          </div>}
                          {(data?.username || data?.password) && <div style={{display: 'flex'}}>
                            {data?.username && <p className="one-line" style={{width: '50%'}}>
                                {'Tên đăng nhập: ' + data.username}
                            </p>}
                            {data?.password && <p className="one-line" style={{width: '50%'}}>
                                {'Mật khẩu: ' + data.password}
                            </p>}
                          </div>}
                        </>}
                      </div>
                      {authentication.role === userConstants.ROLE_TEACHER && (
                        <SubMenu 
                          style={{alignSelf: 'flex-start'}} 
                          renderMenu={
                            <div style={{padding: '8px 16px', display: 'flex', flexDirection: 'column',gap: 8}}>
                              <div 
                                style={{display: 'flex', gap: 12, alignItems: 'center', cursor: 'pointer'}} 
                                onClick={() => {
                                  setIsViewDetail(true)
                                  setStudentEditing(data);
                                  setIsOpenModalEdit(true);
                                }}
                              >
                                <div
                                  className="btn-remove-student"
                                >
                                  {renderIconDetail()}
                                </div>

                                <p style={{textWrap: 'nowrap'}}>Chi tiết</p>
                              </div>
                              <div 
                                  onClick={() => {
                                    if (data?.username === '*******' && data?.password === '******') {
                                      dispatch(
                                        alertActions.error({
                                          message: "Bạn không có quyền chỉnh sửa thông tin học sinh này",
                                          screen: teacherConstants.SCREEN_LIST_STUDENT,
                                          isShowPopup: true
                                        })
                                      );
                                      return;
                                    }
                                    setStudentEditing(data);
                                    setIsOpenModalEdit(true);
                                  }} 
                                  style={{display: 'flex', gap: 12, alignItems: 'center', cursor: 'pointer'}}
                              >
                                <div
                                  className="btn-remove-student"
                                >
                                  {renderIconEdit()}
                                </div>
                                <p style={{textWrap: 'nowrap'}}>Chỉnh sửa</p>
                              </div>
                              <div 
                                style={{display: 'flex', gap: 12, alignItems: 'center', cursor: 'pointer'}} 
                                onClick={() =>
                                  showPopUpRemove({
                                    id: data.id,
                                    name: data.name,
                                    member_id: data.user_id,
                                  })
                                }
                              >
                                <div
                                  className="btn-remove-student"
                                >
                                  {renderIconDelete()}
                                </div>

                                <p style={{textWrap: 'nowrap'}}>Xóa</p>
                              </div>
                            </div>
                          }
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          {/* {
                        students.total_student > configConstants.DEFAULT_LIMIT_STUDENT &&
                        <div className="text-right">
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={configConstants.DEFAULT_LIMIT_STUDENT}
                                totalItemsCount={students.total_student ? students.total_student : 0}
                                pageRangeDisplayed={5}
                                onChange={handlePageChange.bind(this)}
                            />
                        </div>
                    } */}
        </div>
      </div>
      <PopupEditStudent 
        open={isOpenModalEdit} 
        data={studentEditing}
        onClose={() => {
          setIsOpenModalEdit(false);
          setDataStudent(null);
          setIsViewDetail(false);
        }}
        isDetail={isViewDetail}
      />
    </Fragment>
  );
}

export { ListStudent };
