import "./HeaderNews.style.scss";
import { headerNewsLogic } from "./HeaderNews.logic";
import { Link, useParams } from "react-router-dom";
import { TypeHeaderNewsItem } from "../../../_constants/headerNews";
import { useDispatch, useSelector } from "react-redux";
import { PopUpYesNo } from "../../Popup";
import { HashLink } from "react-router-hash-link";
import ButtonNews from "../../Button/ButtonNews";
import { history } from "../../../_helpers";
import { hasDomainStore } from "../../../_base/Validate";
import classNames from "classnames";

const HeaderNews = (props) => {
  let {
    wrapperRef,
    isVisibleTaiff,
    listHeader,
    visibleModal,
    typeIcon,
    isOpenModalNav,
    dataUser,
    dataSelectTariff,
    setVisibleModal,
    handleChangeModalHeader,
    handleLogout,
    handleOpenSubTariff,
    setWrapperRefFind,
    handleSelectOptionTariff,
    handleNavigateCurriculum,
  } = headerNewsLogic(props);
  let { type } = props;
  const authentication = useSelector((state) => state.authentication);
  const dataProfile = useSelector((state) => state.profile.user_info.data);

  const renderFooterItem = (item) => {
    return (
      <div
        key={item.id}
        className={`mg-vertical-0-5 option_item_mobile mg-bottom-0-5 animation_slidein_top  ${
          item.type == type && " font_news_bold"
        }`}
        onClick={() => handleSelectOptionTariff(item.type)}
      >
        {item.name}
      </div>
    );
  };

  // Render Data Select
  const renderDataSelect = (data, index) => (
    <div
      key={data?.id}
      className={`option_item_custom flex-center-column ${
        data.type == type && " font_news_bold"
      }`}
      onClick={() => handleSelectOptionTariff(data.type)}
    >
      {data.name}
    </div>
  );

  // Render Header Item
  const renderHeaderItem = (item, typeOS) => {
    return [TypeHeaderNewsItem.FEE, TypeHeaderNewsItem.TARIFF]?.includes(
      item.type
    ) && hasDomainStore() ? null : (
      <div
        className={`header_item header_item_mobile ${
          [TypeHeaderNewsItem.FEE, TypeHeaderNewsItem.TARIFF]?.includes(
            item.type
          ) && " header_item_tariff"
        }'`}
        key={item.id}
      >
        {![TypeHeaderNewsItem.FEE, TypeHeaderNewsItem.TARIFF]?.includes(
          item.type
        ) ? (
          <HashLink
            scroll={(ele) => ele.scrollIntoView({ behavior: "instant" })}
            to={`/${item?.type}`}
            className="link_header_mobile link_header_item pos_rel"
            ref={(e) =>
              [TypeHeaderNewsItem.FEE, TypeHeaderNewsItem.TARIFF]?.includes(
                item.type
              ) && setWrapperRefFind(e)
            }
          >
            <div className="flex-align header_item_text_container">
              <div
                className={`header_item_text pos_rel color_hover  ${
                  (type == item?.type ||
                    (type == undefined && item?.type == "")) &&
                  "border_after font_news_bold color-primary-green"
                }`}
              >
                {item?.name}
              </div>

              {item?.type == TypeHeaderNewsItem.MOCK_TEST && (
                <div className="item_bonus font_news">FREE</div>
              )}

              {[TypeHeaderNewsItem.FEE, TypeHeaderNewsItem.TARIFF]?.includes(
                item.type
              ) && (
                <div className="ico_drop_tariff flex-align">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="10"
                    viewBox="0 0 12 8"
                    fill="none"
                    style={{ transition: "all 0.3s" }}
                  >
                    <path
                      d="M1 1L6 7L11 1"
                      stroke={
                        type == item?.type ||
                        (type == undefined && item?.type == "")
                          ? "#00cc83"
                          : "#4D4D4D"
                      }
                      strokeLinecap="round"
                      strokeLinejoin="bevel"
                    />
                  </svg>
                </div>
              )}
            </div>
            {typeOS != "mobile" &&
              [TypeHeaderNewsItem.FEE, TypeHeaderNewsItem.TARIFF]?.includes(
                item.type
              ) && (
                <div
                  className="list_option_custom display_none_mobile box-shadow flex-align flex-column  pos_abs"
                  style={{
                    display: isVisibleTaiff ? "flex" : "none",
                  }}
                >
                  {dataSelectTariff?.map((data, index) =>
                    renderDataSelect(data, index)
                  )}
                </div>
              )}

            {typeOS == "mobile" &&
              [TypeHeaderNewsItem.FEE, TypeHeaderNewsItem.TARIFF]?.includes(
                item.type
              ) && (
                <div
                  className={`flex-column ${
                    isVisibleTaiff
                      ? " display_flex_mobile"
                      : " display_none_mobile"
                  }`}
                >
                  {dataSelectTariff.map((data) => renderFooterItem(data))}
                </div>
              )}
          </HashLink>
        ) : (
          <div
            className="link_header_mobile link_header_item pos_rel"
            onClick={
              [TypeHeaderNewsItem.FEE, TypeHeaderNewsItem.TARIFF]?.includes(
                item.type
              ) && typeOS == "mobile"
                ? handleOpenSubTariff
                : () => {}
            }
          >
            <div className="flex-align header_item_text_container">
              <div
                className={`header_item_text pos_rel color_hover  ${
                  (type == item?.type ||
                    (type == undefined && item?.type == "")) &&
                  "border_after font_news_bold color-primary-green"
                }`}
              >
                {item?.name}
              </div>

              {item?.type == TypeHeaderNewsItem.MOCK_TEST && (
                <div className="item_bonus font_news">FREE</div>
              )}

              {[TypeHeaderNewsItem.FEE, TypeHeaderNewsItem.TARIFF]?.includes(
                item.type
              ) && (
                <div className="ico_drop_tariff flex-align">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="10"
                    viewBox="0 0 12 8"
                    fill="none"
                    className={classNames({
                      rotate_180: isVisibleTaiff,
                    })}
                    style={{ transition: "all 0.3s" }}
                  >
                    <path
                      d="M1 1L6 7L11 1"
                      stroke={
                        type == item?.type ||
                        (type == undefined && item?.type == "")
                          ? "#00cc83"
                          : "#4D4D4D"
                      }
                      className={
                        type == item?.type ||
                        (type == undefined && item?.type == "")
                          ? " stroke_primary_green"
                          : ""
                      }
                      strokeLinecap="round"
                      strokeLinejoin="bevel"
                    />
                  </svg>
                </div>
              )}
            </div>
            {typeOS != "mobile" &&
              [TypeHeaderNewsItem.FEE, TypeHeaderNewsItem.TARIFF]?.includes(
                item.type
              ) && (
                <div
                  className="list_option_custom display_none_mobile box-shadow flex-align flex-column  pos_abs"
                  style={{
                    display: isVisibleTaiff ? "flex" : "none",
                  }}
                >
                  {dataSelectTariff?.map((data, index) =>
                    renderDataSelect(data, index)
                  )}
                </div>
              )}

            {typeOS == "mobile" &&
              [TypeHeaderNewsItem.FEE, TypeHeaderNewsItem.TARIFF]?.includes(
                item.type
              ) && (
                <div
                  className={`flex-column list_option_custom_mobile ${
                    isVisibleTaiff
                      ? " display_flex_mobile"
                      : " display_none_mobile"
                  }`}
                >
                  {dataSelectTariff.map((data) => renderFooterItem(data))}
                </div>
              )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div id="header" className="header_container">
      <div className="header_content">
        <div
          onClick={handleChangeModalHeader}
          className="flex-align hide display_flex_mobile pointer_cursor header_menu_icon_container"
        >
          <img
            src={`/assets/images/icon/ico_${typeIcon}.png`}
            alt="Icon Menu"
            className="contain_image header_menu_icon"
          />
        </div>

        <HashLink
          scroll={(ele) => ele.scrollIntoView({ behavior: "instant" })}
          className="flex-center logo_header"
          to="/"
        >
          <img
            alt="Logo"
            src="/assets/images/logo_home.png"
            className="contain_image img_logo"
          />
        </HashLink>

        <div id="header_list" className="header_list display_none_mobile">
          {listHeader.map((item) => renderHeaderItem(item, "pc"))}
        </div>
        {/* {authentication.isLogin ? (
          <div className="flex-align">
            <HashLink to={"/profile"}>
              <div className="flex-align pointer_cursor mg-top-0-5 mg-bottom-0-5">
                <div className=" name_user col-100 display_none_mobile ">
                  {dataUser?.fullname}
                </div>
                <img
                  // loading="lazy"
                  src={dataUser?.avatar}
                  alt="Avatar User"
                  className="img_avt_user cover_image"
                />
              </div>
            </HashLink>

            <div
              onClick={() => setVisibleModal(true)}
              className="flex-align pointer_cursor img_logout_container"
            >
              <img
                src="/assets/images/icon/ico_logout_green.png"
                alt="Icon Logout"
                className="contain_image img_logout"
              />
            </div>
          </div>
        ) :  */}
        {type == TypeHeaderNewsItem.LOGIN ? (
          <HashLink
            scroll={(ele) => ele.scrollIntoView({ behavior: "instant" })}
            to={`/${TypeHeaderNewsItem.LOGIN}`}
            className="pos_rel"
          >
            <div
              className={` header_login header_item_text display_none_mobile  ${
                type == TypeHeaderNewsItem.LOGIN &&
                "border_after  font_news_bold color-primary-green"
              }`}
            >
              ĐĂNG KÝ/ ĐĂNG NHẬP
            </div>
            <div
              className={`pd-top-17-5 header_login header_item_text hide display_flex_mobile flex-align-end ${
                type == TypeHeaderNewsItem.LOGIN && " font_news_bold"
              }`}
            >
              ĐĂNG NHẬP
            </div>{" "}
          </HashLink>
        ) : (
          <ButtonNews
            width="7rem"
            height="2.3rem"
            onClick={() =>
              handleNavigateCurriculum(
                type == TypeHeaderNewsItem.TEACHER
                  ? "teacher"
                  : type == TypeHeaderNewsItem.PARENT
                  ? "parent"
                  : "student"
              )
            }
            border={"none"}
            backgroundColor={"#EB5757"}
            // maxWidth={"40%"}
            // maxHeight={"3.5rem"}
            styleProps={{
              borderRadius: "2rem",
            }}
          >
            <div className="header_login header_item_text  color-white">
              {type == TypeHeaderNewsItem.TEACHER
                ? "VÀO DẠY"
                : type == TypeHeaderNewsItem.PARENT
                ? "TẢI APP"
                : "VÀO HỌC"}
            </div>
          </ButtonNews>

          /* <div
              className={` header_login header_item_text display_none_mobile  ${
                type == TypeHeaderNewsItem.LOGIN &&
                "  border_bottom_2_black hover_border_bottom font_news_bold"
              }`}
            >
              ĐĂNG KÝ/ ĐĂNG NHẬP
            </div>
            <div
              className={`pd-top-17-5 header_login header_item_text hide display_flex_mobile flex-align-end ${
                type == TypeHeaderNewsItem.LOGIN && " font_news_bold"
              }`}
            >
              ĐĂNG NHẬP
            </div> */
        )}
      </div>

      {visibleModal && (
        <PopUpYesNo
          onClickYes={() => handleLogout()}
          onClickNo={() => setVisibleModal(false)}
          labelNo={"Không"}
          message={["Bạn có muốn đăng xuất tài khoản không?"]}
        />
      )}

      {isOpenModalNav && (
        <div className="modal_header_container hide display_flex_mobile">
          <div className="modal_header box-shadow">
            {listHeader.map((item) => renderHeaderItem(item, "mobile"))}
          </div>
        </div>
      )}
    </div>
  );
};

export default HeaderNews;
